<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {computed} from "vue";

const props = defineProps({
	"label": {
		type: String,
		default: () => null,
	},
	"ariaLabel": {
		type: String,
		default: () => null,
	},
	"id": {
		type: String,
		default: (props) => {
			// Auto-generate ID if label is provided, but not ID
			if (props.label) return `checkbox-${Math.random().toString(36).slice(2)}`;
			return null;
		},
	},
	"name": {
		type: String,
		default: () => null,
	},
	// Boolean or a bitmask along with the `bit` property
	"modelValue": {
		type: [Boolean, Number],
		default: () => false,
	},
	"loading": {
		type: Boolean,
		default: () => false,
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	// Lets you use the checkbox with a bitmask. This is the bit we will compare with the modelValue
	"bit": {
		type: Number,
		default: () => null,
	},
});
const emit = defineEmits(["update:modelValue"]);

const isChecked = computed(()=>{
	if (props.bit) return !!(props.modelValue & props.bit);

	return props.modelValue;
});

function handleInput(e) {
	if (props.loading || props.disabled) {
		return e.preventDefault();
	}

	// Bitmask mode
	if (props.bit) {
		const newBitflag = isChecked.value
			? props.modelValue & ~props.bit
			: props.modelValue | props.bit;

		return emit("update:modelValue", newBitflag);
	}

	// Boolean mode
	return emit("update:modelValue", !props.modelValue);
}
</script>

<template>
	<div class="checkbox">
		<div class="flex gap-2 items-center">
			<input
				:id="id"
				:disabled="disabled"
				:value="isChecked"
				type="checkbox"
				:name="name"
				:aria-label="ariaLabel"
				:aria-labelledby="id"
				hidden
				@input="handleInput"
			>
			<slot name="checkbox">
				<button
					class="box"
					:class="{'enabled': isChecked, 'loading': loading, 'disabled': disabled}"
					:aria-label="ariaLabel"
					:aria-labelledby="id"
					@click="handleInput"
				>
					<transition>
						<DynamicIcon
							v-if="isChecked || loading"
							:icon="loading ? 'Loading' : 'Check'"
							size="24"
							:stroke="loading ? '3' : '4'"
							:class="{'animate-spin': loading}"
						/>
					</transition>
				</button>
			</slot>
			<label
				v-if="label"
				:class="{'cursor-progress': loading, 'cursor-not-allowed opacity-60': disabled}"
				class="font-semibold mb-2"
				:for="id"
			>
				{{ label }}
			</label>
		</div>
		<slot />
	</div>
</template>

<style scoped>
label {
	line-height: 0;
	padding: 0;
	margin: 0;
}

.checkbox {
	@apply flex flex-col gap-1;
}

.box.loading {
	@apply cursor-progress bg-sky-400/20 border-sky-400 text-sky-400 transition-all;
}

.box.disabled {
	@apply cursor-not-allowed opacity-70;
}

.box {
	@apply cursor-pointer overflow-hidden w-5 h-5 border border-neutral-700
	bg-neutral-700/50 rounded flex items-center justify-center transition-all
	shadow;
}

.box.enabled {
	@apply bg-sky-400 border-sky-400 text-slate-900 transition-all;
}

.v-enter-active,
.v-leave-active {
	transition: all 0.1s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
	transform: translateX(-100%) rotate(-90deg);
	transition: all 0.1s ease-out;
}
</style>
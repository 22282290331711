<template>
	<g>
		<path
			d="M13.5 6L10.5 18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14 18H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10 6H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconItalic"
};
</script>
<template>
	<g>
		<path
			d="M5.49763 12.0002H18.5027"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.49763 16.0022H18.5027"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.49729 7.99826H18.5024"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconBurgerMenu"
};
</script>
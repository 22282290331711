<template>
	<g>
		<path
			d="M20 12H4M12 4V20V4Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconPlus"
};
</script>
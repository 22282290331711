<template>
	<g>
		<path
			d="M11.655 12.3942L7.65332 8.39258"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.3936 4.65039L15.3952 8.65206"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M2.99609 21.0022H13.5865"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.89648 21.0032L4.44171 19.3705C4.71339 18.5548 5.47577 18.0039 6.3355 18.002H10.2471C11.1094 18.0009 11.8754 18.5524 12.1479 19.3705L12.6891 21.0032"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.89107 7.29654L6.69015 9.05227C6.28321 9.37774 6.03375 9.86081 6.00398 10.381C5.97421 10.9013 6.16693 11.4096 6.53408 11.7794L8.21078 13.4641C8.57492 13.8302 9.07774 14.0238 9.59333 13.9964C10.1089 13.9691 10.5885 13.7235 10.9119 13.3211L12.6886 11.1141C13.109 10.5915 13.5854 10.1164 14.1092 9.69754L16.3102 7.94181C16.7171 7.61635 16.9666 7.13328 16.9963 6.61304C17.0261 6.09281 16.8334 5.58444 16.4662 5.21468L14.7925 3.53097C14.4284 3.16493 13.9256 2.97133 13.41 2.99864C12.8944 3.02594 12.4148 3.27158 12.0914 3.67403L10.3127 5.88095C9.89218 6.40356 9.41544 6.87828 8.89107 7.29654Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.3774 9.48438L20.3038 14.4995C20.8678 14.9787 21.1187 15.7317 20.9548 16.4534C20.791 17.1751 20.2396 17.7459 19.524 17.9347C18.8083 18.1234 18.0471 17.8987 17.5487 17.3517L12.3125 11.5852"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconBan"
};
</script>
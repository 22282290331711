<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0.00220669 6.40777C0.0110724 5.6918 0.435752 5.05443 1.0757 4.73325L10.0834 0.212491C10.6588 -0.0763197 11.3381 -0.070422 11.9085 0.228337L20.4923 4.72463C21.1171 5.0519 21.5283 5.68226 21.5371 6.38752C21.6177 12.8122 19.6649 23.7525 11 23.7525C1.96602 23.7525 -0.0773818 12.8352 0.00220669 6.40777Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11 12C12.6572 12 14 10.6567 14 9C14 7.34326 12.6572 6 11 6C9.34277 6 8 7.34326 8 9C8 10.6567 9.34277 12 11 12ZM14.6953 15.4692C14.8965 15.9546 15 16.4746 15 17H11H7C7 16.7324 7.02637 16.4663 7.0791 16.2056C7.13086 15.9541 7.20605 15.7075 7.30469 15.4692C7.50586 14.9839 7.7998 14.543 8.17188 14.1714C8.54297 13.8003 8.98438 13.5054 9.46973 13.3047C9.9541 13.1035 10.4746 13 11 13C11.4287 13 11.8535 13.0688 12.2578 13.2036C12.3496 13.2339 12.4404 13.2676 12.5303 13.3047C13.0156 13.5054 13.457 13.8003 13.8281 14.1714C14.2002 14.543 14.4941 14.9839 14.6953 15.4692Z"
		/>
	</g>
</template>

<script>
export default {
	name: "IconDiscordRole"
}
</script>
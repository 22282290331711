<script>
export default {
	name: "IconPlay"
}
</script>

<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.514 6.14412L17.612 11.0031C18.259 11.3911 18.259 12.3301 17.612 12.7181L9.514 17.5771C8.848 17.9761 8 17.4961 8 16.7191V7.00112C8 6.22412 8.848 5.74412 9.514 6.14412Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.8399 14.475L21.7519 19.65C22.4689 20.924 21.5479 22.5 20.0859 22.5H14.2629C12.7999 22.5 11.8799 20.926 12.5969 19.65L15.5089 14.475C16.2379 13.175 18.1089 13.175 18.8399 14.475V14.475Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20 11.5V6.53296"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10 18.5H5C3.343 18.5 2 17.157 2 15.5V6.53296"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.602 11.764L3.085 8.331C2.418 7.979 2 7.287 2 6.533V6.5C2 5.395 2.895 4.5 4 4.5H17.967C19.09 4.5 20 5.41 20 6.533V6.553C20 7.295 19.589 7.977 18.932 8.323L12.398 11.765C11.523 12.224 10.477 12.224 9.602 11.764Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.1741 18.1219V16.3679"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.1731 20.057C17.1251 20.057 17.0861 20.096 17.0871 20.144C17.0871 20.192 17.1261 20.231 17.1741 20.231C17.2221 20.231 17.2601 20.192 17.2601 20.144C17.2601 20.096 17.2211 20.057 17.1731 20.057"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconEmailWarning"
};
</script>

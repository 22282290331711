<script setup>
import InspectFileView from "@/partials/inspect/case/inspectFile/InspectFileView.vue";
import {computed} from "vue";

const props = defineProps({
	"resourceId": {
		type: String,
		required: true,
	},
	"theCase": {
		type: Object,
		required: true,
	},
});
const file = computed(() => props.theCase.files.find(f => f.fileId === props.resourceId));
</script>

<template>
	<InspectFileView
			v-if="file"
			:case-sid="theCase.sid"
			:case-type="theCase.caseType"
			:file="file"
			:case-target-id="theCase.targetId"
			:creator-id="theCase.creatorId"
	/>
	<div v-else>
		<p>Could not find file {{ resourceId }}</p>
	</div>
</template>

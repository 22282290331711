<script setup>
import PillBadge from "@/components/badges/PillBadge.vue";
import LocalDate from "@/components/info/parts/LocalDate.vue";
import {inject} from "vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import DarkBadge from "@/components/badges/DarkBadge.vue";

defineProps({
	"fileId": {
		type: String,
		required: true,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
	"pills": {
		type: Object,
		default: () => ({}),
	},
	"createdAt": {
		type: [Number, String, Date],
		required: true,
	},
	"close": {
		type: Function,
		default: () => () => {}
	},
});
const personal = inject("isPersonal");
</script>

<template>
	<div class="flex items-center w-full justify-between">
		<h2>File #{{ fileId }}</h2>

		<div class="CaseActionButtons">
			<CloseButton label="Close panel" @click="close" />
		</div>
	</div>
	<p class="text-slate-500 text-sm pt-1">
		Uploaded
		<LocalDate :date="createdAt" />
	</p>
	<div class="flex gap-2 mt-2">
		<DarkBadge
			v-if="pills.public && !pills.archived"
			title="The file is public. Anyone with a Discord account (while logged into Archivian) can view the file using its direct link."
			label="Public"
			class="cursor-help"
			icon-class="text-cyan-400"
			icon="Globe"
		/>
		<DarkBadge
			v-if="!personal && !pills.visibleToTarget"
			title="The target user cannot see this file in their personal dashboard."
			label="Hidden"
			class="cursor-help"
			icon-class="fill-red-500"
			dot
		/>
		<DarkBadge
			v-if="!personal && pills.visibleToTarget"
			title="The target user can see this file in their personal dashboard."
			label="Visible"
			class="cursor-help"
			icon-class="fill-emerald-500"
			dot
		/>
		<PillBadge
			v-if="pills.public && !pills.archived"
			label="Public"
			color="gray"
			class="cursor-help"
			round="semi"
		/>
		<DarkBadge
			v-if="!personal && pills.archived"
			title="The file is archived. It is equivalent to being deleted, without the loss of the data."
			label="Archived"
			class="cursor-help"
			icon-class="fill-gray-500"
			dot
		/>
	</div>
</template>
<script setup>
import Nodes from "@/script/Nodes";
import {Account} from "@/storage/AccountCache";
import ToolTip from "@/components/badges/ToolTip.vue";
import CheckBox from "@/components/form/Checkbox/CheckBox.vue";
import PermsCond from "@/components/PermsCond.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

defineEmits(["update:content", "update:visibility"]);
defineProps({
	"caseType": {
		type: String,
		default: () => null,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
	"content": {
		type: String,
		default: () => null,
	},
	"visibility": {
		type: Boolean,
		default: () => null,
	},
});
const cases = CaseAppealBrowser();
const account = Account();
</script>

<template>
	<!-- Content of entry -->
	<div id="content">
		<label class="font-medium" for="content">
			Content
		</label>
		<textarea
			id="content"
			placeholder="Content of the entry here"
			name="content"
			rows="5"
			style="min-height: 5em;"
			:disabled="cases.isLoading('entryAdd', cases.currentId)"
			class="input-focus drop-shadow-sm w-full max-h-96 border-details bg-neutral-700/50 rounded-md px-2 py-1"
			@input="$emit('update:content', $event.target.value)"
		/>
	</div>

	<!-- Visibility of new entry -->
	<PermsCond
		v-if="caseType && creatorId"
		:needs="Nodes.Cases.ENTRY.VISIBILITY(caseType, creatorId===account.id, true)"
	>
		<div id="entry-visibility">
			<CheckBox
				label="Show to User"
				:disabled="cases.isLoading('entryAdd', cases.currentId)"
				:model-value="visibility"
				@update:model-value="$emit('update:visibility', $event)"
			/>
			<div class="flex items-center gap-2">
				<p>Whether the user can see this entry when created.</p>
				<ToolTip
					description="User can see date created, edited, and text content. Depending on global settings, they can see the author too (you)."
				/>
			</div>
		</div>
	</PermsCond>
</template>
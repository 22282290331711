<script setup>
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import SummaryContainer from "@/components/lists/summary-container/SummaryContainer.vue";
import {computed, inject} from "vue";
import {useRoute} from "vue-router";

const props = defineProps({
	"users": {
		type: Map,
		default: () => new Map(),
	},
	"linkedCases": {
		type: Array,
		default: () => [],
	},
	"pills": {
		type: Object,
		default: () => ({
			"archived": false,
		}),
	},
});
const route = useRoute();
const personal = inject("isPersonal");
const linkedCaseItems = computed(() => {
	return props.linkedCases.map(e => ({
		label: e.label,
		description: `Case #${e.caseSid}`,
		chevron: true,
		to: {
			name: personal ? "my-cases" : "guild-cases",
			params: {
				... route.params,
				resource: "linked-cases",
				resourceId: e.caseSid
			},
		},
	}));
});
</script>

<template>
	<section>
		<div id="linked-cases">
			<div class="flex justify-between items-baseline">
				<!-- Label -->
				<p class="label">
					Linked Cases
				</p>

				<!-- Add button -->
				<PermsCond v-if="!pills.archived" :needs="Nodes.Cases.LINKED.CASE.CREATE()">
					<RouterLink
						:to="{
							name: 'guild-cases',
							params: { resource: 'linked-cases', resourceId: 'new', caseSid: route.params.caseSid, }
						}"
					>
						<span class="uppercase text-xs font-bold text-sky-400">Add Case</span>
					</RouterLink>
				</PermsCond>
			</div>
			<SummaryContainer
				v-if="linkedCases.length"
				:items="linkedCaseItems"
			/>
			<p v-else class="italic text-slate-500">
				No linked cases.
			</p>
		</div>
	</section>
</template>
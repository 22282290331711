<template>
	<span class="flex">
		<span class="animate-bounce">.</span>
		<span class="animate-bounce a">.</span>
		<span class="animate-bounce b">.</span>
	</span>
</template>

<script>
export default {
	name: "LoadingEllipsis"
};
</script>

<style scoped>
.a {
	animation-delay: .1s;
}

.b {
	animation-delay: .2s;
}
</style>
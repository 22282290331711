<script setup>
import DarkBadge from "@/components/badges/DarkBadge.vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import userCache from "@/storage/UserCache";
import IdBadge from "@/components/badges/IdBadge.vue";
import HorizontalDivider from "@/components/misc/HorizontalDivider.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import LocalDate from "@/components/info/parts/LocalDate.vue";
import {capitalize, computed, onMounted, ref, watch} from "vue";
import {snowflakeToDate} from "archivian-utils";
import ObviousTabBar from "@/components/tab/ObviousTabBar.vue";
import IndexedDBStore from "@/storage/IndexedDB";
import FancyLoader from "@/components/misc/FancyLoader.vue";
import backend from "@/api/backend";
import RecentCaseItem from "@/partials/inspect/guild-member/components/RecentCaseItem.vue";
import AppealCardListItem from "@/partials/inspect/AppealCardListItem.vue";
import AutoModViolationsView from "@/views/guild/auto-mod/violations/AutoModViolationsView.vue";
import DiscordMessage from "@/views/guild/channels/messages/collections/components/DiscordMessage.vue";
import MentionRole from "@/components/misc/MentionRole.vue";
import { Permissions } from "@archivian/constants";
import {Account} from "@/storage/AccountCache";

const emit = defineEmits(["close"]);
const props = defineProps({
	"guildId": {
		type: String,
		default: () => "11111111111111111111",
	},
	"userId": {
		type: String,
		default: () => "11111111111111111111",
	},
});

const account = Account();
const tabs = [
	{ id: "cases", label: "Cases", permission: Permissions.Cases.CASE.READ_ANY() },
	{ id: "appeals", label: "Appeals", permission: Permissions.CaseAppeals.APPEAL.READ("*", "*", "*") },
	{ id: "violations", label: "Violations", permission: Permissions.AutoMod.VIOLATIONS.READ() },

	// TODO The Message Reading is not really message collection. It should be its own permission (backend too)
	{ id: "msg", label: "Messages", permission: Permissions.Messages.Collections.READ() },
	{ id: "msgDeleted", label: "Deletions", permission: Permissions.Messages.Collections.READ() },
].filter(t => account.can(t.permission));
const currentTab = ref(tabs[0].id || null);
const stats = ref({
	cases: 0,
	appeals: 0,
	violations: 0,
	messagesTracked: 0,
});
const recent = ref({
	cases: [],
	appeals: [],
	violations: [],
	messages: [],
	deletedMessages: [],
});
const activeCase = ref(null);
const member = ref(null);
const trustScore = ref({
	user: 0,
	globalAverage: 80,
});
const loading = ref(true);
const idbStore = IndexedDBStore();
const users = userCache();
const accountCreated = computed(()=>{
	return snowflakeToDate(props.userId).date;
});
const trustScoreColor = computed(()=>{
	const avg = trustScore.value.globalAverage / 3;

	if (trustScore.value.user < avg) return "fill-red-500";
	if (trustScore.value.user < avg * 2) return "fill-yellow-500";
	if (trustScore.value.user < trustScore.value.globalAverage) return "fill-lime-500";
	return "fill-green-500";
});
const messages = computed(()=>{
	return recent.value.messages.map(m => {
		m._id = m.id || m._id;
		m.embedsJSON = m.embeds?.length ? JSON.stringify(m.embeds) : null;
		m.hasAttachments = !!m.attachments?.length;
		return m;
	})
});
const messagesDeleted = computed(()=>{
	return recent.value.deletedMessages.map(m => {
		m._id = m.id || m._id;
		m.embedsJSON = m.embeds?.length ? JSON.stringify(m.embeds) : null;
		m.hasAttachments = !!m.attachments?.length;
		return m;
	})
});
const tabTitle = computed(()=>{
	return {
		"cases": "Recent Cases",
		"appeals": "Recent Appeals",
		"violations": "Recent Auto-Mod Violations",
		"msg": "Recent Messages",
		"msgDeleted": "Recent Deleted Messages",
	}[currentTab.value];
});
async function load(newId, oldId) {
	loading.value = true;
	const removeUserInfo = (role) => {
		delete role.member_sid;
		delete role.role_id;
		delete role.created_at;
		delete role.created_At;
		return role;
	}

	// Fetch cache first to render page faster
	const cached = idbStore.getPageCache(`inspectMember:${newId}`);
	if (cached) {
		trustScore.value = cached.trustScore;
		activeCase.value = cached.activeCase;
		recent.value = cached.recent;
		stats.value = cached.stats;
		member.value = cached.member;

		loading.value = false;
	}

	// Continue loading in the background and overwrite data
	if (newId !== oldId) {
		const r = await backend(`/public/guilds/${props.guildId}/misc/member-overview/${newId}`);
		if (r.status !== 200) return loading.value = false;
		await Promise.all([
			idbStore.setPageCache(`inspectMember:${newId}`, props.guildId, r.body),
			idbStore.setUser(r.body.user),
			...(r.body?.member?.roles || []).map(r => idbStore.setRole(props.guildId, removeUserInfo(r))),
			...(r.body.channels || []).map(ch => idbStore.setChannel(props.guildId, ch)),
		]);
		trustScore.value = r.body.trustScore;
		activeCase.value = r.body.activeCase;
		recent.value = r.body.recent;
		stats.value = r.body.stats;
		member.value = r.body.member;
	}

	if (!idbStore.getUser(newId)) {
		await idbStore.fetchUsers(props.guildId, [newId]);
	}

	loading.value = false;
}
onMounted(()=>{
	return load(props.userId, null);
});
watch(() => props.userId, (newId, oldId) => {
	return load(newId, oldId);
});
</script>

<template>
	<div v-if="loading" class="p-16">
		<FancyLoader
			label="Fetching user overview..."
			class="mx-auto"
		/>
		<CloseButton
			class="absolute right-4 top-4"
			label="Close dialog"
			@click="emit('close')"
		/>
	</div>
	<div v-else-if="!idbStore.users[userId]" class="p-16">
		<p class="italic text-neutral-400 text-center">
			Could not find user by ID <IdBadge :label="userId" />
		</p>
		<CloseButton
			class="absolute right-4 top-4"
			label="Close dialog"
			@click="emit('close')"
		/>
	</div>
	<div v-else class="flex h-full">
		<!-- Profile column -->
		<div class="h-full flex flex-col gap-4 overflow-y-auto justify-between w-96 border-r border-neutral-700">
			<!-- Upper half -->
			<div class="relative pt-4 pb-1 flex flex-col gap-2 justify-center items-center">
				<DarkBadge
					:label="Math.round(trustScore.user).toString()"
					dot
					:icon-class="trustScoreColor"
					class="absolute top-4 left-4 cursor-help"
					title="User trust score. Higher is better."
				/>

				<UserAvatar
					:user-id="userId"
					:avatar="users.avatarHashOf(userId)"
					:aria-label="`Avatar of user ${userId}`"
					:size="128"
				/>

				<p class="font-bold text-center">
					{{ users.username(userId) }}
				</p>

				<p class="text-neutral-500 text-center text-sm">
					{{ users.username(userId, true, false) }}
				</p>

				<IdBadge :label="userId" />

				<HorizontalDivider margin class="w-full" />

				<div class="px-4 w-full">
					<p class="text-neutral-500 text-center uppercase text-xs tracking-widest px-4 pb-2 font-bold">
						Summary
					</p>
					<table class="w-full gap-4 text-sm text-neutral-300">
						<tr>
							<td>
								Cases
							</td>
							<td class="text-right">
								{{ stats.cases }}
							</td>
						</tr>
						<tr>
							<td>
								Appeals
							</td>
							<td class="text-right">
								{{ stats.appeals }}
							</td>
						</tr>
						<tr>
							<td>
								Violations
							</td>
							<td class="text-right">
								{{ stats.violations }}
							</td>
						</tr>
						<tr>
							<td>
								Messages Tracked
							</td>
							<td class="text-right">
								{{ stats.messagesTracked }}
							</td>
						</tr>
						<tr v-if="activeCase">
							<td colspan="2">
								<HorizontalDivider margin />
							</td>
						</tr>
						<tr v-if="activeCase">
							<td>
								Active Case
							</td>
							<td class="text-right">
								<RouterLink
									:to="{name: 'guild-cases', params: {caseSid: activeCase.sid}}"
									@click="emit('close')"
								>
									<DarkBadge
										:label="`#${activeCase.sid}`"
										:icon="capitalize(activeCase.caseType)"
										:icon-class="`text-${activeCase.caseType}-normal`"
									/>
								</RouterLink>
							</td>
						</tr>
					</table>
				</div>

				<template v-if="member?.roles">
					<HorizontalDivider margin class="w-full" />

					<div>
						<p class="text-neutral-500 text-center uppercase text-xs tracking-widest px-4 pb-2 font-bold">
							Server Roles
						</p>

						<p v-if="!member.roles?.length" class="italic text-neutral-400 py-2 text-center">
							No roles.
						</p>
						<div class="flex flex-wrap gap-1 justify-center">
							<MentionRole
								v-for="role in member.roles"
								:key="role.id"
								:guild-id="guildId"
								:role-id="role.id"
							/>
						</div>
					</div>
				</template>

				<HorizontalDivider margin class="w-full" />

				<p class="text-xs text-center text-neutral-500 px-4 pb-4">
					<DynamicIcon
						icon="Discord"
						size="16"
					/>
					Joined Discord <LocalDate :date="accountCreated" />
				</p>

				<template v-if="member?.joined_at">
					<HorizontalDivider class="w-full pb-4" />

					<p class="text-xs text-center text-neutral-500 px-4 pb-4">
						<DynamicIcon
							icon="Discord"
							size="16"
						/>
						Joined the server <LocalDate :date="member.joined_at" />
					</p>
				</template>

				<template v-if="member?.left_at">
					<HorizontalDivider class="w-full pb-4" />

					<p class="text-xs text-center text-neutral-500 px-4 pb-4">
						<DynamicIcon
							icon="Discord"
							size="16"
						/>
						Left the server <LocalDate :date="member.left_at" />
					</p>
				</template>
			</div>
		</div>

		<!-- Content container -->
		<div v-if="tabs.length" class="flex flex-col h-full w-full p-4">
			<h2 class="mb-2">
				{{ tabTitle }}
			</h2>
			<ObviousTabBar
				v-model="currentTab"
				:tabs="tabs"
			/>

			<div class="h-full flex flex-col gap-2 overflow-y-auto py-4">
				<template v-if="currentTab === 'cases'">
					<RouterLink
						v-for="theCase in recent.cases"
						:key="theCase._id"
						:to="{name: 'guild-cases', params: {caseSid: theCase.sid}}"
						class="mx-1"
						@click="emit('close')"
					>
						<RecentCaseItem :the-case="theCase" />
					</RouterLink>
					<p v-if="!recent.cases.length" class="italic text-neutral-400 text-center p-4">
						No cases on this user (that you can see).
					</p>
				</template>
				<template v-else-if="currentTab === 'appeals'">
					<RouterLink
						v-for="theAppeal in recent.appeals"
						:key="theAppeal._id"
						:to="{name: 'guild-appeals', params: {appealId: theAppeal._id}}"
						class="mx-1"
						@click="emit('close')"
					>
						<AppealCardListItem
							:appeal="theAppeal"
							:is-personal="false"
						/>
					</RouterLink>
					<p v-if="!recent.appeals.length" class="italic text-neutral-400 text-center p-4">
						No appeals from this user (that you can see).
					</p>
				</template>
				<template v-else-if="currentTab === 'violations'">
					<AutoModViolationsView
						:violations="recent.violations"
						:loading="false"
					/>
				</template>
				<template v-else-if="currentTab === 'msg'">
					<template
						v-for="(message, i) in messages.toReversed()"
						:key="message._id"
					>
						<DiscordMessage
							:msg="message"
							:member-inspect="false"
							class="pr-2"
						/>
						<HorizontalDivider v-if="messages[i+1]" margin />
					</template>
					<p v-if="!messages.length" class="italic text-neutral-400 text-center p-4">
						No messages sent / tracked / insufficient permissions.
					</p>
				</template>

				<template v-else-if="currentTab === 'msgDeleted'">
					<template
						v-for="(message, i) in messagesDeleted.toReversed()"
						:key="message._id"
					>
						<DiscordMessage
							:msg="message"
							:member-inspect="false"
							class="pr-2"
						/>
						<HorizontalDivider v-if="messagesDeleted[i+1]" margin />
					</template>
					<p v-if="!messagesDeleted.length" class="italic text-neutral-400 text-center p-4">
						No messages deleted / tracked / insufficient permissions.
					</p>
				</template>
			</div>
		</div>
		<div v-else class="flex flex-col h-full w-full p-4">
			<p class="italic text-neutral-400 text-center p-4">
				You do not have permission to view any categories of information.
			</p>
		</div>

		<!-- Close button -->
		<CloseButton
			label="Close panel"
			class="absolute right-4 top-4"
			@click="emit('close')"
		/>
	</div>
</template>

<style scoped>
.router-link-active .table-card {
	@apply outline outline-sky-600;
}
</style>
<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {ref} from "vue";
import TextInput from "@/components/form/Text/TextInput.vue";
import JoyrideBox from "@/components/joyrides/JoyrideBox.vue";
import {Account} from "@/storage/AccountCache";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import IdBadge from "@/components/badges/IdBadge.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import UserHighlightPanel from "@/components/panels/UserHighlightPanel.vue";
import UserCache from "@/storage/UserCache";

const props = defineProps({
	"close": {
		type: Function,
		default: () => () => {
		},
	},
	"handleSave": {
		type: Function,
		default: () => () => {
		},
	},
	"handleDelete": {
		type: Function,
		default: () => () => {
		},
	},
	"label": {
		type: String,
		required: true,
	},
	"user": {
		type: Object,
		required: true,
	},
	"lastUserId": {
		type: String,
		default: () => null,
	},
});

const localLabel = ref(props.label);
const account = Account();
const cases = CaseAppealBrowser();
const users = UserCache();
const deleteState = ref({
	timer: null,
	confirmed: false,
});
const cancel = () => {
	if (cases.editing.linkedUser) {
		localLabel.value = props.label;
		cases.editing.linkedUser = false;
	} else props.close();
};
const handleSave = () => {
	if (!props.user.id) return;
	if (props.label === localLabel.value) return;
	return props.handleSave({
		label: localLabel.value,
		userId: props.user.id,
	});
};
const deleteLink = () => {
	if (!deleteState.value.confirmed) {
		deleteState.value.confirmed = true;
		return deleteState.value.timer = setTimeout(() => {
			deleteState.value.confirmed = false;
		}, 3500);
	}

	clearTimeout(deleteState.value.timer);
	return props.handleDelete({
		userId: props.user.id,
		reason: "Unknown reason",
	});
};
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<div class="flex items-center w-full justify-between">
				<h2>Linked User</h2>

				<div class="CaseActionButtons">
					<CloseButton label="Close panel" @click="close" />
				</div>
			</div>
		</template>

		<template #main>
			<JoyrideBox
				joyride-id="case-linked-users"
				description="User linking lets you store users that are somehow involved or related to the case. For example the victim of a scammer, or the instigator of an argument."
			/>

			<UserHighlightPanel
				v-if="lastUserId"
				:username="users.username(lastUserId)||'Unknown Staff'"
				:title="['Last Editor',users.titleOf(lastUserId)].filter(Boolean).join(' • ')"
				:avatar-url="users.avatarOf(lastUserId, 128)"
				:user-id="lastUserId"
			/>

			<div>
				<label for="linkLabel">Label</label>
				<p v-if="!cases.editing.linkedUser">
					{{ label }}
				</p>
				<TextInput
					v-else
					id="linkLabel"
					v-model="localLabel"
					placeholder="E.g. Victim or Instigator"
					maxlength="32"
				/>
			</div>

			<div>
				<label for="selectUser">Linked user</label>
				<div class="flex items-center gap-2 border-details shadow bg-neutral-800/50 p-4 rounded-md">
					<UserAvatar
						:user-id="user.id"
						:avatar="user.avatar"
					/>
					<p>{{ user.username }}</p>
					<IdBadge :label="user.id" />
				</div>
			</div>
		</template>

		<template #footer>
			<div class="flex gap-2 w-full justify-end">
				<BasicButton
					:label="cases.editing.linkedUser ? 'Cancel' : 'Close'"
					type="link"
					@click="cancel"
				/>
				<PermsCond :needs="Nodes.Cases.LINKED.USER.RENAME(lastUserId === account.id)">
					<BasicButton
						v-if="!cases.editing.linkedUser"
						label="Edit"
						:disabled="cases.isLoading('renameLinked', user?.id)"
						@click="cases.editing.linkedUser = true"
					/>
					<BasicButton
						v-else
						label="Save"
						:disabled="!localLabel || cases.isLoading('renameLinked', user?.id)"
						@click="handleSave"
					/>
				</PermsCond>
				<PermsCond :needs="Nodes.Cases.LINKED.USER.DELETE(lastUserId === account.id)">
					<BasicButton
						v-if="!cases.editing.linkedUser"
						:label="deleteState.confirmed ? 'Are you sure?' : 'Delete'"
						:disabled="cases.isLoading('deleteLinked', user?.id)"
						type="danger"
						@click="deleteLink"
					/>
				</PermsCond>
			</div>
		</template>
	</InspectResourceLayout>
</template>
<script>
export default {
	name: "IconGuide",
};
</script>
<template>
	<g>
		<path
			d="M6.499 5.245C6.36106 5.24555 6.24963 5.35773 6.25 5.49567C6.25037 5.63361 6.36239 5.74518 6.50033 5.745C6.63827 5.74482 6.75 5.63294 6.75 5.495C6.75035 5.42842 6.72399 5.36447 6.67681 5.31748C6.62964 5.27049 6.56558 5.24438 6.499 5.245"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3 5.5V5.5C3 3.567 4.567 2 6.5 2V2C8.433 2 10 3.567 10 5.5V5.5C10 7.13851 8.212 8.88881 7.192 9.75435C6.78918 10.0819 6.21182 10.0819 5.809 9.75435C4.788 8.88881 3 7.13851 3 5.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16 17.2C16 16.8397 16.1938 16.5073 16.5073 16.3298C16.8208 16.1523 17.2056 16.1571 17.5145 16.3425L20.5145 18.1425C20.8157 18.3232 21 18.6487 21 19C21 19.3513 20.8157 19.6768 20.5145 19.8575L17.5145 21.6575C17.2056 21.8429 16.8208 21.8477 16.5073 21.6702C16.1938 21.4927 16 21.1603 16 20.8V17.2Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.234 18.995H8.62697C7.07669 18.8856 5.90426 17.5472 5.99997 15.996V15.993C5.90426 14.4418 7.07669 13.1034 8.62697 12.994H15.372C16.9223 12.8846 18.0947 11.5462 17.999 9.995C18.0963 8.44231 16.922 7.10225 15.37 6.995L13 7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>
<template>
	<dialog
		open
		aria-label="Server Switcher menu"
		:class="{
			'cursor-loading': isLoading,
			'min-w-sm': account.staffedGuilds.size > 4,
		}"
		class="p-0 bottom-0 bg-neutral-800/50 left-16 backdrop-blur-2xl border border-neutral-700 border-t-neutral-600 text-slate-200 w-max shadow z-30"
	>
		<DialogOptionList>
			<!-- Personal account -->
			<div id="nav-to-account" class="p-2 flex items-center gap-2">
				<RouterLink
					:to="{name: 'me'}"
					class="grow"
					aria-label="Go to personal dashboard"
					@click="gotoPersonalAccount"
				>
					<div class="p-2 rounded hover:bg-neutral-900/80 hover:border-sky-400 flex items-center gap-2">
						<UserAvatar
							:user-id="account.id"
							:avatar="account.avatarHash"
							:size="24"
							aria-label="Your Discord avatar"
							class="shrink-0"
						/>
						<p class="truncate">
							Personal Dashboard
						</p>
					</div>
				</RouterLink>

				<TipWrapper label="Log out">
					<button
						title="Log out"
						aria-label="Log out"
						class="p-2 leading-none rounded text-slate-500 hover:bg-red-600/40 hover:text-red-100"
						@click="logout"
					>
						<DynamicIcon
							icon="Power"
							size="24"
							class="p-0.5 bg-neutral-900 rounded-full"
						/>
					</button>
				</TipWrapper>
			</div>

			<!-- Personal account separator -->
			<hr class="border-neutral-600">

			<!-- Search bar -->
			<div v-if="account.staffedGuilds.size > 4" class="flex justify-between items-center p-4">
				<ButtonLike
					v-if="!search.open"
					desc="Search button"
					class="font-medium"
					@click.stop="search.open = true"
				>
					{{ label }}
				</ButtonLike>
				<button v-if="!search.open" @click.stop="search.open = true">
					<DynamicIcon
						icon="Search"
						class="cursor-pointer"
					/>
				</button>

				<input
					v-if="search.open"
					id="server-search"
					v-model="search.value"
					aria-label="Search for guild by name or ID"
					autofocus
					placeholder="Server name or ID"
					type="search"
					name="server-search"
					spellcheck="false"
					class="w-full bg-neutral-900/50 border-neutral-700 border p-2 rounded focus-visible:outline-sky-400 focus-visible:outline"
					@input="filterGuild"
				>

				<CloseButton
					v-if="search.open"
					class=" ml-1"
					label="Reset and close search"
					@click.stop="search.open = false"
				/>
			</div>

			<!-- Search separator -->
			<hr v-if="account.staffedGuilds.size > 4" class="border-neutral-600 mx-4">

			<!-- Server List -->
			<div class="overflow-y-auto max-h-64 overflow-x-hidden">
				<div class="truncate p-2 max-w-sm">
					<ServerSwitcherServer
						v-for="guild in search.visible"
						:id="guild.id"
						:key="guild.id"
						:active="route.params?.guildId===guild.id"
						:name="guild.name || 'Unknown '+guild.id"
						:icon="guild.icon"
						@click="selectGuild(guild)"
					/>

					<p v-if="account.staffedGuilds.size && !search.visible && search.value" class="italic">
						No servers matches&hellip;
					</p>

					<div v-else-if="!account.staffedGuilds.size">
						<!--            <p class="text-center mb-2 cursor-default">Ready to get going?</p>-->
						<RouterLink
							:to="{name: 'add'}"
							aria-label="Set up new server"
							@click="emit('close')"
						>
							<div
								class="flex items-center justify-center gap-2 p-4 rounded bg-neutral-700 border border-neutral-600 hover:bg-neutral-700/80 transition-colors shadow"
							>
								<DynamicIcon icon="Plus" />
								<p>Set up new server</p>
							</div>
						</RouterLink>
					</div>
				</div>
			</div>
		</DialogOptionList>
	</dialog>
</template>

<script setup>
import DialogOptionList from "@/components/lists/dialog/DialogOptionList.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import ServerSwitcherServer from "@/partials/navigation/main-nav/ServerSwitcherServer.vue";
import {computed, ref, watch} from "vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import TipWrapper from "@/partials/navigation/main-nav/TipWrapper.vue";
import {Account} from "@/storage/AccountCache";
import {useRoute} from "vue-router";
import ButtonLike from "@/components/panels/ButtonLike.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";

const route = useRoute();
const account = Account();
const selectedGuildId = ref(route.params?.guildId ?? null);
const isLoading = computed(() => {
	return route.params?.guildId !== selectedGuildId.value;
});

function selectGuild(guild) {
	selectedGuildId.value = guild.id;
	emit("selectGuild", guild.id);
}

defineProps({
	"label": {
		type: String,
		default: () => "Switch Server",
	}
});
const emit = defineEmits(["selectGuild", "close"]);

const search = ref({
	open: false,
	value: null,
	visible: account.staffedGuildRecords,
});

watch(() => search.value.open, (newState) => {
	if (!newState) {
		search.value.value = null;
		search.value.visible = account.staffedGuildRecords;
	}
});

function filterGuild(event) {
	if (!event.target.value) return search.value.visible = account.staffedGuildRecords;

	const txt = event.target.value.toLowerCase();
	search.value.visible = account.staffedGuildRecords.filter(g => {
		if (g.id.includes(txt)) return true;

		if (!g.name) return `unknown ${g.id}`.includes(txt);
		return g.name.toLowerCase().includes(txt) ?? false;
	});
}

/**
 * Logs the user out of the account.
 * Purges all account data and redirects you to the index page.
 * @returns {Promise<string>}
 */
function logout() {
	return account.logout();
}

function gotoPersonalAccount() {
	// if (cache.guild.id==="0") return; // No change
	//
	// document.dispatchEvent(new CustomEvent("updateMe"));
	// return cache.selectGuild(null);
	console.log("Goto personal account");
}
</script>

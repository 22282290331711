<script setup>
import UserAvatar from "@/components/icon/UserAvatar.vue";
import UserCache from "@/storage/UserCache";
import LocalDate from "@/components/info/parts/LocalDate.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";

defineProps({
	"message": {
		type: Object,
		required: true,
	},
	"appealingUserId": {
		type: String,
		required: true,
	},
	"accountId": {
		type: String,
		required: true,
	},
});
const users = UserCache();
const toDate = (id) => {
	const dateInfo = id.slice(0,13).replace("-","");
	return new Date(parseInt(dateInfo, 16));
}
</script>

<template>
	<!-- TODO: Some conditional rendering and such could be optimized by using CSS and data-x selectors or classes -->
	<div class="flex gap-2 hover:bg-neutral-700/10">
		<UserAvatar
			class="shrink-0"
			:user-id="message.authorId"
			:avatar="users.avatarHashOf(message.authorId)"
		/>

		<div clasS="flex flex-col">
			<div class="flex gap-2 items-center">
				<strong class="flex items-center gap-1">
					{{ users.username(message.authorId) }}
					<DynamicIcon
						v-if="message.authorId !== appealingUserId"
						:title="`${users.titleOf(message.authorId, false) || message.authorId === accountId ? 'You' : 'Active Staff Member'}`"
						icon="ShieldUser"
						size="16"
					/>
				</strong>
				<LocalDate
					class="text-xs text-neutral-500"
					:date="toDate(message.id)"
					dynamic
				/>
			</div>
			<p class="whitespace-pre-line">
				{{ message.textContent }}
			</p>
		</div>
	</div>
</template>

<style scoped>

</style>
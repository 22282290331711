import { computed, reactive } from "vue";
import { emit } from "./utils";

const wsUrl = computed(() => {
	return `${process.env.VUE_APP_WS_ENDPOINT}${window.location.pathname}`;
});

let ws = null;
const handlers = Object.create(null);

export const socket = reactive({
	connected: false,
	reconnect: true,
	content: null,
	ws: ws,
	send,
	close,
	on,
	op,
	connect,
	updateContext,
});

function connect() {
	if (socket.connected) return;

	cleanup(ws);
	return reconnect();
}

/**
 * Handles initial connection / reconnecting to the WS server
 */
function reconnect() {
	console.debug("[WS] Executing reconnect");

	cleanup(ws);
	ws = null;

	// If no "me" cookie, stop trying
	if (!document.cookie.includes("me=")) {
		console.debug("[WS] No account cookie found. Stopping reconnect");
		return;
	}

	ws = new WebSocket(wsUrl.value);
	ws.addEventListener("close", closed);
	ws.addEventListener("open", open);
	ws.addEventListener("message", message);
	ws.addEventListener("error", error);
}

/**
 * Handler for when the socket connection is opened
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/open_event
 * @param {Event} e
 */
function open(e) {
	console.debug(e);
	console.debug("[WS] Socket open");
	socket.connected = true;
	updateContext();
}

/**
 * Send a message or an object to the backend
 * @param {any} payload
 */
function send(payload) {
	if (ws === null || !socket.connected) return;

	if (typeof payload === "string") return ws.send(payload);
	if (payload.op) throw new Error("[WS] Use the dedicated op() method to send operations");

	return ws.send(JSON.stringify(payload));
}

/**
 * Sends a special operation command to the server
 * @param {number} operation The operation code
 * @param {object|array|null} [payload=null] The payload to send, if any. Must always be an object or array
 */
function op(operation, payload = null) {
	if (!socket.connected) return;

	if (!(payload instanceof Object) && !Array.isArray(payload)) throw new Error("[WS] Payload must be an object or array");
	if (typeof operation !== "number" && !Number.isInteger(operation)) throw new Error("[WS] Operation must be a string");

	if (payload === null) return ws.send(op);
	return ws.send(JSON.stringify({
		op: operation,
		d: payload,
	}));
}

/**
 * A method for manually closing the connection, with the option to not reconnect
 * @param {{reconnect?: boolean}} opts
 */
function close(opts) {
	if (ws === null || !socket.connected) return;
	if (opts.reconnect !== undefined) socket.reconnect = !!opts.reconnect;

	ws.close();
}

/**
 * Handler for incoming WS message
 * @param {MessageEvent} e
 */
function message(e) {
	const payload = JSON.parse(e.data);

	/**
	 * These are for your views when you want to trigger some custom stuff
	 */
	if (typeof payload === "number") {
		emit(payload, null);
	} else if (payload.op) {
		emit(payload.op, payload?.data ?? null);
	}

	/**
	 * These are global hooks, where you always want to execute one action regardless
	 */
	if (payload.op && payload.op in handlers) {
		return handlers[payload.op](payload.data);
	}

	console.debug("[WS] Unhandled WS message", payload);
}

/**
 * Handler for when there's a WS error
 * @param {Event} [e]
 */
function error(e) {
	console.error("[WS] Socket error", e);
}

/**
 * Handler for when connection is closed.
 * Will automatically try to re-connect if not re-connect is set to false
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/close_event
 * @param {CloseEvent} [e]
 * @returns {Promise<void>}
 */
async function closed() {
	console.debug("[WS] Socket closed");
	socket.connected = false;

	if (socket.reconnect) {
		await retry(reconnect, null, 20);
	}
}

/**
 * Handles cleanup of event listeners
 * @param ws
 */
function cleanup(ws) {
	if (ws === null) return;

	ws.removeEventListener("close", closed);
	ws.removeEventListener("open", open);
	ws.removeEventListener("message", message);
	ws.removeEventListener("error", error);
}

/**
 * Attach a listener for when we receive an operation from the server
 * @param {string} event The server operation you want to subscribe to
 * @param {(data: any) => void} handler Your handler for this operation
 */
function on(event, handler) {
	if (event === "ping") {
		/**
		 * Heartbeats are handled by the WS server and Browser API using special
		 * packets that are compliant with the RFC, so no heartbeat implementation
		 * is required.
		 *
		 * These events will usually not show up in the WS communication logs
		 * of your browser's Network tab.
		 */
		throw new Error("[WS] The 'ping' event is reserved");
	}

	handlers[event] = handler;
}

/**
 * Emits the current tab's URL to the server.
 * Updated on every route change.
 * This is used to determine whether to send guild events or personal events.
 */
function updateContext() {
	op(1, {
		url: window.location.pathname,
	});
}

/**
 * Wait for the given milliseconds
 * @param {number} milliseconds The given time to wait
 * @returns {Promise} A fulfilled promise after the given time has passed
 * @author https://bpaulino.com/entries/retrying-api-calls-with-exponential-backoff
 */
function waitFor(milliseconds) {
	return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Execute a promise and retry with exponential backoff
 * based on the maximum retry attempts it can perform
 * @param {() => void} fn function to be executed
 * @param {function} [onRetry] callback executed on every retry
 * @param {number} maxRetries The maximum number of retries to be attempted
 * @param {number} [maxIntervalMS] The maximum interval between retries
 * @author Martin Haslien + https://bpaulino.com/entries/retrying-api-calls-with-exponential-backoff
 */
function retry(fn, onRetry, maxRetries, maxIntervalMS = Infinity) {
	// Notice that we declare an inner function here
	// so we can encapsulate the retries and don't expose
	// it to the caller. This is also a recursive function
	async function retryWithBackoff(retries) {
		try {
			// Make sure we don't wait on the first attempt
			if (retries > 0) {
				// Here is where the magic happens.
				// on every retry, we exponentially increase the time to wait.
				// Here is how it looks for a `maxRetries` = 4
				// (2 ** 1) * 100 = 200 ms
				// (2 ** 2) * 100 = 400 ms
				// (2 ** 3) * 100 = 800 ms
				const timeToWait = Math.min(maxIntervalMS, 2 ** retries * 100);
				console.debug(`[WS] Waiting ${timeToWait} ms before re-connecting...`);
				await waitFor(timeToWait);
			}

			return fn();
		} catch (e) {
			// only retry if we didn't reach the limit
			// otherwise, let the caller handle the error
			if (retries < maxRetries) {
				if (onRetry) onRetry();
				return retryWithBackoff(retries + 1);
			} else {
				console.warn("[WS] Max retries reached. Bubbling the error up");
				throw e;
			}
		}
	}

	return retryWithBackoff(0);
}
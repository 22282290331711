<template>
	<div
		class="UserHighlightPanel p-4 bg-neutral-800/50 border-details shadow rounded-xl flex flex-col gap-2"
	>
		<div class="flex gap-2 justify-between">
			<div class="flex gap-2">
				<!-- Creator avatar -->
				<img
					v-if="avatarUrl !== 'logo'"
					:src="avatarUrl ? avatarUrl : defaultAvatarUrl(username.split('#')[1])"
					:alt="`Avatar of ${username}`"
					class="rounded-full w-16 h-16"
				>
				<img
					v-else
					src="@/assets/logo.svg"
					alt="Archivian logo"
					class="w-16 h-16"
				>

				<!-- Creator info -->
				<div>
					<h3>
						<ButtonLike
							desc="Inspect this user"
							class="w-fit"
							:class="{'hover:text-sky-400': !isPersonal}"
							:disabled="isPersonal"
							@click="emit('inspect')"
						>
							{{ username }}
						</ButtonLike>
					</h3>
					<p v-if="title" class="text-slate-500 text-sm">
						{{ title }}
					</p>
					<IdBadge
						v-if="userId"
						:label="userId"
						class="w-fit"
					/>
				</div>
			</div>

			<!-- Co-contributors -->
			<div v-if="users && users.length">
				<p class="">
					{{ usersLabel }}
				</p>
				<!-- TODO Replace with <StackedAvatars /> -->
				<div class="flex flex-row-reverse">
					<img
						v-for="imageLink in users"
						:key="imageLink"
						:src="imageLink"
						:alt="`Avatar of co-contributor`"
						class="border-2 border-neutral-700 rounded-full w-6 h-6 -ml-2"
					>
				</div>
			</div>

			<!-- Default slot -->
			<slot />
		</div>
		<!-- Slot under -->
		<slot name="under" />
	</div>
</template>

<script setup>
import {defaultAvatarUrl} from "@/script/convert";
import IdBadge from "@/components/badges/IdBadge.vue";
import ButtonLike from "@/components/panels/ButtonLike.vue";
import { inject } from "vue";

const emit = defineEmits(["inspect"]);
defineProps({
	"avatarUrl": {
		// Full URL to avatar, or you can pass "logo" to use the Archivian logo SVG instead
		type: String,
		required: true,
	},
	"userId": {
		type: String,
		default: () => null,
	},
	"username": {
		type: String,
		required: true,
	},
	"title": {
		type: String,
		default: () => null,
	},
	"usersLabel": {
		type: String,
		default: () => null,
	},
	// Array of avatar URL's
	"users": {
		type: Array,
		default: () => null,
	},
});
const isPersonal = inject("isPersonal");
</script>
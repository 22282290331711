<script setup>
import {onBeforeUnmount, ref} from "vue";

const props = defineProps({
	"shapeCount": {
		type: Number,
		default: () => 10,
	},
	"blur": {
		type: [String, Number],
		default: () => 0
	},
	"colors": {
		type: Array,
		default: () => [
			"#38bdf8",
			"#a855f7",
			"#6d28d9",
		],
	},
});

/**
 * Shapes generated with https://www.blobmaker.app/
 */
const shapes = [
	"M26.3,-35.5C38.8,-27.1,56.9,-25.7,59.8,-18.9C62.7,-12.1,50.3,0.1,40.5,7.9C30.7,15.6,23.4,18.9,17.1,23.2C10.7,27.6,5.4,33,-0.7,34C-6.7,34.9,-13.5,31.3,-27.6,29.5C-41.7,27.7,-63.3,27.6,-74.6,18.2C-85.9,8.8,-87,-9.9,-75.7,-18.4C-64.4,-26.8,-40.6,-25.1,-26,-32.8C-11.3,-40.6,-5.6,-57.8,0.6,-58.7C6.9,-59.6,13.8,-44,26.3,-35.5Z",
	"M39.3,-44.9C53.7,-43.7,69.9,-35.8,67.8,-25.9C65.8,-15.9,45.4,-3.9,37.7,9.9C30,23.7,34.9,39.3,30.8,53.4C26.6,67.6,13.3,80.2,1.6,78C-10.1,75.8,-20.2,58.7,-27.8,45.7C-35.5,32.7,-40.6,23.8,-49.9,12.2C-59.1,0.7,-72.3,-13.6,-69,-22C-65.7,-30.5,-45.9,-33.2,-31.7,-34.5C-17.4,-35.7,-8.7,-35.5,1.9,-38.1C12.5,-40.7,25,-46.1,39.3,-44.9Z",
	"M54,-68C69.3,-63.2,80.4,-46.5,74.9,-31.8C69.4,-17.1,47.2,-4.5,37,7.4C26.7,19.3,28.5,30.5,24.4,38.2C20.2,45.9,10.1,50.1,-1.2,51.7C-12.5,53.3,-24.9,52.4,-36.8,47.2C-48.7,42,-59.9,32.6,-64.6,20.4C-69.4,8.3,-67.5,-6.5,-61.8,-18.8C-56,-31.1,-46.4,-40.9,-35.5,-47C-24.5,-53.1,-12.3,-55.6,3.6,-60.5C19.4,-65.4,38.7,-72.7,54,-68Z",
	"M29.9,-39.4C40.1,-33.7,50.8,-26.8,61.1,-15.1C71.4,-3.4,81.4,13.2,78.9,27.1C76.3,41,61.2,52.2,46,57.6C30.8,63,15.4,62.6,0.6,61.7C-14.2,60.9,-28.4,59.7,-39.4,53C-50.3,46.2,-58.2,33.8,-64.4,19.7C-70.6,5.6,-75.3,-10.2,-73.6,-26.6C-72,-43.1,-64.1,-60.2,-50.7,-64.8C-37.4,-69.5,-18.7,-61.8,-4.4,-55.7C9.8,-49.6,19.7,-45.1,29.9,-39.4Z",
	"M33,-54.7C40,-40.3,41.1,-27,47,-14C52.9,-1.1,63.6,11.5,59.2,17.5C54.9,23.4,35.4,22.7,23.1,26.4C10.7,30,5.3,37.9,-6.2,46.5C-17.7,55,-35.5,64.1,-42.1,58.6C-48.7,53.1,-44.1,33,-42.5,18.4C-40.9,3.9,-42.2,-5.1,-39.2,-12.3C-36.3,-19.4,-29,-24.6,-21.7,-38.9C-14.4,-53.1,-7.2,-76.4,2.9,-80.4C13,-84.4,26,-69,33,-54.7Z",
	"M27.6,-32.7C40.8,-28.4,59.9,-27,66.6,-18.7C73.3,-10.5,67.5,4.8,60.2,17.2C52.9,29.6,44.2,39.2,33.9,45.4C23.6,51.7,11.8,54.7,-3.4,59.4C-18.6,64.1,-37.3,70.5,-41.8,62.4C-46.3,54.3,-36.7,31.5,-35.2,16.2C-33.6,0.8,-40.1,-7.2,-39.1,-13.5C-38.1,-19.8,-29.6,-24.4,-21.8,-30.4C-14,-36.5,-7,-44,0.1,-44.1C7.2,-44.2,14.4,-37,27.6,-32.7Z",
	"M35.2,-45C44.8,-41.5,51.1,-30.1,51.2,-19.2C51.2,-8.3,44.9,2.1,41.6,13.8C38.3,25.5,37.8,38.5,31.4,51.9C24.9,65.3,12.5,79,-2.5,82.5C-17.6,86,-35.1,79.3,-40.5,65.5C-45.8,51.8,-39,31.1,-40.9,15.6C-42.9,0,-53.6,-10.5,-54.7,-21C-55.7,-31.6,-47,-42.2,-36.3,-45.4C-25.6,-48.6,-12.8,-44.3,0,-44.3C12.8,-44.3,25.6,-48.6,35.2,-45Z",
	"M24,-29C34.5,-25.4,48.7,-22.9,49.3,-17C50,-11,37.1,-1.6,29.4,5.7C21.7,13,19.3,18.1,15.3,21.9C11.2,25.7,5.6,28.3,-1.9,30.9C-9.5,33.5,-18.9,36.3,-27.4,33.9C-35.8,31.5,-43.2,24,-46,15C-48.8,6.1,-47,-4.3,-48.1,-20.1C-49.1,-35.9,-53.1,-57.2,-45.5,-61.7C-38,-66.2,-19,-54,-6.1,-45.5C6.8,-37.1,13.5,-32.5,24,-29Z",
	"M33.4,-55.5C38.2,-42.6,33.4,-25.9,34.7,-13.3C36.1,-0.6,43.7,8,42.2,14.1C40.8,20.3,30.4,23.9,21.9,26.3C13.3,28.8,6.7,30,0.1,29.9C-6.5,29.8,-13,28.4,-21.7,26C-30.3,23.6,-41.1,20.2,-48.3,12.3C-55.5,4.4,-59.1,-8,-59,-22.6C-58.9,-37.2,-55.2,-53.9,-44.7,-65C-34.3,-76.1,-17.1,-81.4,-1.4,-79.5C14.3,-77.6,28.7,-68.4,33.4,-55.5Z",
	"M39.5,-51.5C47.6,-48.4,48.3,-32.1,46,-19.6C43.8,-7,38.6,1.9,37.2,13.9C35.9,25.9,38.3,41,32.7,47.5C27.2,54,13.6,51.9,5.2,44.7C-3.1,37.5,-6.2,25.2,-9,17.7C-11.7,10.3,-14.2,7.9,-22.8,2.4C-31.5,-3,-46.4,-11.5,-48.6,-19.3C-50.9,-27.1,-40.6,-34.4,-30.4,-36.8C-20.1,-39.2,-10.1,-36.8,2.8,-40.6C15.6,-44.5,31.3,-54.5,39.5,-51.5Z",
	"M20,-23C27.8,-21.8,37.5,-18.6,43.8,-11.4C50,-4.2,52.9,7,47.1,12.4C41.3,17.8,26.9,17.5,17.6,16.6C8.3,15.7,4.1,14.3,-1.7,16.6C-7.5,18.8,-14.9,24.8,-30.3,27.7C-45.8,30.5,-69.2,30.3,-68.6,24.2C-68,18,-43.4,6,-30.1,-2C-16.8,-10,-14.8,-13.8,-11.7,-16.6C-8.6,-19.4,-4.3,-21.1,0.9,-22.3C6.1,-23.5,12.1,-24.3,20,-23Z",
	"M33.5,-44.1C38.2,-42.6,33.2,-25.9,30.8,-14.5C28.3,-3.1,28.4,3.1,25.1,6.6C21.7,10,15.1,10.8,10.4,19.2C5.6,27.5,2.8,43.3,-5.4,50.8C-13.7,58.2,-27.3,57.3,-31.3,48.8C-35.4,40.2,-29.7,24,-29.4,12.5C-29,1,-33.9,-5.8,-36.5,-16.2C-39.1,-26.6,-39.4,-40.6,-32.9,-41.6C-26.5,-42.5,-13.2,-30.4,0.6,-31.2C14.4,-32,28.7,-45.6,33.5,-44.1Z",
	"M47.9,-66C61.6,-55.9,72,-41.4,78.3,-24.8C84.5,-8.2,86.6,10.3,77.2,20.8C67.9,31.2,47.1,33.7,32.3,44.1C17.5,54.5,8.7,72.9,2.7,69.1C-3.3,65.3,-6.5,39.4,-13.7,26.5C-20.9,13.7,-31.9,13.8,-32.8,10.7C-33.7,7.6,-24.5,1.3,-25.3,-11.9C-26,-25.1,-36.8,-45.2,-34.2,-59C-31.7,-72.7,-15.8,-80,0.6,-80.9C17.1,-81.7,34.2,-76.1,47.9,-66Z",
	"M35.9,-41.2C43.9,-43.5,46.1,-29.6,48.8,-16.9C51.5,-4.2,54.6,7.3,56.3,23.2C57.9,39.1,57.9,59.5,48.3,62.2C38.6,64.9,19.3,50.1,4.6,43.7C-10.1,37.4,-20.2,39.6,-30.4,37C-40.6,34.5,-50.8,27.2,-60.1,15.5C-69.5,3.9,-77.9,-12.1,-70.5,-19.3C-63.2,-26.5,-40.1,-24.9,-25.7,-20.6C-11.3,-16.2,-5.7,-9,4.1,-14.7C13.9,-20.4,27.8,-38.9,35.9,-41.2Z",
	"M3.4,-13.2C6.5,-2.3,12.9,-4.3,15.8,-3.2C18.7,-2.2,18.2,1.8,21.8,12.1C25.4,22.4,32.9,39.1,30,54.6C27,70,13.5,84.2,6.7,75C-0.1,65.8,-0.3,33.2,-6,18.6C-11.6,4.1,-22.8,7.6,-31.7,4.6C-40.5,1.6,-47,-7.9,-48.7,-19.9C-50.4,-31.8,-47.3,-46.3,-38.3,-55.4C-29.4,-64.4,-14.7,-68.1,-7.3,-58.1C0.1,-48,0.2,-24.2,3.4,-13.2Z",
	"M27.7,-29.6C37.5,-31,48.2,-25.1,52.5,-16.1C56.9,-7.1,54.8,5,48.5,13.1C42.3,21.2,31.8,25.3,23,26.1C14.2,26.8,7.1,24.3,-2.4,27.6C-12,31,-24,40.2,-26.7,37.5C-29.5,34.8,-23,20.1,-28.7,8C-34.4,-4,-52.3,-13.4,-55.3,-22.3C-58.4,-31.1,-46.5,-39.4,-34.8,-37.4C-23.1,-35.5,-11.5,-23.3,-1.3,-21.5C8.9,-19.8,17.9,-28.3,27.7,-29.6Z",
	"M7.4,-8.6C11.7,-7,18.9,-7.7,31.3,-2.4C43.8,2.9,61.5,14.3,64.6,26C67.7,37.6,56.1,49.5,42.9,52.3C29.7,55,14.9,48.6,4,43.1C-6.9,37.5,-13.7,32.9,-25.7,29.8C-37.6,26.6,-54.7,25,-52.7,19.8C-50.6,14.7,-29.4,6,-26.8,-10.7C-24.1,-27.4,-40,-52.2,-38.6,-54.7C-37.3,-57.2,-18.6,-37.5,-8.6,-25.7C1.5,-13.9,3,-10.1,7.4,-8.6Z",
	"M23.7,-35C36.8,-23.2,57.6,-24.3,55.1,-20.6C52.6,-16.9,26.6,-8.3,20.2,5C13.7,18.4,26.7,36.5,26.6,39.4C26.5,42.3,13.2,30,3.9,24.6C-5.4,19.2,-10.8,20.8,-17.5,20C-24.3,19.3,-32.3,16.2,-32.5,11.5C-32.8,6.8,-25.2,0.5,-26.3,-12.4C-27.4,-25.3,-37,-44.8,-34.1,-60C-31.2,-75.1,-15.6,-85.9,-5.1,-78.8C5.3,-71.8,10.7,-46.9,23.7,-35Z",
	"M48.2,-60.6C63.4,-55.2,77.5,-42.5,78.6,-28.1C79.7,-13.8,67.8,2.2,57.9,14.5C48,26.8,40,35.4,30.6,46.6C21.3,57.8,10.6,71.6,-2.6,75.1C-15.8,78.7,-31.7,72,-43.4,61.6C-55.2,51.2,-63,37.1,-57.7,25.3C-52.5,13.6,-34.2,4.2,-24.4,-2.3C-14.5,-8.8,-13.1,-12.5,-10.5,-21.9C-7.8,-31.3,-3.9,-46.6,6.3,-55.2C16.5,-63.8,32.9,-65.9,48.2,-60.6Z",
	"M11.1,-15.6C11.5,-14.9,7,-7.9,5.8,-3.8C4.5,0.3,6.5,1.5,11.2,8.7C15.9,16,23.3,29.2,21.9,44.1C20.5,59.1,10.3,75.8,0.8,74.7C-8.7,73.6,-17.4,54.7,-31.6,43.9C-45.9,33.2,-65.8,30.5,-66.6,22.8C-67.5,15.2,-49.4,2.5,-38.8,-7.2C-28.2,-16.9,-25.1,-23.6,-19.9,-22.7C-14.7,-21.7,-7.3,-13.2,-1,-11.8C5.3,-10.4,10.6,-16.2,11.1,-15.6Z",
	"M39.5,-57.8C45,-50.4,39,-30.6,32.5,-18.2C26,-5.8,19,-1,18,7C17,14.9,21.9,25.9,19.9,41.1C17.9,56.3,8.9,75.8,-3.7,80.9C-16.3,86,-32.7,76.8,-44.8,64.8C-56.9,52.9,-64.8,38.3,-56.7,27C-48.5,15.8,-24.3,7.9,-12.6,3.3C-1,-1.4,-2,-2.7,-2,-11.9C-2,-21,-1,-38,8,-49C17,-60,34,-65.2,39.5,-57.8Z",
	"M10.3,-18.2C20.1,-7.2,39.2,-13.1,42.8,-11.6C46.4,-10.2,34.4,-1.5,33.1,13.3C31.8,28,41.1,48.7,37.3,53C33.6,57.3,16.8,45.2,7,35.6C-2.8,25.9,-5.5,18.7,-12.8,15.5C-20,12.3,-31.6,13.2,-35.1,9.7C-38.6,6.2,-33.9,-1.6,-32.9,-12.5C-31.9,-23.4,-34.5,-37.3,-29.7,-49.9C-24.8,-62.6,-12.4,-73.9,-6,-65.6C0.3,-57.3,0.6,-29.3,10.3,-18.2Z",
	"M25.5,-34.3C34.2,-28.8,43.2,-22.9,48.6,-13.9C54,-5,56,6.9,51.5,15.5C47,24.2,36.2,29.6,26.6,37.1C16.9,44.7,8.5,54.4,-1.8,56.9C-12,59.3,-24,54.4,-37.9,48.3C-51.8,42.1,-67.7,34.6,-64.7,25.4C-61.7,16.1,-39.9,5.1,-29,-3.6C-18.2,-12.4,-18.3,-18.8,-15.3,-26.2C-12.3,-33.5,-6.1,-41.8,1.1,-43.3C8.4,-44.9,16.8,-39.8,25.5,-34.3Z",
];

// Used to stop setTimeout when component is unmounted
let active = true;
const randomShape = () => shapes[Math.floor(Math.random() * shapes.length)];
const randomColor = () => props.colors[Math.floor(Math.random() * props.colors.length)];
const randomBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
const randomPosition = () => {
	return [randomBetween(-50, 50), randomBetween(-50, 0)];
};

const shapePositions = ref([]);
const randomColors = [];
const randomShapes = [];
for (let i=0;i<props.shapeCount;i++) {
	const pickedShapes = [
		randomShape(),
		randomShape(),
		randomShape(),
	];
	pickedShapes.push(pickedShapes[0]);
	randomShapes.push(pickedShapes);

	const pickedColors = [
		randomColor(),
		randomColor(),
		randomColor(),
	];
	pickedColors.push(pickedColors[0]);
	randomColors.push(pickedColors);

	shapePositions.value.push([0, 0]);
	rotatePositions(i);
}

function rotatePositions(i) {
	if (!active) return;

	shapePositions.value[i] = randomPosition();
	setTimeout(()=> {
		return rotatePositions(i);
	}, 15_000);
}

onBeforeUnmount(() => {
	active = false;
});
</script>

<template>
	<span class="absolute block w-full h-full overflow-hidden" :style="`filter: blur(${blur}px);`">
		<svg
			v-for="(shapeGroup, i) in randomShapes"
			:key="i"
			viewBox="-100 -100 200 200"
			xmlns="http://www.w3.org/2000/svg"
			class="absolute inset-0 h-full w-full animated-svg-movement opacity-80"
			:style="`left: ${shapePositions[i][0]}%; top: ${shapePositions[i][1]}%;`"
			:class="`delay-${i}`"
		>
			<path
				:fill="randomColor()"
				:d="randomShape()"
			>
				<animate
					attributeName="d"
					:values="shapeGroup.join(';')"
					dur="15s"
					repeatCount="indefinite"
				/>
				<animate
					attributeName="fill"
					:values="randomColors[i].join(';')"
					dur="15s"
					repeatCount="indefinite"
				/>
			</path>

		</svg>
	</span>
</template>

<style scoped>
.animated-svg-movement {
	transition: all 15s ease-in-out;
}
</style>
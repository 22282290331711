<template>
	<g>
		<path
			d="M18.0025 2.99625V20.0033"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M2.99625 20.0033H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4.99709 16.0017H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.99791 12H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.3985 7.99833H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.5014 5.49729L18.0025 2.99625L20.5035 5.49729"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconSortAsc"
};
</script>
import { IsResourceOwner } from "./ResourceOwner.js";
export const Policies = {
    /**
     * Permission to do anything related to message policies
     */
    ANY: () => {
        return "module.messages.policies.**";
    },
    /**
     * Get the message policies for a guild
     */
    READ: () => {
        return "module.messages.policies.read";
    },
    /**
     * Permission to update the message policies for a guild
     */
    UPDATE: () => {
        return "module.messages.policies.update";
    },
};
export const DeletedAttachments = {
    /**
     * Permission to anything related to deleted message attachments
     */
    ANY: () => {
        return "module.messages.attachments.**";
    },
    /**
     * Permission to read deleted message attachments
     */
    READ: () => {
        return "module.messages.attachments.read";
    },
    /**
     * Permission to delete a deleted attachment file(s)
     */
    DELETE: () => {
        return "module.messages.attachments.delete";
    },
};
export const Purge = {
    /**
     * Permission to do anything related to message purging
     */
    ANY: () => {
        return "module.messages.purge.**";
    },
    /**
     * Permission to execute message purging
     */
    DELETE: () => {
        return "module.messages.purge.delete";
    },
    /**
     * Permission to doa dry-run of message purging
     */
    DRY_RUN: () => {
        return "module.messages.purge.dry.delete";
    },
};
export const Collections = {
    /**
     * Permission to anything related to message collections
     */
    ANY: () => {
        return "module.messages.collections.**";
    },
    /**
     * Permission to read message collections
     */
    READ: () => {
        return "module.messages.collections.read";
    },
    /**
     * Permission to update a message collection
     */
    UPDATE: (collectionOwner) => {
        return `module.messages.collections.${IsResourceOwner(collectionOwner)}.update`;
    },
    /**
     * Permission to delete a message collection
     */
    DELETE: (collectionOwner) => {
        return `module.messages.collections.${IsResourceOwner(collectionOwner)}.delete`;
    },
};

<template>
	<g>
		<path
			d="M5 7H19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18 7V18C18 19.105 17.105 20 16 20H8C6.895 20 6 19.105 6 18V7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15 3.76001H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.8699 15.37L10.1299 11.63"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.1299 15.37L13.8699 11.63"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconTrashDelete"
};
</script>
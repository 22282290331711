<script setup>
import { lazyTicker } from "archivian-utils";
import {onBeforeUnmount, onMounted, ref} from "vue";

const emit = defineEmits(["tick", "end"]);
const props = defineProps({
	// The date to tick towards or away from
	"date": {
		type: [String, Date],
		default: () => new Date(),
	},
	// Whether to tick towards or away from the date
	"direction": {
		type: String,
		enum: ["away", "towards"],
		required: true,
	},
	// Ticker is constantly updated once a second. Like setInterval, but it also still retains stop logic.
	"constant": {
		type: Boolean,
		default: false,
	},
	// Log every tick to console
	"log": {
		type: Boolean,
		default: false,
	},
});

function handleTick(tickMeta) {
	meta.value.timeout = tickMeta.timeout;
	meta.value.tick = tickMeta.tick;
	if (props.log) console.log(tickMeta);

	if (tickMeta.end) {
		meta.value.end = true;
		emit("end");
	}

	emit("tick", tickMeta.tick);
}

const meta = ref({
	tick: 0,
	end: false,
	timeout: null,
});

onMounted(() => {
	const tickMeta = lazyTicker(
		new Date(props.date),
		props.direction,
		handleTick,
		{
			constant: props.constant,
		}
	);

	meta.value.timeout = tickMeta.timeout;
	meta.value.tick = tickMeta.tick;
});
onBeforeUnmount(() => {
	clearTimeout(meta.value.timeout);
});
</script>

<template>
	<slot
		:key="meta.tick"
		:tick="meta.tick"
		:ended="meta.end"
	/>
</template>

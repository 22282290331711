<template>
	<svg
		v-if="!displayAll"
		:width="size"
		:height="size"
		:aria-label="iconName || `${icon} Icon`"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		class="inline-block"
	>
		<title
			v-if="title"
			:id="title"
			lang="en"
		>{{ title }}</title>
		<g :stroke="color" :stroke-width="stroke">
			<component :is="icon" :color="color" />
		</g>
	</svg>
	<template v-else>
		<svg
			v-for="(ico,i) in Object.keys($options.components)"
			:key="i"
			:width="size"
			:height="size"
			:aria-label="iconName || ico"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			class="inline-block"
		>
			<title
				:id="iconName || ico"
				lang="en"
			>
				{{ iconName || ico }}
			</title>
			<g :stroke="color" :stroke-width="stroke">
				<component :is="ico" :color="color" />
			</g>
		</svg>
	</template>
</template>

<script>
import {default as Lock} from "@/components/icon/templates/IconLock";
import {default as User} from "@/components/icon/templates/IconUser";
import {default as Sparkle} from "@/components/icon/templates/IconSparkle";
import {default as Help} from "@/components/icon/templates/IconHelp";
import {default as Exit} from "@/components/icon/templates/IconExit";
import {default as BoxChecked} from "@/components/icon/templates/IconBoxChecked";
import {default as BoxUnchecked} from "@/components/icon/templates/IconBoxUnchecked";
import {default as BoxUnknown} from "@/components/icon/templates/IconBoxUnknown";
import {default as Search} from "@/components/icon/templates/IconSearch";
import {default as ArrowLeft} from "@/components/icon/templates/IconArrowLeft";
import {default as ArrowUp} from "@/components/icon/templates/IconArrowUp";
import {default as TrashDelete} from "@/components/icon/templates/IconTrashDelete.vue";
import {default as Trash} from "@/components/icon/templates/IconTrash.vue";
import {default as Timer} from "@/components/icon/templates/IconTimer";
import {default as EmailEdit} from "@/components/icon/templates/IconEmailEdit";
import {default as EmailWarning} from "@/components/icon/templates/IconEmailWarning";
import {default as Alarm} from "@/components/icon/templates/IconAlarm";
import {default as BurgerMenu} from "@/components/icon/templates/IconBurgerMenu";
import {default as Home} from "@/components/icon/templates/IconHome";
import {default as Chevron} from "@/components/icon/templates/IconChevron";
import {default as UserAddCircle} from "@/components/icon/templates/IconUserAddCircle";
import {default as Close} from "@/components/icon/templates/IconClose";
import {default as Ban} from "@/components/icon/templates/IconBan";
import {default as Filter} from "@/components/icon/templates/IconFilter";
import {default as SortAsc} from "@/components/icon/templates/IconSortDesc";
import {default as SortDesc} from "@/components/icon/templates/IconSortAsc";
import {default as Visible} from "@/components/icon/templates/IconVisible";
import {default as Invisible} from "@/components/icon/templates/IconInvisible";
import {default as ErrorFilled} from "@/components/icon/templates/IconErrorFilled";
import {default as Document} from "@/components/icon/templates/IconDocument";
import {default as FileAudio} from "@/components/icon/templates/IconFileAudio";
import {default as FileTable} from "@/components/icon/templates/IconFileTable";
import {default as FileWarning} from "@/components/icon/templates/IconFileWarning";
import {default as FileZip} from "@/components/icon/templates/IconFileZip";
import {default as FileText} from "@/components/icon/templates/IconFileText";
import {default as InfoFilled} from "@/components/icon/templates/IconInfoFilled";
import {default as Check} from "@/components/icon/templates/IconCheck";
import {default as Plus} from "@/components/icon/templates/IconPlus";
import {default as CaseSetting} from "@/components/icon/templates/IconCaseSetting";
import {default as BotSetting} from "@/components/icon/templates/IconBotSetting";
import {default as Cases} from "@/components/icon/templates/IconCases";
import {default as Timeout} from "@/components/icon/templates/IconTimeout";
import {default as Kick} from "@/components/icon/templates/IconKick";
import {default as Warning} from "@/components/icon/templates/IconWarning.vue";
import {default as Note} from "@/components/icon/templates/IconNote";
import {default as Group} from "@/components/icon/templates/IconGroup";
import {default as HandKey} from "@/components/icon/templates/IconHandKey";
import {default as Keys} from "@/components/icon/templates/IconKeys";
import {default as DotsHorizontal} from "@/components/icon/templates/IconDotsHorizontal";
import {default as DotsVertical} from "@/components/icon/templates/IconDotsVertical";
import {default as Loading} from "@/components/icon/templates/IconLoading";
import {default as Archived} from "@/components/icon/templates/IconArchived";
import {default as BellRinging} from "@/components/icon/templates/IconBellRinging";
import {default as Archivian} from "@/components/icon/templates/IconArchivian";
import {default as Appeal} from "@/components/icon/templates/IconAppeal";
import {default as Messages} from "@/components/icon/templates/IconMessages";
import {default as ShieldUser} from "@/components/icon/templates/IconShieldUser";
import {default as SearchUser} from "@/components/icon/templates/IconSearchUser";
import {default as Key} from "@/components/icon/templates/IconKey";
import {default as Admin} from "@/components/icon/templates/IconAdmin";
import {default as FolderUpload} from "@/components/icon/templates/IconFolderUpload.vue";
import {default as ArrowDiagonalUp} from "@/components/icon/templates/IconArrowDiagonalUp.vue";
import {default as TimerArrow} from "@/components/icon/templates/IconTimerArrow.vue";
import {default as Misc} from "@/components/icon/templates/IconMisc.vue";
import {default as Copy} from "@/components/icon/templates/IconCopy.vue";
import {default as Support} from "@/components/icon/templates/IconSupport.vue";
import {default as Bold} from "@/components/icon/templates/IconBold.vue";
import {default as Italic} from "@/components/icon/templates/IconItalic.vue";
import {default as Underline} from "@/components/icon/templates/IconUnderline.vue";
import {default as Strikethrough} from "@/components/icon/templates/IconStrikethrough.vue";
import {default as HeadingOne} from "@/components/icon/templates/IconHeadingOne.vue";
import {default as HeadingTwo} from "@/components/icon/templates/IconHeadingTwo.vue";
import {default as Code} from "@/components/icon/templates/IconCode.vue";
import {default as CodeBlock} from "@/components/icon/templates/IconCodeBlock.vue";
import {default as UnorderedList} from "@/components/icon/templates/IconUnorderedList.vue";
import {default as OrderedList} from "@/components/icon/templates/IconOrderedList.vue";
import {default as Quote} from "@/components/icon/templates/IconQuote.vue";
import {default as Image} from "@/components/icon/templates/IconImage.vue";
import {default as Link} from "@/components/icon/templates/IconLink.vue";
import {default as Unlink} from "@/components/icon/templates/IconUnlink.vue";
import {default as TextSize} from "@/components/icon/templates/IconTextSize.vue";
import {default as Passport} from "@/components/icon/templates/IconPassport.vue";
import {default as Report} from "@/components/icon/templates/IconReport.vue";
import {default as Crisis} from "@/components/icon/templates/IconCrisis.vue";
import {default as Audit} from "@/components/icon/templates/IconAudit.vue";
import {default as Subscription} from "@/components/icon/templates/IconSubscription.vue";
import {default as Globe} from "@/components/icon/templates/IconGlobe.vue";
import {default as Dollar} from "@/components/icon/templates/IconDollar.vue";
import {default as Office} from "@/components/icon/templates/IconOffice.vue";
import {default as Badge} from "@/components/icon/templates/IconBadge.vue";
import {default as Power} from "@/components/icon/templates/IconPower.vue";
import {default as Hash} from "@/components/icon/templates/IconHash.vue";
import {default as Megaphone} from "@/components/icon/templates/IconMegaphone.vue";
import {default as Cog} from "@/components/icon/templates/IconCog.vue";
import {default as Wrench} from "@/components/icon/templates/IconWrench.vue";
import {default as Revert} from "@/components/icon/templates/IconRevert.vue";
import {default as ProtectLoupe} from "@/components/icon/templates/IconProtectLoupe.vue";
import {default as LightBulb} from "@/components/icon/templates/IconLightBulb.vue";
import {default as Guide} from "@/components/icon/templates/IconGuide.vue";
import {default as Discord} from "@/components/icon/templates/IconDiscord.vue";
import {default as ChevronsVertical} from "@/components/icon/templates/IconChevronsVertical.vue";
import {default as Pen} from "@/components/icon/templates/IconPen.vue";
import {default as QuestionCircle} from "@/components/icon/templates/IconQuestionCircle.vue";
import {default as ConstructionHat} from "@/components/icon/templates/IconConstructionHat.vue";
import {default as Collapse} from "@/components/icon/templates/IconCollapse.vue";
import {default as Testing} from "@/components/icon/templates/IconTesting.vue";
import {default as DiscordRole} from "@/components/icon/templates/IconDiscordRole.vue";
import {default as Pin} from "@/components/icon/templates/IconPin.vue";
import {default as ChatLines} from "@/components/icon/templates/IconChatLines.vue";
import {default as Play} from "@/components/icon/templates/IconPlay.vue";
import {default as Tune} from "@/components/icon/templates/IconTune.vue";
import {default as BugFocus} from "@/components/icon/templates/IconBugFocus.vue";
import {default as Info} from "@/components/icon/templates/IconInfo.vue";
import {default as Maximize} from "@/components/icon/templates/IconMaximize.vue";
import {default as Download} from "@/components/icon/templates/IconDownload.vue";
import {default as Video} from "@/components/icon/templates/IconVideo.vue";
import {default as Calibrate} from "@/components/icon/templates/IconCalibrate.vue";
import {default as Volume} from "@/components/icon/templates/IconVolume.vue";
import {default as ShieldRobot} from "@/components/icon/templates/IconShieldRobot.vue";
import {default as Asterisk} from "@/components/icon/templates/IconAsterisk.vue";
import {default as Rocket} from "@/components/icon/templates/IconRocket.vue";

export default {
	name: "DynamicIcon",
	components: {
		/* eslint-disable vue/no-reserved-component-names */
		Lock,
		User,
		Sparkle,
		Help,
		Exit,
		BoxChecked,
		BoxUnchecked,
		BoxUnknown,
		Search,
		ArrowLeft,
		ArrowUp,
		TrashDelete,
		Trash,
		Timer,
		EmailEdit,
		EmailWarning,
		Alarm,
		BurgerMenu,
		Home,
		Chevron,
		UserAddCircle,
		Close,
		Ban,
		Filter,
		SortAsc,
		SortDesc,
		Visible,
		Invisible,
		ErrorFilled,
		InfoFilled,
		Document,
		FileAudio,
		FileTable,
		FileWarning,
		FileZip,
		FileText,
		Check,
		Plus,
		CaseSetting,
		BotSetting,
		Cases,
		Timeout,
		Kick,
		Warning,
		Note,
		Group,
		HandKey,
		Keys,
		DotsHorizontal,
		DotsVertical,
		Loading,
		Archived,
		BellRinging,
		Archivian,
		Appeal,
		Messages,
		ShieldUser,
		SearchUser,
		Key,
		Admin,
		FolderUpload,
		ArrowDiagonalUp,
		TimerArrow,
		Misc,
		Copy,
		Support,
		Bold,
		Italic,
		Underline,
		Strikethrough,
		HeadingOne,
		HeadingTwo,
		Code,
		CodeBlock,
		UnorderedList,
		OrderedList,
		Quote,
		Image,
		Link,
		Unlink,
		TextSize,
		Passport,
		Report,
		Crisis,
		Audit,
		Subscription,
		Globe,
		Dollar,
		Office,
		Badge,
		Power,
		Hash,
		Megaphone,
		Cog,
		Wrench,
		Revert,
		ProtectLoupe,
		LightBulb,
		Guide,
		Discord,
		ChevronsVertical,
		Pen,
		QuestionCircle,
		ConstructionHat,
		Collapse,
		Testing,
		DiscordRole,
		Pin,
		ChatLines,
		Play,
		Tune,
		BugFocus,
		Info,
		Maximize,
		Download,
		Video,
		Calibrate,
		Volume,
		ShieldRobot,
		Asterisk,
		Rocket,
		/* eslint-disable vue/no-reserved-component-names */
	},
	props: {
		"icon": {
			type: String,
			required: true,
		},
		"iconName": {
			type: String,
			default: () => null,
		},
		"title": {
			type: String,
			default: () => null,
		},
		"size": {
			type: [Number, String],
			default: 24
		},
		"color": {
			type: String,
			default: () => "currentColor"
		},
		"stroke": {
			type: [Number, String],
			default: 1.5
		},
		// Dev util: Show icons as grid with names on hover
		"displayAll": {
			type: Boolean,
			default: false,
		}
	}
};
</script>
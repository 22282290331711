<template>
	<g>
		<path
			d="M18.0025 21.0037V3.99667"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M2.99625 3.99667H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4.99709 7.99833H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.99791 12H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.3985 16.0017H12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18.0025 21.0038L20.5035 18.5027"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.5014 18.5027L18.0025 21.0038"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconSortDesc"
};
</script>
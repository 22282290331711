<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.9968 6.15779V13.808C20.997 14.6508 20.6624 15.4593 20.0665 16.0553C19.4706 16.6514 18.6623 16.9863 17.8194 16.9863H12.9944L7.99234 20.988V16.9863H6.16658C5.32373 16.9863 4.51541 16.6514 3.91952 16.0553C3.32363 15.4593 2.98899 14.6508 2.98926 13.808V6.15779C2.98926 4.403 4.41179 2.98047 6.16658 2.98047H17.8194C19.5742 2.98047 20.9968 4.403 20.9968 6.15779Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.8332 7.17383L9.1748 12.8332"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="11.9997"
			cy="9.99776"
			r="4.00167"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconTimeout"
};
</script>
/**
 * Defines the permissions possible for Cases
 */
import { IsResourceOwner } from "./ResourceOwner.js";
/********************************************
 * NOTE:
 *
 * We intentionally do `FN: <X extends Y>(arg: X)`
 * instead of `FN: (ARG: Y)` because it improves
 * the inferred type better for some reason.
 *******************************************/
/**
 * Permissions specific to Cases
 */
export const CASE = {
    /**
     * Permission to anything case related
     */
    ANY: () => {
        return "module.cases.case.**";
    },
    /**
     * Permission to read anything case related
     */
    READ_ANY: () => {
        return "module.cases.case.**.read";
    },
    /**
     * Ability to create a case
     */
    CREATE: (caseType) => {
        return `module.cases.case.${caseType}.create`;
    },
    /**
     * Ability to extend the duration of a case
     */
    EXTEND: (caseType, caseOwner) => {
        return `module.cases.case.${caseType}.${IsResourceOwner(caseOwner)}.update.extend`;
    },
    /**
     * Ability to reduce the duration of a case
     */
    REDUCE: (caseType, caseOwner) => {
        return `module.cases.case.${caseType}.${IsResourceOwner(caseOwner)}.update.reduce`;
    },
    /**
     * Ability to read main case details
     */
    READ: (caseType, caseOwner) => {
        return `module.cases.case.${caseType}.${IsResourceOwner(caseOwner)}.read`;
    },
    /**
     * Ability to change a case's visibility
     */
    VISIBILITY: (caseType, caseOwner) => {
        return `module.cases.case.${caseType}.${IsResourceOwner(caseOwner)}.update.visibility`;
    },
    /**
     * Ability to permanently delete the case and all associated data of it (entries, audit logs, files)
     */
    DELETE: (caseType, caseOwner) => {
        return `module.cases.case.${caseType}.${IsResourceOwner(caseOwner)}.delete`;
    },
    /**
     * Ability to archive (soft-delete) a case
     */
    ARCHIVE: (caseType, caseOwner) => {
        return `module.cases.case.${caseType}.${IsResourceOwner(caseOwner)}.update.archive`;
    },
    // Anything that has to do with archival of cases
    ARCHIVED: {
        /**
         * Access to anything case archive related
         */
        ANY: () => {
            return "module.cases.case.archived.**";
        },
        /**
         * Ability to read an archived (soft-deleted) case
         */
        READ: (caseType, caseOwner) => {
            return `module.cases.case.archived.${caseType}.${IsResourceOwner(caseOwner)}.read`;
        },
        /**
         * Ability to restore an archived (soft-deleted) case
         */
        RESTORE: (caseType, caseOwner) => {
            return `module.cases.case.archived.${caseType}.${IsResourceOwner(caseOwner)}.update.restore`;
        },
        /**
         * Ability to permanently delete an archived case
         */
        DELETE: (caseType, caseOwner) => {
            return `module.cases.case.archived.${caseType}.${IsResourceOwner(caseOwner)}.delete`;
        },
    },
};
/**
 * Permissions specific to Case Entries
 */
export const ENTRY = {
    /**
     * Permission to anything entry related
     */
    ANY: () => {
        return "module.cases.entry.**";
    },
    /**
     * Permission to read any sort of entry
     */
    READ_ANY: () => {
        return "case.entry.**.read";
    },
    /**
     * Ability to add entries to a case
     */
    CREATE: (caseType, caseOwner) => {
        return `module.cases.entry.${caseType}.${IsResourceOwner(caseOwner)}.create`;
    },
    /**
     * Ability to edit the content of an entry in a case
     */
    EDIT: (caseType, caseOwner, entryOwner) => {
        return `module.cases.entry.${IsResourceOwner(entryOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.update.edit`;
    },
    /**
     * Ability top read the content and details of an entry in a case
     */
    READ: (caseType, caseOwner, entryOwner) => {
        return `module.cases.entry.${entryOwner}.${caseType}.${caseOwner}.read`;
    },
    /**
     * Ability to change an entry's visibility
     */
    VISIBILITY: (caseType, caseOwner, entryOwner) => {
        return `module.cases.entry.${IsResourceOwner(entryOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.update.visibility`;
    },
    /**
     * Ability to permanently delete an entry
     */
    DELETE: (caseType, caseOwner, entryOwner) => {
        return `module.cases.entry.${IsResourceOwner(entryOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.delete`;
    },
    /**
     * Archive (soft-delete) an entry in a case
     */
    ARCHIVE: (caseType, caseOwner, entryOwner) => {
        return `module.cases.entry.${IsResourceOwner(entryOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.update.archive`;
    },
    // Anything to do with entries that have been archived
    ARCHIVED: {
        /**
         * Ability to do anything with archived entries
         */
        ANY: () => {
            return "module.cases.entry.archived.**";
        },
        /**
         * Ability to read entries in an archived (soft-deleted) case
         */
        READ: (caseType, caseOwner, entryOwner) => {
            return `module.cases.entry.archived.${IsResourceOwner(entryOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.read`;
        },
        /**
         * Ability to restore an archived (soft-deleted) case
         */
        RESTORE: (caseType, caseOwner, entryOwner) => {
            return `module.cases.entry.archived.${IsResourceOwner(entryOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.update.restore`;
        },
        /**
         * Ability to permanently delete an archived case
         *
         */
        DELETE: (caseType, caseOwner, entryOwner) => {
            return `module.cases.entry.archived.${IsResourceOwner(entryOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.delete`;
        },
    },
};
/**
 * Permissions specific to Case Files
 */
export const FILE = {
    /**
     * Permission to anything file related\
     */
    ANY: () => {
        return "module.cases.file.**";
    },
    /**
     * Permission to read any sort of file
     */
    READ_ANY: () => {
        return "case.file.**.read";
    },
    /**
     * Ability to upload a file to a case
     */
    CREATE: (caseType, caseOwner) => {
        return `module.cases.file.${caseType}.${IsResourceOwner(caseOwner)}.create`;
    },
    /**
     * Ability to read details and access the file itself in a case
     */
    READ: (caseType, caseOwner, fileOwner) => {
        return `module.cases.file.${IsResourceOwner(fileOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.read`;
    },
    /**
     * Permanently delete a file in a case
     */
    DELETE: (caseType, caseOwner, fileOwner) => {
        return `module.cases.file.${IsResourceOwner(fileOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.delete`;
    },
    /**
     * Ability to change the visibility of a file in a case
     */
    VISIBILITY: (caseType, caseOwner, fileOwner) => {
        return `module.cases.file.${IsResourceOwner(fileOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.update.visibility`;
    },
    /**
     * Archive (soft-delete) a file in a case
     */
    ARCHIVE: (caseType, caseOwner, fileOwner) => {
        return `module.cases.file.${IsResourceOwner(fileOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.update.archive`;
    },
    // Anything archived file related
    ARCHIVED: {
        /**
         * Ability to do anything with archived files
         */
        ANY: () => {
            return "module.cases.file.archived.**";
        },
        /**
         * Ability to read an archived (soft-deleted) file
         */
        READ: (caseType, caseOwner, fileOwner) => {
            return `module.cases.file.archive.${IsResourceOwner(fileOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.read`;
        },
        /**
         * Ability to restore an archived (soft-deleted) case
         */
        RESTORE: (caseType, caseOwner, fileOwner) => {
            return `module.cases.file.archive.${IsResourceOwner(fileOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.update.restore`;
        },
        /**
         * Permanently delete an archived file
         */
        DELETE: (caseType, caseOwner, fileOwner) => {
            return `module.cases.file.archive.${IsResourceOwner(fileOwner)}.${caseType}.${IsResourceOwner(caseOwner)}.delete`;
        },
    },
};
/**
 * Permissions specific to Linked Users/Cases
 */
export const LINKED = {
    /**
     * Ability to do anything case linking related
     */
    ANY: () => {
        return "module.cases.linked.**";
    },
    // Permission for linking of users to a case
    USER: {
        /**
         * Any permissions to users linked to cases
         */
        ANY: () => {
            return "module.cases.linked.user.**";
        },
        /**
         * Create a link between a user and a case
         */
        CREATE: () => {
            return "module.cases.linked.user.create";
        },
        /**
         * Read linked users in a case
         */
        READ: () => {
            return "module.cases.linked.user.read";
        },
        /**
         * Rename the link between a user and a case
         */
        RENAME: (lastEditorOwner) => {
            return `module.cases.linked.user.${IsResourceOwner(lastEditorOwner)}.update`;
        },
        /**
         * Delete the link between user and a case
         */
        DELETE: (lastEditorOwner) => {
            return `module.cases.linked.user.${IsResourceOwner(lastEditorOwner)}.delete`;
        },
    },
    // Permission to link cases to other cases
    CASE: {
        /**
         * Any permissions to cases linked to other cases
         */
        ANY: () => {
            return "module.cases.linked.case.**";
        },
        /**
         * Create a link between cases
         */
        CREATE: () => {
            return "module.cases.linked.case.create";
        },
        /**
         * Read linked cases in a case
         */
        READ: () => {
            return "module.cases.linked.case.read";
        },
        /**
         * Rename a link between two cases
         */
        RENAME: (lastEditorOwner) => {
            return `module.cases.linked.case.${IsResourceOwner(lastEditorOwner)}.update`;
        },
        /**
         * Delete a link between two cases
         */
        DELETE: (lastEditorOwner) => {
            return `module.cases.linked.case.${IsResourceOwner(lastEditorOwner)}.delete`;
        },
    },
};
/**
 * Permissions specific to settings for cases
 */
export const SETTING = {
    /**
     * Permission to anything case settings related
     */
    ANY: () => {
        return "settings.cases.*";
    },
    /**
     * Permission to view the settings for cases
     */
    READ: () => {
        return `settings.cases.read`;
    },
    /**
     * Permission to update case settings
     */
    UPDATE: () => {
        return `settings.cases.update`;
    },
};

<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.6 12C21.6 17.302 17.302 21.6 12 21.6C6.69807 21.6 2.4 17.302 2.4 12C2.4 6.69809 6.69807 2.40002 12 2.40002C17.302 2.40002 21.6 6.69809 21.6 12ZM13.2 7.20002C13.2 7.86277 12.6628 8.40002 12 8.40002C11.3373 8.40002 10.8 7.86277 10.8 7.20002C10.8 6.53728 11.3373 6.00002 12 6.00002C12.6628 6.00002 13.2 6.53728 13.2 7.20002ZM10.8 10.8C10.1373 10.8 9.60001 11.3373 9.60001 12C9.60001 12.6628 10.1373 13.2 10.8 13.2V16.8C10.8 17.4628 11.3373 18 12 18H13.2C13.8628 18 14.4 17.4628 14.4 16.8C14.4 16.1373 13.8628 15.6 13.2 15.6V12C13.2 11.3373 12.6628 10.8 12 10.8H10.8Z"
			fill="currentColor"
		/>
	</g>
</template>

<script>
export default {
	name: "IconInfoFilled"
};
</script>
<template>
	<g>
		<path
			d="M15.0312 9.34079C15.0312 9.13379 14.8633 8.96679 14.6562 8.96679C14.4492 8.96779 14.2812 9.13579 14.2812 9.34279C14.2812 9.54979 14.4492 9.71779 14.6562 9.71679C14.8633 9.71679 15.0312 9.54879 15.0312 9.34179"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.499 14.7847L11.469 15.8168H9.637V17.2858H8.172V19.1217L6.798 20.4998H3.5V17.1947L9.223 11.4588C8.417 9.36475 8.849 6.90275 10.534 5.21375C12.814 2.92875 16.511 2.92875 18.791 5.21375C21.071 7.49875 21.071 11.2037 18.791 13.4897C17.089 15.1937 14.601 15.6237 12.499 14.7847Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconKey"
};
</script>
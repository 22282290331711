/**
 * Permissions related to the Early Warning System
 */
export const EarlyWarnings = {
    /**
     * Access to anything for the Early Warnings in the Warning System
     */
    ANY: () => {
        return "module.warning.early.**";
    },
    /**
     * Anything related to the settings for the Early Warning System
     */
    SETTINGS: {
        /**
         * Access to read the Early Warning System settings
         */
        READ: () => {
            return "module.warning.early.settings.read";
        },
        /**
         * Access to update the Early Warning System settings
         */
        UPDATE: () => {
            return "module.warning.early.settings.update";
        }
    }
};

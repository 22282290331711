<script setup>
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import GenericSearchResult from "@/components/form/search/GenericSearchResult.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";

const props = defineProps({
	"id": {
		type: String,
		default: () => Math.random().toString(36).slice(2),
	},
	"selected": {
		type: [String, Number, Object, Array],
		default: () => null,
	},
	"searchValue": {
		type: String,
		default: () => "",
	},
	"searchIcon": {
		type: String,
		default: () => "Search",
	},
	"maxResults": {
		type: Number,
		default: () => 4,
	},
	// Input placeholder when input field is focused
	"placeholderFocus": {
		type: String,
		default: () => null,
	},
	// Input placeholder when input field is NOT focused
	"placeholderBlur": {
		type: String,
		default: () => null,
	},
	"results": {
		type: Array,
		required: true,
	},
	"loading": {
		type: Boolean,
		default: () => false,
	},
	"loadingLabel": {
		type: String,
		default: () => "Searching…",
	},
	"removeTitle": {
		type: String,
		default: () => "Unselect this item",
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	// In some cases you might need to fill out space for the right side icon to avoid layout shifts. Other it's the other way around.
	"iconSpaceFiller": {
		type: Boolean,
		default: () => false,
	},
});
const genericSearch = ref(null);
const isOpen = ref(false);
const input = ref(null);
const emit = defineEmits(["update:searchValue", "update:selected", "input", "focus", "blur"]);

function toggle(forceState) {
	if (props.disabled) return isOpen.value = false;

	isOpen.value = !!forceState;

	if (!forceState) {
		input.value?.blur();
	} else {
		setImmediate(() => {
			input.value?.focus();
		});
	}
}

function select(item) {
	emit("update:selected", item);
	if (item) toggle(false);
}

const escWrapper = () => {
	return toggle(false);
};
function checkAutoCloseOnTabOut(event) {
	if (event.key === "Tab") {
		const focused = document.activeElement;
		if (focused && !genericSearch?.value?.contains(focused)) {
			toggle(false);
		}
	}

}
const handleInput = (event) => {
	emit("input", event);
	emit("update:searchValue", event.target.value);
};

watch(() => props.disabled, (isDisabled) => {
	if (isDisabled) return toggle(false);
});
watch(isOpen, (newState) => {
	if (!newState) {
		return emit("focus");
	}
	else {
		return emit("blur");
	}
});

onMounted(()=>{
	document.body.addEventListener("keyup", checkAutoCloseOnTabOut);
});
onBeforeUnmount(()=>{
	document.body.removeEventListener("keyup", checkAutoCloseOnTabOut);
});

</script>

<template>
	<div
		ref="genericSearch"
		v-click-away="() => toggle(false)"
		class="relative"
	>
		<div
			class="w-full"
			:class="{
				'border-details rounded bg-neutral-800': !isOpen,
				'border border-transparent': isOpen,
				'cursor-pointer': !isOpen && !disabled,
				'cursor-not-allowed': disabled,
			}"
			@click.self="() => toggle(true)"
		>
			<div class="relative" :class="{'z-40': isOpen}">
				<!-- Search field; visible when open or closed with nothing selected -->
				<template v-if="isOpen || (!isOpen && !selected)">
					<div class="flex items-center justify-between" @click.self="() => toggle(true)">
						<div class="grow">
							<DynamicIcon
								:icon="searchIcon"
								class="absolute left-2 my-2"
							/>
							<input
								:id="id"
								ref="input"
								class="pl-10 w-full h-10 py-2 bg-transparent no-focus"
								:class="{
									'cursor-pointer': !isOpen && !disabled,
									'cursor-not-allowed': disabled,
								}"
								type="text"
								:placeholder="isOpen ? placeholderFocus : placeholderBlur"
								:maxlength="32"
								:value="searchValue"
								:disabled="disabled"
								tabindex="0"
								@focusin.self="() => toggle(true)"
								@click.self="() => toggle(true)"
								@keyup.esc="escWrapper"
								@input="handleInput"
							>
						</div>
						<!-- Right side icon when closed and nothing selected -->
						<DynamicIcon
							v-if="!disabled && !isOpen && !loading"
							icon="ChevronsVertical"
							class="mr-4"
							@click.self="() => toggle(true)"
						/>
						<DynamicIcon
							v-else-if="!isOpen && loading"
							icon="Loading"
							class="mr-4 animate-spin"
						/>
						<!-- Space filler to keep width consistent -->
						<div v-else-if="iconSpaceFiller" class="w-6 mr-4 cursor-text" />
					</div>
				</template>

				<!-- Closed but with item selected -->
				<button
					v-if="!isOpen && selected"
					class="selected-blurred"
					@click="() => toggle(true)"
				>
					<div class="flex items-center gap-2 w-full">
						<div class="flex items-center gap-2 grow overflow-hidden">
							<slot name="selectedBlur" :value="selected">
								<p>Selected Item</p>
							</slot>
						</div>

						<DynamicIcon
							v-if="!isOpen"
							icon="ChevronsVertical"
							class="mr-4"
							@click.self="() => toggle(true)"
						/>
					</div>
				</button>
			</div>

			<transition>
				<div
					v-if="isOpen"
					style="width: calc(100% + 1rem);"
					class="backdrop-blur-2xl absolute -left-2 -top-2 bg-transparent rounded border-details shadow pt-16 z-30"
				>
					<GenericSearchResult
						v-if="selected"
						class="bg-neutral-700/50 hover:bg-neutral-700/50 justify-between border-y border-neutral-700"
						style="cursor: default"
					>
						<div class="flex items-center gap-2 grow overflow-hidden">
							<slot name="selectedFocus" :value="selected">
								Search Result Focus
							</slot>
						</div>
						<CloseButton
							v-if="!loading && !disabled"
							:label="removeTitle"
							@click="() => select(null)"
						/>
					</GenericSearchResult>

					<hr v-else-if="searchValue" class="border-neutral-700">

					<div v-if="loading">
						<slot name="loading">
							<div class="flex items-center gap-2 p-4 overflow-hidden">
								<DynamicIcon icon="Loading" class="animate-spin" />
								<p>{{ loadingLabel }}</p>
							</div>
						</slot>
					</div>
					<div v-else class="max-h-72 overflow-y-auto">
						<div
							v-for="(result, index) in results.slice(0, maxResults)"
							:key="result._id||result.id||result.sid||index"
							@click="() => select(result)"
						>
							<slot name="result-item" :result="result">
								<GenericSearchResult>
									{{ result }}
								</GenericSearchResult>
							</slot>
						</div>
					</div>

					<div
						v-if="$slots.footer"
						class="bg-neutral-700/50 px-4 text-center py-1 text-sm flex items-center justify-center w-full"
					>
						<slot name="footer" />
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: all .2s ease-out;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
	transform-origin: top;
	transform: translateY(-0.5rem);
	transition: all .1s ease-in;
}

.selected-blurred {
	@apply pl-4 w-full h-13 py-3 bg-transparent cursor-pointer flex items-center
	focus:outline-none focus-visible:ring-2 focus-visible:ring-sky-400 rounded-md;
}
</style>
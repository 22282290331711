<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15 21H4C3.448 21 3 20.552 3 20V9C3 8.448 3.448 8 4 8H15C15.552 8 16 8.448 16 9V20C16 20.552 15.552 21 15 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 5V4C21 3.448 20.552 3 20 3H19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 15V16C21 16.552 20.552 17 20 17H19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 5V4C7 3.448 7.448 3 8 3H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13 3H15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 11V9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCopy"
};
</script>
<template>
	<g>
		<path
			d="M16 6V12C16 14.2091 14.2091 16 12 16V16C9.79086 16 8 14.2091 8 12V6"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 19H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconUnderline"
};
</script>
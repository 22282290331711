<template>
	<div>
		<div
			class="tab-item"
			:class="{ 'active': active}"
		>
			<div class="flex gap-2 px-6 py-2 items-baseline">
				<p class="font-medium select-none">
					{{ p.label }}
				</p>
				<AlertBadge v-if="p.badge" :label="p.badge" />
			</div>
		</div>
	</div>
</template>

<script setup>
import AlertBadge from "@/components/badges/AlertBadge.vue";

const p = defineProps({
	"label": {
		type: String,
		required: true,
	},
	"badge": {
		type: [String, Number],
		default: () => null,
	},
	"active": {
		type: Boolean,
		default: () => false,
	}
});
</script>

<style scoped>
.tab-item {
	@apply rounded-md cursor-pointer hover:border-neutral-600 hover:bg-neutral-600/50 transition-all border border-transparent;
}
.tab-item.active {
	@apply bg-neutral-600/50 border-neutral-600;
}
</style>
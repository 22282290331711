<script setup>
import {computed, ref} from "vue";

defineEmits(["update:modelValue"]);
const props = defineProps({
	// Percentage filled. 0 to 100.
	"modelValue": {
		type: Number,
		default: 50,
		required: true,
	},
	"size": {
		type: [Number,String],
		default: 128,
	},
	"stroke": {
		type: [Number,String],
		default: 16,
	},
});

const track = ref(null);
const randomId = Math.random().toString(36).slice(2)+Math.random().toString(36).slice(2);
const dashArray = computed(()=>{
	const value = totalLength.value/100 * props.modelValue;
	return totalLength.value + value;
});
const totalLength = computed(()=>{
	if (!track.value) return 0;
	return track.value.getTotalLength();
});
</script>

<template>
	<div>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			:viewBox="`0 0 ${size} ${size}`"
			preserveAspectRatio="none"
			:width="`${size}px`"
			:height="`${size}px`"
		>
			<defs>
				<filter id="highlight">
					<feDropShadow
						dx="1"
						dy="0"
						flood-color="#7dd3fc"
						stdDeviation="0.1"
					/>
					<feDropShadow
						dx="-1"
						dy="0"
						flood-color="#0369a1"
						stdDeviation="0.1"
					/>
				</filter>
				<linearGradient
					id="blueGradient"
					x1="0%"
					y1="0%"
					x2="100%"
					y2="100%"
				>
					<stop
						offset="0%"
						style="stop-color:#0369a1;stop-opacity:1"
					/>
					<stop
						offset="100%"
						style="stop-color:#38bdf8;stop-opacity:1"
					/>
				</linearGradient>
			</defs>
			<path
				:id="randomId"
				ref="track"
				fill="none"
				stroke="#404040"
				:stroke-width="stroke"
				:d="`M ${size/2},${stroke} A ${size/2 - stroke},${size/2 - stroke} 0 1 1 ${stroke},${size/2}`"
				:transform="`rotate(-135 ${size/2} ${size/2})`"
				stroke-linecap="round"
			/>
			<path
				fill="none"
				filter="url(#highlight)"
				stroke="url(#blueGradient)"
				:stroke-width="stroke"
				:d="`M ${size/2},${stroke} A ${size/2 - stroke},${size/2 - stroke} 0 1 1 ${stroke},${size/2}`"
				:transform="`rotate(-135 ${size/2} ${size/2})`"
				stroke-linecap="round"
				:stroke-dasharray="dashArray"
				:stroke-dashoffset="totalLength"
			/>
			<foreignObject
				:height="size"
				:width="size"
			>
				<slot />
			</foreignObject>
		</svg>
	</div>
</template>

<style scoped>

</style>
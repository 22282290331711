<template>
	<g>
		<path
			d="M4.85547 4.85352L6.27106 6.26911"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.0901 2.99609V4.99693"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4.99693 10.0901H2.99609"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.1451 19.1441L17.7295 17.7285"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.9104 21.0028V19.002"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.0029 13.9084H21.0038"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.9998 17.0021L10.4992 18.5027C9.83596 19.1662 8.93628 19.5389 7.99817 19.5389C7.06005 19.5389 6.16037 19.1662 5.49712 18.5027V18.5027C4.83368 17.8395 4.46094 16.9398 4.46094 16.0017C4.46094 15.0636 4.83368 14.1639 5.49712 13.5006L6.99775 12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 6.99775L13.5006 5.49712C14.1639 4.83368 15.0636 4.46094 16.0017 4.46094C16.9398 4.46094 17.8395 4.83368 18.5027 5.49712V5.49712C19.1662 6.16037 19.5389 7.06005 19.5389 7.99817C19.5389 8.93628 19.1662 9.83596 18.5027 10.4992L17.0021 11.9998"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconUnlink"
};
</script>
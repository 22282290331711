<template>
	<g>
		<path
			d="M14.5 16V5C14.5 3.89543 13.6046 3 12.5 3H11.5C10.3954 3 9.5 3.89543 9.5 5V16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2.50776 18.7919C8.49347 21.736 15.5065 21.736 21.4922 18.7919C21.8091 18.613 22.0036 18.276 22 17.9121V17C22 16.4477 21.5523 16 21 16H2.99999C2.4477 16 1.99999 16.4477 1.99999 17V17.9121C1.99641 18.276 2.19093 18.613 2.50776 18.7919Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 16V13.6407C20.9983 9.63473 18.3485 6.11226 14.5 5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.5 5C5.65148 6.11226 3.0017 9.63473 3 13.6407V16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconConstructionHat"
}
</script>
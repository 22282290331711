<script setup>
import {inject, ref} from "vue";
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {Account} from "@/storage/AccountCache";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const emit = defineEmits(["edit"]);
defineProps({
	"caseType": {
		type: String,
		required: true,
	},
	"pills": {
		type: Object,
		default: () => ({}),
	},
	"close": {
		type: Function,
		default: () => () => {
		},
	},
	"caseCreatorId": {
		type: String,
		default: () => null,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
	"handleSave": {
		type: Function,
		default: () => () => {
		},
	},
	"allowArchive": {
		type: Boolean,
		default: false,
	},
	"allowDelete": {
		type: Boolean,
		default: false,
	},
	"isEditing": {
		type: Boolean,
		default: false,
	},
	"entrySid": {
		type: Number,
		required: true,
		default: () => null,
	}
});

const account = Account();
const personal = inject("isPersonal");
const caseArchived = personal ? false : inject("isArchived") ?? false;
const cases = CaseAppealBrowser();
const handlers = inject("handlers");

const confirm = ref({
	type: null,
	prompted: false,
	timer: null,
});

function doConfirm(type) {
	clearTimeout(confirm.value.timer);

	if (confirm.value.prompted && confirm.value.type === type) {
		if (type === "archive") {
			handlers.handleArchiveEntry({reason: "Unknown"});
		} else if (type === "delete") {
			handlers.handleDeleteEntry({reason: "Unknown"});
		} else if (type === "restore") {
			handlers.handleRestoreEntry({reason: "Unknown"});
		}
		return;
	}

	confirm.value.type = type;
	confirm.value.prompted = true;

	confirm.value.timer = setTimeout(() => {
		confirm.value.prompted = false;
		confirm.value.type = null;
	}, 4000);
}
</script>

<template>
	<div v-if="!personal && !caseArchived" class="flex gap-2 w-full justify-end">
		<!-- Edit button -->
		<PermsCond
			v-if="!pills.archived"
			:needs="Nodes.Cases.ENTRY.EDIT(
				caseType,
				caseCreatorId===account.id,
				creatorId===account.id
			)"
		>
			<BasicButton
				:label="isEditing ? 'Cancel' : 'Edit'"
				type="link"
				:disabled="cases.isLoading('entryEdit', entrySid)"
				@click="emit('edit', !isEditing)"
			/>
		</PermsCond>
		<!-- Save button -->
		<BasicButton
			v-if="isEditing"
			label="Save"
			:disabled="cases.isLoading('entryEdit', entrySid)"
			@click="handleSave"
		/>
		<!-- Restore button -->
		<PermsCond
			v-if="pills.archived && !isEditing"
			:needs="Nodes.Cases.ENTRY.ARCHIVED.RESTORE(caseType, caseCreatorId===account.id, creatorId===account.id)"
		>
			<BasicButton
				:label="confirm.type === 'restore' && confirm.prompted ? 'Are you sure?' : 'Restore'"
				:disabled="cases.isLoading('entryRestore', entrySid)"
				type="link"
				@click="doConfirm('restore')"
			/>
		</PermsCond>
		<!-- Archive button -->
		<PermsCond
			v-if="allowArchive && !pills.archived && !isEditing"
			:needs="Nodes.Cases.ENTRY.ARCHIVE(caseType, caseCreatorId===account.id, creatorId===account.id)"
		>
			<BasicButton
				:label="confirm.type === 'archive' && confirm.prompted ? 'Are you sure?' : 'Archive'"
				:disabled="cases.isLoading('entryArchive', entrySid)"
				type="link"
				@click="doConfirm('archive')"
			/>
		</PermsCond>
		<!-- Delete button -->
		<PermsCond
			v-if="(allowDelete || pills.archived) && !isEditing"
			:needs="
				pills.archived
					? Nodes.Cases.ENTRY.ARCHIVED.DELETE(caseType, caseCreatorId===account.id, creatorId===account.id)
					: Nodes.Cases.ENTRY.DELETE(caseType, caseCreatorId===account.id, creatorId===account.id)
			"
		>
			<BasicButton
				:label="confirm.type === 'delete' && confirm.prompted ? 'Are you sure?' : 'Delete'"
				:disabled="cases.isLoading('entryDelete', entrySid)"
				type="danger"
				@click="doConfirm('delete')"
			/>
		</PermsCond>
	</div>
	<div v-else-if="personal || caseArchived" class="flex gap-2 w-full justify-end">
		<!-- Close button -->
		<BasicButton
			label="Close"
			type="link"
			@click="close"
		/>
	</div>
</template>
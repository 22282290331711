<template>
	<g>
		<path
			d="M18 15V16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18 20V21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.3996 16.5L16.2996 17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.7004 19L20.6004 19.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.3996 19.5L16.2996 19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.7004 17L20.6004 16.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.4 16.6C20.2 17.4 20.2 18.6 19.4 19.4C18.6 20.2 17.4 20.2 16.6 19.4C15.8 18.6 15.8 17.4 16.6 16.6C17.4 15.8 18.6 15.8 19.4 16.6"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.0005 5.2C16.7005 6.9 16.7005 9.6 15.0005 11.2C13.3005 12.8 10.6005 12.9 9.00048 11.2C7.40048 9.5 7.30048 6.8 9.00048 5.2C10.7005 3.6 13.3005 3.6 15.0005 5.2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4 20C4 17.5 6 15.5 8.5 15.5H11.1"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconBotSetting"
};
</script>
<script>
export default {
	name: "IconMaximize"
}
</script>

<template>
	<g>
		<path
			d="M7 21H3V17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3 7V3H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 17V21H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 21L3 3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 3L3 21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17 3H21V7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {ref} from "vue";
import TextInput from "@/components/form/Text/TextInput.vue";
import JoyrideBox from "@/components/joyrides/JoyrideBox.vue";
import {Account} from "@/storage/AccountCache";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import GenericSearchResult from "@/components/form/search/GenericSearchResult.vue";
import {useRoute} from "vue-router";
import CloseButton from "@/components/buttons/CloseButton.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import {capitalize} from "archivian-utils";
import UserHighlightPanel from "@/components/panels/UserHighlightPanel.vue";
import UserCache from "@/storage/UserCache";

const props = defineProps({
	"close": {
		type: Function,
		default: () => () => {
		},
	},
	"handleSave": {
		type: Function,
		default: () => () => {
		},
	},
	"handleDelete": {
		type: Function,
		default: () => () => {
		},
	},
	"label": {
		type: String,
		required: true,
	},
	"lastUserId": {
		type: String,
		required: true,
	},
	"targetCase": {
		type: Object,
		required: true,
	},
});

const route = useRoute();
const localLabel = ref(props.label);
const deleteState = ref({
	timer: null,
	confirmed: false,
});
const cases = CaseAppealBrowser();
const users = UserCache();
const account = Account();
const cancel = () => {
	if (cases.editing.linkedCase) {
		localLabel.value = props.label;
		cases.editing.linkedCase = false;
	} else props.close();
};

const handleSave = () => {
	if (!props.targetCase.sid) return;
	if (props.label === localLabel.value) return;
	return props.handleSave({
		label: localLabel.value,
		sid: props.targetCase.sid,
	});
};
const deleteLink = () => {
	if (!deleteState.value.confirmed) {
		deleteState.value.confirmed = true;
		return deleteState.value.timer = setTimeout(() => {
			deleteState.value.confirmed = false;
		}, 3500);
	}

	clearTimeout(deleteState.value.timer);
	return props.handleDelete({
		sid: props.targetCase.sid,
		reason: "Unknown reason",
	});
};
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<div class="flex items-center w-full justify-between">
				<h2>Linked Case</h2>

				<div class="CaseActionButtons">
					<CloseButton label="Close panel" @click="close" />
				</div>
			</div>
		</template>

		<template #main>
			<JoyrideBox
				joyride-id="case-linked-cases"
				description="Case linking lets connect cases that are somehow related to another. For example, an official warning that led to a timeout."
			/>

			<UserHighlightPanel
				v-if="lastUserId"
				:username="users.username(lastUserId)||'Unknown Staff'"
				:title="['Last Editor',users.titleOf(lastUserId)].filter(Boolean).join(' • ')"
				:avatar-url="users.avatarOf(lastUserId, 128)"
				:user-id="lastUserId"
			/>

			<div>
				<label for="linkLabel">Label</label>
				<p v-if="!cases.editing.linkedCase">
					{{ label }}
				</p>
				<TextInput
					v-else
					id="linkLabel"
					v-model="localLabel"
					placeholder="E.g. Victim or Instigator"
					maxlength="32"
				/>
			</div>

			<div>
				<label for="selectUser">Linked Case</label>
				<RouterLink
					:to="{
						name:'guild-cases',
						params: { guildId: route.params.guildId, caseSid: targetCase.sid }
					}"
				>
					<GenericSearchResult class="border-details rounded-md shadow bg-neutral-800/50">
						<DynamicIcon v-if="!targetCase.caseType.toLowerCase().includes('unknown')" :icon="targetCase.caseType ?? 'Question'" />
						<p>{{ capitalize(targetCase?.caseType ?? 'Unknown Case Type') }} &bull; #{{ targetCase.sid }}</p>
					</GenericSearchResult>
				</RouterLink>
			</div>
		</template>

		<template #footer>
			<div class="flex gap-2 w-full justify-end">
				<BasicButton
					:label="cases.editing.linkedCase ? 'Cancel' : 'Close'"
					type="link"
					@click="cancel"
				/>
				<PermsCond :needs="Nodes.Cases.LINKED.CASE.RENAME(lastUserId === account.id)">
					<BasicButton
						v-if="!cases.editing.linkedCase"
						label="Edit"
						:disabled="cases.isLoading('renameLinked', targetCase.sid)"
						@click="cases.editing.linkedCase = true"
					/>
					<BasicButton
						v-else
						label="Save"
						:disabled="!localLabel || localLabel===label || cases.isLoading('renameLinked', targetCase.sid)"
						@click="handleSave"
					/>
				</PermsCond>
				<PermsCond :needs="Nodes.Cases.LINKED.CASE.DELETE(lastUserId === account.id)">
					<BasicButton
						v-if="!cases.editing.linkedCase"
						:label="deleteState.confirmed ? 'Are you sure?' : 'Delete'"
						:disabled="cases.isLoading('deleteLinked', targetCase.sid)"
						type="danger"
						@click="deleteLink"
					/>
				</PermsCond>
			</div>
		</template>
	</InspectResourceLayout>
</template>
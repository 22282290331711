/**
 * Flags for cases (settings apply per-case)
 */
export const CaseFlagsMask = {
    // The case is visible to the target
    showCase: 1 << 0,
    // Previously used for demo, now unused
    UNUSED: 1 << 1,
    // Show the creators of the case/creators in resources (case, entries, files, links)
    showCreators: 1 << 2,
    // Deny user from making appeals on this case
    denyAppeals: 1 << 3,
};

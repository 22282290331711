<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import InspectFileHeaderView from "@/partials/inspect/case/inspectFile/InspectFileHeaderView.vue";
import Nodes from "@/script/Nodes";
import {Account} from "@/storage/AccountCache";
import InspectFileFooterView from "@/partials/inspect/case/inspectFile/InspectFileFooterView.vue";
import InspectFileMainView from "@/partials/inspect/case/inspectFile/InspectFileMainView.vue";
import {computed, inject} from "vue";
import {caseFileLink, flagsToObject} from "@/script/convert";

const props = defineProps({
	"file": {
		type: Object,
		required: true,
	},
	"caseType": {
		type: String,
		required: true,
	},
	"caseSid": {
		type: [Number, String],
		required: true,
	},
	"caseTargetId": {
		type: String,
		default: () => null,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
});
const handlers = inject("handlers");
const account = Account();
const fileLink = computed(() => caseFileLink(props.file.guildId, props.caseSid, props.file.fileId));

/**
 * @type {ComputedRef<FileFlags & {archived:boolean}>}
 */
const pills = computed(() => {
	const flags = flagsToObject("file", props.file.flags);
	const visibilityFlags = flagsToObject("fileVisibility", props.file.visibilityFlags);

	// Archived entry is hidden from user. Display as hidden.
	if (visibilityFlags.showFile && !!props.file?.locked?.at) flags.visibilityFlags = false;

	return {
		...flags,
		...visibilityFlags,
		archived: !!props.file?.locked?.at,
	};
});

const permissions = computed(() => {
	const p = {
		archiveFile: !props.creatorId
			? null
			: Nodes.Cases.FILE.ARCHIVE(props.caseType, props.creatorId === account.id, props.file.creatorId === account.id),
		restoreFile: !props.creatorId
			? null
			: Nodes.Cases.FILE.ARCHIVED.RESTORE(props.caseType, props.creatorId === account.id, props.file.creatorId === account.id),
		deleteFile: null, // A bit more complicated than a ternary to make
	};

	if (props.creatorId) {
		if (props.file?.archived?.at) {
			p.deleteFile = Nodes.Cases.FILE.ARCHIVED.DELETE(props.caseType, props.creatorId === account.id, props.file.creatorId === account.id);
		} else {
			p.deleteFile = Nodes.Cases.FILE.DELETE(props.caseType, props.creatorId === account.id, props.file.creatorId === account.id);
		}
	}

	return p;
});
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<InspectFileHeaderView
				:file-id="file.fileId"
				:creator-id="file.creatorId"
				:created-at="file.createdAt"
				:pills="pills"
				:close="handlers.closeResource"
			/>
		</template>

		<template #main>
			<InspectFileMainView
				:pills="pills"
				:case-sid="caseSid"
				:file="file"
				:target-id="caseTargetId"
				:handle-update-visibility="handlers.updateFileVisibility"
				:file-link="fileLink"
			/>
		</template>

		<template #footer>
			<InspectFileFooterView
				:id="file.fileId"
				:close="handlers.closeResource"
				:pills="pills"
				:archive-file-permission="permissions.archiveFile"
				:restore-file-permission="permissions.restoreFile"
				:delete-file-permission="permissions.deleteFile"
				:handle-archive-file="handlers.archiveFile"
				:handle-restore-file="handlers.restoreFile"
				:handle-delete-file="handlers.deleteFile"
			/>
		</template>
	</InspectResourceLayout>
</template>

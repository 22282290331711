/**
 * A simple utility that helps us merge duplicate HTTP requests.
 * It then distributes the responses to all the promises that is waiting.
 */
export default class RequestMerger {
	constructor(requestFunction) {
		this.requests = new Map();
		this.makeRequest = requestFunction;
	}

	/**
	 * A quick way to make an identifier for a request
	 * @param {string} url
	 * @param {"GET"|"POST"|"PATCH"|"DELETE"} [method="GET"]
	 * @param {?Object} [options]
	 * @returns {string}
	 */
	makeIdentifier(url, method, options) {
		return `${method}:${url}:${JSON.stringify(options)}`;
	}

	/**
	 * Enqueues a HTTP request
	 * @param {string} requestId
	 * @param {string} path Path in endpoint to use
	 * @param {("GET"|"POST"|"PUT"|"PATCH"|"DELETE")} [method="GET"]
	 * @param {{body?: Object, query?: Object, loginOn401?: boolean, headers?: Record<string, string|number|boolean>}} [data={loginOn401:true}] The data to send. Set
	 *     'data.loginOn401' to {@link false} in order to not automatically trigger re-login prompt
	 * @returns {Promise<BackendResult>}
	 */
	queue(requestId, path, method = "GET", data) {
		// If a queue exists, add the resolve/reject to the array of calls waiting for this response
		if (this.requests.has(requestId)) return this.requests.get(requestId);

		// TODO Maybe we can pass an abort controller here and set up ability to abort all if user navigates away?

		const promise = this.makeRequest(path, method, data, requestId);
		this.requests.set(requestId, promise);
		return promise;
	}

	/**
	 * Removes all paths that have no promises waiting for them.
	 * @param {?string} [identifier] Optionally, the specific identifier to clean up
	 */
	cleanup(identifier) {
		if (identifier) return this.requests.delete(identifier);
		return this.requests.clear();
	}
}
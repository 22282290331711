/**
 * Discord's EPOCH.
 * Considered inception of Discord as well.
 */
export const EPOCH = 1_420_070_400_000;
export function msToHours(ms) {
    return Math.floor(ms / 1000 / 60 / 60);
}
const msUnits = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: 24 * 60 * 60 * 1000 * 365 / 12,
    week: 24 * 60 * 60 * 1000 * 7,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
};
/**
 * Normalises a duration to MS and future date relative to now
 */
export function durationSyntaxToDate(durations) {
    let ms = 0;
    for (const key in durations)
        ms += durations[key] * msUnits[key];
    return {
        ms,
        date: new Date(Date.now() + ms),
    };
}
/**
 * Spreads or compacts durations across groups. For example 195 hours -> 1 week, 1 day, 3 hours.
 * @param duration The duration to normalise
 * @param [removeUnused=true] If there's a group with 0, that group will be removed from the returned object
 */
export function spreadDuration(duration, removeUnused = true) {
    const units = {
        year: 365,
        day: 24,
        hour: 60,
        minute: 60,
        second: 1,
    };
    // Drop poorly divisible units
    if ("month" in duration) {
        duration.day += (duration.month * 30);
        delete duration.month;
    }
    if ("week" in duration) {
        duration.day += (duration.week * 7);
        delete duration.week;
    }
    const order = ["second", "minute", "hour", "day", "year"];
    for (let i = 0; i < order.length; i++) {
        const parent = order[i + 1]
            ? order[i + 1]
            : order[i];
        const leftover = duration[order[i]] % units[parent];
        duration[parent] += (duration[order[i]] - leftover) / units[parent];
        duration[order[i]] = leftover;
    }
    if (removeUnused) {
        for (const key in duration) {
            if (!duration[key]) {
                delete duration[key];
            }
        }
    }
    return duration;
}
/**
 * Takes a duration string and breaks it down into provided components.
 * • Accepts: #Y#MO#W#D#H#M#S
 * • Can also handle out-of order time, and repeating the same duration.
 * • Normalises time, e.g. 70 second -> 1 minute, 10 second.
 * • Removes unused durations, e.g. if no years, it will not be included in returned result
 * @example const times = duration("4W2MO6H120M30S"); // {day:88,hour:8,second:30}
 * @param duration
 * @param [throwUnknown=false] Make the parser throw on unknown duration values instead of ignoring them
 */
export function deconstructDurationSyntax(duration, throwUnknown = false) {
    const arr = duration
        .toUpperCase()
        .split(/(\d+)/)
        .filter(Boolean);
    const times = {
        year: 0,
        month: 0,
        week: 0,
        day: 0,
        hour: 0,
        minute: 0,
        second: 0,
    };
    while (arr.length) {
        const num = parseInt(arr.shift());
        const unit = arr.shift();
        if (isNaN(num)) {
            throw new Error("Invalid syntax; duration string must start with a number, followed by unit");
        }
        switch (unit) {
            case "S":
                times.second += num;
                break;
            case "M":
                times.minute += num;
                break;
            case "H":
                times.hour += num;
                break;
            case "D":
                times.day += num;
                break;
            case "W":
                times.week += num;
                break;
            case "MO":
                times.month += num;
                break;
            case "Y":
                times.year += num;
                break;
            default:
                if (throwUnknown)
                    throw new Error(`Unknown duration unit '${unit}'`);
        }
    }
    return times;
}
/**
 * Calculates the duration between two timestamps.<br/>
 * **String:** e.g. 1hr, 30 secs<br/>
 * **Mention:** e.g &lt;t:123123123:R&gt;
 * @param futureDate
 * @param [pastDate=new Date()]
 * @param [opts={}] Additional options
 * @returns {{year: number, day: number, hour: number, second: number, string: string, mention: string}}
 */
export function timeLeftUntil(futureDate, pastDate = new Date(), opts = {}) {
    const dist = futureDate.getTime() + 1000 - pastDate.getTime();
    const timeLeftData = {
        year: Math.floor(dist / msUnits.year),
        day: Math.floor(dist / msUnits.day),
        hour: Math.floor((dist % msUnits.day) / msUnits.hour),
        minute: Math.floor((dist % msUnits.hour) / msUnits.minute),
        second: Math.floor((dist % msUnits.minute) / msUnits.second),
        string: "0 secs",
        mention: `<t:${(futureDate.getTime() / msUnits.second).toFixed(0)}:R>`,
    };
    if (!opts.highestValueOnly) {
        const strings = Array();
        if (timeLeftData.year > 0) {
            strings.push(`${timeLeftData.year} yr${timeLeftData.year === 1
                ? ""
                : "s"}`);
        }
        if (timeLeftData.day > 0) {
            strings.push(`${timeLeftData.day} day${timeLeftData.day === 1
                ? ""
                : "s"}`);
        }
        if (timeLeftData.hour > 0) {
            strings.push(`${timeLeftData.hour} hr${timeLeftData.hour === 1
                ? ""
                : "s"}`);
        }
        if (timeLeftData.minute > 0) {
            strings.push(`${timeLeftData.minute} min${timeLeftData.minute === 1
                ? ""
                : "s"}`);
        }
        if (timeLeftData.second > 0) {
            strings.push(`${timeLeftData.second} sec${timeLeftData.second === 1
                ? ""
                : "s"}`);
        }
        if (!opts.and)
            timeLeftData.string = strings.join(", ");
        else {
            const last = strings.pop();
            timeLeftData.string = `${strings.join(", ")} and ${last}`;
        }
        return timeLeftData;
    }
    if (timeLeftData.year) {
        timeLeftData.year = Math.round(dist / (1000 * 60 * 60 * 24 * 365));
        timeLeftData.day = 0;
        timeLeftData.hour = 0;
        timeLeftData.minute = 0;
        timeLeftData.second = 0;
        timeLeftData.string = `${timeLeftData.year} yr${timeLeftData.year > 1 ? "s" : ""}`;
        return timeLeftData;
    }
    if (timeLeftData.day) {
        timeLeftData.day = Math.round(dist / (1000 * 60 * 60 * 24));
        timeLeftData.hour = 0;
        timeLeftData.minute = 0;
        timeLeftData.second = 0;
        timeLeftData.string = `${timeLeftData.day} day${timeLeftData.day > 1 ? "s" : ""}`;
        return timeLeftData;
    }
    if (timeLeftData.hour) {
        timeLeftData.hour = Math.round(dist / (1000 * 60 * 60));
        timeLeftData.minute = 0;
        timeLeftData.second = 0;
        timeLeftData.string = `${timeLeftData.hour} hr${timeLeftData.hour > 1 ? "s" : ""}`;
        return timeLeftData;
    }
    if (timeLeftData.minute) {
        timeLeftData.minute = Math.round(dist / (1000 * 60));
        timeLeftData.second = 0;
        timeLeftData.string = `${timeLeftData.minute} min${timeLeftData.minute > 1 ? "s" : ""}`;
        return timeLeftData;
    }
    if (timeLeftData.second) {
        timeLeftData.second = Math.round(dist / 1000);
        timeLeftData.string = `${timeLeftData.second} sec${timeLeftData.second > 1 ? "s" : ""}`;
        return timeLeftData;
    }
    return timeLeftData;
}
/**
 * Deconstructs a Discord Snowflake into metadata, including timestamp.
 * Code is a compilation of Discord.JS source code.
 */
export function deconstructSnowflake(snowflake) {
    const idToBinary = num => {
        let bin = "";
        let high = parseInt(num.slice(0, -10)) || 0;
        let low = parseInt(num.slice(-10));
        while (low > 0 || high > 0) {
            bin = String(low & 1) + bin;
            low = Math.floor(low / 2);
            if (high > 0) {
                low += 5_000_000_000 * (high % 2);
                high = Math.floor(high / 2);
            }
        }
        return bin;
    };
    // @ts-ignore
    const BINARY = idToBinary(snowflake).toString(2).padStart(64, "0");
    const ts = parseInt(BINARY.substring(0, 42), 2) + EPOCH;
    const mentionRelative = `<t:${parseInt((ts / 1000).toFixed(0))}:R>`;
    const mentionAbsolute = `<t:${parseInt((ts / 1000).toFixed(0))}>`;
    return {
        timestamp: ts,
        get date() {
            return new Date(this.timestamp);
        },
        workerId: parseInt(BINARY.substring(42, 47), 2),
        processId: parseInt(BINARY.substring(47, 52), 2),
        increment: parseInt(BINARY.substring(52, 64), 2),
        binary: BINARY,
        mentionRelative,
        mentionAbsolute,
    };
}
/**
 * Converts a Snowflake to date
 */
export function snowflakeToDate(snowflake) {
    const dateBits = Number(BigInt.asUintN(64, BigInt(snowflake)) >> 22n);
    const date = new Date(dateBits + EPOCH);
    return {
        date,
        mentionRelative: `<t:${parseInt((date.getTime() / 1000).toFixed(0))}:R>`,
        mentionAbsolute: `<t:${parseInt((date.getTime() / 1000).toFixed(0))}>`,
    };
}
/**
 * Creates a "fake" Discord snowflake using a given date
 */
export function dateToSnowflake(date) {
    return ((BigInt(date.getTime()) - BigInt(EPOCH)) << 22n).toString();
}
/**
 * Converts a MongoDB ObjectId to a Date
 */
export function objectIdToDate(objectId) {
    return new Date(parseInt(objectId.toString().substring(0, 8), 16) * 1000);
}
/**
 * Converts a Date to a faux MongoDB ObjectId
 */
export function dateToObjectId(date) {
    // First 8 for time, latter 16 for random and PID/incremental
    return Math.floor(date.getTime() / 1000).toString(16).padEnd(24, "0");
}
/**
 * Extracts the timestamp information from an UUIDv7 ID
 * - 32-digit hexadecimal format without hyphens: `0189dcd553117d408db09496a2eef37b`
 * - 8-4-4-4-12 hyphenated format: `0189dcd5-5311-7d40-8db0-9496a2eef37b`
 * - Hyphenated format with surrounding braces: `{0189dcd5-5311-7d40-8db0-9496a2eef37b}`
 * - RFC 4122 URN format: `urn:uuid:0189dcd5-5311-7d40-8db0-9496a2eef37b`
 */
export function uuidV7ToDate(uuid) {
    const intArr = _parseUUIDV7(uuid);
    const timestampBytes = new Uint8Array(8);
    timestampBytes.set(new Uint8Array(intArr.buffer.slice(0, 6)), 2);
    const dataView = new DataView(timestampBytes.buffer).getBigUint64(0, false);
    return new Date(Number(dataView));
}
/**
 * Make a mentionable relative timestamp for Discord
 */
export function toRelativeMention(date) {
    if (typeof (date) === "number")
        date = new Date(date);
    return `<t:${parseInt((date.getTime() / 1000).toFixed(0))}:R>`;
}
/**
 * Calculates the difference between two dates in months.
 * If you have a partial month, it is counted as a full month.
 * @author T.J. Crowder (https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript#answer-2536445)
 */
export function monthDiff(fromDate, toDate) {
    let months;
    months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
    months -= fromDate.getMonth();
    months += toDate.getMonth();
    return months <= 0 ? 0 : months;
}
/**
 * Builds a byte array from a string representation.
 *
 * This method accepts the following formats:
 *
 * - 32-digit hexadecimal format without hyphens: `0189dcd553117d408db09496a2eef37b`
 * - 8-4-4-4-12 hyphenated format: `0189dcd5-5311-7d40-8db0-9496a2eef37b`
 * - Hyphenated format with surrounding braces: `{0189dcd5-5311-7d40-8db0-9496a2eef37b}`
 * - RFC 4122 URN format: `urn:uuid:0189dcd5-5311-7d40-8db0-9496a2eef37b`
 *
 * Leading and trailing whitespaces represents an error.
 *
 * @throws SyntaxError if the argument could not parse as a valid UUID string.
 */
function _parseUUIDV7(uuid) {
    let _a, _b, _c, _d;
    let hex = undefined;
    switch (uuid.length) {
        case 32:
            hex = (_a = /^[0-9a-f]{32}$/i.exec(uuid)) === null || _a === void 0 ? void 0 : _a[0];
            break;
        case 36:
            hex =
                (_b = /^([0-9a-f]{8})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{12})$/i
                    .exec(uuid)) === null || _b === void 0 ? void 0 : _b.slice(1, 6).join("");
            break;
        case 38:
            hex =
                (_c = /^\{([0-9a-f]{8})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{12})\}$/i
                    .exec(uuid)) === null || _c === void 0 ? void 0 : _c.slice(1, 6).join("");
            break;
        case 45:
            hex =
                (_d = /^urn:uuid:([0-9a-f]{8})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{12})$/i
                    .exec(uuid)) === null || _d === void 0 ? void 0 : _d.slice(1, 6).join("");
            break;
        default:
            break;
    }
    if (hex) {
        const inner = new Uint8Array(16);
        for (let i = 0; i < 16; i += 4) {
            const n = parseInt(hex.substring(2 * i, 2 * i + 8), 16);
            inner[i + 0] = n >>> 24;
            inner[i + 1] = n >>> 16;
            inner[i + 2] = n >>> 8;
            inner[i + 3] = n;
        }
        return inner;
    }
    else {
        throw new SyntaxError("could not parse UUID string");
    }
}
/**
 * A lazy ticker that ticks towards a future date, or away from a past date.
 * The ticker will be as lazy as possible, only ticking when it needs to.
 *
 * For example, if mode is "away" and 50 seconds already passed,
 * it will only tick once every second for 10 seconds, then once a minute.
 * Once it passes a week-long ticks, it will stop ticking.
 *
 * And for "towards", and it's 3 minutes and 10 seconds left,
 * it will tick once a minute for 2 minutes, then once a second for 10 + 60 seconds.
 * Once the date is reached, it will stop ticking.
 * If future date is a week or more away, it will not tick.
 *
 * For every tick, it will execute your callback and pass some metadata.
 * @param date The target date (future or past)
 * @param direction Which direction we're heading
 * @param tickCb Your function to execute for every tick
 * @param [options] Additional options
 * @param [_meta] Metadata for the ticker. Do not modify this object. Do not pass this object yourself.
 * @returns A reference to the Tick Meta object that is also passed in every callback
 */
export function lazyTicker(date, direction = "away", tickCb, options, _meta = { tick: 0, end: false, msUntilNext: 0, timeout: null }) {
    /**
     * The entire thing could be improved by using setInterval for more correct timings.
     * With setTimeout you gradually shift more out of accuracy, since timer is set after
     * execution. With setInterval it is more accurate and does not care about execution time.
     *
     * Though it would mean more tricky logic to start, stop, and change intervals.
     */
    // Order of the units we try to get rid of first, to be as lazy as possible
    const order = ["day", "hour", "minute", "second"];
    let nextTimeout = 0;
    const dist = Math.abs(date.getTime() - Date.now());
    const table = {
        week: Math.floor(dist / msUnits.week),
        day: Math.floor(dist / msUnits.day),
        hour: Math.floor((dist % msUnits.day) / msUnits.hour),
        minute: Math.floor((dist % msUnits.hour) / msUnits.minute),
        second: Math.floor((dist % msUnits.minute) / msUnits.second),
    };
    // Exit early: If >=week(s), do not tick
    if (table.week) {
        _meta.tick++;
        _meta.end = true;
        _meta.msUntilNext = 0;
        tickCb(_meta);
        return _meta;
    }
    // If "towards" and date is same or expired, do not tick
    if (direction === "towards" && Date.now() >= date.getTime()) {
        _meta.tick++;
        _meta.end = true;
        _meta.msUntilNext = 0;
        tickCb(_meta);
        return _meta;
    }
    // Starting from the largest unit, ff there are any values in this unit, we must use it
    for (let i = 0; i < order.length; i++) {
        if (options.constant) {
            nextTimeout = 1000;
            break;
        }
        let unitName = order[i];
        const duration = table[unitName];
        const lowerUnit = order[i + 1];
        if (!duration)
            continue; // Skip if nothing to wait for in this unit
        // "Away" is rather easy
        if (direction === "away") {
            // Find highest unit used
            // - We already are on it due to outer loop
            // Get the unit below it
            // - Already populated at the start of the loop
            // If lower unit, and it is 0, we can begin being lazy
            if (lowerUnit && table[lowerUnit] === 0) {
                nextTimeout = msUnits[unitName];
                break;
            }
            // If no unit below, use the lowest unit...
            const nextUnit = lowerUnit || unitName;
            // ... else, use said unit
            nextTimeout = msUnits[nextUnit];
            break;
        }
        // If 2 or more duration in this unit, and there is a lower unit, wait for Duration - 1
        if (duration >= 2 && lowerUnit) {
            nextTimeout = (duration - 1) * msUnits[unitName];
            break;
        }
        // Duration will have 1 left now, check lower unit if it has anything we can wait for
        // e.g. 1 minute left, and 5 seconds. We can wait 5 seconds, then we handle the minute.
        if (lowerUnit && table[lowerUnit]) {
            nextTimeout = table[lowerUnit] * msUnits[lowerUnit];
            break;
        }
        // Nothing left in this unit, count down this unit using the lower unit
        let nextUnit = lowerUnit || unitName;
        nextTimeout = msUnits[nextUnit]; // table[unitName] === 1 now anyway
    }
    _meta.msUntilNext = nextTimeout;
    if (nextTimeout) {
        _meta.timeout = setTimeout(() => {
            _meta.tick++;
            _meta.end = false;
            return tickCb(lazyTicker(date, direction, tickCb, options, _meta));
        }, nextTimeout);
    }
    else {
        _meta.tick++;
        _meta.end = true;
        tickCb(_meta);
    }
    return _meta;
}

<template>
	<!-- Error with files -->
	<ErrorCallout
		v-if="state.error.label"
		:label="state.error.label"
		:texts="state.error.texts"
	/>
	<!-- File field -->
	<div id="files" class="h-min relative grid grid-cols-3 gap-2 w-full">
		<!-- Drop field -->
		<PermsCond :needs="needs">
			<DropFileField
				v-if="(files.length + state.queue.completed.length + Object.keys(state.queue.next).length) < maxFiles"
				width="full"
				:max-size="maxFileSize"
				@set-files="handleQueueFiles"
			/>
		</PermsCond>

		<!-- Files queued for upload / currently uploading -->
		<UploadingFile
			v-for="f in state.queue.next"
			:id="f.id"
			:key="f.id"
			:file-size="f.size"
			:file-name="state.queue.current.id === f.id ? `(${Math.round(state.queue.current.progress*100)}%) ${f.name}` : `(Queued) ${f.name}`"
			:background-image="f.preview"
			:mime="f.type"
			:icon="fileIcon(f.type || f.fakeMime, f.ext)"
			:progress="state.queue.current.id === f.id ? state.queue.current.progress : 0"
			@abort="abortFileUpload"
		/>

		<!-- Files uploaded just now -->
		<UploadedFile
			v-for="f in state.queue.completed"
			:id="f.id"
			:key="f.id"
			:create-inspect-file-link="createInspectFileLink"
			:create-direct-file-link="createDirectFileLink"
			class="w-full"
			:file-size="f.size"
			:file-name="f.newName"
			:mime="f.type"
			:background-image="f.preview"
			:icon="fileIcon(f.type || f.fakeMime, f.ext)"
			:is-archived="f.isArchived"
			@archive="emit('archive', $event)"
			@delete="emit('delete', $event)"
			@restore="emit('restore', $event)"
		/>

		<!-- Dummy uploaded file -->
		<!--		<UploadedFile-->
		<!--				key="123123"-->
		<!--				id="123123"-->
		<!--				class="w-full"-->
		<!--				:file-size="10_000_0"-->
		<!--				file-name="test.bat"-->
		<!--				mime="text/bat"-->
		<!--				:icon="fileIcon('text/bat')"-->
		<!--				@action="handleFileAction"-->
		<!--		/>-->

		<!-- Already uploaded files -->
		<UploadedFile
			v-for="f in files"
			:id="f.id"
			:key="f.id"
			:create-inspect-file-link="createInspectFileLink"
			:create-direct-file-link="createDirectFileLink"
			:file-size="f.size"
			:file-name="f.name"
			:mime="f.mime"
			:icon="fileIcon(f.mime, f.ext)"
			:background-image="f.mime?.startsWith('image') ? f.image : ''"
			:is-archived="f.isArchived"
			@archive="emit('archive', $event)"
			@delete="emit('delete', $event)"
			@restore="emit('restore', $event)"
		/>
	</div>
</template>

<script setup>
import {ref} from "vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import DropFileField from "@/components/form/file-upload/DropFileField.vue";
import UploadingFile from "@/components/form/file-upload/UploadingFile.vue";
import UploadedFile from "@/components/form/file-upload/UploadedFile.vue";
import {fileIcon, fileSize, fileToBase64} from "@/script/convert";
import {file} from "@/script/APICalls";
import {useRoute} from "vue-router";
import PermsCond from "@/components/PermsCond.vue";

const route = useRoute();
const emit = defineEmits(["archive", "delete", "restore"]);

const props = defineProps({
	"guildId": {
		type: String,
		required: true,
	},
	// Max amount of files this field can contain
	"maxFiles": {
		type: Number,
		required: true,
		default: () => 2,
	},
	// File types to accept (for <input type="file">)
	"accept": {
		type: String,
		default: () => null,
	},
	// Max size a single file can be (in Bytes)
	"maxFileSize": {
		type: Number,
		default: () => 10 * 1024 * 1024 // 10 MiB
	},
	/**
	 * @typedef {Object} UploadedFile
	 * @prop {string} id File ID, might be similar to name
	 * @prop {string} name The name + any extension
	 * @prop {number} size File-size in bytes
	 * @prop {string} mime Mimetype of the file
	 * @prop {string} [image] Base64 or image URL, used as preview of the file
	 * @prop {string} [publicLink] A publicly accessible link of the file
	 * @prop {boolean} [isArchived] If the file is archived or not
	 */
	/**
	 * A list of existing {@see UploadedFile} to display
	 */
	"files": {
		type: Array,
		default: () => null,
	},
	/**
	 * The permission node user needs in order to upload files.
	 * For viewing of files, don't rely on UI. Rely instead on API not sending any.
	 */
	"needs": {
		type: String,
		default: () => null,
	},
	"createInspectFileLink": {
		// (fileId, ext) => string
		type: Function,
		default: () => () => {
		},
	},
	"createDirectFileLink": {
		// (fileId, ext) => string
		type: Function,
		default: () => () => {
		},
	},
});

const state = ref({
	"error": {
		label: null,
		texts: []
	},
	"queue": {
		instance: null,
		"current": {
			id: null,
			progress: 0,
		},
		next: {},
		completed: []
	}
});

/**
 * Handles appending dropped files into the file upload queue
 * @param {FileList} files
 */
async function handleQueueFiles(files) {
	if (files.length > props.maxFiles) return state.value.error.label = "Too many files. Max " + props.maxFiles;
	if (state.value.queue.instance && state.value.queue.instance.size > props.maxFiles) return state.value.error.title = "Too many files. Max " + props.maxFiles;
	state.value.error.title = null;

	const exceeding = [];
	for await (const file of files) {
		file.id = file.name + file.lastModified?.toString(16) + file.size?.toString(16) + file?.type;

		// Skip duplicates
		if (state.value.queue.instance && state.value.queue.instance.ids.has(file.id)) continue;

		// Check file size
		if (file.size > props.maxFileSize) {
			exceeding.push(file);
			continue;
		}

		if (!file.type) file.fakeMime = "a/" + file.name.split(".").slice(-1)[0];
		if (file.type.startsWith("image/")) {
			file.preview = await fileToBase64(file).catch(() => null);
		}

		state.value.queue.next[file.id] = file;

		state.value.queue.current.id = file.id;
	}

	if (exceeding.length) {
		state.value.error.texts = exceeding.map(f => `${f.name}`);
		state.value.error.label = `One or more files are too large (over ${fileSize(props.maxFileSize)} limit)`;
	}

	if (exceeding.length === files.length) {
		return exceeding.length = 0;
	} else {
		exceeding.length = 0;
	}

	return startUpload(Object.values(state.value.queue.next));
}

/**
 * Start the uploading of the files. Handles anything that happens during the upload.
 * @param fileList
 * @returns {Promise<void>}
 */
async function startUpload(fileList) {
	// Check first if we have active queue
	if (state.value.queue.instance && (state.value.queue.instance.files.length || state.value.queue.instance.currentFile)) {
		return state.value.queue.instance.addToQueue(fileList);
	}

	state.value.queue.instance = file.prepareUpload(props.guildId, parseInt(route.params.caseSid), fileList);

	state.value.queue.instance.addEventListener("progress", e => {
		state.value.queue.current.id = e.detail.fileId;
		state.value.queue.current.progress = parseFloat((e.detail.percentage).toFixed(2));
	});

	state.value.queue.instance.addEventListener("limitReached", err => {
		fileList.length = 0;
		state.value.queue.next = {};
		state.value.error.label = err.detail.message;
	}, true);

	state.value.queue.instance.addEventListener("fileUploaded", e => {
		const {fileId, response} = e.detail;

		const queued = state.value.queue.next[fileId];
		delete state.value.queue.next[fileId];

		// Rename our queued file to the one our API told it's named
		queued.newName = response.fileId;
		queued.id = response.fileId;
		state.value.queue.completed.unshift(queued);
	});

	await state.value.queue.instance.start();
	state.value.queue.instance = null;
}

/**
 * Aborts the uploading of a file or a queued file
 * @param {string} fileId
 */
function abortFileUpload(fileId) {
	if (!state.value.queue.instance) return;

	// Abort upload and remove from queue
	state.value.queue.instance.abort(fileId);
	// Remove from UI
	delete state.value.queue.next[fileId];
}

</script>
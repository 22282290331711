<script setup>
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import SummaryContainer from "@/components/lists/summary-container/SummaryContainer.vue";
import {computed, inject} from "vue";
import UserCache from "@/storage/UserCache";
import {useRoute} from "vue-router";

const props = defineProps({
	"linkedUsers": {
		type: Array,
		default: () => [],
	},
	"pills": {
		type: Object,
		default: () => ({
			"archived": false,
		}),
	},
});
const route = useRoute();
const users = UserCache();
const personal = inject("isPersonal");
const linkedUserItems = computed(() => {
	return props.linkedUsers.map(e => ({
		label: e.label,
		description: users.username(e.userId) ?? "Unknown User",
		chevron: true,
		to: {
			name: personal ? "my-cases" : "guild-cases",
			params: {
				... route.params,
				resource: "linked-users",
				resourceId: e.userId
			},
		},
	}));
});
</script>

<template>
	<section>
		<div id="linked-users">
			<div class="flex justify-between items-baseline">
				<!-- Label -->
				<p class="label">
					Linked Users
				</p>

				<!-- Add button -->
				<PermsCond v-if="!pills.archived" :needs="Nodes.Cases.LINKED.USER.CREATE()">
					<RouterLink
						:to="{
							name: 'guild-cases',
							params: { resource: 'linked-users', resourceId: 'new', caseSid: route.params.caseSid }
						}"
					>
						<span class="uppercase text-xs font-bold text-sky-400">Add User</span>
					</RouterLink>
				</PermsCond>
			</div>
			<SummaryContainer
				v-if="linkedUsers.length"
				:items="linkedUserItems"
			/>
			<p v-else class="italic text-slate-500">
				No linked users.
			</p>
		</div>
	</section>
</template>

<style scoped>

</style>
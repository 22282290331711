<template>
	<g>
		<path
			d="M6.6665 18.6673L9.33317 5.33398"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.6665 18.6673L17.3332 5.33398"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20.0002 8.66667H5.3335"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18.6667 15.3346H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconHash"
};
</script>
<script setup>
import EarlyWarningSystemView from "@/views/guild/members/early-warning/EarlyWarningSystemView.vue";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import ModerationSettingsService from "@/services/ModerationSettingsService";
import FancyLoader from "@/components/misc/FancyLoader.vue";

const route = useRoute();

const loading = ref(false);
const saving = ref(false);

let originalValues =  ref({
	enabled: true,
	channelId: null,
	threshold: 100,
	showRecentCases: true,
});
const settings = ref({
	enabled: true,
	channelId: null,
	threshold: 100,
	showRecentCases: true,
});

const averages = ref({
	guild: null,
	global: 40,
});

const isModified = computed(()=>{
	if (settings.value.enabled !== originalValues.value.enabled) return true;
	if (settings.value.channelId !== originalValues.value.channelId) return true;
	if (settings.value.threshold !== originalValues.value.threshold) return true;
	if (settings.value.showRecentCases !== originalValues.value.showRecentCases) return true;

	return false;
});

const reset = () => {
	settings.value = structuredClone(originalValues.value);
};
const save = async () => {
	saving.value = true;
	const r = await ModerationSettingsService.updateEarlyWarningSettings(route.params.guildId, settings.value);

	// Some error, handle it
	if (r === null) {
		saving.value = false;
		return;
	}

	originalValues.value = structuredClone(settings.value);
	saving.value = false;
};
onMounted(async ()=>{
	loading.value = true;

	const r = await ModerationSettingsService.getEarlyWarningSettings(route.params.guildId);

	// Settings
	originalValues.value = r.settings;
	settings.value = structuredClone(originalValues.value);

	// Averages
	averages.value.global = Math.round(r.stats.global);
	if (r.stats.guild?.length && r.stats.guild.length >= 25) {
		averages.value.guild = Math.round(r.stats.guild.reduce((acc, sample) => acc + sample.score, 0) / r.stats.guild.length);
	}

	loading.value = false;
});
</script>

<template>
	<div v-if="loading" class="flex items-center justify-center h-full">
		<FancyLoader label="Loading your setting&hellip;" />
	</div>
	<EarlyWarningSystemView
		v-else
		v-model:channel-id="settings.channelId"
		v-model:enabled="settings.enabled"
		v-model:threshold="settings.threshold"
		v-model:show-recent-cases="settings.showRecentCases"
		:settings="settings"
		:averages="averages"
		:is-modified="isModified"
		:is-saving="saving"
		@reset="reset"
		@save="save"
	/>
</template>

<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {ref} from "vue";
import TextInput from "@/components/form/Text/TextInput.vue";
import JoyrideBox from "@/components/joyrides/JoyrideBox.vue";
import SearchDiscordUser from "@/components/form/search/SearchDiscordUser.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const props = defineProps({
	"close": {
		type: Function,
		default: () => () => {
		},
	},
	"handleSave": {
		type: Function,
		default: () => () => {
		},
	},
	"guildId": {
		type: String,
		required: true,
	},
	"excludeUserIds": {
		type: Array,
		default: () => [],
	},
});
const label = ref("");
const user = ref(null);
const cases = CaseAppealBrowser();
const createLink = () => {
	if (!user.value) return;
	return props.handleSave({
		label: label.value,
		userId: user.value.id,
	});
};
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<div class="flex items-center w-full justify-between">
				<h2>Link New User</h2>

				<div class="CaseActionButtons">
					<CloseButton label="Close creation panel" @click="close" />
				</div>
			</div>
		</template>

		<template #main>
			<JoyrideBox
				joyride-id="case-linked-users"
				description="User linking lets you store users that are somehow involved or related to the case. For example the victim of a scammer, or the instigator of an argument."
			/>
			<div>
				<label for="linkLabel">Label</label>
				<TextInput
					id="linkLabel"
					v-model="label"
					placeholder="E.g. Victim or Instigator"
					maxlength="32"
					autofocus
				/>
			</div>

			<div>
				<label for="selectUser">Select user to link</label>
				<SearchDiscordUser
					id="selectUser"
					v-model="user"
					:guild-id="guildId"
					:exclude-ids="excludeUserIds"
				/>
			</div>
		</template>

		<template #footer>
			<div class="flex gap-2 w-full justify-end">
				<BasicButton
					:label="cases.isLoading('addLinked', user?.id) ? 'Close' : 'Cancel'"
					type="link"
					@click="close"
				/>
				<PermsCond :needs="Nodes.Cases.LINKED.USER.CREATE()">
					<BasicButton
						:label="cases.isLoading('addLinked', user?.id) ? 'Creating...' : 'Create'"
						:disabled="!user || !label || cases.isLoading('addLinked', user?.id)"
						@click="createLink"
					/>
				</PermsCond>
			</div>
		</template>
	</InspectResourceLayout>
</template>
<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {computed, h} from "vue";
import ButtonLike from "@/components/panels/ButtonLike.vue";

const props = defineProps({
	"label": {
		type: String,
		required: true,
	},
	"icon": {
		type: String,
		default: null,
	},
	"iconSize": {
		type: Number,
		default: 16,
	},
	"iconStroke": {
		type: Number,
		default: 1,
	},
	"iconClass": {
		type: [String, Object],
		default: "",
	},
	"dot": {
		type: Boolean,
		default: false,
	},
	// When true, badge is treated as a clickable button
	"button": {
		type: Boolean,
		default: false,
	},
});

const component = computed(()=>{
	if (props.button) return ButtonLike;
	return h("span");
});
</script>

<template>
	<component
		:is="component"
		:desc="label"
		class="DarkBadge inline-flex items-center whitespace-nowrap gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-300 ring-1 ring-inset ring-neutral-700/50 bg-neutral-900 break-words"
	>
		<svg
			v-if="dot"
			class="h-1.5 w-1.5"
			:class="iconClass"
			viewBox="0 0 6 6"
			aria-hidden="true"
		>
		  <circle
			  cx="3"
			  cy="3"
			  r="3"
		  />
		</svg>
		<DynamicIcon
			v-if="icon"
			:icon="icon"
			:size="iconSize"
			:stroke="iconStroke"
			:class="iconClass"
		/>
		{{ label }}
	</component>
</template>

<style scoped>
.DarkBadge {
	word-break: break-word;
}
</style>
<script>
export default {
	name: "IconCalibrate"
};
</script>

<template>
	<g>
		<path
			d="M2.99622 7.9983V4.99705C2.99622 3.89202 3.89202 2.99622 4.99705 2.99622H7.9983"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.0016 2.99622H19.0029C20.1079 2.99622 21.0037 3.89202 21.0037 4.99705V7.9983"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21.0037 16.0016V19.0029C21.0037 20.1079 20.1079 21.0037 19.0029 21.0037H16.0016"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.9983 21.0037H4.99705C3.89202 21.0037 2.99622 20.1079 2.99622 19.0029V16.0016"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="12"
			cy="12"
			r="3.00125"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 6.99792V8.99876"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 15.0012V17.0021"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.0021 12H15.0012"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.99876 12H6.99792"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

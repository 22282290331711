<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M2.99609 6.99776C2.99609 4.7877 4.7877 2.99609 6.99776 2.99609H17.0019C19.212 2.99609 21.0036 4.7877 21.0036 6.99776V17.0019C21.0036 19.212 19.212 21.0036 17.0019 21.0036H6.99776C4.7877 21.0036 2.99609 19.212 2.99609 17.0019V6.99776Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 15.9999L16.5879 11.412C17.3692 10.6309 18.6358 10.6309 19.4171 11.412L21.0037 12.9986"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="7.49867"
			cy="7.49672"
			r="1.50063"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.34961 18.6457L7.58137 14.4139C8.3627 13.6329 9.62923 13.6329 10.4106 14.4139L16.8302 20.8336"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconImage"
};
</script>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20 11.1818C20 15.7228 16.587 19.9688 12 20.9998C7.413 19.9688 4 15.7228 4 11.1818V7.24275C4 6.43075 4.491 5.69875 5.243 5.39175L10.243 3.34575C11.369 2.88475 12.631 2.88475 13.757 3.34575L18.757 5.39175C19.509 5.69975 20 6.43075 20 7.24275V11.1818V11.1818Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 8.5V7.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 14.5V15.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.264 13.784C10.523 14.208 10.965 14.501 11.5 14.501H12H12.596C13.372 14.501 14 13.872 14 13.097C14 12.453 13.561 11.892 12.937 11.734L11.062 11.263C10.439 11.107 10 10.546 10 9.90205C10 9.12605 10.629 8.49805 11.404 8.49805H12H12.5C13.033 8.49805 13.475 8.79005 13.733 9.21305"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconSubscription"
};
</script>
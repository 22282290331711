import backend from "@/api/backend";

/**
 * Performs session validation/TTL extension
 * @return {Promise<boolean>} Returns false if session is not valid, else will return true and have new cookies etc. set (if applicable)
 */
export async function validateSession() {
	const result = await backend("/public/accounts/me/sessions/validate", "POST");
	return result.status === 200 && result.body === true;
}

/**
 * Checks to see if the user is logged in or not
 * @return {Promise<boolean>}
 */
export async function isLoggedIn() {
	console.log("Performing login check");
	/**
	 * For now, we simply call validateSession, but in the future we may want to do something more complex,
	 * or some other means to check if the user is logged in.
	 */
	return await validateSession();
}
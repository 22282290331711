<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.3525 21.0024H5.64723C4.61967 21.0024 3.75916 20.2241 3.6564 19.2017L3.00613 12.699C2.94958 12.1363 3.13375 11.576 3.51309 11.1567C3.89242 10.7373 4.43149 10.498 4.99696 10.498H19.0028C19.5683 10.498 20.1073 10.7373 20.4867 11.1567C20.866 11.576 21.0502 12.1363 20.9936 12.699L20.3434 19.2017C20.2406 20.2241 19.3801 21.0024 18.3525 21.0024V21.0024Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.99805 2.9963H16.0014"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.99805 6.7463H18.003"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCases"
};
</script>
<template>
	<g>
		<rect
			x="9"
			y="11.5"
			width="6"
			height="4"
			rx="1.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.75 11.5V10.25C13.75 9.2835 12.9665 8.5 12 8.5V8.5C11.0335 8.5 10.25 9.2835 10.25 10.25V11.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20 13.0209C20 16.7956 15.3552 19.5997 13.1401 20.7255C12.4255 21.0909 11.579 21.0916 10.8636 20.7275C8.65012 19.6027 4 16.7918 4 13.0209V7.56938C4.00735 7.05853 4.39423 6.63324 4.9021 6.5777C7.02402 6.41646 9.05835 5.66412 10.7746 4.40594C11.5019 3.86469 12.4981 3.86469 13.2254 4.40594C14.9416 5.66412 16.9759 6.41646 19.0979 6.5777C19.6057 6.63322 19.9926 7.05852 20 7.56938V13.0209Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconAdmin"
};
</script>
<template>
	<div>
		<!-- Drop field -->
		<div
class="border-2 rounded-md p-7 h-full"
			 :class="{
					'border-solid border-blue-600 bg-blue-50/50': isHighlighted,
					'border-dashed border-neutral-300': !isHighlighted,
					'w-full': width==='full',
					'w-72': width==='fixed',
			}"
			 @dragover="handleDragOver($event)"
			 @dragleave="highlight(false)"
			 @drop.prevent="handleFileDrop"
>
			<div class="text-center">
				<DynamicIcon
					:icon="icon"
					class="text-blue-900 cursor-pointer"
					size="32"
					@click="fileField.click()"
				/>
				<p>
					<span
						class="text-blue-900 font-medium cursor-pointer"
						@click="fileField.click()"
					>Upload a {{ uploadX }}</span> or drag and drop
</p>

				<p class="text-xs text-neutral-400">
up to {{ maxFileSize }}
</p>
				<input
:id="fileFieldId"
ref="fileField"
type="file"
:name="fileFieldId"
multiple
					   hidden
@input="handleFileInput"
>
			</div>
		</div>
</div>
</template>

<script setup>
import { computed, ref } from "vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import { fileSize } from "@/script/convert";

const fileField = ref(null);
const fileFieldId = Math.random().toString(16).slice(2);
const isHighlighted = ref(false);
const emit = defineEmits(["setFiles"]);

const props = defineProps({
	// Max file size, in Bytes. We convert to the closest unit.
	"maxSize": {
		type: Number,
		default: () => null,
	},
	// Filetypes we accept
	"accept": {
		type: String,
		default: () => null,
	},
	// Variable text saying what the user is uploading, e.g. "file", "image", or "JSON file" etc.
	"uploadX": {
		type: String,
		default: () => "file"
	},
	// Name of icon to use
	"icon": {
		type: String,
		default: "FolderUpload",
	},
	"width": {
		type: String,
		default: () => "full",
		enum: ["full", "fixed"],
	}
});

const maxFileSize = computed(() => {
	return fileSize(props.maxSize || 10_485_760, false, 0);
});

/**
 * Handles setting/removing highlighting of file drop-zone
 * @param {boolean} enabled Enable/disable highlighting
 */
function highlight(enabled) {
	isHighlighted.value = enabled;
}

function handleDragOver(event) {
	event.preventDefault();
	event.dataTransfer.dropEffect = "copy";
	isHighlighted.value = true;
}

function handleFileInput(e) {
	emit("setFiles", e.target.files);
	isHighlighted.value = false;
}

/**
 * Handles capturing the file dropping
 * @param {DragEvent} event
 */
function handleFileDrop(event) {
	// Cache files into the file <input>
	fileField.value = event.dataTransfer.files;
	emit("setFiles", event.dataTransfer.files);
	isHighlighted.value = false;
}
</script>
import { ManagedRulesQuota } from "../automod/index.js";
/**
 * Configuration for Archivian Accounts' auto-mod rules (Discord Auto-Mod Rules Library)
 */
export const AutoModRules = {
    /**
     * Max number of private Auto Mod rules a user can have.
     * Deleting private records will recover the limit.
     */
    maxPrivate: ManagedRulesQuota.account.maxPrivate,
    /**
     * Max number of published Auto Mod rules a user can have.
     * Deleting published records MAY recover the limit. Public records may need to be manually handled before the limit is recovered.
     */
    maxPublic: ManagedRulesQuota.account.maxPublic,
};

export const PERMISSION = {
    /**
     * Access to do anything permission related
     */
    ANY: () => {
        return "permissions.**";
    },
    /**
     * Ability to change whether Archivian should also use Discord permissions
     */
    DISCORD_TOGGLE: () => {
        return "permissions.discord.update";
    },
};
/**
 * Permission set Auto Mod settings
 */
export const AUTOMOD = {
    /**
     * Permission to anything related to Auto Mod settings
     */
    ANY: () => {
        return "settings.automod.**";
    },
    /**
     * Permission to read the Auto Mod settings
     */
    READ: () => {
        return "settings.automod.read";
    },
    /**
     * Permission to modify the Auto Mod settings
     */
    UPDATE: () => {
        return "settings.automod.update";
    },
};

<template>
	<g>
		<path
			d="M16 18L12 14L8 18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8 6L12 10L16 6"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCollapse"
};
</script>
export const INVITE = {
    /**
     * Permission to anything staff invite related
     */
    ANY: () => {
        return "staff.invite.**";
    },
    /**
     * Permission to create a new invite code / link
     */
    CREATE: () => {
        return "staff.invite.create";
    },
};
export const MANAGE = {
    /**
     * Permission to anything staff manage related
     */
    ANY: () => {
        return "staff.manage.**";
    },
    /**
     * Permission to add staff members directly
     */
    ADD: () => {
        return "staff.manage.create";
    },
    /**
     * Permission to remove staff members directly
     */
    REMOVE: () => {
        return "staff.manage.remove";
    },
};

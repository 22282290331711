<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import CaseHeaderView from "@/partials/inspect/case/inspectCase/CaseHeaderView.vue";
import CaseMainView from "@/partials/inspect/case/inspectCase/CaseMainView.vue";
import {computed, ref} from "vue";
import {flagsToObject} from "@/script/convert";
import {useRoute} from "vue-router";
import {Account} from "@/storage/AccountCache";
import CaseFooterView from "@/partials/inspect/case/inspectCase/CaseFooterView.vue";
import InspectMemberDialog from "@/partials/inspect/guild-member/InspectMemberDialog.vue";

const props = defineProps({
	"theCase": {
		type: Object,
		required: true,
	},
	"appeals": {
		type: Array,
		default: () => [],
	},
	// To tell the difference between staff and personal dashboard view.
	"personal": {
		type: Boolean,
		default: () => false,
	},
	// The guild case is from, if personal view
	"guild": {
		type: Object, // {id:string, name:string, icon:(null|string)}
		default: () => {
			const route = useRoute();
			const account = Account();
			const guildId = route.params.guildId;
			return account.getGuild(guildId);
		},
	},
	"handlers": {
		type: Object,
		default: () => ({}),
	},
	// Personal view use only: used to conditionally display Appeal button
	"canAppeal": {
		type: Boolean,
		default: () => true,
	},
});
/**
 * @type {ComputedRef<CaseFlags & {expired:boolean, undone:boolean, archived:boolean, system: boolean}>}
 */
const pills = computed(() => {
	const flags = flagsToObject("case", props.theCase.flags);
	return {
		...flags,
		expired: props.theCase?.expireAt && new Date(props.theCase?.expireAt) < new Date(),
		undone: props.theCase?.undone?.at,
		archived: props.theCase?.archived?.at,
		system: !!props.theCase?.system,
	};
});
const memberModal = ref(false);
const toggle = () => {
	if (props.personal) return;
	memberModal.value = !memberModal.value;
};
</script>

<template>
	<InspectResourceLayout>
		<template #head>
			<CaseHeaderView
				:target-id="theCase.targetId"
				:case-type="theCase.caseType"
				:sid="theCase.sid"
				:created-at="theCase.createdAt"
				:guild="guild"
				:close="handlers.close"
				:pills="pills"
			/>
		</template>

		<template #main>
			<CaseMainView
				:the-case="theCase"
				:pills="pills"
				:appeals="appeals"
				:guild="guild"
				:can-appeal="canAppeal"
				@inspect="toggle"
			/>

			<InspectMemberDialog
				v-model="memberModal"
				:user-id="theCase.targetId"
				:guild-id="theCase.guildId"
			/>
		</template>

		<template #footer>
			<CaseFooterView
				:case-type="theCase.caseType"
				:creator-id="theCase.creatorId"
				:pills="pills"
				:can-appeal="canAppeal"
				:has-appeals="!!appeals.length"
				:close="handlers.close"
			/>
		</template>
	</InspectResourceLayout>
</template>
<template>
	<g>
		<path
			d="M18.0218 12.0178L19.7525 10.2871C20.8589 9.21365 21.3012 7.62736 20.9097 6.13632C20.5183 4.64528 19.3538 3.48082 17.8628 3.08935C16.3717 2.69788 14.7854 3.14014 13.712 4.24657L10.0104 7.94811C9.20905 8.74894 8.75879 9.83543 8.75879 10.9684C8.75879 12.1013 9.20905 13.1878 10.0104 13.9886L9.99944 13.9996C10.3442 14.3378 10.7445 14.6143 11.1829 14.817"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.8064 9.19141C13.2448 9.39442 13.6451 9.67122 13.9898 10.0097C14.7912 10.8106 15.2415 11.8971 15.2415 13.03C15.2415 14.1629 14.7912 15.2494 13.9898 16.0503L10.2883 19.7518C8.61311 21.377 5.94357 21.3568 4.29317 19.7064C2.64276 18.056 2.62256 15.3865 4.24779 13.7113L5.97851 11.9806"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconLink"
};
</script>
<template>
	<g>
		<path
			d="M12 5V19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.99902 10L12 4.99902L17.001 10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconArrowUp",
};
</script>

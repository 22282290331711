<script setup>
import GenericSearch from "@/components/form/search/GenericSearch.vue";
import {computed, onMounted, ref} from "vue";
import GenericSearchResult from "@/components/form/search/GenericSearchResult.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import ToolTip from "@/components/badges/ToolTip.vue";
import backend from "@/api/backend";
import {isValidSnowflake} from "archivian-utils";
import {iconForChannelType} from "@/script/constants";
import IndexedDBStore from "@/storage/IndexedDB";

const props = defineProps({
	"id": {
		type: String,
		default: () => null,
	},
	"guildId": {
		type: String,
		required: true,
	},
	"modelValue": {
		type: [String, Number, Object, Array],
		default: () => null,
	},
	"maxResults": {
		type: Number,
		default: () => 4,
	},
	"excludeIds": {
		type: Array,
		default: () => [],
	},
	"autoReset": {
		type: Boolean,
		default: () => true,
	},
	"simple": {
		type: Boolean,
		default: () => false,
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	"placeholderBlur": {
		type: String,
		default: () => "Select a Discord server channel",
	},
	"channelTypes": {
		type: Array,
		default: () => ["text"],
		enum: [
			"announcementsThread",
			"announcements",
			"text",
			"dm",
			"voice",
			"group",
			"category",
			"voiceStage",
			"directory",
			"forum",
			"media",
			"privateThread",
			"publicThread",
			"news",
			"newsThread",
			"guildPublicThread",
			"guildPrivateThread",
		],
	},
	// The v-model will give you an ID instead of an object
	"bindUsingId": {
		type: Boolean,
		default: false,
	},
	// If no channel is selected, return an empty string instead of null
	"emptyAsString": {
		type: Boolean,
		default: false,
	},
	// Filter results further: the channel must have one of the provided parent IDs
	"hasParentIds": {
		type: Array,
		default: () => []
	},
	// Icon to use when the input is blurred
	"iconBlur": {
		type: String,
		default: () => "Discord"
	}
});
const tooltip = "If you don't see your channel, try reloading the page. If it still doesn't show, it might be a special channel or not supported for this selection.";
const emit = defineEmits(["update:modelValue", "focus", "blur"]);
const searchValue = ref("");
const results = ref([]);
const allChannelsCached = ref([]);
const loading = ref(false);
const idbStore = IndexedDBStore();

onMounted(async () => {
	const {body, status} = await backend(`/public/guilds/${props.guildId}/discord/channels`, "GET", {
		query: {
			types: props.channelTypes,
			//fields: ["id", "name", "color"]
		}
	});

	for (const channel of body) {
		await idbStore.setChannel(props.guildId, channel);
	}

	if (status !== 200) {
		console.error(status, body);
		return alert("Unable to load channels");
	}

	// Performant array merge
	allChannelsCached.value.push(...body);
});

async function doSearch(event) {
	results.value = [];
	loading.value = true;

	if (!event?.target?.value) return loading.value = false;

	const isId = isValidSnowflake(event.target.value.trim());
	const query = {limit: props.maxResults + props.excludeIds.length};
	if (isId) query.channelId = event.target.value.trim();
	else query.name = event.target.value.trim();

	const str = event.target.value.trim().toLowerCase();
	results.value = allChannelsCached.value
		.filter(channel => {
			// If we define an array of hasParentIds, the channel MUST have one of the parents
			if (props.hasParentIds.length && !props.hasParentIds.includes(channel.parent_id)) {
				return false;
			}
			if (props.excludeIds.includes(channel.id)) return false;
			if (props.modelValue?.id === channel.id) return false;

			if (channel.id.includes(str)) return true;
			return (channel.name || "Unknown Channel").toLowerCase().includes(str);
		});

	loading.value = false;
}

function handleSelect(event) {
	if (props.bindUsingId && event?.id) event = event.id;
	if (props.emptyAsString && !event) event = "";

	emit("update:modelValue", event);
	if (props.autoReset) {
		searchValue.value = "";
		results.value = [];
	}
}

const selected = computed(() => {
	if (!props.modelValue) return null;
	if (typeof props.modelValue === "string" || typeof props.modelValue === "number") {
		return allChannelsCached.value.find(channel => channel.id === props.modelValue);
	}

	return props.modelValue;
});
</script>

<template>
	<GenericSearch
		:id="id"
		v-model:search-value="searchValue"
		:selected="selected"
		:search-icon="iconBlur"
		:max-results="maxResults"
		:placeholder-blur="placeholderBlur"
		placeholder-focus="Search by name or channel ID"
		:results="results"
		:loading="loading"
		:disabled="disabled"
		remove-title="Unselect this channel"
		@update:selected="handleSelect"
		@input="doSearch"
		@focus="emit('focus')"
		@blur="emit('blur')"
	>
		<template v-if="!simple" #footer>
			<DynamicIcon
				icon="LightBulb"
				size="18"
			/>
			<p>Special channels cannot be selected.</p>
			<ToolTip class="text-left ml-2" :description="tooltip" />
		</template>

		<template #selectedBlur="{ value }">
			<DynamicIcon :icon="iconForChannelType(value.type)" />
			<p class="m-0 p-0 leading-none text-inherit text-nowrap overflow-ellipsis overflow-hidden">
				{{ value.name }}
			</p>
		</template>

		<template #selectedFocus="{ value }">
			<DynamicIcon :icon="iconForChannelType(value.type)" />
			<p class="m-0 p-0 leading-none text-inherit text-nowrap overflow-ellipsis overflow-hidden">
				{{ value.name }}
			</p>
		</template>

		<template #result-item="{ result }">
			<GenericSearchResult>
				<DynamicIcon :icon="iconForChannelType(result.type)" />
				<p class="text-inherit text-nowrap overflow-ellipsis overflow-hidden">
					{{ result.name }}
				</p>
			</GenericSearchResult>
		</template>
	</GenericSearch>
</template>
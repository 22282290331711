<script setup>
import IndexedDBStore from "@/storage/IndexedDB";
import {computed} from "vue";

const props = defineProps({
	// The ID of the role
	"roleId": {
		type: String,
		//required: true,
		default: () => "123123123123123123"
	},
	// The guild the channel belongs to
	"guildId": {
		type: String,
		required: true,
	},
	"small": {
		type: Boolean,
		default: false,
	},
});

const idbStore = IndexedDBStore();
const role = computed(()=>{
	const r = structuredClone(
		idbStore.getRole(props.roleId) || {
			color: 0x99AAB5,
			name: null,
			id: props.roleId,
			position: 0,
		}
	);

	// 0 means inherit (?), but in UI it uses #C4C9CE
	if (r.color === 0) r.color = 0xC4C9CE;

	return r;
});
</script>

<template>
	<div
		class="MentionRole pt-0.5 pb-1 pl-1 pr-1.5 rounded bg-neutral-700/10 border border-neutral-700/50 w-fit inline-flex items-center gap-1"
		:title="roleId"
	>
		<svg
			class="h-3 w-3 shrink-0"
			viewBox="0 0 6 6"
			aria-hidden="true"
			:style="`fill: #${ role.color.toString(16) }`"
		>
			<circle
				cx="3"
				cy="3"
				r="3"
			/>
		</svg>
		<span
			:class="{
				'italic text-neutral-500': !idbStore.getRole(roleId)?.name,
				'text-xs': small
			}"
		>
			{{ role.name ?? `unknown role` }}
		</span>

		<!-- TODO Role Icon here -->
	</div>
</template>

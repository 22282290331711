import backend from "@/api/backend";

/**
 * @typedef {Object} BaseAPIOptions Base options available for most or all service methods
 * @property {?boolean} [withStatus] Return {@link BackendResult} instead of just the data from body
 */

/**
 * Fetches all staff members of a guild
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<Object[] | BackendResult>}
 */
export async function fetchStaff(guildId, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/staff`);

	if (r.status !== 200 && !options.withStatus) return [];
	return options.withStatus ? r : r.body;
}

/**
 * Fetches your permission nodes for a guild
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} options
 * @returns {Promise<string[] | BackendResult>}
 */
export async function fetchGuildPermissionNodes(guildId, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/staff/me/permissions`);

	if (r.status !== 200 && !options.withStatus) return [];
	return options.withStatus ? r : r.body;
}

/**
 * Fetches your personal dashboard permission nodes
 * @param {?(BaseAPIOptions)} options
 * @returns {Promise<string[] | BackendResult>}
 */
export async function fetchPersonalPermissionNodes(options = {}) {
	// There are currently no personal dashboard permissions needed, so return full access. And thus, no endpoint either.
	// This function exists in case we ever need it, we can then just replace the hard-coded result with a backend call.
	const r = await backend("/public/accounts/me");

	if (r.status !== 200 && !options.withStatus) return [];
	return options.withStatus ? r : r.body.permissions;
}

/**
 * @typedef {Object} FetchTitlesOptions
 * @property {?boolean} [staff] Populate staff property with staff (objects) that has this title
 * @property {?boolean} [permissionSets] Populate the permission sets linked to the titles
 * @property {?boolean} [quota] Add a property stating how many titles in total this guild can have
 */
/**
 * Fetches all titles of a guild
 * @param {string} guildId
 * @param {?(BaseAPIOptions & FetchTitlesOptions)} [options]
 * @returns {Promise<Object[] | {titles: Object[], users?: Object[], quota?: number} | BackendResult>}
 */
export async function fetchTitles(guildId, options = {}) {
	/**
	 * The returned data is not the titles, but an object which will contain the titles.
	 * - if staff is populated:
	 * 	- each title will have a `staff` property with staff members (as objects) that has this title, with the join table entry as its parent
	 * 	- there will be a `users` property (Object[]) in the root object with the staff members as Discord users
	 * - if permissionSets is populated:
	 * 	- each title will have relations to permission nodes linked. Its has the join-table entries
	 * 	- if quota is populated:
	 * 	 - root object will hold a number indicating max titles this guild can create
	 */
	const r = await backend(`/public/guilds/${guildId}/titles`, "GET", {
		query: options,
	});

	if (r.status !== 200 && !options.withStatus) return [];
	return options.withStatus ? r : r.body.titles;
}

/**
 * Fetches all the teams of a guild
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} [options={}]
 * @returns {Promise<unknown>}
 */
export async function fetchTeams(guildId, options=  {}) {
	// TODO Implement this when we have teams feature.
	console.log(guildId, options);
	return [];
}

/**
 *
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} [options={}]
 * @returns {Promise<boolean | BackendResult>}
 */
export async function fetchUseDiscordPermissions(guildId, options=  {}) {
	const r = await backend(`/public/guilds/${guildId}/misc/use-discord-permissions`, "GET", {
		query: options,
	});

	if (r.status !== 200 && !options.withStatus) return false;
	return options.withStatus ? r : r.body.enabled;
}

/**
 *
 * @param {string} guildId
 * @param {boolean} enabled
 * @param {?(BaseAPIOptions)} [options={}]
 * @returns {Promise<{enabled: boolean, alreadyInUse: boolean} | BackendResult>}
 */
export async function setUseDiscordPermissions(guildId, enabled, options=  {}) {
	const r = await backend(`/public/guilds/${guildId}/misc/use-discord-permissions`, "PUT", {
		body: { enabled },
	});

	if (r.status !== 200 && !options.withStatus) return false;
	return options.withStatus ? r : r.body;
}

/**
 * Returns a list of managed permission sets a guild can have, along with the limit of how many sets a title can have
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} [options={}]
 * @returns {Promise<{sets: Object[], maxSets: number} | BackendResult>}
 */
export async function getManagedPermissionSets(guildId, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/permission-sets`);

	if (r.status !== 200 && !options.withStatus) return { sets: [], maxSets: 0 };
	return options.withStatus ? r : r.body;
}






/**
 * Overwrites the permission set with the new state. Not every field can changed.
 * @param {string} permissionSetId The ID of the existing permission set
 * @param {Object} newPermissionSet The new state of the permission set
 * @param {?(BaseAPIOptions)} [options={}]
 * @returns {Promise<{sets: Object[], maxSets: number} | BackendResult>}
 */
export async function adminOverwritePermissionSet(permissionSetId, newPermissionSet, options = {}) {
	const r = await backend(`/public/archivian/permission-sets/${permissionSetId}`, "PUT", {
		body: newPermissionSet,
	});

	if (r.status !== 200 && !options.withStatus) return null;
	return options.withStatus ? r : r.body;
}

/**
 * Returns a list of managed permission sets available in the system
 * @param {?(BaseAPIOptions)} [options={}]
 * @returns {Promise<Object[] | BackendResult>}
 */
export async function adminGetManagedPermissionSets(options = {}) {
	const r = await backend(`/public/archivian/permission-sets`);

	if (r.status !== 200 && !options.withStatus) return [];
	return options.withStatus ? r : r.body;
}

/**
 * Admin Only: Creates a new Permission Set
 * @param {Object} newPermissionSet
 * @param {?(BaseAPIOptions)} [options={}]
 * @returns {Promise<Object | BackendResult>} The new permission set
 */
export async function adminCreateManagedSet(newPermissionSet, options = {}) {
	const r = await backend(`/public/archivian/permission-sets`, "POST", {
		body: newPermissionSet,
	});

	if (r.status !== 200 && !options.withStatus) return null;
	return options.withStatus ? r : r.body;
}

/**
 * Admin Only: Get the permissions of a user in a given guild
 * @param {UserID} userId
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} [options={}]
 * @return {Promise<{nodes: string[]} | BackendResult>}
 */
export async function adminGetUserPermissions(userId, guildId, options = {}) {
	let query;
	if (guildId) query = { guildId };

	const r = await backend(`/public/archivian/permission-sets/users/${userId}`, "GET", { query });

	if (r.status !== 200 && !options.withStatus) return null;
	return options.withStatus ? r : r.body;
}

export default {
	fetchStaff,
	fetchGuildPermissionNodes,
	fetchPersonalPermissionNodes,
	fetchTitles,
	fetchTeams,
	fetchUseDiscordPermissions,
	setUseDiscordPermissions,
	getManagedPermissionSets,

	// Admin related methods
	adminOverwritePermissionSet,
	adminGetManagedPermissionSets,
	adminCreateManagedSet,
	adminGetUserPermissions,
};
<template>
	<template v-if="bypassIf || (needs && ((evaluate(needs) && !invert) || (!evaluate(needs) && invert)))">
		<slot />
	</template>
</template>

<script setup>
import EvalPerms from "@/script/EvalPerms";
import { Account } from "@/storage/AccountCache";

const account = Account();
const props = defineProps({
	// Needs one or all if array. Any by default, all if `all` prop is passed
	"needs": {
		type: [String, Array],
		required: true,
	},
	"default": {
		type: Boolean,
		default: () => false,
	},
	"invert": {
		type: Boolean,
		default: () => false,
	},
	// True to skip permission check
	"bypassIf": {
		type: Boolean,
	},
	// If all nodes in "needs" need to be met in order to be true
	"all": {
		type: Boolean,
		default: false,
	}
});

function evaluate(node) {
	// Allow `case.true.entry.update.false` to become `case.own.update.others`.
	if (node.includes(".true") || node.includes(".false")) {
		const n = node.split(".");
		for (const index in n) {
			if (n[index] === "true") n[index] = "own";
			if (n[index] === "false") n[index] = "others";
		}
		node = n.join(".");
	}

	const nodes = account.permissionNodes || [];
	if (!nodes || !nodes.length) return props.default;

	let r;
	if (!Array.isArray(node)) {
		r = EvalPerms(node, nodes || []);
	} else {
		for (const n of node) {
			r = EvalPerms(n, nodes || []);
			if (!r.access && props.all) return false;
			if (r.access && !props.all) break;
			// By virtue of the two conditions, only other outcome is that user passed all permissions
		}
	}
	return r.access;
}
</script>
import { defineStore} from "pinia";
import { UI } from "@/storage/UICache";
import { useRoute } from "vue-router";

/**
 * App State is a temporary container for shared states that do not fit into any other stores.
 * It is not persistent, and it is scoped to each tab.
 *
 * You want to wipe some properties when you leave the current route as well.
 *
 * Its purpose is to let you store data someplace other than the parent component for when
 * you need to navigate across a subset of related routes.
 */
export const AppState = defineStore("AppState", {
	state: () => {
		return {
			/**
			 * @type {Map<string, Announcement>}
			 */
			"announcements": new Map(),
			"cases": new Map(),
			"appeals": new Map(),
			"autoModTemplates": {
				quota: {
					public: 0,
					publicMax: 25,
					private: 0,
					privateMax: 10,
					managedMax: 6,
					managed: 0,
				},
			},
			"kv": {},
		};
	},
	getters: {
		/**
		 * Returns a list of announcements that should be visible,
		 * based on what has been dismissed and the page/context you're currently in
		 * @returns {Announcement[]}
		 */
		contextAnnouncements: () => {
			if (!this.announcements.size) return [];

			const ui = UI();
			const route = useRoute();

			const visible = [];

			const targets = {
				guild: false,
				title: false,
				team: false,
				public: false,
				bot: false,
				staff: false,
				user: false,
				guilds: false,
				users: false,
			};

			if (route.params.guildId && route.meta.isGuild) {
				targets.guilds = true;
			} else {
				targets.users = true;
			}

			for (const announcement of this.announcements.values()) {
				if (ui.isDismissed("announcements", announcement.id)) continue;
				// TODO: We need to check each target and see if it matches current context
				visible.push(announcement);
			}

			return visible;
		}
	}
});

/**
 * A target for this announcement
 * @typedef {Object} AnnouncementTarget
 * @property {number} type
 * @property {string} id
 * @property {string} [extra]
 */
/**
 * @typedef {Object} Announcement
 * @property {string} id
 * @property {string} description
 * @property {string} [title]
 * @property {string} [style]
 * @property {string} [icon]
 * @property {string} [link]
 * @property {number} [expiresAt=null] A future date in which this announcement expires
 * @property {number|null} [flags=0] Optional bitflags for this announcement
 * @property {AnnouncementTarget} targets Targets for this announcement (visible if matching OR)
 */
<script setup>
import {UI} from "@/storage/UICache";
import IconContainer from "@/components/icon/IconContainer.vue";
import {ref} from "vue";
import CloseButton from "@/components/buttons/CloseButton.vue";

defineProps({
	"styled": {
		type: Boolean,
		default: () => false,
	},
	"fullHeight": {
		type: Boolean,
		default: () => false,
	},
	"minWidth": {
		type: Boolean,
		default: () => false,
	},
	// Small (width) sidebar
	"small": {
		type: Boolean,
		default: () => false,
	},
});
const open = ref(false);
const ui = UI();
</script>

<template>
	<div class="InspectCardSidenav w-full relative" :class="{ 'h-full': fullHeight }">
		<div class="burger-menu" :class="{ small }">
			<IconContainer
				v-if="!open"
				icon="BurgerMenu"
				title="Open sidebar"
				class="w-fit hover:bg-neutral-700 cursor-pointer transition-colors"
				@click="open = !open"
			/>
		</div>
		<div
			:id="ui.setAnchor('mainList')"
			class="slideout InspectCard-sidenav shadow border-details"
			tabindex="-1"
			:class="{
				'h-full': fullHeight,
				'min-w-64': minWidth && !small,
				'slideout-open': open,
				'small': small,
			}"
		>
			<CloseButton
				v-if="open"
				class="absolute top-2 left-2"
				label="Close slideout menu"
				@click="open = false"
			/>
			<slot name="sidenav" />
		</div>

		<div
			:id="ui.setAnchor('main')"
			class="content-window"
			:class="{ small }"
			tabindex="-1"
		>
			<slot />
		</div>
	</div>
</template>

<style scoped>
.InspectCardSidenav {
	@apply grid grid-cols-12 grid-rows-1 gap-4;
}
.InspectCard-sidenav {
	@apply relative flex-shrink-0 flex-col gap-4 overflow-y-auto p-4 rounded-lg bg-neutral-800 z-30;
}

/* Burger menu */
.burger-menu.small {
	/*
	- always start at col 1
	- span 3 cols to being
	- span 2 cols at sm
	- span 1 col at md
	- burger becomes hidden at lg and up
	 */
	@apply col-start-1 col-span-3 sm:col-span-2 md:col-span-1 lg:hidden;
}
.burger-menu:not(.small) {
	/*
	- always start at col on
	- to being take 3 cols
	- at sm take 2 cols
	- at md take 1 col
	- at lg and up we can't tell it to take less than a col, but it must be visible
	- at 2xl burger becomes hidden
	 */
	@apply col-span-3 sm:col-span-2 md:col-span-1 2xl:hidden;
}

/* Slideout small */
.slideout {
	/*
	Always start and end at row 1.
	It will either be fine, or intentionally overlay.
	- always start at col 1, regardless
	 */
	@apply row-start-1 row-end-1 col-start-1;
}
.slideout.small:not(.slideout-open) {
	/*
	- hidden to being
	- always visible at lg (3 columns)
	- 3 columns at xl
	- 2 columns at 2xl and up
	 */
	@apply hidden lg:flex lg:col-end-5 xl:col-end-4 2xl:col-end-3;
}
.slideout.small.slideout-open {
	/*
	- cover everything to being (if burger, means small size already)
	- 10 columns at xs (end at 11)
	- 8 columns at sm (end at 9)
	- 6	columns at md (end at 7)
	- 4 columns at lg <- At this point it would be visible anyway, but we keep going in case you already have it open
	- 3 columns at xl			( copy from .slideout.small:not(.open) )
	- 2 columns at xl and up	( copy from .slideout.small:not(.open) )
	*/
	@apply col-end-13 xs:col-end-11 sm:col-end-9 md:col-end-7 lg:col-end-5 xl:col-end-4 2xl:col-end-3;
}

/* Slideout normal */
.slideout:not(.small):not(.slideout-open) {
	/*
	- hidden to being
	- at 2xl become visible (flex)
	- at 2xl (and up) take 4 columns (end at 5)
	 */
	@apply hidden 2xl:flex 2xl:col-end-5;
}
.slideout.slideout-open:not(.small) {
	/*
	- to begin take full with (end at 13)
	- at sm take 10 cols (end at 11)
	- at md take 8 cols (end at 9)
	- at lg take 6 cols (end at 7)
	- skip xl
	- at 2xl (and up) take 4 cols (end at 5) <- At this point it becomes visible and takes this space anyway
	 */
	@apply col-end-13 sm:col-end-11 md:col-end-9 lg:col-end-8 2xl:col-end-5;
}

.content-window {
	/*
	Always start and end at row 1.
	It will either be fine, or intentionally overlay.

	- we always want this container to grow where possible.
	- we always end on the last column, regardless
	 */
	@apply row-start-1 row-end-1 grow col-end-13;
}
.content-window.small {
	/*
	- start with 3 space for menu (start at 4)
	- at sm burger takes only 2 spaces (start at 3)
	- at md burger takes 1 space (start at 2)
	- at lg, burger disappear and sidebar becomes visible
	- at lg, sidemenu appears, and it takes 4 cols (start at 5)
	- at xl, sidemenu takes 3 cols (start at 4)
	- at 2xl and up, sidemenu takes 2 cols (start at 3)
	 */
	@apply col-start-4 sm:col-start-3 md:col-start-2 lg:col-start-5 xl:col-start-4 2xl:col-start-3;
}
.content-window:not(.small) {
	/*
	- to being with burger takes 3 cols (start at 4)
	- at sm, burger takes 2 cols (start at 3)
	- at md, burger takes 1 col (start at 2)
	- at this point we can't tell it to take less than 1 col, but it must be visible
	- at 2xl, burger becomes hidden and sidenav appears (4 cols, start at 5)
	 */
	@apply col-start-4 sm:col-start-3 md:col-start-2 2xl:col-start-5;
}
</style>
<script setup>
import PaginationNav from "@/partials/navigation/paginator/PaginationNav.vue";
import {computed} from "vue";

const emit = defineEmits(["goto"]);
const props = defineProps({
	// The object key that is considered the key
	"idKey": {
		type: String,
		required: true,
	},
	// The count of items available
	"totalItems": {
		type: Number,
		default: 0,
	},
	"totalPages": {
		type: Number,
		default: 1,
	},
	"pageLimit": {
		type: Number,
		default: 25,
	},
	"currentPage": {
		type: Number,
		default: 1,
	},
	// A list of the current items
	"currentItems": {
		type: Array,
		default: () => [],
	},
	"isLoading": {
		type: Boolean,
		default: false,
	},
	"initialized": {
		type: Boolean,
		default: false,
	},
});

const paginatorIsRedundant = computed(() => {
	// This allows the default "No results" text
	if (!props.currentItems.length && props.currentPage === 1) return false;
	return props.totalItems <= props.pageLimit && props.currentPage === 1;
});
</script>

<template>
	<div>
		<PaginationNav
			v-if="initialized && !paginatorIsRedundant"
			:current-items="currentItems.length"
			:limit="pageLimit"
			:total-items="totalItems"
			:total-pages="totalPages"
			:page="currentPage"
			:disabled="isLoading"
			class="py-4 max-auto"
			@goto="emit('goto', $event)"
		/>

		<slot name="top" />

		<template
			v-for="item in currentItems"
			:key="item[idKey]"
		>
			<slot name="item" :item="item" />
		</template>

		<slot name="bottom" />

		<PaginationNav
			v-if="currentItems.length && initialized && !paginatorIsRedundant"
			:current-items="currentItems.length"
			:limit="pageLimit"
			:total-items="totalItems"
			:total-pages="totalPages"
			:page="currentPage"
			:disabled="isLoading"
			class="py-4 max-auto"
			@goto="emit('goto', $event)"
		/>
	</div>
</template>

<template>
	<g>
		<path
			d="M12 5H20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="6"
			cy="5"
			r="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 12H20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 19H20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="6"
			cy="12"
			r="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="6"
			cy="19"
			r="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconUnorderedList"
};
</script>
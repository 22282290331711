<template>
	<g>
		<path
			d="M13.6004 20H10.4004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17 10.032V10V10C17 7.239 14.761 5 12 5V5C9.239 5 7 7.239 7 10V10V10.032V12.504C7 12.828 6.817 13.123 6.528 13.268L6.025 13.519C5.397 13.834 5 14.476 5 15.178V15.178C5 16.202 5.83 17.032 6.854 17.032H17.146C18.17 17.032 19 16.202 19 15.178V15.178C19 14.476 18.603 13.834 17.975 13.52L17.472 13.269C17.183 13.123 17 12.828 17 12.504V10.032Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18.9512 6.04883C18.2392 4.79883 17.2012 3.76083 15.9512 3.04883"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.04883 6.04883C5.76083 4.79883 6.79883 3.76083 8.04883 3.04883"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconBellRinging"
};
</script>
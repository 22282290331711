<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import ButtonLike from "@/components/panels/ButtonLike.vue";

const props = defineProps({
	"modelValue": {
		type: [String,Number,null],
		default: () => null,
		required: true,
	},
	"placeholder": {
		type: String,
		default: () => null,
	},
	"size": {
		type: String,
		enum: ["narrow", "full"],
		default: () => "full"
	},
	"id": {
		type: String,
		default: () => null,
	},
	"name": {
		type: String,
		default: () => "",
	},
	"maxlength": {
		type: [String, Number],
		default: () => null,
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	"icon": {
		type: String,
		default: () => null,
	},
	"autofocus": {
		type: Boolean,
		default: () => false,
	},
	"autoComplete": {
		type: String,
		default: () => "off",
	},
	"step": {
		type: [Number,String],
		default: () => 1,
	},
	"min": {
		type: [Number,String],
		default: () => null,
	},
	"max": {
		type: [Number,String],
		default: () => null,
	},
});
const emit = defineEmits(["update:modelValue"]);
/**
 * I wanted Shift to add 10 each time with button and keyboard click, but handling
 * both proved difficult, because you need Keyup if you press enter to check if Shift was held.
 * But doing that would trigger @keyup AND @input, so you increase twice.
 *
 * Some day it would be nice to make it work with += 10.
 */
const handleIncrease = (e) => {
	if (e?.key && e.key !== "Enter") return;
	if (props.disabled) return;

	let value = parseInt(props.modelValue) + props.step;
	if (e?.shiftKey) value += 10;

	if (props.max !== undefined && value > props.max) value = props.max;
	emit("update:modelValue", value);
}
const handleDecrease = (e) => {
	if (e?.key && e.key !== "Enter") return;
	if (props.disabled) return;

	let value = parseInt(props.modelValue) - props.step;
	if (e?.shiftKey) value -= 10;

	if (props.min !== undefined && value < props.min) value = props.min;
	emit("update:modelValue", value);
}
const inputValue = event => {
	let val = parseInt(event.target.value);
	if (props.min !== undefined) val = Math.max(val, parseInt(props.min));
	if (props.max !== undefined) val = Math.min(val, parseInt(props.max));
	console.log(val)
	emit('update:modelValue', val)
}
</script>

<template>
	<div class="relative">
		<DynamicIcon
			v-if="icon"
			:icon="icon"
			class="text-input-icon"
		/>
		<div class="border-details number-input text-input input-focus flex">
			<input
				:id="id"
				class="border-none bg-transparent w-full outline-none h-full pl-2"
				:class="{
					'full-size': size==='full',
					'icon-space': !!icon,
				}"
				type="number"
				:placeholder="placeholder"
				:name="name"
				:value="modelValue"
				:disabled="disabled"
				:autocomplete="autoComplete"
				:aria-autocomplete="autoComplete"
				:step="step"
				:min="min"
				:max="max"
				autofocus
				@input="inputValue"
			>
			<div class="flex flex-col divide-y divide-neutral-600/40 border-l border-neutral-600/40 grow">
				<ButtonLike
					desc="Increase"
					class="hover:text-white transition-colors hover:bg-neutral-600/40 h-full"
					:disabled="disabled"
					@click="handleIncrease"
				>
					<DynamicIcon
						size="16"
						stroke="2"
						icon="Chevron"
						style="display: block;"
						class="rotate-180 h-full"
					/>
				</ButtonLike>
				<ButtonLike
					desc="Decrease"
					class="hover:text-white transition-colors hover:bg-neutral-600/40 h-full"
					:disabled="disabled"
					@click="handleDecrease"
				>
					<DynamicIcon
						size="16"
						stroke="2"
						icon="Chevron"
						class="h-full"
						style="display: block;"
					/>
				</ButtonLike>
			</div>
		</div>
	</div>
</template>

<style scoped>
.text-input {
	@apply w-full rounded-md bg-neutral-600/40 shadow-md;
}
.text-input:disabled {
	@apply bg-neutral-700/20;
}

.text-input > input {
	@apply h-10 py-2;
}

.text-input-icon {
	@apply absolute left-2 top-0.5 translate-y-1/2;
}

input.icon-space {
	@apply pl-10;
}

/*
These three CSS selectors MUST be separated, else they break it and make it styled again
The Tailwind `appearance-none` does also not work. Tested with Firefox Developer Edition.
 */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
</style>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.5286 7.99776H3.4715C3.21273 7.99803 2.96447 7.89535 2.78149 7.71237C2.59851 7.52938 2.49583 7.28113 2.49609 7.02236V4.9715C2.49583 4.71273 2.59851 4.46447 2.78149 4.28149C2.96447 4.09851 3.21273 3.99583 3.4715 3.99609H20.5296C20.7882 3.99609 21.0362 4.09889 21.219 4.28185C21.4017 4.4648 21.5043 4.7129 21.504 4.9715V7.02336C21.504 7.28196 21.4012 7.52995 21.2183 7.71272C21.0353 7.89548 20.7872 7.99803 20.5286 7.99776Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.1626 20.003H6.83825C5.79765 20.003 4.93079 19.2053 4.84442 18.1683L3.99707 7.99805H20.0037L19.1564 18.1683C19.07 19.2053 18.2032 20.003 17.1626 20.003Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.5567 12.5547L11.6118 14.4995L10.4434 13.332"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconArchived"
};
</script>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.0161 18.9023L4.78863 14.5274C4.01902 14.2757 3.49785 13.5585 3.49609 12.7487V10.2477C3.49871 9.43865 4.01972 8.72247 4.78863 8.47092L18.0161 4.0961C18.5898 3.9036 19.2209 3.99787 19.7132 4.34959C20.2055 4.70131 20.4993 5.2678 20.5032 5.87284V17.1255C20.4993 17.7306 20.2055 18.2971 19.7132 18.6488C19.2209 19.0005 18.5898 19.0948 18.0161 18.9023Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.31018 15.6934C8.10733 16.0999 8.00054 16.5476 7.99805 17.0019C8.00862 18.5432 9.18504 19.826 10.7197 19.9695C12.2544 20.1131 13.6483 19.0708 13.9445 17.5581"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.31055 15.6934L13.9449 17.5581"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconMegaphone"
};
</script>
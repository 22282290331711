<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69806 17.3019 2.39999 12 2.39999C6.69806 2.39999 2.39999 6.69806 2.39999 12C2.39999 17.3019 6.69806 21.6 12 21.6ZM10.4485 8.75147C9.97989 8.28284 9.2201 8.28284 8.75147 8.75147C8.28284 9.22009 8.28284 9.97989 8.75147 10.4485L10.3029 12L8.75147 13.5515C8.28284 14.0201 8.28284 14.7799 8.75147 15.2485C9.22009 15.7172 9.97989 15.7172 10.4485 15.2485L12 13.6971L13.5515 15.2485C14.0201 15.7172 14.7799 15.7172 15.2485 15.2485C15.7172 14.7799 15.7172 14.0201 15.2485 13.5515L13.6971 12L15.2485 10.4485C15.7172 9.97989 15.7172 9.22009 15.2485 8.75147C14.7799 8.28284 14.0201 8.28284 13.5515 8.75147L12 10.3029L10.4485 8.75147Z"
			fill="currentColor"
		/>
	</g>
</template>

<script>
export default {
	name: "IconErrorFilled"
};
</script>
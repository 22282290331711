<template>
	<g>
		<path
			d="M10.9997 18.0025H5.99759C4.34004 18.0025 2.99634 16.6588 2.99634 15.0013V6.99792C2.99634 5.34038 4.34004 3.99667 5.99759 3.99667H18.0026C19.6601 3.99667 21.0038 5.34038 21.0038 6.99792V10.9996"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20.9649 6.6128L13.5342 10.9534C12.5989 11.4998 11.4418 11.4998 10.5066 10.9534L3.03735 6.59039"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.0994 20.7283L21.2111 16.6165C21.6017 16.2259 21.6017 15.5926 21.2111 15.202L19.8027 13.7935C19.6151 13.606 19.3607 13.5006 19.0955 13.5006C18.8302 13.5006 18.5758 13.606 18.3882 13.7935L14.2765 17.9053C14.1001 18.0817 14.001 18.3209 14.001 18.5704V20.5036C14.001 20.6363 14.0537 20.7635 14.1475 20.8573C14.2412 20.9511 14.3684 21.0037 14.5011 21.0037H16.4344C16.6838 21.0037 16.923 20.9047 17.0994 20.7283V20.7283Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconEmailEdit"
};
</script>
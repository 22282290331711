<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15 16.9995L16 14.9995L13.052 13.0185L11.109 12.8945L10 13.9995L12 16.9995H15Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.8129 3.03541C7.18892 2.54641 2.54592 7.18941 3.03492 12.8134C3.40692 17.0924 6.90692 20.5924 11.1859 20.9644C16.8099 21.4534 21.4529 16.8114 20.9639 11.1864C20.5919 6.90841 17.0919 3.40841 12.8129 3.03541Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.95117 7.96144L8.00017 11.0004L9.00517 9.00544L13.0002 8.00044L14.1822 3.27344"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconGlobe"
};
</script>
import backend from "@/api/backend";
/**
 * @typedef {Object} BaseAPIOptions Base options available for most or all service methods
 * @property {?boolean} [withStatus] Return {@link BackendResult} instead of just the data from body
 */


/**
 * @typedef {Object} FetchGuildRoleOptions
 * @property {?string[]} [fields] The fields you are interested in
 */
/**
 * Return a list of all guild roles a guild has.
 * Optionally, only return some of the fields you need.
 * @param {string} guildId
 * @param {?FetchGuildRoleOptions & BaseAPIOptions} [options]
 * @returns {Promise<Array<Object> | BackendResult>}
 */
export async function fetchGuildRoles(guildId, options = {}) {
	let r;
	if (options.fields) {
		r = await backend(`/public/guilds/${guildId}/discord/roles`, "GET", {
			query: {
				fields: options.fields
			}
		});
	} else {
		r = await backend(`/public/guilds/${guildId}/discord/roles`);
	}

	if (options.withStatus) return r;
	if (r.status !== 200) {
		console.error("Failed to fetch guild roles", r.body);
		return [];
	}

	return r.body;
}

/**
 * @typedef {Object} SearchGuildRolesOptions
 * @property {?string} [roleId] Check if role ID matches part of the ID provided (`role.id.includes(roleId)`)
 * @property {?string} [name] Search for a role by name, case-insensitive
 * @property {?number} [limit] Max number of results to return
 */
/**
 * Search for a role in a given guild
 * @param {string} guildId
 * @param {?(BaseAPIOptions & SearchGuildRolesOptions)} [options]
 * @returns {Promise<Object[] | BackendResult>}
 */
export async function searchGuildRoles(guildId, options = {}) {
	const {status, body} = await backend(`/public/guilds/${guildId}/discord/roles/search`, "GET", {
		query: options,
	});

	if (status !== 200 && !options.withStatus) return [];
	return options.withStatus ? {body, status} : body;
}

/**
 * Fetch display details for a given guild by ID
 * @param {string} guildId
 * @param {?BaseAPIOptions} [options]
 * @returns {Promise<DisplayGuild | null | BackendResult>}
 */
export async function fetchGuild(guildId, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/discord`);

	if (options.withStatus) return r;
	if (r.status !== 200) {
		console.error("Failed to fetch guild", r.body);
		return null;
	}
	return r.body;
}

/**
 * @typedef {Object} FetchChannelsOptions
 * @property {?string[]} [fields] The fields you are interested in
 * @property {?string[]} [types] Filter by types of channels
 * @property {?string[]} [excludeIds] IDs of channels you are not interested in
 */
/**
 * Fetches channels from a guild by ID
 * @param {string} guildId
 * @param {?(BaseAPIOptions & FetchChannelsOptions)} [options]
 * @returns {Promise<Object[] | BackendResult>}
 */
export async function fetchChannels(guildId, options = {}) {
	let {body, status} = await backend(`/public/guilds/${guildId}/discord/channels`, "GET", {
		query: options
	});

	if (status !== 200 && !options.withStatus) return [];

	// For now, fields and excludeIds is not supported in the endpoint, so we do it client-side
	if (status === 200 && options.excludeIds) {
		body = body.filter(channel => !options.excludeIds.includes(channel.id));
	}
	if (status === 200 && options.fields) {
		body = body.map(channel => {
			const newChannel = {};
			for (const field of options.fields) {
				newChannel[field] = channel[field];
			}
			return newChannel;
		});
	}

	return options.withStatus ? {body, status} : body;
}

/**
 * Fetches the member data of the given user IDs
 * @param {string} guildId
 * @param {UserID[]} userIds
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<Object[] | BackendResult>}
 */
export async function fetchGuildMembers(guildId, userIds, options = {}) {
	if (!userIds.length) return options.withStatus ? {status:200, body: []} : [];

	const {status, body} = await backend(`/public/guilds/${guildId}/discord/members`, "GET", {
		query: {
			userIds
		}
	});

	if (status !== 200 && !options.withStatus) return [];
	return options.withStatus ? {status, body} : body;
}

/**
 * Fetches Discord guild member data of the given user IDs
 * @param {string} guildId
 * @param {UserID[]} userIds
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<Object[] | BackendResult>}
 */
export async function fetchUsers(guildId, userIds, options = {}) {
	if (!userIds.length) return options.withStatus ? {status:200, body: []} : [];

	const {status, body} = await backend(`/public/guilds/${guildId}/discord/users`, "GET", {
		query: {
			userIds
		}
	});

	if (status !== 200 && !options.withStatus) return [];
	return options.withStatus ? {status, body} : body;
}

/**
 * @typedef {Object} SecurityRecommendationContent
 * @property {'text'|'badge'|'point'|'header'} type
 * @property {string|{icon?: string, text?:string, tooltip?:string, color?:string}|{icon?: string, text?:string, tooltip?:string, color?:string}[]} data
 */

/**
 * @typedef {Object} SecurityRecommendation
 * @property {string} identifier
 * @property {string} title
 * @property {SecurityRecommendationContent[]} content
 */
/**
 * Fetches security recommendations for a given guild
 * @param {string} guildId
 * @param {boolean} [mock]
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<SecurityRecommendation[] | BackendResult>}
 */
export async function getGuildSecurityRecommendations(guildId, mock=false, options={}) {
	if (mock) return [{"identifier":"elevatedPerms2FA","title":"2FA for Moderators Actions","content":[{"type":"text","data":"If possible, it is recommended to enable Community in your server to get access to 2FA for Moderator actions."},{"type":"text","data":"This would enforce your staff to have properly secured accounts if they want to be able to perform moderation actions in Discord."},{"type":"point","data":[{"icon":"Plus","color":"gray","text":"Enable Community"},{"icon":"Check","color":"gray","text":"Require 2FA for Moderator Actions"}]}]},{"identifier":"afkChannel","title":"AFK Voice Channel","content":[{"type":"text","data":"We recommend setting a dedicated AFK channel for voice activity. Anyone who doesn't talk for some time gets moved there automatically. This way you can avoid people accidentally leaving the microphone on."},{"type":"badge","data":{"icon":"Plus","color":"green","text":"AFK Channel, 5 mins"}}]},{"identifier":"suspiciousAlertsEnabled","title":"Suspicious Activity Alerts","content":[{"type":"text","data":"We recommend having a channel Discord can send alerts to when they detect suspicious activity. Activity such as raids and DM spam."},{"type":"point","data":[{"color":"gray","icon":"Hash","text":"Set alerts channel"},{"color":"green","icon":"Check","text":"Enable Activity Alerts"}]}]}];
	const {status, body} = await backend(`/public/guilds/${guildId}/misc/security/recommendations`);

	if (status !== 200 && !options.withStatus) return [];
	return options.withStatus ? {status, body} : body;
}

export default {
	fetchGuildRoles,
	searchGuildRoles,
	fetchGuild,
	fetchChannels,
	fetchGuildMembers,
	fetchUsers,
	getGuildSecurityRecommendations,
}
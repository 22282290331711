export const ManagedRulesQuota = {
    account: {
        /**
         * Max number of private Auto Mod rules a user can have.
         * Deleting private records will recover the limit.
         */
        maxPrivate: () => 25,
        /**
         * Max number of published Auto Mod rules a user can have.
         * Deleting published records MAY recover the limit. Public records may need to be manually handled before the limit is recovered.
         */
        maxPublic: () => 10,
    },
    guild: {
        /**
         * Max number of private Auto Mod rules a guild can have.
         * Deleting private records will recover the limit.
         */
        maxPrivate: () => 25,
        /**
         * Max number of published Auto Mod rules a guild can have.
         * Deleting published records MAY recover the limit. Public records may need to be manually handled before the limit is recovered.
         */
        maxPublic: () => 10,
    }
};
export const RuleTemplate = {
    /**
     * Max length the rule name can be.
     * Based on Discord's maxlength, with some buffer for custom prefix subtracted
     *
     * For now, 20 chars are reserved for prefix.
     */
    nameMaxLength: () => 80,
    /**
     * Max length of the description for auto-mod templates.
     * Ideally, it can fit inside a Discord embed description or field value.
     */
    descriptionMaxLength: () => 1024,
};
export const Defaults = {
    /**
     * Default flags for the Auto Mod system
     */
    flags: () => 0,
    /**
     * Default flags for the managed rules system (Audit alerts)
     */
    managedRulesFlags: () => 0,
};

// For staff: appeal-specific behavior settings as bitflags
export const CaseAppealBehaviorMask = {
    // Target and reviewers can chat with each other
    chatEnabled: 1 << 0,
    // The case is locked from further changes by user
    lockedForUser: 1 << 1,
    // The case is locked from further changes by staff
    lockedForStaff: 1 << 2,
    // Show to user when a moderator last viewed the appeal
    showLastViewedDate: 1 << 3,
    // Show to user who the last moderator to view the appeal was
    showLastViewedBy: 1 << 4,
    // Show to user who's assigned to review the case
    showAssigned: 1 << 5,
};
/**
 * Bitflag mask for Case Settings -> Appeals
 * Implements {@link ICaseAppealConfigFlagBooleans}
 */
export const CaseAppealSettingsMask = {
    /**
     * By default, users can make appeals on this type of case
     */
    appealsEnabled: 1 << 0,
    /**
     * By default, the chat is enabled
     */
    chatEnabled: 1 << 1,
    /**
     * By default, file uploads are enabled
     */
    filesEnabled: 1 << 2,
    /**
     * A case will be automatically generated for this case type if it is discovered that no case exists.
     * - If ban command is used on an already banned user, it will be generated regardless.
     * - Not necessarily all cases has something for this, e.g. notes has no way to
     */
    caseOnDiscovery: 1 << 3,
};
// Bitflags for alerting related to appeals
export const CaseAppealAlertingSettingsMask = {
    // When an appeal is created
    created: 1 << 0,
    // When a verdict (specific subset of states) is set
    verdict: 1 << 1,
    // When the state is changed
    stateChange: 1 << 2,
};
// For the appealing user: appeal-specific notification settings as bitflags
export const CaseAppealNotificationMask = {
    // When a verdict is reached
    verdict: 1 << 0,
    // If chat is enabled: when reviewers send a message
    chat: 1 << 1,
    // State is changed to "awaiting" (reviewers are requesting to appeal to provide something)
    awaiting: 1 << 2,
    // Whenever the state of the appeals to any other states
    stateChange: 1 << 3,
};
// For the appealing user: Flags for the events we will alert the appealing user via
export const CaseAppealNotificationChannelMask = {
    // Send alerts via a DM
    message: 1 << 0,
    // Send alerts via an email
    email: 1 << 1,
};
// Bitflag mask for Case Settings -> Alerting
export const CaseAppealSettingsAlertingMask = {
    "appeals": {
        // New appeal is created
        created: 1 << 0,
        // Appeal is updated (add/remove assignment, change state)
        updated: 1 << 1,
        // Appeal has verdict reached
        verdict: 1 << 2,
    },
};

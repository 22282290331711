<template>
	<g>
		<path
			d="M19.2967 17.047L14.035 20.4071C12.7967 21.1976 11.2033 21.1976 9.96495 20.4071L4.70327 17.047C3.64019 16.3691 3 15.2085 3 13.9628V7.67717C3 5.64553 4.6729 4 6.73832 4H17.2617C19.3271 4 21 5.64553 21 7.67717V13.9628C21 15.2085 20.3598 16.3691 19.2967 17.047V17.047Z"
/>
		<path
			d="M12 7C12 7.65661 12.1293 8.30679 12.3806 8.91342C12.6319 9.52004 13.0002 10.0712 13.4645 10.5355C13.9288 10.9998 14.48 11.3681 15.0866 11.6194C15.6932 11.8707 16.3434 12 17 12"
			stroke-linecap="round"
/>
		<path
			d="M7 12C7.65661 12 8.30679 11.8707 8.91342 11.6194C9.52004 11.3681 10.0712 10.9998 10.5355 10.5355C10.9998 10.0712 11.3681 9.52004 11.6194 8.91342C11.8707 8.30679 12 7.65661 12 7"
			stroke-linecap="round"
/>
		<path
			d="M17 12C16.3434 12 15.6932 12.1293 15.0866 12.3806C14.48 12.6319 13.9288 13.0002 13.4645 13.4645C13.0002 13.9288 12.6319 14.48 12.3806 15.0866C12.1293 15.6932 12 16.3434 12 17"
			stroke-linecap="round"
/>
		<path
			d="M12 17C12 16.3434 11.8707 15.6932 11.6194 15.0866C11.3681 14.48 10.9998 13.9288 10.5355 13.4645C10.0712 13.0002 9.52004 12.6319 8.91342 12.3806C8.30679 12.1293 7.65661 12 7 12"
			stroke-linecap="round"
/>
	</g>
</template>

<script>
export default {
	name: "IconArchivian"
};
</script>

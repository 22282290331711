<template>
	<g>
		<circle
			cx="12.0003"
			cy="11.9998"
			r="9.00375"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.0002 6.49609V8.99714"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.49902 11.9993V9.99846C9.49902 9.44595 9.94693 8.99805 10.4994 8.99805H13.5007C14.0532 8.99805 14.5011 9.44595 14.5011 9.99846V11.9993"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.99805 19.4861V13.0004C6.99805 12.4479 7.44595 12 7.99846 12H16.0018C16.5543 12 17.0022 12.4479 17.0022 13.0004V19.4861"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.49902 15.2502H14.5011"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.49902 17.7522H14.5011"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconOffice"
};
</script>
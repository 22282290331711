<template>
	<g>
		<path
			d="M12.9999 7H10.9999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.9999 10H10.9999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.8804 13H11.1196C10.8721 13 10.6619 13.1811 10.6251 13.4258L10.2613 15.8517C10.2181 16.1396 10.3025 16.432 10.4925 16.6526C10.6824 16.8731 10.9591 17 11.2502 17H12.7498C13.0409 17 13.3176 16.8731 13.5075 16.6526C13.6975 16.432 13.7819 16.1396 13.7387 15.8517L13.3749 13.4258C13.3382 13.1811 13.1279 13 12.8804 13V13Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7 21H17.5C18.8807 21 20 19.8807 20 18.5V8.37167C20 7.57602 19.6839 6.81296 19.1213 6.25035L16.7497 3.87868C16.187 3.31607 15.424 3 14.6283 3H7.5C6.11929 3 5 4.11929 5 5.5V19C5 20.1046 5.89543 21 7 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconFileZip"
};
</script>
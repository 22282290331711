<template>
	<div class="DetailedRadio">
		<DetailedRadioItem
			v-for="(option,index) of options"
			:id="option.id"
			:key="option.id"
			class="RadioItem transition-colors"
			:position="positions[index]"
			:selected="option.id===modelValue"
			:label="option.label"
			:description="option.description"
			:extra="option.extra||{}"
			@click="updateSelection(option.id)"
		/>
	</div>
</template>

<script setup>
import DetailedRadioItem from "@/components/form/radio/DetailedRadioItem.vue";
import { ref } from "vue";

const positions = ref([]);

const props = defineProps({
	// RadioExtra: { badge?: { label: string, icon?: string, color?: string }, guildIcon?: string, userAvatar?: string }
	// { id: string, label: string, description?: string, extra?: RadioExtra }[]
	"options": {
		type: Array,
		required: true,
	},
	"modelValue": {
		type: [String,Number],
		required: true,
	},
	"disabled": {
		type: Boolean,
		default: false,
	},
});

function getPosition(index) {
	const c = (i, o) => !i ? "first" : i === o.length - 1 ? "last" : "middle";

	if (!props.modelValue) return c(index, props.options);

	const selectedIndex = props.options.findIndex(opt => opt.id === props.modelValue);

	const pos = c(index, props.options);
	if (selectedIndex === -1) return pos;

	// If item is below selected
	if (index === selectedIndex + 1) {
		return "belowSelected";
	}

	return pos;
}

function updatePositions() {
	const p = [];
	for (let i = 0; i < props.options.length; i++) p.push(getPosition(i));

	positions.value = p;
}

updatePositions();

const emitter = defineEmits(["update:modelValue"]);

function updateSelection(optionId) {
	if (props.disabled) return;
	if (props.modelValue === optionId) return;
	emitter("update:modelValue", optionId);
	setTimeout(updatePositions, 0);
}

</script>

<style scoped>
.RadioItem:last-child {
	border-bottom-right-radius: 0.375rem;
	border-bottom-left-radius: 0.375rem;
	border-bottom-width: 1px;
}

.RadioItem:first-child {
	border-top-right-radius: 0.375rem;
	border-top-left-radius: 0.375rem;
}
</style>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19 8H15C14.448 8 14 7.552 14 7V3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 14V11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.999 16.775C11.861 16.775 11.749 16.887 11.75 17.025C11.75 17.163 11.862 17.275 12 17.275C12.138 17.275 12.25 17.163 12.25 17.025C12.25 16.887 12.138 16.775 11.999 16.775"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconFileWarning"
};
</script>
<script>
export default {
	name: "IconQuestionCircle"
}
</script>

<template>
	<g>
		<circle
			cx="12"
			cy="12"
			r="9.00375"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.68469 9.6847C9.92172 8.66314 10.8522 7.95484 11.8999 7.99832C13.0726 7.93337 14.0779 8.82697 14.1509 9.99916C14.1509 11.5037 12 12 12 13.0004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.125 15.7516C12.125 15.8206 12.069 15.8766 12 15.8766C11.9309 15.8766 11.8749 15.8206 11.8749 15.7516C11.8749 15.6825 11.9309 15.6265 12 15.6265"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 15.6265C12.0691 15.6265 12.1251 15.6825 12.1251 15.7515"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>
<script>
export default {
	name: "IconAsterisk"
}
</script>

<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 7.60999V12.11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.71997 10.72L12 12.11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.34998 15.75L12 12.11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.65 15.75L12 12.11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.28 10.72L12 12.11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>
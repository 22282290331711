<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.0005 5.22852V5.22852C15.7405 5.22852 18.7715 8.25952 18.7715 11.9995V11.9995C18.7715 15.7395 15.7405 18.7705 12.0005 18.7705V18.7705C8.26049 18.7705 5.22949 15.7395 5.22949 11.9995V11.9995C5.22949 8.25952 8.26049 5.22852 12.0005 5.22852Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 12.3762V8.61523"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.999 15.0473C11.916 15.0473 11.849 15.1143 11.85 15.1973C11.85 15.2803 11.917 15.3473 12 15.3473C12.083 15.3473 12.15 15.2803 12.15 15.1973C12.15 15.1153 12.083 15.0473 11.999 15.0473"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20.3262 5.75586C23.1042 9.44786 23.1042 14.5489 20.3262 18.2409"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.66748 18.2428C0.889484 14.5508 0.889484 9.44981 3.66748 5.75781"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCrisis"
};
</script>
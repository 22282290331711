<template>
	<g>
		<path
			d="M12.9524 8.66016L11.0469 15.3296"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.90284 12.9473L6.99805 11.0415L8.90284 9.13672"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.0977 14.8536L17.0024 12.9478L15.0977 11.043"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7.99818 2.99023H16.0015C18.7641 2.99023 21.0036 5.22974 21.0036 7.99232V15.9957C21.0036 17.3223 20.4766 18.5946 19.5385 19.5327C18.6004 20.4707 17.3281 20.9977 16.0015 20.9977H7.99818C5.2356 20.9977 2.99609 18.7582 2.99609 15.9957V7.99232C2.99609 6.66568 3.5231 5.39338 4.46117 4.45531C5.39924 3.51724 6.67154 2.99023 7.99818 2.99023Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCodeBlock"
};
</script>
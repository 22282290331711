<script setup>
import InspectEntryView from "@/partials/inspect/case/inspectEntry/InspectEntryView.vue";
import {computed} from "vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";

const props = defineProps({
	"entry": {
		type: Object,
		required: true,
	},
	"theCase": {
		type: Object,
		required: true,
	},
	"close": {
		type: Function,
		default: () => () => {},
	},
});

/**
 * Any case must have at least one entry that is neither deleted nor archived.
 * I.e., there must always be some "active" entry.
 * The component handles permissions based rendering.
 */
const hasMultipleActiveEntries = computed(() => {
	let otherActiveFound = false;

	for (let i=0;i<props.theCase.entries.length;i++) {
		// Ignore archived
		if (props.theCase.entries[i].archived.at) continue;

		// If we found another (+ this new one), we have enough
		if (otherActiveFound) return true;
		otherActiveFound = true;
	}

	// Loop would have returned early if it found multiple
	return false;
});
</script>

<template>
	<InspectEntryView
		v-if="entry"
		:the-case="theCase"
		:entry="entry"
		:close="close"
		:allow-delete="hasMultipleActiveEntries"
		:allow-archive="hasMultipleActiveEntries"
	/>
	<div v-else class="rounded-lg p-4 flex gap-2">
		<ErrorCallout
			class="h-fit"
			label="Entry not found"
			texts="This entry does not exist, has been deleted, or you do not have permission to view it"
		/>
		<CloseButton
			label="Close this panel"
			class="h-fit"
			@click="close"
		/>
	</div>
</template>

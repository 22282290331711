<script setup>
import InspectCardSlideoutNav from "@/views/guild/moderation-channels/temp/tabs/InspectCardSlideoutNav.vue";
import {computed, ref} from "vue";
import HorizontalDivider from "@/components/misc/HorizontalDivider.vue";
import SwitchBox from "@/components/form/SwitchBox.vue";
import SearchGuildChannel from "@/components/form/search/SearchGuildChannel.vue";
import {useRoute} from "vue-router";
import NumberInput from "@/components/form/NumberInput.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {Account} from "@/storage/AccountCache"
import { Permissions } from "@archivian/constants";
import ConstructionCallout from "@/components/info/callouts/ConstructionCallout.vue";
import InfoCallout from "@/components/info/callouts/InfoCallout.vue";

const emit = defineEmits(["update:enabled", "update:channelId", "update:threshold", "update:showRecentCases", "reset", "save"]);
defineProps({
	"settings": {
		type: Object,
		required: true,
	},
	"averages": {
		type: Object,
		required: true,
	},
	"isSaving": {
		type: Boolean,
		default: false,
	},
	"isModified": {
		type: Boolean,
		default: false,
	},
});
const route = useRoute();
const account = Account();
const canUpdate = computed(()=>{
	return account.can(Permissions.WarningSystem.EarlyWarnings.SETTINGS.UPDATE());
});
let resetPrompted = ref(false);
let timeout;
const handleReset = () => {
	clearTimeout(timeout);

	if (!resetPrompted.value) {
		resetPrompted.value = true;

		return timeout = setTimeout(() => {
			resetPrompted.value = false;
		}, 4000);
	}

	resetPrompted.value = false;
	emit("reset");
};
</script>

<template>
	<div class="p-4 h-full min-h-0">
		<InspectCardSlideoutNav
			full-height
			styled
			small
		>
			<template #sidenav>
				<template v-if="canUpdate">
					<BasicButton
						:label="isSaving ? 'Saving...' : 'Save Changes'"
						:disabled="isSaving || !isModified"
						@click="emit('save')"
					/>
					<BasicButton
						type="link"
						:label="resetPrompted ? 'Are you sure?' : 'Reset'"
						:disabled="isSaving || !isModified"
						@click="handleReset"
					/>
				</template>
				<p v-else class="italic text-neutral-600">
					No permission to update settings.
				</p>
			</template>
			<template #default>
				<div class="flex flex-col overflow-y-auto h-full pl-2 pr-4">
					<h2>Early Warning System</h2>
					<p class="pb-4">
						Receive a Discord alert when users with low trust score joins the server.
					</p>

					<ConstructionCallout
						label="Trust score calculation is still under development and calibration. Scores and averages may change without notice."
					/>

					<HorizontalDivider margin />

					<SwitchBox
						label="Waring system enabled"
						class="mb-4 w-fit"
						:disabled="!canUpdate"
						:enabled="settings.enabled"
						@toggle="emit('update:enabled', $event)"
					/>

					<label for="output-channel">Send alerts to&hellip;</label>
					<SearchGuildChannel
						id="output-channel"
						:model-value="settings.channelId"
						:channel-types="['text']"
						:guild-id="route.params.guildId"
						:disabled="!canUpdate"
						bind-using-id
						@update:model-value="emit('update:channelId', $event)"
					/>

					<HorizontalDivider margin />

					<h4>Threshold</h4>
					<p>
						Any new member that scores below the threshold will trigger an alert.
					</p>

					<div class="flex gap-4 items-center my-2">
						<div class="flex">
							<NumberInput
								:model-value="settings.threshold"
								:disabled="!canUpdate"
								class="w-24"
								@update:model-value="emit('update:threshold', $event)"
							/>
						</div>
						<input
							:value="settings.threshold"
							:disabled="!canUpdate"
							class="py-4 input-range"
							type="range"
							min="1"
							max="100"
							:step="1"
							@input="emit('update:threshold', parseInt($event.target.value))"
						>
					</div>

					<p class="text-sm text-gray-500 hover:text-gray-300 transition-colors px-2 pb-2 pt-2.5 rounded-md bg-neutral-800/50">
						<DynamicIcon icon="LightBulb" />
						Global average is currently <strong>{{ averages.global }}</strong>.
						<template v-if="averages.guild!==null">
							In this Discord server it is currently {{ averages.guild }}.
						</template>
					</p>

					<InfoCallout
						class="mt-2"
						label="In the future we might disable or auto-adjust your score setting if it is set too low and triggers too many alerts."
					/>

					<HorizontalDivider margin />

					<h4>Show Recent Cases</h4>
					<p>
						If the user that joins had previous cases, the alert will contain a small overview of the three latest cases.
					</p>

					<SwitchBox
						class="w-fit"
						label="Include recent cases"
						:enabled="settings.showRecentCases"
						:disabled="!canUpdate"
						@toggle="emit('update:showRecentCases', $event)"
					/>

					<!-- So switch-box doesn't touch bottom -->
					<div class="pb-16" />
				</div>
			</template>
		</InspectCardSlideoutNav>
	</div>
</template>

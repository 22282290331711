import { defineStore } from "pinia";
import DiscordService from "@/services/DiscordService";
import PermissionService from "@/services/PermissionService";
import {unref} from "vue";
import { Permissions } from "@archivian/constants";

const models = {
	guilds: null,
	staff: null,
	teams: null,
	titles: null,
	permissions: null,
	channels: null,
	users: null,
	roles: null,
	members: null,
	pageDataCache: null,
};
window.idbStoreModels = models;

/**
 * This store is a wrapper for the IndexedDB database.
 * The data here lives only for the duration of the current tab.
 *
 * Its purpose is to allow reactive reading of a subset of data that is relevant for the current context.
 * Context meaning e.g. the guild we're in, or personal dashboard.
 *
 * On navigation to a new context, the data should be cleared (store.$reset()) and then call .init() again.
 *
 * It is also acting as a centralized interface for updating data whenever the WebSocket provides updates.
 * The updates are merged into the current active dataset, as well as replicated into the IndexedDB.
 */

const IndexedDBStore = defineStore("IndexedDB", {
	state: () => {
		return {
			/**
			 * ID of the current context. Null or undefined for personal dashboard, else guild ID.
			 * Used for skipping redundant .init() calls
			 */
			guildId: undefined,

			/**
			 * Various reactive data we need for the current context.
			 * They are loaded in bulk on .init(), and after that it is
			 * kept up to date incrementally in various ways, but mostly
			 * through web-socket events and API responses.
			 */
			guilds: {},
			staff: {},
			teams: {},
			titles: {},
			permissionNodes: [],
			channels: {},
			users: {},
			roles: {},
			members: {},
			pageDataCache: {},

			// Holds references to other stores it may update the data of
			globalStores: {
				/**
				 * Holds reference to the Account store (AccountCache.js)
				 */
				account: null,
				/**
				 * Holds reference to the User store (UserCache.js)
				 */
				users: null,
				/**
				 * Holds reference to the UI store (UI.js)
				 */
				ui: null,
				/**
				 * Holds reference to the temporary application cache store (AppCache.js)
				 */
				cache: null,
			}
		};
	},
	getters: {
		models: () => models,
	},
	actions: {
		/////////////////////////////////////////////////
		//				  INIT & GENERIC
		/////////////////////////////////////////////////
		/**
		 * Wipes all context-specific data, since we can't use $reset due to still needing the models and such
		 */
		wipe() {
			this.guildId = undefined;
			this.guilds = {};
			this.staff = {};
			this.teams = {};
			this.titles = {};
			this.permissionNodes = [];
			this.channels = {};
			this.users = {};
			this.roles = {};
			this.members = {};
			this.pageDataCache = {};
			console.debug("[IndexedDB Store] Data wiped");
		},
		/**
		 * Purges all stores in the indexedDB.
		 * Used only by logout to remove all traces of the user's session.
		 * E.g., you do not want to leave any trace on a shared public computer.
		 * @returns {Promise<void>}
		 */
		async purgeAll() {
			const { default: idb } = await import("@/db/Database");
			await idb.purgeAll();
		},
		/**
		 * Populates {@link IDBModel}s, so we can make IndexedDB queries later.
		 * All models should be populated before you call `init`.
		 * @param {IDBModel | Array<IDBModel>} model
		 */
		populateModel(model) {
			if (Array.isArray(model)) {
				for (let m of model) {
					models[m.name] = unref(m);
				}
			} else {
				models[model.name] = unref(model);
			}
		},
		/**
		 * Initializes the data for a given guild by ID.
		 * Leave empty or pass null to load for the personal dashboard.
		 *
		 * Should be called AFTER you have populated models.
		 * @param {?string | null} guildId
		 * @param {boolean} [force=false] If true, it will re-fetch all data from the IndexedDB if we already have this guild initialized
		 * @returns {Promise<void>}
		 */
		async init(guildId, force = false) {
			if (!force && this.guildId === guildId) return;
			this.guildId = guildId || "";

			// Here we may have custom implementations on how to load the necessary data for each model.

			if (models.guilds) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.guilds;
				const guilds = await model.findMany({});
				for (let i=0;i<guilds.length;i++) this.guilds[guilds[i].id] = guilds[i];
			}

			if (models.staff && guildId) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.staff;
				const staffs = await model.findMany({ guildId: guildId });
				for (let i=0;i<staffs.length;i++) this.staff[staffs[i].userId] = staffs[i];
			}

			if (models.teams && guildId) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.teams;
				const teams = await model.findMany({ guildId: guildId });
				for (let i=0;i<teams.length;i++) this.teams[teams[i].id] = teams[i];
			}

			if (models.titles && guildId) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.titles;
				const titles = await model.findMany({ guildId: guildId });
				for (let i=0;i<titles.length;i++) this.titles[titles[i].id] = titles[i];
			}

			if (models.permissions) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.permissions;
				// null is the ID for personal dashboard
				const permissionNodes = await model.findOneById(guildId ?? "");
				this.permissionNodes = permissionNodes || Permissions.Personal.DEFAULT();
			}

			if (models.channels && guildId) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.channels;
				const channels = await model.findMany({guild_id: guildId});
				for (let i=0;i<channels.length;i++) this.channels[channels[i]._id || channels[i].id] = channels[i];
			}

			if (models.users) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.users;
				const users = await model.findMany({});
				for (let i=0;i<users.length;i++) this.users[users[i]._id || users[i].id] = users[i];
			}

			if (models.roles && guildId) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.roles;
				const roles = await model.findMany({guild_id: guildId});
				for (let i=0;i<roles.length;i++) this.roles[roles[i]._id || roles[i].id] = roles[i];
			}

			if (models.members && guildId) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.members;
				const members = await model.findMany({guildId: guildId});
				for (let i=0;i<members.length;i++) this.members[members[i]._id || members[i].id] = members[i];
			}

			if (models.pageDataCache) {
				/**
				 * @type {IDBModel}
				 */
				const model = models.pageDataCache;
				const dataCache = await model.findMany({guildId: guildId || ""});
				for (let i=0;i<dataCache.length;i++) this.pageDataCache[dataCache[i].id] = dataCache[i].data;
			}
		},
		/**
		 * Loads all the data necessary for a given guild by ID from the backend.
		 * @param {string} guildId
		 */
		async fetchAll(guildId) {
			this.guildId = guildId;

			await Promise.all([
				this.fetchGuild(guildId),
				this.fetchStaff(guildId),
				this.fetchTeams(guildId),
				this.fetchTitles(guildId),
				this.fetchPermissionNodes(guildId),
				this.fetchChannels(guildId),
				this.fetchRoles(guildId),
			]);

			await this.init(guildId, true);
		},


		/////////////////////////////////////////////////
		//					   GET
		/////////////////////////////////////////////////
		/**
		 * Get a staff record (no relations populated) by their Discord user ID
		 * @param {UserID} userId
		 * @returns {null|Object}
		 */
		getStaff(userId) {
			return this.staff[userId];
		},
		/**
		 * Get a team by their DB ID
		 * @param {string} teamId
		 * @returns {null|Object}
		 */
		getTeam(teamId) {
			return this.teams[teamId];
		},
		/**
		 * Get a title by its ID
		 * @param {string} titleId
		 * @returns {null|Object}
		 */
		getTitle(titleId) {
			return this.titles[titleId];
		},
		/**
		 * Get a Discord channel by ID
		 * @param {string} channelId
		 * @returns {null|Object}
		 */
		getChannel(channelId) {
			return this.channels[channelId];
		},
		/**
		 * Get a Discord user by ID
		 * @param {UserID} userId
		 * @returns {null|Object}
		 */
		getUser(userId) {
			return this.users[userId];
		},
		/**
		 * Get a Discord server role by ID
		 * @param {string} roleId
		 * @returns {null|Object}
		 */
		getRole(roleId) {
			return this.roles[roleId];
		},
		/**
		 * Get a guild by ID
		 * @param {string} guildId
		 * @returns {null|Object}
		 */
		getGuild(guildId) {
			return this.guilds[guildId];
		},
		/**
		 * Get a guild member by their user ID
		 * @param {string} guildId
		 * @param {UserID} userId
		 * @returns {null|Object}
		 */
		getMember(guildId, userId) {
			return this.members[userId];
		},
		/**
		 * Get some cached data from a page identifier
		 * @param {string} pageIdentifier
		 * @returns {null|Object}
		 */
		getPageCache(pageIdentifier) {
			return this.pageDataCache[pageIdentifier];
		},

		/////////////////////////////////////////////////
		//					SET MANY
		/////////////////////////////////////////////////
		/**
		 * Short hand for:
		 * - purge staff members
		 * - setStaff for each staff
		 * @param {string} guildId
		 * @param {Object[]} newRecords
		 */
		async setStaffs(guildId, newRecords) {
			await this.purgeStaff(guildId);
			for (let i=0; i<newRecords.length; i++) {
				await this.setStaff(newRecords[i].userId, newRecords[i]);
			}
		},
		/**
		 * Short hand for:
		 * - purge teams
		 * - setTeam for each team
		 * @param {string} guildId
		 * @param {Object[]} newRecords
		 */
		async setTeams(guildId, newRecords) {
			await this.purgeTeams(guildId);
			for (let i=0; i<newRecords.length; i++) {
				await this.setTeam(newRecords[i]);
			}
		},
		/**
		 * Short hand for:
		 * - purge titles
		 * - setTitle for each title
		 * @param {string} guildId
		 * @param {Object[]} newRecords
		 */
		async setTitles(guildId, newRecords) {
			await this.purgeTitles(guildId);
			for (let i=0; i<newRecords.length; i++) {
				await this.setTitle(newRecords[i]);
			}
		},
		/**
		 * Short hand for:
		 * - purge channels
		 * - setChannel for each channel
		 * @param {string} guildId
		 * @param {Object[]} newRecords
		 */
		async setChannels(guildId, newRecords) {
			await this.purgeChannels(guildId);
			for (let i=0; i<newRecords.length; i++) {
				await this.setChannel(guildId, newRecords[i]);
			}
		},
		/**
		 * DOES NOT purge users. It simply inserts/overwrites multiple at the same time.
		 * Short hand for:
		 * - setUser for each user
		 * @param {Object[]} newRecords
		 * @returns {Promise<void>}
		 */
		async setUsers(newRecords) {
			for (let i=0; i<newRecords.length; i++) {
				await this.setUser(newRecords[i]);
			}
		},

		/**
		 * DOES NOT purge members. It simply inserts/overwrites multiple at the same time.
		 * Short hand for:
		 * - setMember for each member
		 * @param {Object[]} newRecords
		 */
		async setMembers(newRecords) {
			for (let i=0; i<newRecords.length; i++) {
				await this.setMember(newRecords[i]);
			}
		},
		/**
		 * Short hand for:
		 * - purge roles
		 * - setRole for each role
		 * @param {string} guildId
		 * @param {Object[]} newRecords
		 * @param {?{populate: boolean}} [options]
		 */
		async setRoles(guildId, newRecords, options = {}) {
			await this.purgeRoles(guildId);
			for (let i=0; i<newRecords.length; i++) {
				await this.setRole(guildId, newRecords[i]);

				if (options.populate) {
					this.roles[newRecords[i].id] = newRecords[i];
				}
			}
		},
		/**
		 * DOES NOT purge guilds. It simply inserts/overwrites multiple at the same time.
		 * Short hand for:
		 * - setGuild for each guild
		 * @param {Object[]} newRecords
		 */
		async setGuilds(newRecords) {
			for (let i=0; i<newRecords.length; i++) {
				await this.setGuild(newRecords[i]);
			}
		},


		/////////////////////////////////////////////////
		//					 SET ONE
		/////////////////////////////////////////////////
		/**
		 * Sets permission nodes for a guild by ID, or personal dashboard if null
		 * @param {string | null} guildId `null` for personal dashboard
		 * @param {string[]} permissionNodes
		 * @returns {Promise<string[]>} The new record
		 */
		async setPermissions(guildId, permissionNodes) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.permissions;
			await model.upsert(permissionNodes, guildId);
			this.permissionNodes = permissionNodes;
			return permissionNodes;
		},
		/**
		 * Insert or overwrite a single staff record by their Discord user ID
		 * @param {UserID} userId
		 * @param {Object} newRecord
		 * @param {?{populate: boolean}} [options]
		 * @returns {Promise<Object>} The new record
		 */
		async setStaff(userId, newRecord, options = {}) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.staff;
			await model.upsert(newRecord);

			if (options.populate) {
				this.staff[newRecord.userId] = newRecord;
			}

			return newRecord;
		},
		/**
		 * Insert or overwrite a single team by its ID
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setTeam(newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.teams;
			await model.upsert(newRecord);
			this.teams[newRecord.id] = newRecord;
			return newRecord;
		},
		/**
		 * Inserts or overwrites a single title by its ID
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setTitle(newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.titles;
			await model.upsert(newRecord);
			this.titles[newRecord.id] = newRecord;
			return newRecord;
		},
		/**
		 * Inserts or overwrites a single channel by ID
		 * @param {string} guildId
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setChannel(guildId, newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.channels;
			await model.upsert(newRecord);

			if (!newRecord.id && newRecord._id) newRecord.id = newRecord._id;

			this.channels[newRecord.id] = newRecord;
			return newRecord;
		},
		/**
		 * Inserts or overwrites a single Discord user by their user ID
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setUser(newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.users;

			if (!newRecord.id && newRecord._id) newRecord.id = newRecord._id;
			if (!newRecord.id && newRecord.userId) newRecord.id = newRecord.userId;

			await model.upsert(newRecord);

			// Users is global and should always populate
			this.users[newRecord.id] = newRecord;

			return newRecord;
		},
		/**
		 * Inserts or overwrites a single Discord member by their user ID
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setMember(newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.members;
			await model.upsert(newRecord);
			if (!newRecord.id && newRecord._id) newRecord.id = newRecord._id;
			this.members[newRecord.id] = newRecord;
			return newRecord;
		},
		/**
		 * Inserts or overwrites a single Discord server role by its ID
		 * @param {string} guildId
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setRole(guildId, newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.roles;
			await model.upsert(newRecord);
			if (!newRecord.id && newRecord._id) newRecord.id = newRecord._id;
			this.roles[newRecord.id] = newRecord;
			return newRecord;
		},
		/**
		 * Inserts or overwrites a single guild by its ID
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setGuild(newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.guilds;
			if (!newRecord.id && newRecord._id) newRecord.id = newRecord._id;
			await model.upsert(newRecord);
			this.guilds[newRecord.id] = newRecord;
			return newRecord;
		},
		/**
		 * Inserts or overwrites a single page cache record by its ID
		 * @param {string} pageIdentifier
		 * @param {string} guildId
		 * @param {Object} newRecord
		 * @returns {Promise<Object>} The new record
		 */
		async setPageCache(pageIdentifier, guildId, newRecord) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.pageDataCache;
			await model.upsert({ id: pageIdentifier, guildId, data: newRecord});
			this.pageDataCache[pageIdentifier] = newRecord;
			return newRecord;
		},


		/////////////////////////////////////////////////
		//					REMOVE ONE
		/////////////////////////////////////////////////
		/**
		 * Removes a single staff member by their Discord user ID
		 * @param {string} guildId
		 * @param {UserID} userId
		 */
		async removeStaff(guildId, userId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.staff;
			await model.deleteMany({ guildId, userId, id: userId });
			this.staff[userId] = undefined;
		},
		/**
		 * Removes a single team by its ID
		 * @param {string} teamId
		 */
		async removeTeam(teamId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.teams;
			await model.deleteMany({ id: teamId });
			this.teams[teamId] = undefined;
		},
		/**
		 * Removes a single title by its ID
		 * @param {string} titleId
		 */
		async removeTitle(titleId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.titles;
			await model.deleteMany({ id: titleId });
			this.titles[titleId] = undefined;
		},
		/**
		 * Removes a single Discord server channel by its ID
		 * @param {string} channelId
		 */
		async removeChannel(channelId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.channels;
			await model.deleteMany({ id: channelId });
			this.channels[channelId] = undefined;
		},
		/**
		 * Removes a single Discord user by their user ID
		 * @param {UserID} userId
		 */
		async removeUser(userId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.users;
			await model.deleteMany({ id: userId });
			this.userId[userId] = undefined;
		},
		/**
		 * Removes a single Discord server role by its ID
		 * @param {string} roleId
		 */
		async removeRole(roleId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.roles;
			await model.deleteMany({ id: roleId });
			this.roles[roleId] = undefined;
		},
		/**
		 * Removes a guild by its ID
		 * @param {string} guildId
		 */
		async removeGuild(guildId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.guilds;
			await model.deleteMany({ id: guildId });
		},

		/////////////////////////////////////////////////
		//					REMOVE MANY
		/////////////////////////////////////////////////
		/**
		 * Removes all staff member records for a given guild
		 * @param {string} guildId
		 */
		async purgeStaff(guildId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.staff;
			await model.deleteMany({ guildId });
			this.staff = {};
		},
		/**
		 * Removes all team records for a given guild
		 * @param {string} guildId
		 */
		async purgeTeams(guildId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.teams;
			await model.deleteMany({ guildId });
			this.teams = {};
		},
		/**
		 * Removes all title records for a given guild
		 * @param {string} guildId
		 */
		async purgeTitles(guildId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.titles;
			await model.deleteMany({ guildId });
			this.titles = {};
		},
		/**
		 * Removes all channel records belonging to a given guild
		 * @param {string} guildId
		 */
		async purgeChannels(guildId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.channels;
			await model.deleteMany({ guildId });
			this.channels = {};
		},
		/**
		 * Removes all roles belonging to a given guild
		 * @param {string} guildId
		 */
		async purgeRoles(guildId) {
			/**
			 * @type {IDBModel}
			 */
			const model = models.roles;
			await model.deleteMany({ guildId });
			this.roles = {};
		},
		// Guilds should not be purged. It holds guild user is not staff in etc.
		// Users should not be purged. It holds context-agnostic data.


		/////////////////////////////////////////////////
		//				   API FETCHERS
		/////////////////////////////////////////////////
		/**
		 * Fetches all the roles for a given guild from the backend, populating them in the storage
		 * @param {string} guildId
		 * @returns {Promise<Array<Object>>}
		 */
		async fetchRoles(guildId) {
			const roles = await DiscordService.fetchGuildRoles(guildId);
			if (roles.length) await this.setRoles(guildId, roles);
			return roles;
		},

		/**
		 * Fetches the display details for a given guild by ID, populating it in the storage
		 * @param guildId
		 * @returns {Promise<DisplayGuild | null>}
		 */
		async fetchGuild(guildId) {
			const guild = await DiscordService.fetchGuild(guildId);
			if (guild) await this.setGuild(guild);
			return guild;
		},

		/**
		 * Fetches the staff members for a given guild from the backend, populating them in the storage
		 * @param {string} guildId
		 * @returns {Promise<Object[]>}
		 */
		async fetchStaff(guildId) {
			const staff = await PermissionService.fetchStaff(guildId);
			if (staff.length) await this.setStaffs(guildId, staff);
			return staff;
		},

		/**
		 * Fetches all the teams a guild has without any relations. Populates them in storage.
		 * @param {string} guildId
		 * @returns {Promise<Object[]>}
		 */
		async fetchTeams(guildId) {
			console.error("NOT IMPLEMENTED", guildId);
			return [];
			// const result = await PermissionService.fetchTeams(guildId);
			// if (result.teams.length) await this.setTeams(guildId, result.teams);
			// return result.teams;
		},

		/**
		 * Fetches all titles a guild has without any relations. Populates them in storage
		 * @param {string} guildId
		 * @returns {Promise<Object[]>}
		 */
		async fetchTitles(guildId) {
			const result = await PermissionService.fetchTitles(guildId);
			if (result.titles) await this.setTitles(guildId, result.titles);
			return result.titles;
		},

		/**
		 * Fetches your permission nodes for a given guild, populates them in storage
		 * @param {?string|null} guildId Leave empty or null for personal dashboard
		 * @returns {Promise<string[]>}
		 */
		async fetchPermissionNodes(guildId) {
			console.log("FETCHING PERMISSIONS")
			if (!guildId && guildId !== null) guildId = null;

			let nodes = [];
			if (!guildId) {
				console.log("FETCHING PERSONAL")
				nodes = await PermissionService.fetchPersonalPermissionNodes();
				console.log(nodes)
			} else {
				nodes = await PermissionService.fetchGuildPermissionNodes(guildId);
			}

			if (nodes.length) {
				await this.setPermissions(guildId||"", nodes);
			} else if (!guildId) {
				await this.setPermissions(guildId||"", Permissions.Personal.DEFAULT());
			}

			return nodes;
		},

		/**
		 * Fetches the channels for a given guild from the backend, populating them in the storage
		 * @param {string} guildId
		 * @returns {Promise<Object[]>}
		 */
		async fetchChannels(guildId) {
			const channels = await DiscordService.fetchChannels(guildId);
			if (channels.length) await this.setChannels(guildId, channels);
			return channels;
		},

		/**
		 * Fetch Discord users by given {@link UserID}s. Populates results in storage
		 * @param {string} guildId
		 * @param {UserID[]} userIds
		 * @returns {Promise<Object[]>}
		 */
		async fetchUsers(guildId, userIds) {
			const users = await DiscordService.fetchUsers(guildId, userIds);
			if (users.length) await this.setUsers(users);
			return users;
		},

		/**
		 * Fetch guild members by given {@link UserID}s. Populates results in storage
		 * @param {string} guildId
		 * @param {UserID[]} userIds
		 * @returns {Promise<Object[]>}
		 */
		async fetchMembers(guildId, userIds) {
			const members = await DiscordService.fetchGuildMembers(guildId, userIds);
			if (members.length) await this.setMembers(members);
			return members;
		},
	},
});

export default IndexedDBStore;
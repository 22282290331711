<template>
	<g>
		<path
			d="M14 13.5H19C19.552 13.5 20 13.948 20 14.5V18.5C20 19.052 19.552 19.5 19 19.5H15C14.448 19.5 14 19.052 14 18.5V13.5C14 7.5 15 6.5 18 4.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4 13.5H9C9.552 13.5 10 13.948 10 14.5V18.5C10 19.052 9.552 19.5 9 19.5H5C4.448 19.5 4 19.052 4 18.5V13.5C4 7.5 5 6.5 8 4.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconQuote"
};
</script>
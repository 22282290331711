<template>
	<g>
		<path
			d="M12 8V9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 16V15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.266 14.289C10.524 14.712 10.966 15.003 11.499 15.003H12.595C13.37 15.003 13.999 14.375 13.999 13.599C13.999 12.955 13.561 12.393 12.937 12.237L11.063 11.767C10.438 11.61 10 11.048 10 10.404C10 9.629 10.628 9 11.404 9H12.5C13.033 9 13.475 9.292 13.733 9.714"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20.8632 10.4363C21.7262 15.3313 18.4572 19.9993 13.5632 20.8623C8.66824 21.7253 4.00024 18.4573 3.13724 13.5623C2.27424 8.66726 5.54224 3.99926 10.4372 3.13626C15.3322 2.27326 20.0002 5.54126 20.8632 10.4363"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconDollar"
};
</script>
<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {ref} from "vue";
import TextInput from "@/components/form/Text/TextInput.vue";
import JoyrideBox from "@/components/joyrides/JoyrideBox.vue";
import SearchCase from "@/components/form/search/SearchCase.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const props = defineProps({
	"close": {
		type: Function,
		default: () => () => {
		},
	},
	"handleSave": {
		type: Function,
		default: () => () => {
		},
	},
	"excludeCaseSids": {
		type: Array,
		default: () => [],
	},
	"guildId": {
		type: String,
		required: true,
	},
});
const cases = CaseAppealBrowser();
const label = ref("");
const selectedCase = ref(null);
const createLink = () => {
	if (!selectedCase.value) return;
	return props.handleSave({
		label: label.value,
		caseSid: selectedCase.value.sid,
	});
};
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<div class="flex items-center w-full justify-between">
				<h2>Link New Case</h2>

				<div class="CaseActionButtons">
					<CloseButton label="Close creation panel" @click="close" />
				</div>
			</div>
		</template>

		<template #main>
			<JoyrideBox
				joyride-id="case-linked-users"
				description="Case linking lets you create bidirectional connections between two cases. For example a warning that was ignored, leading to a timeout."
			/>
			<div>
				<label for="linkLabel">Label</label>
				<TextInput
					id="linkLabel"
					v-model="label"
					placeholder="E.g. Previous warning or Repeat offence"
					maxlength="32"
					autofocus
				/>
			</div>

			<div>
				<label for="selectCase">Select case to link</label>
				<SearchCase
					id="selectCase"
					v-model="selectedCase"
					:guild-id="guildId"
					:exclude-ids="excludeCaseSids"
				/>
			</div>
		</template>

		<template #footer>
			<div class="flex gap-2 w-full justify-end">
				<BasicButton
					:label="cases.isLoading('addLinked', selectedCase?.sid) ? 'Close' : 'Cancel'"
					type="link"
					@click="close"
				/>
				<PermsCond :needs="Nodes.Cases.LINKED.CASE.CREATE()">
					<BasicButton
						:label="cases.isLoading('addLinked', selectedCase?.sid) ? 'Creating...' : 'Create'"
						:disabled="!selectedCase || !label || cases.isLoading('addLinked', selectedCase?.sid)"
						@click="createLink"
					/>
				</PermsCond>
			</div>
		</template>
	</InspectResourceLayout>
</template>
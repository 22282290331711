<script>
export default {
	name: "IconChatLines"
}
</script>

<template>
	<g>
		<path
			d="M7 8.5H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 13.5H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.58582 19L11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071L14.4142 19H18C19.6569 19 21 17.6569 21 16V6C21 4.34315 19.6569 3 18 3H6C4.34315 3 3 4.34315 3 6V16C3 17.6569 4.34315 19 6 19H9.58582Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>
<template>
	<nav
		id="main-nav"
		aria-label="Main navigation menu"
		class="w-min px-4 my-4 flex flex-col justify-between gap-4 border-r border-r-neutral-700 relative z-20"
	>
		<div class="top h-min">
			<!-- Logo -->
			<RouterLink :to="logoHome">
				<img
					src="@/assets/logo.svg"
					alt="Archivian Logo"
					class="w-1/2 mx-auto"
				>
			</RouterLink>

			<!-- Nav items list -->
			<div class="mt-12 flex flex-col gap-2 px-2 relative">
				<div class="active-highlight top-0" :style="`transform: translateY(calc(7rem + ${activeTopOffset}rem))`" />
				<!-- Nav item -->
				<NavItem
					v-for="(item,index) in p.sidebar"
					:key="index"
					:link="item.link"
					:tooltip="item.tooltip"
					:badge-count="item.badgeCount"
					:icon="item.icon"
				/>
			</div>
		</div>

		<div class="bottom h-min flex flex-col justify-between gap-4">
			<slot />
		</div>
	</nav>
</template>

<script setup>
import NavItem from "@/partials/navigation/main-nav/NavItem";
import {ref, watchEffect, computed} from "vue";
import {useRoute} from "vue-router";

const p = defineProps({
	"sidebar": {
		type: Array,
		required: true,
	}
});
const route = useRoute();
const activeTopOffset = ref(48);
// Execute a function every time the route changes, but only after it has been changed
watchEffect(() => {
	const names = p.sidebar.map(item => item.link.name);
	// Check each route's match to see if it matches any name in the sidebar
	for (const match of route.matched) {
		const index = names.indexOf(match.name);
		if (index === -1) continue;

		activeTopOffset.value = index * 3.34;
		break;
	}
});

const logoHome = computed(()=>{
	if (route.meta.isPersonal) return {name: 'me'};
	return {name: 'guild-landing'};
});
</script>

<style scoped>
.active-highlight {
	@apply fixed w-16 h-16 bg-sky-500 rounded-full -left-1 blur-3xl transition-all;
}
</style>

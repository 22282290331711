<script setup>
import PaginationContainer from "@/partials/navigation/paginator/PaginationContainer.vue";
import {nextTick, onBeforeUnmount, onMounted, ref, watch, inject} from "vue";
import IndexedDBStore from "@/storage/IndexedDB";
import FancyLoader from "@/components/misc/FancyLoader.vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import {useRoute, useRouter} from "vue-router";
import {
	fetchGuildAppeal,
	fetchGuildAppeals, fetchPersonalAppeal,
	fetchPersonalAppeals,
} from "@/services/CaseService";
import {GUILD_OPS, hook, unhook, USER_OPS} from "@/ws/utils";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import AppealCardListItem from "@/partials/inspect/AppealCardListItem.vue";
import {UI} from "@/storage/UICache";

const props = defineProps({
	"currentAppeal": {
		type: [Object,null],
		required: true,
		default: () => null,
	},
});
const route = useRoute();
const router = useRouter();
const ui = UI();
const idbStore = IndexedDBStore();
const appeals = CaseAppealBrowser();
const isPersonal = inject("isPersonal");
const initialized = ref(false);
const pageLimit = 25;
const isLoading = ref(false);
const items = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const newUnloadedAppeals = ref([]);

const handlers = {
	/**
	 * Attempts to scroll the active item into view
	 */
	scrollActiveIntoView: () => {
		const el = document.querySelector(`.table-card.active`);
		if (el) el.scrollIntoView({behavior: "smooth", block: "center"});
	},
	/**
	 * Fetches a specific appeal by ID
	 * @param {string} id
	 * @returns {Promise<Object|null>}
	 */
	fetchItem: async (id) => {
		if (isPersonal) {
			const result = await fetchPersonalAppeal(id);
			if (!result.appeal) return null;
			await Promise.all([
				idbStore.setGuilds([result.guild]),
				idbStore.setUsers(result.users)
			]);
			return result.appeal
		}

		const result = await fetchGuildAppeal(
			route.params.guildId,
			id,
		);
		if (!result.appeal) return null;
		await idbStore.setUsers(result.users);
		return result?.appeal;
	},
	/**
	 * Fetches appeals by page
	 * @param {number} page
	 * @param {number} limit
	 * @returns {Promise<Object[]>} Appeals found
	 */
	fetchItems: async ({page, limit}) => {
		if (isPersonal) {
			const result = await fetchPersonalAppeals(page, limit);
			if (!result.appeals?.length) return [];

			await Promise.all([
				idbStore.setGuilds(result.guilds),
				idbStore.setUsers(result.users)
			]);
			totalItems.value = result.pages.totalItems;
			totalPages.value = result.pages.totalPages;

			return result.appeals;
		}

		const result = await fetchGuildAppeals(
			route.params.guildId,
			page,
			limit,
		);
		if (!result.appeals?.length) return [];

		for (const lastMessages of result.lastMessagesTime) {
			if (!lastMessages.lastMessageId) continue;
			ui.markUpdated("appealChat", lastMessages.appealId, new Date(lastMessages.lastMessageDate).getTime());
		}

		await idbStore.setUsers(result.users);
		totalItems.value = result.pages.totalItems;
		totalPages.value = result.pages.totalPages;

		return result.appeals;
	},
	/**
	 * Updates an item in the list
	 * @param {Object} newAppeal
	 */
	updateItemInList: newAppeal => {
		const index = items.value.findIndex(c => c._id === newAppeal._id);
		if (index === -1) return;

		items.value[index] = newAppeal;
	},
	/**
	 * Handles navigating to a page number
	 * @param {number} page
	 * @returns {Promise<void>}
	 */
	goto: async page => {
		isLoading.value = true;
		items.value = await handlers.fetchItems({
			page: page,
			limit: pageLimit,
		});

		if (route.query.page !== page.toString()) {
			await router.push({
				...route,
				query: {
					...route.query,
					page: page,
				}
			});
		}

		currentPage.value = page;
		isLoading.value = false;
	},
	/**
	 * Handles an appeal being updated
	 * @param {{_id: string}} event
	 * @returns {Promise<*>}
	 */
	handleAppealUpdated: async ({_id}) => {
		// Ignore if SID is not inside the list of current cases
		if (!items.value.find(c => c._id === _id)) return;

		// Update case
		const theAppeal = await handlers.fetchItem(_id);
		if (appeals.currentId !== _id) return;
		return handlers.updateItemInList(theAppeal);
	},
	/**
	 * Handles an being permanently deleted for guilds.
	 * Handles an being permanently deleted, archived, or hidden for users.
	 * @param {{_id?: string}} event
	 */
	handleAppealDeleted: (event) => {
		// Appeals cannot be deleted, I think? At least not by the guild
		return console.log("handleAppealDeleted", event);
	},
	/**
	 * Handles an appeal being created.
	 * @param {{_id: string}} event The ID of the appeal
	 */
	handleAppealCreated: (event) => {
		// Ignore if we're not on the first page
		if (currentPage.value !== 1) return;

		if (newUnloadedAppeals.value.includes(event._id)) return;
		totalItems.value++;
		// We increment the indicator that there are new cases
		newUnloadedAppeals.value.push(event._id);
	},
	/**
	 * Handles a new incoming message for the given appeal.
	 * Meant for having a "New Message" indicator in the appeal list.
	 * @param {NewAppealChatMessage} message
	 */
	newMessage: (message) => {
		ui.markUpdated("appealChat", message.appealId);
	}
};

watch(() => props.currentCase, (newValue) => {
	if (!newValue) return;
	nextTick(handlers.scrollActiveIntoView);
});
watch(() => route.query.page, async (newValue) => {
	const page = parseInt(newValue) || 1;
	if (Number.isNaN(page)) return;
	if (page === currentPage.value) return;

	isLoading.value = true;
	items.value = await handlers.fetchItems({
		page: page,
		limit: pageLimit,
	});
	currentPage.value = page;
	isLoading.value = false;
});
onMounted(async ()=>{
	isLoading.value = true;

	let startPage = parseInt(route.query.page) || 1;
	if (Number.isNaN(startPage)) startPage = 1;
	currentPage.value = startPage;

	items.value = await handlers.fetchItems({
		page: startPage,
		limit: pageLimit,
	});
	isLoading.value = false;

	hook([
		isPersonal
			? USER_OPS.APPEAL_UPDATE
			: GUILD_OPS.APPEAL_UPDATE,
	], handlers.handleAppealUpdated);

	// Is this one necessary?
	hook([
		isPersonal
			? USER_OPS.APPEALS
			: GUILD_OPS.APPEALS,
	], handlers.handleAppealDeleted);

	hook([
		isPersonal
			? USER_OPS.APPEAL_CREATED
			: GUILD_OPS.APPEAL_CREATED,
	], handlers.handleAppealCreated);

	hook([
		isPersonal
			? USER_OPS.NEW_APPEAL_CHAT_MESSAGE
			: GUILD_OPS.NEW_APPEAL_CHAT_MESSAGE,
	], handlers.newMessage);

	initialized.value = true;
});
onBeforeUnmount(() => {
	appeals.$reset();

	unhook([
		isPersonal
			? USER_OPS.APPEAL_UPDATE
			: GUILD_OPS.APPEAL_UPDATE,
	], handlers.handleAppealUpdated);

	// Is this one necessary?
	unhook([
		isPersonal
			? USER_OPS.APPEALS
			: GUILD_OPS.APPEALS,
	], handlers.handleAppealDeleted);

	unhook([
		isPersonal
			? USER_OPS.APPEAL_CREATED
			: GUILD_OPS.APPEAL_CREATED,
	], handlers.handleAppealCreated);

	unhook([
		isPersonal
			? USER_OPS.NEW_APPEAL_CHAT_MESSAGE
			: GUILD_OPS.NEW_APPEAL_CHAT_MESSAGE,
	], handlers.newMessage);
});
</script>

<template>
	<PaginationContainer
		:id-key="isPersonal ? '_id' : 'sid'"
		:is-loading="isLoading"
		:total-items="totalItems"
		:total-pages="totalPages"
		:page-limit="pageLimit"
		:current-items="items"
		:current-page="currentPage"
		class="h-full"
		:initialized="initialized"
		@goto="handlers.goto"
	>
		<template #top>
			<FancyLoader
				v-if="!initialized"
				label="Loading case list&hellip;"
				class="mx-auto pt-6"
			/>
			<div v-if="newUnloadedAppeals.length >= 1" class="mx-2 p-2 rounded bg-neutral-900 border-details my-2">
				<p class="text-sm text-neutral-500">
					<strong>{{ newUnloadedAppeals.length }} new appeal{{ newUnloadedAppeals.length === 1 ? "" : "s" }}</strong> has been created since you last loaded this page.
				</p>
			</div>
		</template>
		<template #item="{ item: appeal }">
			<ErrorCallout
				v-if="appeal.deleted"
				:key="appeal._id"
				class="mx-2"
				:label="`#${ appeal.sid } &bull; Deleted`"
				:texts="[ 'This case has been permanently deleted and is no longer available.' ]"
			/>
			<div v-else class="py-2 px-2">
				<RouterLink
					:key="appeal._id"
						:to="{
						name: isPersonal ? 'my-appeals' : 'guild-appeals',
						params: { appealId: appeal._id }
					}"
				>
					<AppealCardListItem
						:appeal="appeal"
						:is-personal="isPersonal"
					/>
				</RouterLink>
			</div>
		</template>
	</PaginationContainer>
</template>

<style scoped>
.router-link-active .table-card {
	@apply outline outline-sky-600;
}
</style>
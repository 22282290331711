<template>
	<g>
		<circle
			cx="12"
			cy="10.5"
			r="2.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20 12.5209C20 16.2956 15.3552 19.0997 13.1401 20.2255C12.4254 20.5909 11.579 20.5916 10.8636 20.2274C8.65012 19.1027 4 16.2918 4 12.5209V7.06937C4.00735 6.55852 4.39423 6.13323 4.9021 6.07769C7.02402 5.91645 9.05835 5.16411 10.7746 3.90593C11.5019 3.36469 12.4981 3.36469 13.2254 3.90593C14.9416 5.16411 16.9759 5.91645 19.0979 6.07769C19.6057 6.13321 19.9926 6.55851 20 7.06937V12.5209Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.9007 17.8015L16.4409 16.882C16.0175 16.035 15.1518 15.5 14.2049 15.5H9.79502C8.84808 15.5 7.98241 16.035 7.55894 16.882L7.10156 17.7968"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconShieldUser"
};
</script>
<template>
	<g>
		<path
			d="M11 4H20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.1504 9H20.0004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.25 9V4L4 5.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11 14H20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.1504 19H20.0004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4 15C4 14.4477 4.44772 14 5 14H5.61448C6.1433 14 6.61278 14.3384 6.78 14.8401V14.8401C6.91647 15.2495 6.82812 15.7005 6.54727 16.0282L4 19H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4 9H6.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconOrderedList"
};
</script>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.734 9.764L12.002 3L20.267 9.764C20.731 10.143 21 10.712 21 11.311V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V11.312C3 10.712 3.269 10.143 3.734 9.764V9.764Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconHome"
};
</script>
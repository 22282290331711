<template>
	<g>
		<path
			d="M11.9972 17.502C11.7221 17.502 11.497 17.727 11.5 18.0022C11.5 18.2773 11.7251 18.5024 12.0002 18.5024C12.2754 18.5024 12.5004 18.2773 12.5004 18.0022C12.5004 17.727 12.2754 17.502 11.9972 17.502"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.9972 11.498C11.7221 11.498 11.497 11.7231 11.5 11.9983C11.5 12.2734 11.7251 12.4985 12.0002 12.4985C12.2754 12.4985 12.5004 12.2734 12.5004 11.9983C12.5004 11.7231 12.2754 11.498 11.9972 11.498"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.9972 5.49609C11.7221 5.49609 11.497 5.72119 11.5 5.9963C11.5 6.27142 11.7251 6.49651 12.0002 6.49651C12.2754 6.49651 12.5004 6.27142 12.5004 5.9963C12.5004 5.72119 12.2754 5.49609 11.9972 5.49609"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconDotsVertical"
};
</script>
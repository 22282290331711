<script setup>
import {inject, ref} from "vue";
import PermsCond from "@/components/PermsCond.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {clearTimeout, setTimeout} from "timers-browserify";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const props = defineProps({
	"id": {
		type: String,
		required: true,
	},
	"close": {
		type: Function,
		default: () => () => {
		},
	},
	"pills": {
		type: Object,
		default: () => ({})
	},
	"handleArchiveFile": {
		type: Function,
		default: () => () => {
		},
	},
	"handleDeleteFile": {
		type: Function,
		default: () => () => {
		},
	},
	"handleRestoreFile": {
		type: Function,
		default: () => () => {
		},
	},
	"archiveFilePermission": {
		type: String,
		default: () => null,
	},
	"restoreFilePermission": {
		type: String,
		default: () => null,
	},
	"deleteFilePermission": {
		type: String,
		default: () => null,
	},
});

const cases= CaseAppealBrowser();
const personal = inject("isPersonal");
const confirm = ref({
	type: null,
	timer: null,
});
const confirmAction = type => {
	if (confirm.value.type !== type) {
		confirm.value.timer = setTimeout(() => {
			confirm.value.type = null;
			confirm.value.timer = null;
		}, 3500);
		return confirm.value.type = type;
	}

	clearTimeout(confirm.value.timer);
	switch (type) {
		case "archive":
			return props.handleArchiveFile({
				id: props.id,
				reason: "Unknown reason",
			});
		case "restore":
			return props.handleRestoreFile({
				id: props.id,
				reason: "Unknown reason",
			});
		case "delete":
			return props.handleDeleteFile({
				id: props.id,
				reason: "Unknown reason",
			});
	}
};
</script>

<template>
	<div class="flex gap-2 w-full justify-end">
		<BasicButton
			label="Close"
			type="link"
			@click="close"
		/>
		<template v-if="!personal">
			<PermsCond v-if="!pills.archived && archiveFilePermission" :needs="archiveFilePermission">
				<BasicButton
					:label="confirm.type!=='archive' ? 'Archive' : 'Are you sure?'"
					type="link"
					:disabled="cases.isLoading('fileArchive', id)"
					@click="() => confirmAction('archive')"
				/>
			</PermsCond>
			<PermsCond v-else-if="restoreFilePermission" :needs="restoreFilePermission">
				<BasicButton
					:label="confirm.type!=='restore' ? 'Restore' : 'Are you sure?'"
					type="link"
					:disabled="cases.isLoading('fileRestore', id)"
					@click="() => confirmAction('restore')"
				/>
			</PermsCond>
			<PermsCond v-if="deleteFilePermission" :needs="deleteFilePermission">
				<BasicButton
					:label="confirm.type!=='delete' ? 'Delete File' : 'Are you sure?'"
					type="danger"
					:disabled="cases.isLoading('fileDelete', id)"
					@click="() => confirmAction('delete')"
				/>
			</PermsCond>
		</template>
	</div>
</template>

// Flags for case entries
export const CaseEntryFlagMask = {
    // Used to be `system`, now unused
    UNUSED: 1 << 0,
    // This entry was created as part of an expiry. E.g., manually unban, automatically unmute, etc.
    expireEntry: 1 << 1,
    // This entry was created as part of a case archivation
    archiveEntry: 1 << 2,
    // This entry is visible to the case target
    visibleToTarget: 1 << 3,
    // This entry is the one created with undoing
    undoEntry: 1 << 4,
};

<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.0004 5C9.40741 7.768 9.40741 12.232 12.0004 15C14.5934 12.232 14.5934 7.768 12.0004 5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 5C14.768 5 17 7.232 17 10C17 12.768 14.768 15 12 15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 15C9.232 15 7 12.768 7 10C7 7.232 9.232 5 12 5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 10H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.778 22H6.222C4.995 22 4 21.005 4 19.778V4.222C4 2.995 4.995 2 6.222 2H17.778C19.005 2 20 2.995 20 4.222V19.778C20 21.005 19.005 22 17.778 22Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15 18.5H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconPassport"
};
</script>
export var SysConfigKeys;
(function (SysConfigKeys) {
    SysConfigKeys["Staff"] = "Staff";
    SysConfigKeys["Titles"] = "Titles";
    SysConfigKeys["FeatureSignup"] = "FeatureSignup";
})(SysConfigKeys || (SysConfigKeys = {}));
export var SYSFeatureSignupEmailTemplate;
(function (SYSFeatureSignupEmailTemplate) {
    /**
     * Get an overview of what feature notifications you're signed up for.
     * Value is the template ID in the email service
     * TODO Modify value when we have Postmark templates for this.
     */
    SYSFeatureSignupEmailTemplate["feature.signup.overview"] = "feature.signup.overview";
})(SYSFeatureSignupEmailTemplate || (SYSFeatureSignupEmailTemplate = {}));
/**
 * Identifiers possible for cooldowns
 */
export var SYSFeatureSignupCooldownType;
(function (SYSFeatureSignupCooldownType) {
    /**
     * Cooldown for modifying feature signup properties
     */
    SYSFeatureSignupCooldownType["feature.signup.modify"] = "feature.signup.modify";
    /**
     * Cooldown for fetching the list of feature signups
     */
    SYSFeatureSignupCooldownType["feature.signup.subscriptions.cooldown"] = "feature.signup.subscriptions.cooldown";
})(SYSFeatureSignupCooldownType || (SYSFeatureSignupCooldownType = {}));
const x = () => {
    return {
        _id: SysConfigKeys.Staff,
        _inUse: true,
        _isEnabled: true,
        maxStaffCount: [1, 2, 3],
    };
};

<template>
	<g>
		<path
			d="M5 12H19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10 7L5 12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10 17L5 12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconArrowLeft"
};
</script>
<template>
	<div class="select-none relative">
		<!-- Or ish h-32 -->
		<div
			class="w-full h-full rounded-md bg-cover bg-center z-0"
			:style="`background-image: url('${backgroundImage}')`"
		>
			<div
				class="w-full h-full flex flex-col justify-between rounded border border-neutral-700 shadow"
				:class="{
					'bg-gradient-to-t from-black/90 to-black/10': backgroundImage,
					'bg-black/10': !backgroundImage
				}"
			>
				<!-- Context menu -->
				<div class="relative flex w-full items-center justify-end shrink-0">
					<!-- Menu button -->
					<button
						class="bg-neutral-200 flex items-center justify-center w-6 h-6 m-3 rounded-full cursor-pointer"
						@click="toggleMenu"
					>
						<DynamicIcon
							icon="DotsHorizontal"
							size="20"
							stroke="3"
							class="text-slate-800"
						/>
					</button>

					<!-- Menu content -->
					<dialog
						v-if="states.visible"
						v-click-away="toggleMenu"
						:open="states.visible"
						class="top-10 border-details mr-3 select-none cursor-pointer py-1 px-0 rounded-md bg-neutral-800/40 backdrop-blur-2xl shadow whitespace-nowrap"
					>
						<div class="flex flex-col">
							<RouterLink
								:to="createInspectFileLink(id)"
								class="px-4 py-2 hover:bg-neutral-700/50 w-full"
								@click="toggleMenu"
							>
								Inspect file
							</RouterLink>
							<hr class="mb-1 border-neutral-700 w-full">
							<button
								v-if="isArchived"
								:class="{'hover:bg-sky-800/40 hover:text-sky-200':!states.archiveConfirm,
								'bg-sky-800/40 text-sky-200':states.archiveConfirm}"
								class="px-4 py-2 w-full"
								@click="action('restore')"
							>
								{{ !states.restoreConfirm ? "Restore" : "Are you sure?" }}
							</button>
							<button
								v-else
								:class="{'hover:bg-sky-800/40 hover:text-sky-200':!states.archiveConfirm,
								'bg-sky-800/40 text-sky-200':states.archiveConfirm}"
								class="px-4 py-2 w-full"
								@click="action('archive')"
							>
								{{ !states.archiveConfirm ? "Archive" : "Are you sure?" }}
							</button>
							<button
								:class="{'hover:bg-red-800/40 hover:text-red-200':!states.deleteConfirm,
								'bg-red-800/40 text-red-200':states.deleteConfirm}"
								class="px-4 py-2 w-full"
								@click="action('delete')"
							>
								{{ !states.deleteConfirm ? "Delete" : "Are you sure?" }}
							</button>
						</div>
					</dialog>
				</div>

				<!-- Text + optional:icon -->
				<RouterLink :to="createInspectFileLink(id)">
					<div
						class="mx-3 mb-3 text-xs self-stretch justify-self-stretch"
						:class="{'h-full text-center': !backgroundImage}"
					>
						<DynamicIcon
							v-if="!backgroundImage"
							:icon="icon"
							class="mb-1"
						/>
						<p class="font-medium text-base truncate" :class="{'text-white': backgroundImage}">
							{{ fileName }}
							<DynamicIcon
								v-if="isArchived"
								size="18"
								stroke="2"
								icon="Archived"
								title="This file is archived."
							/>
						</p>
						<p v-if="fileSize!==null" :class="{'text-white': backgroundImage}">
							({{ fs(fileSize) }})
						</p>
					</div>
				</RouterLink>
			</div>
		</div>
	</div>
</template>

<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {fileSize as fs} from "@/script/convert.js";
import {ref} from "vue";

const props = defineProps({
	// ID for uniquely identifying this file
	"id": {
		type: String,
		required: true,
	},
	// Full mime-type of the file
	"mime": {
		type: String,
		required: true,
	},
	// Full name, including any extension
	"fileName": {
		type: String,
		required: true,
	},
	// Size in bytes, we cast to unit automatically
	"fileSize": {
		type: Number,
		required: true,
	},
	// File is visible to target or not
	"visible": {
		type: Boolean,
		default: () => false,
	},
	// Base64 of the image, if image was selected
	"backgroundImage": {
		type: String,
		default: () => null,
	},
	// Overwrite the icon to use. Is not used if background image is set
	"icon": {
		type: String,
		default: () => "Document"
	},
	"isArchived": {
		type: Boolean,
		required: false,
	},
	"createInspectFileLink": {
		// (fileId, ext) => string
		type: Function,
		default: () => () => "",
	},
	"createDirectFileLink": {
		// (fileId, ext) => string
		type: Function,
		default: () => () => "",
	},
});

const emit = defineEmits(["archive", "delete", "restore"]);
const states = ref({
	visible: false,
	deleteConfirm: false,
	archiveConfirm: false,
	restoreConfirm: false,
	textCopied: false,
});

function toggleMenu() {
	states.value.deleteConfirm = false;
	states.value.archiveConfirm = false;
	states.value.restoreConfirm = false;
	states.value.visible = !states.value.visible;
}

function action(type) {
	if (type === "archive") {
		if (!states.value.archiveConfirm) {
			states.value.deleteConfirm = false;
			states.value.restoreConfirm = false;
			return states.value.archiveConfirm = true;
		}

		return emit("archive", {id: props.id});
	}

	if (type === "restore") {
		if (!states.value.restoreConfirm) {
			states.value.deleteConfirm = false;
			states.value.archiveConfirm = false;
			return states.value.restoreConfirm = true;
		}

		return emit("restore", {id: props.id});
	}

	if (!states.value.deleteConfirm) {
		states.value.archiveConfirm = false;
		states.value.restoreConfirm = false;
		return states.value.deleteConfirm = true;
	}

	return emit("delete", {id: props.id});
}

// function emit(target, value) {
// 	states.value.visible = false;
//
// 	// Override for copying link
// 	if (target === "public" && props.publicLink) {
// 		if (states.value.textCopied) return;
//
// 		states.value.textCopied = true;
// 		setTimeout(() => {
// 			states.value.textCopied = false;
// 		}, 1500);
//
// 		return navigator.clipboard.writeText(props.publicLink);
// 	}
//
// 	return emitter("action", {id: props.id, target, value});
// }
</script>

<style scoped>
.link-copied {
	animation: FadeOutGreen 1.5s ease-out;
}

@keyframes FadeOutGreen {
	from {
		color: #065f46;
		background-color: #d1fae5;
	}
	to {
		color: inherit;
		background-color: transparent;
	}
}
</style>
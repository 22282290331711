<script setup>
import FancyLoader from "@/components/misc/FancyLoader.vue";
import CaseService from "@/services/CaseService";
import {computed, onMounted, ref} from "vue";
import {handleErr} from "@/script/convert";
import {useRoute} from "vue-router";
import CheckBannedView from "@/views/account/guilds/check-banned/CheckBannedView.vue";
import BanDiscoveryDemoControls from "@/views/account/guilds/check-banned/components/BanDiscoveryDemoControls.vue";
import {Account} from "@/storage/AccountCache";

const account = Account();
const route = useRoute();
const theCase = ref(null);
const appeal = ref(null);
const error = ref({
	label: "",
	texts: [],
});
const isLoading = ref(true);

const demo = ref({
	enabled: true,
	banned: true,
	appeal: false,
});

const demoAppeal = computed(()=>{
	if (route.params.guildId !== '11111111111111111111') return null;
	if (!demo.value.enabled) return null;
	if (!demo.value.banned) return null;
	if (!demo.value.appeal) return null;
	return {
		"_id": "abcdef0123456789abcd",
		"state": "open",
		"guildId": "11111111111111111111",
		"userId": account.userId,
		"appealContent": { "content": "................................" },
		"meta": {},
		"case": {
			"_id": "abcdef0123456789abcd",
			"sid": 123,
			"caseType": "ban"
		},
		"flags": 0,
		"verdict": { "date": null },
		"dates": {
			"createdAt": "2024-06-06T21:42:37.187Z",
			"updatedAt": "2024-06-06T21:42:37.187Z"
		}
	};
});
const demoCase = computed(()=> {
	if (route.params.guildId !== '11111111111111111111') return null;
	if (!demo.value.enabled) return null;
	if (!demo.value.banned) return null;
	return {
		"_id": "abcdef0123456789abcd",
		"sid": 123,
		"targetId": account.userId,
		"creatorId": null,
		"guildId": "11111111111111111111",
		"system": true,
		"flags": 1,
		"caseType": "ban",
		"undone": { "at": null },
		"archived": { "at": null },
		"createdAt": "2024-06-06T21:42:27.317Z",
		"updatedAt": "2024-06-06T21:42:27.317Z",
		"expireAt": null
	}
});

onMounted(async () => {
	if (route.params.guildId === "11111111111111111111") {
		return setTimeout(()=>{
			isLoading.value = false;
		}, 1500);
	}

	try {
		// Null means: not banned, feature is disabled, or an Archivian case exists and user cannot see it
		const r = await CaseService.checkIfImBannedIn(route.params.guildId);
		theCase.value = r.case;
		appeal.value = r.appeal;
	} catch(e) {
		error.value = handleErr(e, "Failed to check ban status");
		return;
	}

	isLoading.value = false;
});
</script>

<template>
	<div v-if="isLoading" class="flex items-center justify-center h-full">
		<FancyLoader label="Checking ban..." />
	</div>
	<div v-else class="flex flex-col h-full">
		<BanDiscoveryDemoControls
			v-if="route.params.guildId==='11111111111111111111'"
			v-model:enabled="demo.enabled"
			v-model:banned="demo.banned"
			v-model:appeal="demo.appeal"
		/>
		<CheckBannedView
			class="items-center justify-center"
			:demo-mode="route.params.guildId==='11111111111111111111'"
			:can-appeal="!!theCase || demo.banned"
			:the-case="theCase || demoCase"
			:open-appeal="appeal || demoAppeal"
		/>
	</div>
</template>

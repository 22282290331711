<script>
export default {
	name: "IconVideo"
}
</script>

<template>
	<g>
		<path
			d="M7 3V21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17 21V3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 7H3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 17H3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17 17H21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17 7H21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 12H3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script setup>
import DataTable from "@/components/lists/DataTable.vue";
import {computed, ref} from "vue";
import LocalDate from "@/components/info/parts/LocalDate.vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import IdBadge from "@/components/badges/IdBadge.vue";
import UserCache from "@/storage/UserCache";
import BasicButton from "@/components/buttons/BasicButton.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import PaginationNav from "@/partials/navigation/paginator/PaginationNav.vue";
import FancyLoader from "@/components/misc/FancyLoader.vue";
import {UI} from "@/storage/UICache";
import {timeLeftUntil} from "archivian-utils";
import MentionChannel from "@/components/misc/MentionChannel.vue";
import HorizontalDivider from "@/components/misc/HorizontalDivider.vue";

defineEmits(["goto"]);
const props = defineProps({
	"violations": {
		type: Array,
		required: true,
	},
	"loading": {
		type: Boolean,
		default: () => false,
	},
	"pages": {
		type: Object,
		default: () => ({
			previousPage: 0,
			nextPage: 0,
			currentPage: 0,
			totalPages: 0,
			totalItems: 0,
		}),
	},
});

const modal = ref(null);
const inspected = ref(null);
const ui = UI();
const users = UserCache();
const filteredViolations = computed(()=>{
	return props.violations;
});
const headers = [
	{label: "Violation ID", key: "sid", width: "10rem", class: 'pl-4', colClass: 'hidden sm:table-cell'},
	{label: "User", key: "userId"},
	{label: "Trigger", key: "type", colClass: 'hidden md:table-cell'},
	{label: "Action(s) taken", key: "actions", colClass: 'hidden lg:table-cell'},
	{label: "When", key: "createdAt", colClass: 'hidden xl:table-cell'},
	{label: "", key: "buttons", colClass: 'hidden sm:table-cell', width: "8rem"},
];
const inspect = (item) => {
	inspected.value = item;
	if (item && modal.value) modal.value.showModal();
	else if (modal.value) modal.value.close();
}
/**
 * Convert a trigger type to a human readable string.
 * @param {import('discord-api-types/v10').AutoModerationRuleTriggerType} type
 */
const triggerTypeToDisplay = (type) => {
	if (type === 1) return "Custom keywords";
	// type 2 was killed off?
	if (type === 3) return "Suspected Spam";
	if (type === 4) return "Commonly Flagged Words";
	if (type === 5) return "Mention Spam";
	if (type === 6) return "Member Profile";
	return "Unknown Trigger";
}
/**
 * Convert an action type to a human readable string.
 * @param {import('discord-api-types/v10').AutoModerationActionType} type
 * @returns {{label: string}}
 */
const actionTypeToDisplay = (type) => {
	if (type === 1) return {
		label: "Message Blocked",
		color: "red",
		title: "The member's message was blocked before it could be sent in the server",
	};
	if (type === 2) return {
		label: "System Alert",
		color: "yellow",
		title: "An alert by Discord's system was sent to a channel",
	};
	if (type === 3) return {
		label: "Timeout",
		color: "blue",
		title: "The member received a timeout because of this",
	};
	if (type === 4) return {
		label: "Block Interactions",
		color: "orange",
		title: "The member was prevented from saving changes to profile. If the member has content that violates this rule, they will not be able to interact (messages, voice channels, etc.) in the server until it is removed.",
	};
	return {
		label: "Unknown",
		color: "gray",
		title: "This action is still unknown to us. Please contact support if you ever get this one.",
	};
}
</script>

<template>
	<div class="p-4 h-full min-h-0">
		<div class="flex flex-col overflow-y-auto h-full">
			<h2>Auto Mod Logs</h2>
			<p class="pb-4">
				A collection of Auto Moderation violations performed by users.
			</p>

			<DataTable
				:id="ui.setAnchor('main')"
				:headers="headers"
				:items="filteredViolations"
				separator
				sticky-header
				:row-classes="['even:bg-neutral-800/50']"
			>
				<template #[`item.sid`]="{ item }">
					<span class="font-mono font-bold pl-4">#{{ item.sid }}</span>
				</template>

				<template #[`item.userId`]="{ item }">
					<div class="flex gap-2 items-center py-2">
						<UserAvatar
							:user-id="item.userId"
							:aria-label="users.username(item.userId, true)"
							:avatar="users.avatarHashOf(item.userId)"
							size="48"
						/>
						<div>
							<p>{{ users.username(item.userId, true) }}</p>
							<IdBadge :label="item.userId" class="text-sm" />
						</div>
					</div>
				</template>

				<template #[`item.createdAt`]="{ item }">
					<LocalDate :date="item.createdAt" time />
				</template>

				<template #[`item.type`]="{ item }">
					{{ triggerTypeToDisplay(item.autoModActions[0].rule_trigger_type) }}
				</template>

				<template #[`item.actions`]="{ item }">
					<div class="flex gap-2 flex-wrap">
						<span
							v-for="(action,i) in item.autoModActions"
							:key="i"
							:title="actionTypeToDisplay(action.action.type).title"
							class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-neutral-800 bg-neutral-900"
						>
							<svg
								class="h-1.5 w-1.5"
								:class="{ [`fill-${actionTypeToDisplay(action.action.type).color}`]: true }"
								viewBox="0 0 6 6"
								aria-hidden="true"
							>
								<circle
									cx="3"
									cy="3"
									r="3"
								/>
							</svg>
							{{ actionTypeToDisplay(action.action.type).label }}
						</span>
					</div>
				</template>

				<template #[`item.buttons`]="{ item }">
					<BasicButton
						label="Inspect"
						type="link"
						@click="inspect(item)"
					/>
				</template>
			</DataTable>

			<div v-if="loading" class="p-4 flex justify-center">
				<FancyLoader label="Fetching Auto Mod violations&hellip;" />
			</div>

			<p v-else-if="!violations.length" class="p-4 flex justify-center italic text-neutral-500">
				No records here yet.
			</p>

			<PaginationNav
				v-if="violations.length > 1 && pages.totalPages > 1"
				:current-items="violations.length"
				:limit="25"
				:total-pages="pages.totalPages"
				:total-items="pages.totalItems"
				:page="pages.currentPage"
				:disabled="false"
				class="py-8"
				@goto="$emit('goto', $event)"
			/>

			<dialog ref="modal" class="modal">
				<div class="flex items-center justify-between p-4">
					<h2>Violation #{{ inspected?.sid }} Details</h2>
					<CloseButton
						label="Close dialog"
						@click="inspect(null)"
					/>
				</div>
				<HorizontalDivider />
				<div v-if="inspected" class="flex flex-col gap-2 p-4">
					<h3 class="pb-1 pt-4">
						Trigger Information
					</h3>
					<!-- Trigger info -->
					<div v-if="inspected.autoModActions[0].matched_keyword" class="p-4 rounded-lg border-details bg-neutral-800/50">
						<h4 class="inline-flex items-center gap-2">
							<svg
								class="h-3 w-3 fill-gray"
								viewBox="0 0 6 6"
								aria-hidden="true"
							>
								<circle
									cx="3"
									cy="3"
									r="3"
								/>
							</svg>
							Trigger Keyword
						</h4>
						<p class="max-w-xl text-small text-neutral-500">
							The keyword rule that was matched, causing the rule to be triggered
						</p>
						<span class="font-mono p-1 rounded border-details text-sky-400 bg-neutral-800">
							{{ inspected.autoModActions[0].matched_keyword }}
						</span>

						<template v-if="inspected.autoModActions[0].matched_content">
							found in
							<span class="font-mono p-1 rounded border-details text-sky-400 bg-neutral-800">
							{{ inspected.autoModActions[0].matched_content }}
						</span>
						</template>
					</div>
					<div v-if="inspected.autoModActions[0]?.content" class="p-4 rounded-lg border-details bg-neutral-800/50">
						<h4 class="inline-flex items-center gap-2">
							<svg
								class="h-3 w-3 fill-gray"
								viewBox="0 0 6 6"
								aria-hidden="true"
							>
								<circle
									cx="3"
									cy="3"
									r="3"
								/>
							</svg>
							Full Content
						</h4>
						<p class="max-w-xl text-small text-neutral-500">
							The full content, in which the keyword was found
						</p>
						<pre class="max-w-xl text-wrap">{{ inspected.autoModActions[0].content }}</pre>
					</div>

					<h3 class="pb-1 pt-4">
						Actions taken
					</h3>
					<!-- Action Info -->
					<div
						v-for="(action,i) in inspected.autoModActions"
						:key="i"
						class="p-4 rounded-lg border-details bg-neutral-800/50"
					>
						<h4 class="inline-flex items-center gap-2">
							<svg
								class="h-3 w-3"
								:class="{ [`fill-${actionTypeToDisplay(action.action.type).color}`]: true }"
								viewBox="0 0 6 6"
								aria-hidden="true"
							>
								<circle
									cx="3"
									cy="3"
									r="3"
								/>
							</svg>
							{{ actionTypeToDisplay(action.action.type).label }}
						</h4>
						<p class="max-w-xl text-small text-neutral-500">
							{{ actionTypeToDisplay(action.action.type).title }}
						</p>
						<p v-if="action.action.type === 2">
							Send alert to
							<MentionChannel
								:guild-id="inspected.guildId"
								:channel-id="action.action.metadata.channel_id"
							/>.
							<br>
							<span v-if="action.alert_system_message_id" class="text-xs italic text-neutral-500">
								Alert message ID {{ action.alert_system_message_id }}
							</span>
						</p>
						<p v-if="action.action.type === 3">
							Timeout for {{ timeLeftUntil(new Date(Date.now()-200 + (action.action.metadata.duration_seconds * 1000))).string }}
						</p>
					</div>
				</div>
			</dialog>
		</div>
	</div>
</template>
<style scoped>
/* Defined so the variables are detected */
.fill-red {
	@apply fill-red-400;
}
.fill-yellow {
	@apply fill-yellow-400;
}
.fill-blue {
	@apply fill-blue-400;
}
.fill-orange {
	@apply fill-orange-400;
}
.fill-gray {
	@apply fill-gray-400;
}
</style>
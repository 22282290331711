<script>
export default {
	name: "IconLightBulb",
};
</script>

<template>
	<g>
		<path
			d="M9 21H15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.9989 17.1588V18.2248H8.99893V17.1588C8.99893 16.1018 8.50893 15.1148 7.69393 14.4408C6.28293 13.2718 5.35493 11.5418 5.25793 9.5898C5.07293 5.8488 8.14793 2.5598 11.8929 2.5028C15.6699 2.4438 18.7499 5.4868 18.7499 9.2498C18.7499 11.3518 17.7869 13.2258 16.2789 14.4618C15.4699 15.1248 14.9989 16.1128 14.9989 17.1588Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.54004 15.4492H15.45"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>
<script>
export default {
	name: "IconRevert"
};
</script>

<template>
	<g>
		<path
			d="M3.51953 8.99808C4.85962 5.22323 8.53418 2.78923 12.5327 3.02781C16.5313 3.26639 19.8903 6.12006 20.772 10.0275C21.6538 13.9349 19.8457 17.9545 16.337 19.8871C12.8284 21.8197 8.46469 21.1995 5.63341 18.366"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.99902 14.9994L12.0003 11.9982V6.99609"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.99825 8.99776H2.99658V4.99609"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

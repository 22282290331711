<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.2779 10.5356L14.2531 7.56032C14.2719 7.5415 14.2803 7.51466 14.2755 7.48851C14.2707 7.46236 14.2534 7.44022 14.2291 7.42927C12.4731 6.67436 10.4349 7.05757 9.07299 8.39867C7.76807 9.67426 7.34863 11.6046 8.00655 13.3067L3.54869 17.7676C2.81255 18.5046 2.81255 19.6986 3.54869 20.4357C4.28573 21.1718 5.47976 21.1718 6.2168 20.4357L10.7107 15.9418C12.4616 16.5933 14.4324 16.1342 15.7151 14.776C16.9978 13.4178 17.3436 11.424 16.5931 9.71322C16.5816 9.68987 16.5596 9.6734 16.5339 9.66886C16.5082 9.66432 16.482 9.67225 16.4631 9.69021L13.4128 12.6695L12.3464 11.6L11.2779 10.5356Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.10281 19.5492C10.9737 22.0648 16.119 21.253 19.0275 17.668C21.936 14.0829 21.6696 8.88085 18.4102 5.61162C15.1507 2.34239 9.94947 2.06043 6.35572 4.95815C2.76197 7.85587 1.93481 12.9987 4.4387 16.8771"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconWrench"
};
</script>
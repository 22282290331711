<template>
	<div
		role="alert"
		class="InfoCallout rounded-md bg-sky-800/30 border border-sky-700 border-t-sky-600 p-4 shadow-inner shadow-sky-700/30"
	>
		<div class="flex gap-4 items-center">
			<DynamicIcon
				icon="InfoFilled"
				class="shrink-0 text-sky-200"
				stroke="0"
				size="24"
			/>
			<h4 class="text-sky-200 grow leading-5 font-semibold">
				{{ label }}
			</h4>
			<CloseButton
				v-if="dismiss || modalId"
				label="Close callout"
				@click="dismissModal(modalId)"
			/>
		</div>

		<slot />

		<div
			v-if="detailsLabel && detailsTo"
			class="font-semibold flex gap-2 text-sm ml-6 mt-2 text-sky-300"
		>
			<div
				v-if="detailsLabel && detailsTo"
				class="flex gap-1 items-end hover:gap-2 transition-all cursor-pointer p-4"
			>
				<p>{{ detailsLabel }}</p>
				<DynamicIcon
					icon="ArrowLeft"
					size="20"
					class="rotate-180"
					stroke="1.5"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import DynamicIcon from "@/components/icon/DynamicIcon";
import CloseButton from "@/components/buttons/CloseButton.vue";

export default {
	name: "InfoCallout",
	components: {CloseButton, DynamicIcon},
	props: {
		"modalId": {
			type: String,
			default: () => null,
		},
		"label": {
			type: String,
			required: true,
		},
		"dismiss": {
			type: Boolean,
			default: () => null,
		},
		"detailsLabel": {
			type: String,
			default: () => null,
		},
		"detailsTo": {
			type: Object,
			default: () => null,
		}
	},
	emits: ["close"],
	methods: {
		dismissModal(modalId) {
			this.$emit("close", modalId);
		}
	}
};
</script>

<style scoped>
li {
	list-style: disc;
}
</style>
import { defineStore } from "pinia";
import backend, {APIEndpoint} from "@/api/backend";
import UserCache from "@/storage/UserCache";
import EvalPerms from "@/script/EvalPerms";
import IndexedDBStore from "@/storage/IndexedDB";

/**
 * Holds high-level information about you as an Archivian user, independent of which context (guild) you are in.
 * One exception is the permissions, which is context-dependent.
 *
 * Its purpose is to handle basic auth-level information, as well as hold information relevant to your Discord account.
 * It also handles things related to the logged-in session.
 */
export const Account = defineStore("Account", {
	state: () => {
		const data = loadAccountData();
		return {
			/**
			 * Your own user ID
			 * @type {UserID|null}
			 */
			userId: data?.userId,
			/**
			 * IDs of guilds user is staff in
			 * @type {Set<string>}
			 */
			staffedGuilds: new Set(data?.staffedGuilds ?? []),
			/**
			 * Keeps track of last account validation against backend
			 * @type {Date|null}
			 */
			sessionLastValidatedAt: null,
		};
	},
	getters: {
		permissionNodes: () => {
			return IndexedDBStore().permissionNodes;
		},
		guilds: () => {
			return IndexedDBStore().guilds;
		},
		/**
		 * Check if we managed to populate data from Session Storage already
		 * @returns {boolean}
		 */
		isPopulated: (state) => !!state.userId,
		/**
		 * Alias for `account?.userId`
		 * @returns {UserID|string | null}
		 */
		id: (state) => state?.userId ?? null,
		/**
		 * Use the current user's preferred name, with fallback to "Unknown User (you)"
		 * @returns {string|"Unknown User (you)"}
		 */
		name: (state) => {
			if (!state.userId) return "Unknown User (you)";
			const users = UserCache();
			return users.username(state.userId) || "Unknown User (you)";
		},
		/**
		 * Get your current avatar hash, or null if you don't have one / not found
		 * @return {string|null}
		 */
		avatarHash: (state) => {
			if (!state.userId) return null;
			return IndexedDBStore().getUser(state.userId)?.avatar ?? null;
		},
		/**
		 * Get the guilds you're staff of as an array of guild display records
		 * @returns {DisplayGuild[]}
		 */
		staffedGuildRecords: (state) => {
			return Array.from(state.staffedGuilds).map(id => {
				const g = IndexedDBStore().getGuild(id);
				return g || { id, name: "Unknown Server", icon: null };
			});
		},
		/**
		 * Gets the permission nodes for the current context (personal Dashboard or Guild)
		 * @returns {string[]}
		 */
		permissions: () => IndexedDBStore().permissionNodes || [],
		/**
		 * Checks if it's time to validate the session.
		 * This is usually just a time-based thing to check if the user is still logged in from time to time when they navigate.
		 * @returns {boolean}
		 */
		itsTimeToValidateSession: (state) => {
			const msSince = Date.now() - (state.sessionLastValidatedAt?.getTime() ?? 0);
			const shouldValidate = msSince > 1000 * 60 * 5; // 5 minutes
			console.debug(`[Account] Time to re-validate? (%s) - %s ms since last, which was %s`, shouldValidate, msSince, state.sessionLastValidatedAt?.getTime());
			return shouldValidate;
		},
	},
	actions: {
		/**
		 * Forcefully logs you out and purges all caches.
		 */
		async logout() {
			// Kil off the WS connection first
			const WebSocket = await import("@/ws/WebSocket");
			WebSocket.socket.close({ reconnect: false });

			/**
			 * First make the HTTP call to remove cookies. It must succeed.
			 * Usually, this endpoint will clear everything; ls, session, cookies, indexed DB, etc.
			 * It should also reload your browser
			 * @type {{status: number, body: {success: true}}}
			 */
			const result = await backend("/public/accounts/me/logout", "POST");
			if (result.status !== 200) {
				console.error("Failed to log out", result.body);
				return;
			}

			/**
			 * Only after that is done we do the rest of the processing.
			 * The HTTP header in the response should tell the browser to clear everything,
			 * but let's also do it again, but manually, just to be safe.
			 */
			const idbStore = IndexedDBStore();
			await idbStore.purgeAll();
			idbStore.wipe();
			window.sessionStorage.clear();
			window.localStorage.clear();

			// Finally, take the user to the landing page
			return window.location.href = "/";
		},
		/**
		 * Quickly check if user has permission to do something using permission evaluation
		 * @param {string} permissionNode
		 * @returns {boolean}
		 */
		can(permissionNode) {
			return EvalPerms(permissionNode, this.permissionNodes || []).access;
		},
		/**
		 * Fetches the user data from the backend
		 * @param {boolean} [isOptional=false]
		 * @return {Promise<void>}
		 */
		async fetchAccountData(isOptional = false) {
			let result = null;
			if (isOptional) {
				try {
					result = await backend("/public/accounts/me");
				} catch(_) {
					console.error("Optional account loading failed.")
				}
			} else {
				result = await backend("/public/accounts/me");
			}

			if (isOptional && result.status!==200) return;

			const idbStore = IndexedDBStore();

			if (!isOptional && result.status === 401 || result.status === 403) {
				const redirectPath = window.location.pathname === "/" ? `?redirect=${window.location.pathname}` : "";
				window.location.href = `${APIEndpoint()}/public/accounts/login${redirectPath}`;
			}

			if (!isOptional && result.status !== 200) {
				console.error(result.body);
				throw new Error(`[Account] Failed to load account data: ${result.status}`);
			}

			// Store self & update Discord user of self
			if (result?.body?.user) {
				result.body.user = await idbStore.setUser(result.body.user);
			}
			this.userId = result.body.user.id;

			// Update guild's information and cache guilds you staff
			for (const guild of result.body.staffedGuilds) {
				await idbStore.setGuild(guild);
				this.staffedGuilds.add(guild.id);
			}

			// Update last validation date
			this.sessionLastValidatedAt = new Date();

			this.save();
		},
		/**
		 * Get one of your staffed guilds by ID
		 * @param {string} guildId
		 * @param {boolean} [fallback=true] If guild is not found, return something instead of null
		 * @returns {Partial<DisplayGuild> | null}
		 */
		getGuild(guildId, fallback = true) {
			const guild = IndexedDBStore().getGuild(guildId);
			if (!guild && fallback) return { id: guildId, name: "Unknown Server", icon: null };
			return guild ?? null;
		},
		/**
		 * Quickly check if a user is a staff of a given guild
		 * @param {string} guildId
		 * @returns {boolean}
		 */
		isStaffIn(guildId) {
			return this.staffedGuilds.has(guildId);
		},
		/**
		 * Saves some information to Session Storage, with the purpose of only making
		 * it faster to open up new tabs with Archivian while you're actively browsing
		 * the dashboard.
		 */
		save() {
			window.sessionStorage.setItem("accountData", JSON.stringify({
				userId: this.userId,
				staffedGuilds: Array.from(this.staffedGuilds),
				/**
				 * Permission nodes should be stored here, because session storage
				 * is shared among tabs, but user might be browsing different guilds
				 * in different tabs!
				 *
				 * Route guards will handle loading the correct permissions into memory!
				 */
			}));
		},
		/**
		 * Get all info you need regarding a guild:
		 * - guild display info
		 * - staff members, with IDs
		 * - titles
		 * - teams
		 * - staff's Discord users
		 * - your own permissions
		 *
		 * TODO Load the types from rbac or so, put it into constants, then use the typedef here
		 * @param {string} guildId
		 * @returns {Promise<void>}
		 */
		async getGuildStash(guildId) {
			/**
			 * When to use:
			 * - user navigates to the guild from another guild or personal dashboard
			 * - each tab opened
			 * - user loads page for first time (no other session in this tab)
			 * - WebSocket operation tells you there's new data
			 */
			const r = await backend(`/public/guilds/${guildId}/misc/first-time`);
			if (r.status!==200) {
				return console.error("Failed fetch guild stash", r.body);
			}

			const { staff, titles, teams, permissions, guild, users } = r.body;
			const idbStore = IndexedDBStore();
			// If guild ID is wrong/not found, this will be undefined, and the rest will be empty arrays.
			if (guild) {
				await idbStore.setGuild(guild);
			}

			await Promise.all([
				idbStore.setPermissions(guildId, permissions),
				idbStore.setUsers(users),

				// Clear DB records for the guild, inserts the new ones
				idbStore.setStaffs(guildId, staff),
				idbStore.setTitles(guildId, titles),
				idbStore.setTeams(guildId, teams),
			]);

			idbStore.wipe(); // Blank slate, just in case
			await idbStore.init(guildId); // Now populate its data
		},
		/**
		 * Marks the current user as not staff in a guild by ID
		 * @param {string} guildId
		 */
		markNotStaffInGuild(guildId) {
			this.staffedGuilds.delete(guildId);
			this.save();
		}
	}
});

/**
 * @typedef {Object} PartialGuildStaffWithIDs
 * @property {StaffID} id
 * @property {UserID} userId
 * @property {string} guildId
 * @property {boolean} isOwner
 * @property {Date} createdAt
 * @property {Date|null} updatedAt
 * @property {boolean} useDiscordPermissions
 * @property {String|null} discordPermissions
 * @property {Date|null} discordPermissionsUpdatedAt
 * @property {string|null} titleId
 * @property {string[]} teamIds
 */

/**
 * @typedef {Object} UserInformation
 * @property {string} id Discord user ID
 * @property {string} username Discord username
 * @property {string|null} avatar Discord avatar hash
 */
/**
 * @typedef {Object} DisplayGuild
 * @property {string} id Discord guild ID
 * @property {string} name Discord guild name
 * @property {string|null} icon Discord guild icon hash
 * @property {string|null} banner Discord guild banner hash
 * @property {string|null} splash Discord guild splash hash
 * @property {string|null} description Discord guild description
 * @property {string|null} discovery_splash Discord guild discovery splash hash
 */

/**
 * @typedef {Object} AccountData
 * @property {UserID|string} userId
 * @property {string[]} staffedGuilds
 * @property {DisplayGuild[]} guilds
 */

/**
 * Loads account data, if present
 */
function loadAccountData() {
	const accountData = window.sessionStorage.getItem("accountData");
	if (!accountData) return {};

	try {
		return JSON.parse(accountData);
	} catch (_) {
		window.sessionStorage.removeItem("accountData");
		return {};
	}
}
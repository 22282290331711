<template>
	<InspectCaseLayout>
		<template #head>
			<h2>Appeal Verdict</h2>
		</template>

		<template #main>
			<ErrorCallout
				v-if="error.label || error.texts?.length"
				:label="error.label"
				:texts="error.texts"
			/>
			<InfoCallout
				v-if="hasVerdict"
				label="This appeal already has a verdict."
			/>
			<div>
				<DetailedRadio
					v-model="state.selected"
					:options="[{
							id: 'approved',
							label: 'Approved',
							description: grantText
						}, {
							id: 'rejected',
							label: 'Rejected',
							description: rejectText
						}]"
				/>
			</div>

			<div>
				<!-- TODO Change to our custom checkbox -->
				<InfoCallout
					v-if="!canArchive"
					label="You must either have permission to archive the case or be assigned to this appeal in order to use this."
				/>
				<div class="flex gap-2">
					<CheckBox
						id="checkbox"
						v-model="state.checkbox"
						:disabled="hasVerdict || !canArchive"
						type="checkbox"
						name="checkbox"
					/>
					<div>
						<label for="checkbox" class="font-medium cursor-pointer">
							{{
								state.selected === "approved"
									? `Archive ${appeal ? "case #" + appeal.case.sid : "original case"}`
									: "Deny further appeals"
							}}
						</label>
						<p>
							{{
								state.selected === "approved"
									? "Implies that the user is innocent and case should not have existed to begin with."
									: "Disallows the appealing user form re-appealing with new appeals."
							}}
						</p>
					</div>
				</div>
			</div>

			<div id="message">
				<div class="flex justify-between gap-1">
					<p class="italic text-gray-400 text-right">
						Visible to staff and the appealing user.
					</p>
				</div>
				<TextArea
					:value="state.statement"
					:disabled="hasVerdict"
					label="Closing statement"
					:placeholder="state.selected==='approved'
						? 'Condition for grant, findings of investigation or review, or other final message.'
						: 'Reason for rejection, final message, or other.'"
					:minlength="32"
					:maxlength="1024"
					style="min-height: 5em;"
					:lazy="false"
					@update="state.statement = $event"
				/>
			</div>
		</template>

		<template #footer>
			<div class="flex gap-2 w-full justify-end">
				<BasicButton
					:label="hasVerdict ? 'Close' : 'Cancel'"
					:disabled="saving"
					type="link"
					@click="emit('close')"
				/>
				<BasicButton
					label="Finish"
					:disabled="hasVerdict || saving || state.statement.length < 32"
					@click="handleCreateVerdict"
				/>
			</div>
		</template>
	</InspectCaseLayout>
</template>

<script setup>
import InspectCaseLayout from "@/partials/layouts/InspectCaseLayout.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import DetailedRadio from "@/components/form/radio/DetailedRadio.vue";
import {computed, ref, watch} from "vue";
import TextArea from "@/components/form/TextArea.vue";
import InfoCallout from "@/components/info/callouts/InfoCallout.vue";
import Nodes from "@/script/Nodes";
import {Account} from "@/storage/AccountCache";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import CheckBox from "@/components/form/Checkbox/CheckBox.vue";

const account = Account();
const emit = defineEmits(["updateCache", "close"]);
const props = defineProps({
	"appeal": {
		type: Object,
		required: true,
	},
	"createVerdict": {
		type: Function,
		required: true,
	},
	"saving": {
		type: Boolean,
		default: () => false,
	},
	"hasVerdict": {
		type: Boolean,
		default: () => false,
	},
	"error": {
		type: Object,
		default: () => ({label: "", texts: []}),
	},
});
const state = ref({
	selected: "approved",
	archive: false,
	checkbox: false,
	saving: false,
	statement: "",
});

const canArchive = computed(() => {
	// Allow if rejecting makes render conditions simpler
	if (state.value.selected==="rejected") return true;

	// Allow if user is one of the assigned
	if (props.appeal.meta.assigned.includes(account.userId)) return true;

	// If we're about to approve, then user must have access to archive the case
	const archivePerm = Nodes.Cases.CASE.ARCHIVE(
		props.appeal.case.caseType,
		props.appeal.case.creatorId === account.userId,
	);
	return account.can(archivePerm);
});
const grantText = computed(() => {
	if (props.appeal.case.caseType === "ban") return "Automatically unbans the user and closes the appeal.";
	if (props.appeal.case.caseType === "timeout") return "Automatically lifts timeout on the user and closes the appeal.";
	return "Closes the appeal and marks case as undone.";
});
const rejectText = computed(() => {
	if (props.appeal.case.caseType === "ban") return "Closes appeal, and user remains banned.";
	if (props.appeal.case.caseType === "timeout") return "Closes appeal, and user remains unable to write/speak for now.";
	return "Closes the appeal.";
});

watch(() => state.value.selected, () => {
	// Reset checkbox when you switch approve/reject
	state.value.checkbox = false;
});

const handleCreateVerdict = async () => {
	const result = await props.createVerdict(
		state.value.statement,
		state.value.selected === "approved",
		state.value.checkbox,
	);

	// If failed, we want to stay on the page and show error
	if (result) emit("close");
}
</script>
<script setup>
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import {Account} from "@/storage/AccountCache";
import {inject, ref} from "vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {useRoute} from "vue-router";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

defineProps({
	"caseType": {
		type: String,
		required: true,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
	"pills": {
		type: Object,
		default: () => ({
			"demo": false,
			"showCase": false,
			"expired": false,
			"undone": false,
			"archived": false,
		}),
	},
	// Personal view use only: used to conditionally display Appeal button
	"canAppeal": {
		type: Boolean,
		default: () => false,
	},
	"hasAppeals": {
		type: Boolean,
		default: () => false,
	},
	"close": {
		type: Function,
		default: () => () => {
		},
	},
});

const cases = CaseAppealBrowser();
const account = Account();
const route = useRoute();
const personal = inject("isPersonal");
const handlers = inject("handlers");
const confirm = ref({
	type: null,
	prompted: false,
	timer: null,
});

function doConfirm(type) {
	clearTimeout(confirm.value.timer);

	if (confirm.value.prompted && confirm.value.type === type) {
		if (type === "archive") {
			handlers.handleArchive({reason: "Unknown"});
		} else if (type === "delete") {
			handlers.handleDelete({reason: "Unknown"});
		} else if (type === "restore") {
			handlers.handleRestore({reason: "Unknown"});
		}
		return;
	}

	confirm.value.type = type;
	confirm.value.prompted = true;

	confirm.value.timer = setTimeout(() => {
		confirm.value.prompted = false;
		confirm.value.type = null;
	}, 4000);
}
</script>

<template>
	<PermsCond
		v-if="!personal"
		:needs="[
			Nodes.Cases.CASE.ARCHIVED.RESTORE(caseType, creatorId===account.id),
			Nodes.Cases.CASE.ARCHIVE(caseType, creatorId===account.id),
			pills.archived
				? Nodes.Cases.CASE.ARCHIVED.DELETE(caseType, creatorId===account.id)
				: Nodes.Cases.CASE.DELETE(caseType, creatorId===account.id),
		]"
	>
		<div class="flex gap-2 w-full justify-end">
			<PermsCond
				v-if="!!pills.archived"
				:needs="Nodes.Cases.CASE.ARCHIVED.RESTORE(caseType,creatorId===account.id)"
			>
				<BasicButton
					v-if="!!pills.archived"
					id="restore"
					:label="confirm.type === 'restore' && confirm.prompted ? 'Are you sure?' : 'Restore'"
					:disabled="cases.isLoading('restoreCase', cases.currentId)"
					type="link"
					@click="doConfirm('restore')"
				/>
			</PermsCond>
			<PermsCond v-else :needs="Nodes.Cases.CASE.ARCHIVE(caseType, creatorId===account.id)">
				<BasicButton
					v-if="!pills.archived"
					id="archive"
					:label="confirm.type === 'archive' && confirm.prompted ? 'Are you sure?' : 'Archive'"
					:disabled="cases.isLoading('archiveCase', cases.currentId)"
					type="link"
					@click="doConfirm('archive')"
				/>
			</PermsCond>
			<PermsCond
				:needs="pills.archived
					? Nodes.Cases.CASE.ARCHIVED.DELETE(caseType,creatorId===account.id)
					: Nodes.Cases.CASE.DELETE(caseType,creatorId===account.id)
				"
			>
				<BasicButton
					id="delete"
					:label="confirm.type === 'delete' && confirm.prompted ? 'Are you sure?' : 'Delete'"
					:disabled="cases.isLoading('deleteCase', cases.currentId)"
					type="danger"
					@click="doConfirm('delete')"
				/>
			</PermsCond>
		</div>
	</PermsCond>

	<div v-if="personal" class="flex gap-2 w-full justify-end">
		<template v-if="canAppeal">
			<RouterLink
				:to="{
					name: 'my-cases',
					params: {
						resource: 'appeals',
						resourceId: 'new',
						caseId: route.params.caseId
					}
				}"
			>
				<BasicButton label="Create Appeal" />
			</RouterLink>
		</template>

		<BasicButton
			v-else
			label="Close"
			type="link"
			@click="close"
		/>
	</div>
</template>
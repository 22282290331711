import backend from "@/api/backend";
import {api} from "@/script/APICalls";
/**
 * @typedef {Object} BaseAPIOptions Base options available for most or all service methods
 * @property {?boolean} [withStatus] Return {@link BackendResult} instead of just the data from body
 */
/**
 * @typedef {Object} PaginationInformation
 * @property {number} previousPage The previous page number. Might be the same as `currentPage` if there is no earlier page.
 * @property {number} nextPage The next page number. Might be the same as `currentPage` if there is no later page.
 * @property {number} currentPage The page you are currently on
 * @property {number} totalPages The total amount of pages
 * @property {number} totalItems How many items we have on the current page. May not be the same as the limit
 */

/**
 * Fetches an overview of cases in a guild with a given page
 * @param {string} guildId
 * @param {number} page
 * @param {number} limit
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{users: DisplayUser[], pages: PaginationInformation, cases: Object[] } | null | BackendResult>}
 */
export async function fetchGuildCasesPage(guildId, page, limit, options ={}) {
	const {status, body} = await backend(`/public/guilds/${guildId}/cases`, "GET", {
		query: {
			page,
			limit,
		}
	});

	if (status !== 200 && !options.withStatus) return {
		users: [],
		cases: [],
		pages: {
			previousPage: 0,
			nextPage: 0,
			currentPage: 0,
			totalPages: 0,
			totalItems: 0,
		},
	};
	return options.withStatus ? {status, body} : body;
}

/**
 * Fetches an overview of cases a user has with a given page
 * @param {number} page
 * @param {number} limit
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{users: DisplayUser[], guilds: Object[], pages: PaginationInformation, cases: Object[] } | null | BackendResult>}
 */
export async function fetchPersonalCasesPage(page, limit, options ={}) {
	const {status, body} = await backend(`/public/accounts/me/cases`, "GET", {
		query: {
			page,
			limit,
		}
	});

	if (status !== 200 && !options.withStatus) return {
		users: [],
		guilds: [],
		cases: [],
		pages: {
			previousPage: 0,
			nextPage: 0,
			currentPage: 0,
			totalPages: 0,
			totalItems: 0,
		},
	};
	return options.withStatus ? {status, body} : body;
}

/**
 * Fetches a specific case by its SID. Also populates appeals.
 * @param {string} guildId
 * @param {number} caseSid
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{users: DisplayUser[], case: Object } | BackendResult>}
 */
export async function fetchGuildCase(guildId, caseSid, options = {}) {
	const {status, body} = await backend(`/public/guilds/${guildId}/cases/${caseSid}`);

	if (status !== 200 && !options.withStatus) return {users: [], case: null};
	return options.withStatus ? {status, body} : body;
}

/**
 * Fetches the chat messages for an appeal
 * @param {string} guildId
 * @param {string} appealId
 * @param {?(BaseAPIOptions & {personal: boolean})} [options]
 * @returns {Promise<{lastMessageId?: string|null, messages: (IAppealMessage | IAppealMessageEvent)[]} | BackendResult>}
 */
export async function fetchChatMessages(guildId, appealId, options={}) {
	const {status, body} = await backend(`/public/guilds/${guildId}/cases/appeals/${appealId}/chat`, "GET", {
		query: { asUser: !!options.personal },
	});

	if (status !== 200 && !options.withStatus) return {lastMessageId: null, messages: []};
	return options.withStatus ? {status, body} : body;
}

/**
 * Sends a new text chat message
 * @param {string} guildId
 * @param {string} appealId
 * @param {{id: string, textContent: string}} messagePayload
 * @param {?(BaseAPIOptions & {personal: boolean})} [options]
 * @returns {Promise<null | {oldId: string, newId: string, message: (IAppealMessage | IAppealMessageEvent)} | BackendResult>}
 */
export async function sendChatMessage(guildId, appealId, messagePayload, options={}) {
	const {status, body} = await backend(`/public/guilds/${guildId}/cases/appeals/${appealId}/chat`, "PUT", {
		body: messagePayload,
		query: { asUser: !!options.personal },
	});

	if (status !== 200 && !options.withStatus) return null;
	return options.withStatus ? {status, body} : body;
}
/**
 * Fetches appeals in descending order that were created before or after a given appeal ID.
 * Deviates outwards from the input appeal ID.
 * @param {string} guildId
 * @param {number} [page=1] The page to get. 1 indexed.
 * @param {number} [limit=25]
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{users: DisplayUser[], hasMore: {after: boolean, before: boolean}, appeals: Object[], lastMessagesTime: {appealId: string, lastMessageId?: string, lastMessageDate?: string}[] } | null | BackendResult>} */
export async function fetchGuildAppeals(guildId, page, limit = 25, options ={}) {
	const {status, body} = await backend(`/public/guilds/${guildId}/cases/appeals`, "GET", {
		query: {
			page,
			limit,
		}
	});

	if (status !== 200 && !options.withStatus) return {
		appeals: [],
		users: [],
		lastMessagesTime: [],
		page: {
			previousPage: 1,
			nextPage: 2,
			currentPage: 1,
			totalPages: 0,
			totalItems: 0,
		},
	};
	return options.withStatus ? {status, body} : body;
}


/**
 * Fetches a specific appeal by its ID
 * @param {string} guildId
 * @param {string} appealId
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{users: DisplayUser[], appeal: Object } | BackendResult>}
 */
export async function fetchGuildAppeal(guildId, appealId, options = {}) {
	const {status, body} = await backend(`/public/guilds/${guildId}/cases/appeals/${appealId}`);

	if (status !== 200 && !options.withStatus) return {users: [], appeal: null};
	return options.withStatus ? {status, body} : body;
}

/**
 * Fetches a specific case by its ID. Also populates appeal data.
 * @param {string} caseId Case DB ID
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{users: DisplayUser[], guild: Object, case: Object, appeals: Object[]} | BackendResult>}
 */
export async function fetchPersonalCase(caseId, options={}) {
	const {status, body} = await backend(`/public/accounts/me/cases/${caseId}`);

	if (status !== 200 && !options.withStatus) return {users: [], guild: null, case: null, appeals: []};
	return options.withStatus ? {status, body} : body;
}

/**
 * Create a new case appeal
 * @param {string} caseId The DB ID of the case
 * @param {string} content Appeal text
 * @param {boolean} subscribe Whether to subscribe to updates on this appeal
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{appeal: Object, users: DisplayUser[], guild?: Object} | BackendResult>}
 */
export async function createCaseAppeal(caseId, content, subscribe, options={}) {
	const {status, body} = await backend(`/public/accounts/me/cases/appeals`, "POST", {
		body: {
			caseId,
			content,
			subscribe,
		}
	});

	if (status !== 200 && !options.withStatus) return {users: [], guild: null, appeal: null};
	return options.withStatus ? {status, body} : body;
}

/**
 * Fetches all the appeals the user has made, given a page and limit per page
 * @param {number} page
 * @param {number} limit
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{appeals: Object[], users: DisplayUser[], guilds: Object[], pages: PaginationInformation, lastMessagesTime: {appealId: string, lastMessageId?: string, lastMessageDate?: string}[]} | BackendResult>}
 */
export async function fetchPersonalAppeals(page, limit, options ={}) {
	const {status, body} = await backend(`/public/accounts/me/cases/appeals`, "GET", {
		query: {
			page,
			limit,
		}
	});

	if (status !== 200 && !options.withStatus) return {
		users: [],
		appeals: [],
		guilds: [],
		lastMessagesTime: [],
		pages: {
			previousPage: 0,
			nextPage: 0,
			currentPage: 0,
			totalPages: 0,
			totalItems: 0,
		},
	};
	return options.withStatus ? {status, body} : body;
}

/**
 * Fetches a personal appeal by DB ID
 * @param {string} appealId
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{appeal: Object, users: DisplayUser[], guild?: Object} | BackendResult>}
 */
export async function fetchPersonalAppeal(appealId, options = {}) {
	const {status, body} = await backend(`/public/accounts/me/cases/appeals/${appealId}`);

	if (status !== 200 && !options.withStatus) return {users: [], appeal: null, guild: null};
	return options.withStatus ? {status, body} : body;
}

/**
 * Archives a case, either directly or done via appeal verdict with "archive case" option checked
 * @param {string} guildId
 * @param {number} caseSid
 * @param {string} reason
 * @param {boolean} [viaAppeal=false] A query to speed up permission checking; indicate we should check if user was
 *     assigned to an appeal and archived it that way
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<{theCase?: Object, users: Object<string, UserDisplayInfo>, entry?: Object} | BackendResult>}
 */
export async function archiveCase(guildId, caseSid, reason, viaAppeal = false, options = {}) {
	const {body, status} = await backend(`/public/guilds/${guildId}/cases/${caseSid}`, "DELETE", {
		query: {
			reason,
			viaAppeal,
		},
	});
	if (status !== 200 && !options.withStatus) return { theCase: null, users: [], entry: null };
	return options.withStatus ? {status, body} : body;
}

/**
 * Create a verdict for an appeal
 * @param {string} guildId
 * @param {string} appealId Appeal DB ID
 * @param {("approved"|"rejected")} verdict
 * @param {string} statement
 * @param {?(BaseAPIOptions & {denyFurther: boolean})} [options]
 * @returns {Promise<*>}
 */
export async function createAppealVerdict(guildId, appealId, verdict, statement, options = {}) {
	Object.assign({denyFurther: false}, options);

	const {body, status} = await backend(`/public/guilds/${guildId}/cases/appeals/${appealId}/verdict`, "POST", {
		body: {
			message: statement,
			state: verdict,
			denyFurther: options.denyFurther,
		}
	});

	// 200 created, 204 no change?
	if (status !== 200 && !options.withStatus) return { theCase: null, users: [], entry: null };
	return options.withStatus ? {status, body} : body;
}


/**
 * Assign (add to set) a staff to an appeal
 * @param {string} guildId
 * @param {string} appealId Appeal DB ID
 * @param {UserID} userId Discord ID of the staff member
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<Object | BackendResult>}
 */
export async function assignStaff(guildId, appealId, userId, options={}) {
	const {body, status} = await api(`/public/guilds/${guildId}/cases/appeals/${appealId}/assigned`, "PUT", {
		body: {userId}
	});

	// body is the new appeal state, censored
	if (!status.toString().startsWith("2") && !options.withStatus) return null;
	return options.withStatus ? {status, body} : body;
}

/**
 * Remove a staff assignment from an appeal
 * @param {string} guildId
 * @param {string} appealId Appeal DB ID
 * @param {UserID} userId Discord ID of the staff member
 * @param {string} [reason="Unknown reason"] Reason for removing
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<Object | BackendResult>}
 */
export async function unAssignStaff(guildId, appealId, userId, reason = "Unknown reason", options={}) {
	const {body, status} = await api(`/public/guilds/${guildId}/cases/appeals/${appealId}/assigned/${userId}`, "DELETE", {
		query: {reason}
	});

	// 200 changed, 204 no change
	if (![200, 204].includes(status)) {
		if (!options.withStatus) throw body;
		return {body, status};
	}

	return body;
}

/**
 * Set the status of an appeal
 * @param {string} guildId
 * @param {string} appealId Appeal DB ID
 * @param {("open"|"reviewing"|"investigating"|"deliberating"|"awaiting")} state The new status
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<Object | BackendResult>}
 * @throws {{message:string, data?:*}} If response status is not 200 | 204
 */
export async function setState(guildId, appealId, state, options={}) {
	const {body, status} = await api(`/public/guilds/${guildId}/cases/appeals/${appealId}`, "PATCH", {
		body: {state}
	});

	// 200 changed, 204 no change
	if (![200, 204].includes(status)) {
		if (!options.withStatus) throw body;
		return {body, status};
	}

	return body;
}

/**
 * Check if the current user is banned in a given guild by ID.
 * Will auto-generate a ban case if:
 * - they are banned
 * - and a case did not already exist
 * - and the guild has the feature to appeal existing bans enabled
 * Else it will return null.
 * @param {string} guildId
 * @returns {Promise<{ case?: Object, appeal?: Object, guild?: Object, users: Object[] }>} Items may be null/empty array depending on what the situation is
 */
export async function checkIfImBannedIn(guildId) {
	// POST
	// { case?: Object, appeal?: Object, guild?: Object, users: Object[] }
	const {body, status} = await api(`/public/guilds/${guildId}/cases/from-discord-ban`, "POST");

	if (status !== 200) throw body;
	return body;
}

export default {
	// Guild + Personal
	sendChatMessage,
	fetchChatMessages,

	// Guild
	fetchGuildCase,
	fetchGuildCasesPage,
	fetchGuildAppeals,
	fetchGuildAppeal,
	archiveCase,
	createAppealVerdict,
	assignStaff,
	unAssignStaff,
	setState,

	// Personal
	fetchPersonalCase,
	fetchPersonalCasesPage,
	createCaseAppeal,
	fetchPersonalAppeal,
	fetchPersonalAppeals,
	checkIfImBannedIn
};
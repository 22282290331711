<script setup>
import Nodes from "@/script/Nodes";
import FileUpload from "@/components/form/file-upload/FileUpload.vue";
import {computed, inject} from "vue";
import {caseFileLink} from "@/script/convert";
import {Account} from "@/storage/AccountCache";
import PermsCond from "@/components/PermsCond.vue";
import {useRouter} from "vue-router";

const props = defineProps({
	"files": {
		type: Array,
		default: () => [],
	},
	"guildId": {
		type: String,
		required: true,
	},
	"caseType": {
		type: String,
		required: true,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
	"sid": {
		type: [Number, String],
		required: true,
	},
	"pills": {
		type: Object,
		default: () => ({
			"archived": false,
		}),
	},
});
const router = useRouter();
const handlers = inject("handlers");
const personal = inject("isPersonal");
const account = Account();
const fileItems = computed(() => {
	return props.files.map(f => {
		const name = f.name ?? f.meta?.ext ? `${f.fileId}.${f.meta?.ext}` : f.fileId;
		return {
			id: f.fileId,
			name: name,
			size: f.meta.size,
			mime: f.meta.mime,
			ext: f.meta.ext,
			image: f.meta.mime?.startsWith("image")
				? caseFileLink(props.guildId, props.sid, f.fileId)
				: null,
			publicLink: "CREATE_FILE_CDN_LINK_HERE",
			isArchived: !!f.locked?.at,
		};
	});
});
const createInspectFileLink = (fileId) => {
	return router.resolve({
		name: personal ? "my-cases" : "guild-cases",
		params: {
			guildId: props.guildId,
			caseSid: props.sid,
			resource: "files",
			resourceId: fileId,
		},
	}).fullPath;
};
const createDirectFileLink = (fileId) => {
	// https://api.archivian.net/v2/files/guilds/777300621539672104/cases/1/files/657df1
	return `https://api.archivian.net/public/guilds/${props.guildId}/cases/${props.sid}/files/${fileId}/file`;
};
</script>

<template>
	<section>
		<!-- Exiting files + file upload -->
		<div id="files">
			<!-- Top bar -->
			<div class="flex justify-between items-baseline">
				<p class="label">
					Files
				</p>
				<PermsCond
					v-if="!personal && !pills.archived"
					:needs="Nodes.Cases.ENTRY.CREATE(caseType,creatorId===account.id)"
				>
					<span class="uppercase text-xs font-bold text-slate-400 cursor-not-allowed">Must be added via Discord.</span>
				</PermsCond>
			</div>

			<!-- File-upload temporarily disabled until implementation is fully completed -->
			<FileUpload
				v-if="files.length"
				:guild-id="guildId"
				:needs="Nodes.Cases.FILE.CREATE(caseType, creatorId===account.id)"
				:max-files="0"
				:max-file-size="50*1024*1024"
				:create-inspect-file-link="createInspectFileLink"
				:create-direct-file-link="createDirectFileLink"
				:files="fileItems"
				@archive="handlers.archiveFile"
				@delete="handlers.deleteFile"
				@restore="handlers.restoreFile"
			/>
			<p v-else class="italic text-slate-500">
				No files.
			</p>
		</div>
	</section>
</template>
<script>
export default {
	name: "IconProtectLoupe",
};
</script>

<template>
	<g>
		<path
			d="M14.5978 13.2649C13.7155 14.1473 12.3885 14.4112 11.2356 13.9337C10.0827 13.4562 9.33105 12.3312 9.33105 11.0833C9.33105 9.83549 10.0827 8.71052 11.2356 8.23298C12.3885 7.75545 13.7155 8.01941 14.5978 8.90177C15.8027 10.1066 15.8027 12.0601 14.5978 13.2649"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3.99707 13.8856V7.34963C3.99683 6.22475 4.7022 5.22058 5.76047 4.83925L9.82884 3.37464C11.2307 2.86991 12.7648 2.86991 14.1666 3.37464L18.2403 4.84192C19.2977 5.22293 20.0029 6.22573 20.0037 7.34963V13.8856C20.0037 17.2817 15.2524 19.7948 13.0675 20.7738C12.3891 21.0802 11.6117 21.0802 10.9333 20.7738C8.74838 19.7948 3.99707 17.2764 3.99707 13.8856Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.49902 14.9999L10.2352 13.2637L8.49902 14.9999Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script setup>
import MiniButton from "@/components/buttons/MiniButton.vue";
import {UI} from "@/storage/UICache";
import ReadMore from "@/components/misc/ReadMore.vue";

defineProps({
	"title": {
		type: String,
		default: () => null,
	},
	"description": {
		type: String,
		default: () => null,
	},
	"joyrideId": {
		type: String,
		required: true,
	},
	"closeTipLabel": {
		type: String,
		default: () => "Close Tip",
	},
	"readMore": {
		type: [String, Object],
		default: () => null,
	},
	"readMoreLabel": {
		type: String,
		default: () => "Read more",
	},
});
const ui = UI();
</script>

<template>
	<div
		v-if="!ui.isDismissed('joyride', joyrideId)"
		class="p-4 rounded text-sm border border-sky-400 flex flex-col justify-between"
	>
		<div>
			<h3 v-if="title">
				{{ title }}
			</h3>
			<p class="text-sky-400">
				<slot name="description">
					{{ description }}
				</slot>
			</p>
		</div>
		<div class="flex items-center justify-between">
			<ReadMore
				v-if="readMore"
				:to="readMore"
				:label="readMoreLabel"
			/>
			<MiniButton
				:label="closeTipLabel"
				@click="ui.dismiss('joyride', joyrideId)"
			/>
		</div>
	</div>
</template>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.001 12H5C3.89543 12 3 12.8954 3 14V19C3 20.1046 3.89543 21 5 21H10C11.1046 21 12 20.1046 12 19V13.999C12 13.4688 11.7894 12.9604 11.4145 12.5855C11.0396 12.2106 10.5312 12 10.001 12V12Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15 21H17.5C18.8807 21 20 19.8807 20 18.5V8.37167C20 7.57602 19.6839 6.81296 19.1213 6.25035L16.7497 3.87868C16.187 3.31607 15.424 3 14.6283 3H7.5C6.11929 3 5 4.11929 5 5.5V9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.9764 8H16.5C15.6716 8 15 7.32843 15 6.5V3.02362"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6 15H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.5 15V18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconFileText"
};
</script>
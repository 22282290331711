import { validateSession } from "@/api/auth";

/**
 * Performs validation of the user session in the background.
 * If invalid, the user will be redirected to the login, which will then redirect back to the current page.
 * @return {Promise<void|number>}
 */
export async function validateSessionInBackground() {
	const isValid = await validateSession();
	if (isValid) return;

	// Else we need to redirect user to login page, but we want to pick up where we left off
	const redirectAfterLogin = window.location.path;
	return requestAnimationFrame(() => window.location.href = `${process.env.VUE_APP_API_ENDPOINT}/public/accounts/login?redirect=${redirectAfterLogin}`);
}

/**
 * Will redirect user to the first `available` page if the current route is the root page.
 * If no matches for the desired route, will redirect to the `fallback` route.
 * @param {Object} route
 * @param {string} rootName
 * @param {Object[]} available
 * @param {Object} fallback
 * @return {Object | undefined} If needed, the new route to replace the current route with
 */
export function redirectToFirst(route, rootName, available, fallback) {
	if (route.name === rootName) {
		if (!available.length) return fallback;
		return available[0];
	} else {
		const found = available.find(r => r.name === route.name);
		if (!found) return fallback;
	}
}
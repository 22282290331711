<script setup>
import TabbedListInspect from "@/partials/layouts/TabbedListInspect.vue";
import {onBeforeUnmount, onMounted, ref, provide, computed} from "vue";
import {UI} from "@/storage/UICache";
import {hook, unhook, USER_OPS} from "@/ws/utils";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import {Account} from "@/storage/AccountCache";
import InspectAppeal from "@/partials/inspect/appeal/InspectAppeal.vue";
import ServerIcon from "@/components/icon/ServerIcon.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import IdBadge from "@/components/badges/IdBadge.vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import TableCard from "@/components/panels/TableCard.vue";
import IndexedDBStore from "@/storage/IndexedDB";
import GuildAppealInspectChat from "@/views/guild/case-system/appeals/chat/GuildAppealInspectChat.vue";
import CaseService from "@/services/CaseService";
import FancyLoader from "@/components/misc/FancyLoader.vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import AppealList from "@/partials/inspect/AppealList.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

provide("isPersonal", true);
const idbStore = IndexedDBStore();
const appeals = CaseAppealBrowser();
const ui = UI();
const account = Account();
const route = useRoute();
const router = useRouter();
const initialized = ref(false);
const error = ref({
	label: "",
	texts: [],
});
const panels = computed(() => {
	return {
		none: !route.params.appealId, // no resource is inferred ofc
		appealOnly: !!route.params.appealId && !route.params.resource,
		both: !!route.params.appealId && !!route.params.resource
	}
});

const handlers = {
	close() {
		router.push({name: route.name, params: {}});
	},
	closeResource() {
		router.push({name: route.name, params: {appealId: route.params.appealId}});
	},
	/**
	 * Fetches an appeal by its ID
	 * @param {string} appealId
	 * @returns {Promise<Object|null>} `null` if not found, else the appeal
	 */
	async fetchAppeal(appealId) {
		const result = await CaseService.fetchPersonalAppeal(appealId);
		if (!result.appeal) return null;

		await Promise.all([
			idbStore.setUsers(result.users),
			result.guild && idbStore.setGuild(result.guild),
		]);

		return result.appeal;
	},
	/**
	 * Fetches and populates the chat messages
	 * @returns {Promise<void>}
	 */
	async fetchMessages(guildId, appealId) {
		const messageRecords = await CaseService.fetchChatMessages(guildId, appealId, {personal: true});
		appeals.currentResource = messageRecords.messages;
	},
	/**
	 * Sends a message to the chat
	 * @param {string} textContent
	 */
	async sendMessage(textContent) {
		if (!appeals.currentItem) return;

		const payload = {
			textContent,
			id: Math.random().toString(36).slice(2)+Math.random().toString(36).slice(2)+Math.random().toString(36).slice(2),
		};
		await CaseService.sendChatMessage(appeals.currentItem.guildId, route.params.appealId, payload, {personal: true});
	},
	/**
	 * Handles an incoming Appeals chat message
	 * @param {NewAppealChatMessage} payload
	 */
	async handleIncomingMessage(payload) {
		const id = appeals.currentId;
		if (payload.appealId !== id) return;
		if (payload.guildId !== appeals.currentItem.guildId) return;
		if (payload.message.t !== "message") return; // TODO Feature for events too

		await idbStore.setUsers(payload.users);
		appeals.currentResource.push(payload.message);
	},
	/**
	 * Handles an appeal being updated
	 * @param {string} _id The ID of the appeal that was updated
	 * @returns {Promise<void>}
	 */
	async handleAppealUpdated({ _id }) {
		// If ID is not the current one, ignore it
		if (_id !== appeals.currentId) return;

		// Fetch the updated appeal
		const newAppealState = await handlers.fetchAppeal(_id);
		if (!newAppealState || _id !== appeals.currentId) return;

		// Update the current appeal
		appeals.currentItem = newAppealState;
	},
}

onMounted(async () => {
	appeals.$reset();
	/**
	 * Messages are loaded when you open the message panel.
	 * @type {IAppealMessage[]}
	 */
	appeals.currentResource = [];

	if (route.params.appealId) {
		appeals.currentItem = await handlers.fetchAppeal(route.params.appealId);
	}

	hook([
		USER_OPS.APPEAL_UPDATE,
	], handlers.handleAppealUpdated);
	hook([
		USER_OPS.NEW_APPEAL_CHAT_MESSAGE,
	], handlers.handleIncomingMessage);

	initialized.value = true;
});
onBeforeRouteUpdate(async (to, from, next) => {
	if (to.name !== from.name) return next();
	if (to.params.appealId === from.params.appealId) return next();
	if (!to.params.appealId) {
		appeals.currentItem = null;
		appeals.currentResource = [];
	} else {
		const appeal = await handlers.fetchAppeal(to.params.appealId);
		if (!appeal) return next(false);
		appeals.currentItem = appeal;

		// Messages are loaded when you open the message panel.
		appeals.currentResource = [];
	}

	return next();
});
onBeforeUnmount(() => {
	appeals.$reset();

	unhook([
		USER_OPS.APPEAL_UPDATE,
	], handlers.handleAppealUpdated);
	unhook([
		USER_OPS.NEW_APPEAL_CHAT_MESSAGE,
	], handlers.handleIncomingMessage);
});
</script>

<template>
	<TabbedListInspect
		class="self-stretch min-h-0 min-w-0 flex"
		:divider-classes="{
			'hidden xl:block': panels.appealOnly,
			'hidden 2xl:block': panels.both,
		}"
	>
		<template #list>
			<div v-if="!initialized" class="flex justify-center items-center h-full">
				<FancyLoader label="Fetching latest appeals&hellip;" />
			</div>
			<AppealList
				v-else-if="initialized && !error.label"
				:id="ui.setAnchor('mainList')"
				tabindex="-1"
				:current-appeal="appeals.currentItem"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.appealOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both
				}"
				class="overflow-y-auto w-xs flex-col"
			/>
			<ErrorCallout
				v-else-if="error.label"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.appealOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both,
				}"
				:label="error.label"
				:texts="error.texts"
				class="m-4 w-max"
			/>
		</template>

		<template #default>
			<div class="overflow-y-auto max-h-full h-full">
				<div v-if="!appeals.currentItem" class="hidden lg:flex w-full h-full flex-col gap-2 items-center justify-center overflow-hidden">
					<div class="py-4 rounded-full relative">
						<div
							class="z-0 w-52 h-52 rounded-full absolute bg-sky-500/50 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
						/>
						<TableCard class="shadow-2xl relative z-10 bg-neutral-800">
							<template #content>
								<ServerIcon
									server-id="00000000000000000000"
									name="Example Server"
									size="48"
									class="shrink-0"
								/>
								<div class="flex flex-col truncate">
									<p class="font-bold truncate">
										#10&nbsp;&bull;&nbsp;Example Server
									</p>
									<IdBadge label="12345678901234567890" class="w-fit" />
								</div>
							</template>

							<template #footer-left>
								<DynamicIcon
									icon="warning"
									class="text-warning-normal"
								/>
								<p class="text-warning-normal">
									Warning
								</p>
							</template>

							<template #footer-right>
								<p class="text-sm">
									By staff.user
								</p>
								<UserAvatar
									user-id="11111111111111111111"
									size="24"
									class="shrink-0"
								/>
							</template>
						</TableCard>
					</div>

					<div class="mt-12">
						<h2>Appeal Overview</h2>
						<p class="max-w-md">
							If you have made an appeal on a case, you will find those here.
						</p>
					</div>
				</div>
				<div
					v-else
					class="p-4 h-full"
					:class="{'grid lg:grid-cols-2 gap-4': panels.both}"
				>
					<!-- Main inspect window -->
					<InspectAppeal
						v-if="appeals.currentItem"
						:id="ui.setAnchor('main')"
						tabindex="-1"
						:appeal="appeals.currentItem"
						:guild="account.getGuild(appeals.currentItem.guildId)"
						class="border border-neutral-700"
						:class="{'hidden lg:block': panels.both}"
						personal
						@close="handlers.close"
					/>

					<template v-if="panels.both">
						<GuildAppealInspectChat
							v-if="route.params?.resource==='chat'"
							:id="ui.setAnchor('mainSecondary')"
							tabindex="-1"
							class="border border-neutral-700"
							:messages="appeals.currentResource"
							:handlers="handlers"
							:appeal="appeals.currentItem"
							@close="handlers.closeResource"
						/>
					</template>
				</div>
			</div>
		</template>
	</TabbedListInspect>
</template>
<template>
	<g>
		<path
			d="M6 14V15.0045"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6 18.9961V20.0006"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.4051 15.5003L4.27459 15.9958"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.74063 18.0042L8.59531 18.4997"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.4051 18.4997L4.27459 18.0042"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7.74063 15.9958L8.59531 15.5003"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6 15C7.10457 15 8 15.8954 8 17C8 18.1046 7.10457 19 6 19C4.89543 19 4 18.1046 4 17C4 15.8954 4.89543 15 6 15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 20H19C20.1046 20 21 19.1046 21 18V8.94C21 7.83543 20.1046 6.94 19 6.94H12.5291C12.1981 6.94 11.8886 6.7762 11.7024 6.50253L10.2974 4.43747C10.1111 4.16379 9.80157 3.99999 9.47054 4H5C3.89543 4 3 4.89543 3 6V11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCaseSetting"
};
</script>
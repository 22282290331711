<template>
	<g>
		<path
			d="M12.5001 12.7114L13.8277 11.9738C14.4902 11.6058 14.9011 10.9075 14.9011 10.1496C14.8226 8.88929 13.7418 7.92852 12.481 7.99833C11.3544 7.95156 10.354 8.71311 10.0991 9.81149"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="12.5001"
			cy="12"
			r="9.00375"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.6002 15.9018C12.6002 15.957 12.5554 16.0017 12.5002 16.0017C12.4449 16.0016 12.4002 15.9569 12.4002 15.9017C12.4001 15.8464 12.4449 15.8017 12.5001 15.8016C12.5267 15.8015 12.5522 15.8121 12.5709 15.8309C12.5897 15.8497 12.6003 15.8752 12.6002 15.9018"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconHelp"
};
</script>
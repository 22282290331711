<script setup>
import {computed, h} from "vue";
import {RouterLink} from "vue-router";

const emit = defineEmits(["update:modelValue"]);
defineProps({
	"modelValue": {
		type: String,
		default: () => null,
	},
	"tabs": {
		type: Array,
		default: () => [
			{ label: "My Account", to: '/', id: 'account' },
			{ label: "Company", to: '/', disabled: true, id: 'company' },
			{ label: "Team Members", to: '/', id: 'members' },
		],
	},
	"ariaLabel": {
		type: String,
		default: () => "Select tab",
	}
});

const randomId = computed(() => Math.random().toString(36).slice(2));
const component = (item) => {
	if (item.to && !item.disabled && !item.current) return RouterLink;
	return h("button");
}
</script>

<template>
	<div>
		<div class="sm:hidden">
			<label :for="randomId" class="sr-only">
				{{ ariaLabel }}
			</label>
			<!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
			<select
				:id="randomId"
				:name="randomId"
				:value="modelValue"
				class="block w-full rounded-md border-neutral-600 focus:border-sky-400 focus:ring-sky-400"
				@change="emit('update:modelValue', $event.target.value)"
			>
				<option
					v-for="tab in tabs"
					:key="tab.id"
					:value="tab.id"
				>
					{{ tab.label }}
				</option>
			</select>
		</div>
		<div v-if="true" class="hidden sm:block">
			<nav class="isolate flex gap-1 p-1 rounded-xl bg-neutral-800 justify-between" aria-label="Tabs">
				<component
					:is="component(tab)"
					v-for="tab in tabs"
					:key="tab.id"
					:disabled="tab.disabled"
					class="tabItem"
					:class="{
						'active text-neutral-100': tab.id === modelValue,
						'text-neutral-300': tab.id !== modelValue
					}"
					@click="emit('update:modelValue', tab.id)"
				>
					{{ tab.label }}
				</component>
			</nav>
		</div>
	</div>
</template>

<style scoped>
.tabItem.active {
	@apply bg-neutral-700 hover:bg-neutral-700 hover:text-neutral-100;
}
.tabItem {
	@apply py-1 px-4 text-sm font-medium w-full text-nowrap text-center rounded-lg transition-colors
	hover:bg-neutral-700/50 hover:text-neutral-300
	disabled:bg-neutral-800 disabled:cursor-not-allowed disabled:opacity-70
}
</style>
<script setup>
import Nodes from "@/script/Nodes";
import UserHighlightPanel from "@/components/panels/UserHighlightPanel.vue";
import PermsCond from "@/components/PermsCond.vue";
import UserCache from "@/storage/UserCache";
import {computed, inject} from "vue";
import CheckBox from "@/components/form/Checkbox/CheckBox.vue";
import {Account} from "@/storage/AccountCache";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const props = defineProps({
	"creatorId": {
		type: String,
		default: () => null,
	},
	"lastEditorId": {
		type: String,
		default: () => null,
	},
	"caseCreatorId": {
		type: String,
		default: () => null,
	},
	"targetUserId": {
		type: String,
		default: () => null,
	},
	"caseType": {
		type: String,
		default: () => null,
	},
	"content": {
		type: String,
		default: () => null,
	},
	"entryContent": {
		type: String,
		required: true,
	},
	"visibilityEnabled": {
		type: Boolean,
		required: true,
	},
	"entryId": {
		type: String,
		default: () => null,
	},
	"isEditing": {
		type: Boolean,
		required: false,
	},
	"entrySid": {
		type: Number,
		required: true,
		default: () => null,
	},
	"pills": {
		type: Object,
		default: () => ({}),
	},
});
const account = Account();
const users = UserCache();
const cases = CaseAppealBrowser();
const personal = inject("isPersonal");
defineEmits(["update:entryContent", "update:visibilityEnabled"]);

const highlight = computed(()=>{
	if (props.pills.system && props.creatorId === process.env.VUE_APP_BOT_ID) return {
		avatarUrl: "logo",
		username: "Archivian",
		title: "Entry Creator • System",
		userId: props.creatorId,
	};

	return {
		avatarUrl: users.avatarOf(props.creatorId, 128),
		username: users.username(props.creatorId) || "Unknown Staff",
		title: ['Entry Creator', users.titleOf(props.creatorId, !props.pills.system)].filter(Boolean).join(' • '),
		userId: props.creatorId,
	};
});
</script>

<template>
	<UserHighlightPanel
		v-if="creatorId"
		:user-id="highlight.userId"
		:username="highlight.username"
		:title="highlight.title"
		:avatar-url="highlight.avatarUrl"
		users-label="Edited by"
		:users="lastEditorId ? [users.avatarOf(lastEditorId)] : []"
	/>

	<!-- Entry content -->
	<div id="content">
		<p class="label">
			Entry content
		</p>
		<p v-if="!isEditing" class="readable">
			{{ content }}
		</p>
		<textarea
			v-else
			:value="entryContent"
			rows="5"
			class="input-focus border-details"
			style="min-height: 5em;"
			:disabled="cases.isLoading('entryEdit', entrySid)"
			@input="$emit('update:entryContent', $event.target.value)"
		/>
	</div>

	<PermsCond
		v-if="!personal && isEditing && caseType && creatorId && caseCreatorId"
		:needs="Nodes.Cases.ENTRY.VISIBILITY(caseType, caseCreatorId===account.id, creatorId===account.id)"
	>
		<div id="visibility">
			<CheckBox
				label="Show to User"
				:disabled="cases.isLoading('entryVisibility', entrySid) || cases.isLoading('entryEdit', entrySid)"
				:model-value="visibilityEnabled"
				@update:model-value="$emit('update:visibilityEnabled', $event)"
			/>
			<div class="flex items-center gap-2">
				<p>
					Whether <span v-if="targetUserId" class="font-bold">{{
						users.username(targetUserId, false) || "the case target"
					}}</span><span v-else>the user</span> can see
					this entry.
				</p>
			</div>
		</div>
	</PermsCond>
</template>

<style scoped>
textarea {
	@apply drop-shadow-sm w-full max-h-96 bg-neutral-700/50 rounded-md px-2 py-1;
}
</style>
<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import NewCaseEntryFooter from "@/partials/inspect/case/newEntry/NewCaseEntryFooter.vue";
import NewCaseEntryHeader from "@/partials/inspect/case/newEntry/NewCaseEntryHeader.vue";
import NewCaseEntryMain from "@/partials/inspect/case/newEntry/NewCaseEntryMain.vue";
import {ref} from "vue";

const props = defineProps({
	"caseType": {
		type: String,
		default: () => null,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
	"defaultVisible": {
		type: Boolean,
		default: () => false,
	},
	"handlers": {
		type: Object,
		default: () => ({}),
	},
});
const newEntry = ref({
	content: "",
	visible: props.defaultVisible,
});
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<NewCaseEntryHeader
				:close="handlers.closeResource"
			/>
		</template>

		<template #main>
			<NewCaseEntryMain
				v-model:content="newEntry.content"
				v-model:visibility="newEntry.visible"
				:case-type="caseType"
				:creator-id="creatorId"
			/>
		</template>

		<template #footer>
			<NewCaseEntryFooter
				:create="() => handlers.createEntry(newEntry)"
				:close="handlers.closeResource"
				:disabled="!newEntry.content.length"
			/>
		</template>
	</InspectResourceLayout>
</template>
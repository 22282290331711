<script setup>
import NewAppealView from "@/partials/inspect/appeal/newAppeal/NewAppealView.vue";
import {onBeforeRouteLeave, useRouter} from "vue-router";
import {createCaseAppeal} from "@/services/CaseService";
import {handleErr} from "@/script/convert";
import {onMounted, ref} from "vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import IndexedDBStore from "@/storage/IndexedDB";
import BasicButton from "@/components/buttons/BasicButton.vue";

const props = defineProps({
	"theCase": {
		type: Object,
		required: true,
		default: () => null,
	},
	"demoMode": {
		type: Boolean,
		default: () => false,
	}
});
const router = useRouter();
const error = ref({label: "", texts: []});
const cases = CaseAppealBrowser();
const idbStore = IndexedDBStore();
const showDemoResult = ref(false);
const handlers = {
	gotoCases: () => router.push({name: "my-cases"}),
	/**
	 * Creates a new appeal with the content.
	 * SUBSCRIBE ARGUMENT IS NOT YET IMPLEMENTED.
	 * @param {string} content
	 * @param {boolean} subscribe
	 * @returns {Promise<void>}
	 */
	async createAppeal(content, subscribe) {
		if (props.demoMode) return handlers.createDemoAppeal();

		if (!cases.currentId) return;
		cases.addLoading("appealCreate", cases.currentId);

		const result = await createCaseAppeal(cases.currentId, content, subscribe);
		if (!result.appeal) {
			error.value = handleErr("An unknown error occurred trying to create the appeal", "Could not create appeal");
			return;
		}

		await Promise.all([
			idbStore.setUsers(result.users),
			idbStore.setGuild(result.guild),
		]);

		return router.push({
			name: "my-appeals",
			params: {
				appealId: result.appeal._id,
			},
		});
	},
	createDemoAppeal() {
		return new Promise(r => {
			cases.addLoading("appealCreate", cases.currentId);

			setTimeout(()=>{
				cases.removeLoading("appealCreate", cases.currentId);
				showDemoResult.value = true;
				return r();
			}, 1500);
		});
	},
	resetDemo() {
		showDemoResult.value = false;
	},
}

onMounted(()=>{
	cases.$reset();
	cases.isPersonal = true; // Not sure if this is used anymore
	cases.itemIdKey = "_id";
	cases.currentItem = props.theCase;
});
onBeforeRouteLeave(() => {
	cases.$reset();
});
</script>

<template>
	<div class="flex flex-col lg:grid lg:grid-cols-2 gap-4 overflow-y-auto">
		<div v-if="!showDemoResult" class="max-w-xs mx-auto">
			<h1 class="font-bold text-lg">
				Ready to appeal
			</h1>
			<ul class="list-disc pl-4">
				<li>fill out as much details as possible</li>
				<li>you can use built-in live chat to talk with the staff after the appeal is submitted</li>
				<li>upload any relevant video or image proof you can to YouTube/Imgur and add them in your message</li>
			</ul>
			<p class="my-4">
				Click on <strong>Submit Appeal</strong> when ready.<br>
				Once submitted, the server staff will be alerted and check your appeal.
			</p>
		</div>
		<div v-else class="max-w-xs mx-auto col-span-2">
			<h1 class="font-bold text-lg">
				Demo concluded
			</h1>
			<ol class="list-decimal pl-4">
				<li>A ban case would have been generated by now.</li>
				<li>An appeal would also have been created, if the user went through with submitting the appeal.</li>
				<li>After appeal submission, the user would have been taken to their personal dashboard for browsing appeals, with the newly created appeal selected.</li>
			</ol>
			<div class="flex gap-4 py-4">
				<BasicButton
					label="Go back"
					@click="router.go(-1)"
				/>
			</div>
		</div>

		<NewAppealView
			v-if="!showDemoResult"
			:can-appeal="true"
			:cancel="handlers.gotoCases"
			:create-appeal="handlers.createAppeal"
		/>
	</div>
</template>

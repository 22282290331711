<template>
	<g>
		<path
			d="M17 14C17 13.4477 17.4477 13 18 13H18.6145C19.1433 13 19.6128 13.3384 19.78 13.8401V13.8401C19.9165 14.2495 19.8282 14.7005 19.5473 15.0282L17 18H20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 6V18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13 6V18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 12H13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconHeadingTwo"
};
</script>
<template>
	<g>
		<path
			d="M20 20V19.25C20 16.9028 18.0972 15 15.75 15H8.25C5.90279 15 4 16.9028 4 19.25V20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="12"
			cy="7"
			r="4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconUser"
};
</script>
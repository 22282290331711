<template>
	<div class="text-input relative">
		<div v-if="showFieldAboveTextarea" class="flex justify-between">
			<label :for="id" class="font-semibold mb-1">{{ label }}</label>
			<span
				v-if="maxlength && maxlength-trimInput(currentValue).length < 51"
				:title="`${maxlength - trimInput(currentValue).length} characters left`"
			>
				{{ trimInput(currentValue).length }}/{{ maxlength }}
			</span>
			<span
				v-if="minlength && minlength > trimInput(currentValue).length"
				class="text-neutral-600"
				:title="`At least ${minlength} characters required`"
			>
				Need {{ minlength - trimInput(currentValue).length }} chars more
			</span>
		</div>
		<textarea
			:id="id"
			v-model.trim="currentValue"
			class="text-area input-focus border-details bg-neutral-600/40 px-2 py-1 shadow-md rounded w-full"
			:class="{
				'resize-none': saving || autoGrow,
				[classes]: classes
			}"
			type="text"
			:name="id"
			:maxlength="maxlength"
			:placeholder="placeholder"
			:disabled="saving || disabled"
			@input="handleUpdate"
			@keyup="emit('keyup', $event)"
			@change="emit('update', trimInput(currentValue)||null)"
		/>
		<div v-if="saving" class="absolute right-1 bottom-1">
			<DynamicIcon
				icon="Loading"
				class="animate-spin"
				title="Saving changes&hellip;"
			/>
		</div>
		<p v-if="permission && !perms.can(permission)">
			{{ value }}
		</p>
	</div>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import DynamicIcon from "@/components/icon/DynamicIcon";

const emit = defineEmits(["keyup", "update", "update:modelValue"]);
const perms = {can: () => true};
const props = defineProps({
	"label": {
		type: String,
		default: () => null,
	},
	"id": {
		type: String,
		default: () => Math.random().toString(16).slice(2),
	},
	"placeholder": {
		type: String,
		default: () => null,
	},
	"value": {
		type: String,
		default: () => "",
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	"saving": {
		type: Boolean,
		default: () => false,
	},
	"permission": {
		type: String,
		default: () => null,
	},
	"lazy": {
		type: Boolean,
		default: () => true,
	},
	"minlength": {
		type: [String, Number],
		default: () => null,
	},
	"maxlength": {
		type: [String, Number],
		default: () => null,
	},
	// Classes to append to the underlying textarea
	"classes": {
		type: String,
		default: () => null,
	},
	// Disables re-size and makes the chat auto-grow
	"autoGrow": {
		type: Boolean,
		default: () => false,
	},
});

const currentValue = ref(props.value || "");

const showFieldAboveTextarea = computed(()=>{
	if (props.label) return true;
	if (!props.maxlength) return false;
	if (props.minlength && props.minlength > trimInput(currentValue.value).length) return true;
	return props.maxlength - trimInput(currentValue.value).length < 51
});
function trimInput(input) {
	return input.trim().split(/[ \n]+/).join(" ").trim();
}
function handleUpdate(event) {
	if (props.autoGrow) {
		event.target.style.height = "auto";
		event.target.style.height = `${event.target.scrollHeight}px`;
	}

	if (props.lazy) return;

	emit("update:modelValue", trimInput(event.target.value));
	emit("update", trimInput(event.target.value));
}
watch(()=>props.value, (newValue)=>{
	currentValue.value = newValue;
});
</script>

<style scoped>
.text-area {
	@apply disabled:cursor-not-allowed  disabled:opacity-60;
}
</style>
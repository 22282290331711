<script setup>

</script>

<template>
	<button class="search-result-item">
		<slot />
	</button>
</template>

<style scoped>
.search-result-item {
	@apply w-full p-4 cursor-pointer hover:bg-neutral-600/20 hover:text-sky-400 transition-all flex items-center gap-2
	focus-visible:bg-sky-400/20 outline-none;
}
</style>

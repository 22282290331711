import {ChannelType} from "discord-api-types/v10";

/**
 * Returns the DynamicIcon icon name to use for a given Discord Channel by type
 * @param {ChannelType} channelType
 * @returns {string}
 */
export function iconForChannelType(channelType) {
	switch(channelType) {
		case ChannelType.GuildText:
			return "Hash";
		case ChannelType.DM:
			return "Hash";
		case ChannelType.GuildVoice:
			return "Volume";
		case ChannelType.GroupDM:
			return "Group";
		case ChannelType.GuildCategory:
			return "Cases";
		case ChannelType.GuildAnnouncement:
			return "Megaphone";
		case ChannelType.AnnouncementThread:
			return "Megaphone";
		case ChannelType.PublicThread:
			return "Hash";
		case ChannelType.PrivateThread:
			return "Hash";
		case ChannelType.GuildStageVoice:
			return "Volume";
		case ChannelType.GuildDirectory:
			return "Folder";
		case ChannelType.GuildForum:
			return "Messages";
		case ChannelType.GuildMedia:
			return "Image";
		case ChannelType.GuildNews:
			return "Hash";
		case ChannelType.GuildNewsThread:
			return "Hash";
		case ChannelType.GuildPublicThread:
			return "Hash";
		case ChannelType.GuildPrivateThread:
			return "Hash";
		default:
			return "Hash";
	}
}

/**
 * Returns a display name for a given type of Discord channel
 * @param {ChannelType} channelType
 * @returns {string}
 */
export function nameForChannelType(channelType) {
	switch (channelType) {
		case ChannelType.GuildText:
			return "Text";
		case ChannelType.DM:
			return "DM";
		case ChannelType.GuildVoice:
			return "Voice";
		case ChannelType.GroupDM:
			return "Group DM";
		case ChannelType.GuildCategory:
			return "Category";
		case ChannelType.GuildAnnouncement:
			return "Announcement";
		case ChannelType.AnnouncementThread:
			return "Announcement Thread";
		case ChannelType.PublicThread:
			return "Public Thread";
		case ChannelType.PrivateThread:
			return "Private Thread";
		case ChannelType.GuildStageVoice:
			return "Stage";
		case ChannelType.GuildDirectory:
			return "Directory";
		case ChannelType.GuildForum:
			return "Forum";
		case ChannelType.GuildMedia:
			return "Media";
		case ChannelType.GuildNews:
			return "News";
		case ChannelType.GuildNewsThread:
			return "News Thread";
		case ChannelType.GuildPublicThread:
			return "Public Thread";
		case ChannelType.GuildPrivateThread:
			return "Private Thread";
		default:
			return "Discord Channel";
	}
}
<script setup>
import InspectGuildMemberContainer from "@/partials/inspect/guild-member/InspectGuildMemberContainer.vue";
import {ref, watch} from "vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
	"modelValue": {
		type: Boolean,
		default: false,
	},
	"userId": {
		type: String,
		default: () => "11111111111111111111",
	},
	"guildId": {
		type: String,
		default: () => "11111111111111111111",
	},
});

const memberDialog = ref(null);
watch(() => props.modelValue, (newState) => {
	if (!memberDialog.value) return;

	// Correction for user previously closing with Esc key
	if (!newState && !memberDialog.value.open) {
		return emit("update:modelValue", true);
	}

	if (newState) {
		memberDialog.value.showModal();
	} else {
		memberDialog.value.close();
	}
});
</script>

<template>
	<dialog
		ref="memberDialog"
		class="modal h-full w-auto custom-scrollbar"
	>
		<InspectGuildMemberContainer
			v-if="modelValue && userId && guildId"
			:user-id="userId"
			:guild-id="guildId"
			@close="emit('update:modelValue', false)"
		/>
	</dialog>
</template>

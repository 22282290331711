<template>
	<g>
		<rect
			x="2.99634"
			y="2.99625"
			width="18.0075"
			height="18.0075"
			rx="4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<rect
			x="8.99878"
			y="8.99875"
			width="6.0025"
			height="6.0025"
			rx="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconBoxUnknown"
};
</script>

<template>
	<g>
		<path
			d="M14.1931 5.5809C16.525 7.91272 16.525 11.6933 14.1931 14.0252C11.8613 16.357 8.08069 16.357 5.74887 14.0252C3.41704 11.6933 3.41704 7.91272 5.74887 5.5809C8.08069 3.24908 11.8613 3.24908 14.1931 5.5809"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.1504 14.0605L20.0004 19.9905"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconSearch"
};
</script>
<template>
	<g>
		<path
			d="M18.5024 11.9983C18.5024 12.2745 18.2784 12.4985 18.0022 12.4985C17.7259 12.4985 17.502 12.2745 17.502 11.9983C17.502 11.722 17.7259 11.498 18.0022 11.498C18.2784 11.498 18.5024 11.722 18.5024 11.9983"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.5004 11.9983C12.5004 12.2745 12.2765 12.4985 12.0002 12.4985C11.724 12.4985 11.5 12.2745 11.5 11.9983C11.5 11.722 11.724 11.498 12.0002 11.498C12.2765 11.498 12.5004 11.722 12.5004 11.9983"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.49749 11.9983C6.49749 12.2745 6.27354 12.4985 5.99728 12.4985C5.72102 12.4985 5.49707 12.2745 5.49707 11.9983C5.49707 11.722 5.72102 11.498 5.99728 11.498C6.27354 11.498 6.49749 11.722 6.49749 11.9983"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconDotsHorizontal"
};
</script>
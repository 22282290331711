<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20 12.5209C20 16.2956 15.3552 19.0996 13.1401 20.2255C12.4254 20.5908 11.579 20.5916 10.8636 20.2274C8.65012 19.1027 4 16.2918 4 12.5209V7.06934C4.00735 6.55849 4.39423 6.1332 4.9021 6.07766C7.02402 5.91642 9.05835 5.16408 10.7746 3.9059C11.5019 3.36466 12.4981 3.36466 13.2254 3.9059C14.9416 5.16408 16.9759 5.91642 19.0979 6.07766C19.6057 6.13318 19.9926 6.55848 20 7.06934V12.5209Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.9007 17.8015L16.441 16.882C16.0175 16.035 15.1519 15.5 14.2049 15.5H9.79508C8.84814 15.5 7.98248 16.035 7.559 16.882L7.10162 17.7968"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9 9.5V11.5C9 12.0523 9.53726 12.5 10.2 12.5H13.8C14.4627 12.5 15 12.0523 15 11.5V9.5C15 8.94772 14.4627 8.5 13.8 8.5H10.2C9.53726 8.5 9 8.94772 9 9.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.5 8.254V7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconShieldRobot"
};
</script>
/**
 * This file contains exclusively Archivian Admin/Dev related permissions.
 *
 * It SHOULD NOT contain permissions for:
 * - official Archivian staff (new perms file needed for that)
 * - owners of custom bot instances
 */
export const Bots = {
    /**
     * For the permission to register a new bot into the system
     */
    CREATE() {
        return "admin.bots.create";
    },
    /**
     * The ability to list all bots IDs (Discord) registered in the system
     */
    READ_USERS() {
        return "admin.bots.users.read";
    },
};

<script>
export default {
	name: "IconInfo"
}
</script>

<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 17V12H11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.749 8C11.611 8 11.499 8.112 11.5 8.25C11.5 8.388 11.612 8.5 11.75 8.5C11.888 8.5 12 8.388 12 8.25C12 8.112 11.888 8 11.749 8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

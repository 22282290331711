<template>
	<g>
		<path
			d="M11 5.9963H21.0042"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.0022 18.0011V5.99609"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.92697 18.0014V9.99805"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.49609 9.99826H10.499"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconTextSize"
};
</script>
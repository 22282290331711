<template>
	<g>
		<path
			d="M1.99609 13.0005L4.5477 8.74789C4.84136 8.25844 5.26851 7.86288 5.77904 7.60761L6.36447 7.3149C6.78121 7.10653 7.24074 6.99805 7.70667 6.99805H10.8445"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.2307 14.1395C14.1973 14.4538 15.2396 14.4467 16.2019 14.1195L18.8029 16.7104C18.9897 16.8945 19.2409 16.9985 19.5032 17.0005H21.0036C21.5555 16.9989 22.0024 16.552 22.0041 16.0002V14.4938C22.0041 14.2272 21.8976 13.9716 21.7083 13.7837L19.113 11.2084C19.2784 10.7423 19.3662 10.2523 19.3731 9.75786C19.3938 8.5146 18.9194 7.31411 18.0544 6.42092C17.1893 5.52773 16.0046 5.01513 14.7613 4.99609C12.1892 4.99544 10.0812 7.037 9.99954 9.60779C9.98934 10.2901 10.1294 10.9664 10.4097 11.5885"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3.99707 20.0021L9.76134 17.9193C10.5659 17.6285 11.2561 17.0877 11.7307 16.3759L14.7248 11.8861C15.1603 11.233 15.0743 10.3634 14.5192 9.8084V9.8084C13.8764 9.16552 12.8341 9.16542 12.1911 9.80817L9.99957 11.9988H7.99874"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconHandKey"
};
</script>
<template>
	<g>
		<path
			d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 7V12"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.534 8.4668C17.486 10.4188 17.486 13.5838 15.534 15.5358C13.582 17.4878 10.417 17.4878 8.46498 15.5358C6.51298 13.5838 6.51298 10.4188 8.46498 8.4668"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconPower"
};
</script>
<template>
	<g>
		<path
			d="M17.0018 17.0017L12.9131 12.875"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.547 3.99609H19.0033C20.1084 3.99609 21.0042 4.8919 21.0042 5.99693V19.0023C21.0042 20.1074 20.1084 21.0032 19.0033 21.0032H5.9979C4.89287 21.0032 3.99707 20.1074 3.99707 19.0023V11.4702"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.30762 6.62716H11.8099"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.30762 9.62911H10.2022"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="9.08063"
			cy="8.1275"
			r="6.08453"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconAudit"
};
</script>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4 14.5H10V20.5H4V14.5Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.5 21V21C15.567 21 14 19.433 14 17.5V17.5C14 15.567 15.567 14 17.5 14V14C19.433 14 21 15.567 21 17.5V17.5C21 19.433 19.433 21 17.5 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 3L7.5 10H16.5L12 3Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconMisc"
};
</script>
<script setup>
import SwitchBox from "@/components/form/SwitchBox.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {useRouter} from "vue-router";

defineEmits(["update:enabled", "update:banned", "update:appeal"]);
defineProps({
	enabled: Boolean,
	banned: Boolean,
	appeal: Boolean,
});
const router = useRouter();
const canGoBack = window.history.length > 1;
</script>

<template>
	<div class="bg-neutral-950 rounded p-4 my-2 flex items-center">
		<h2 class="text-lg mr-2">
			Demo Controls
		</h2>
		<div class="flex items-center grow justify-center divide-x divide-neutral-700">
			<SwitchBox
				class="px-2"
				label="Feature Enabled"
				:enabled="enabled"
				@toggle="$emit('update:enabled', $event)"
			/>
			<SwitchBox
				class="px-2"
				label="User is banned"
				:disabled="!enabled"
				:enabled="enabled && banned"
				@toggle="$emit('update:banned', $event)"
			/>
			<SwitchBox
				class="px-2"
				label="Has existing appeal"
				:disabled="!enabled || !banned"
				:enabled="enabled && appeal && banned"
				@toggle="$emit('update:appeal', $event)"
			/>
		</div>

		<BasicButton
			v-if="canGoBack"
			label="Go back"
			type="light"
			@click="router.go(-1)"
		/>
		<h2 v-else class="text-lg mr-2 opacity-0 select-none">
			<!-- Else invisible container for some controls centering -->
			Demo Controls
		</h2>
	</div>
</template>

<style scoped>

</style>
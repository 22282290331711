<script setup>
import FancyLoader from "@/components/misc/FancyLoader.vue";
import GuildAppealInspectChat from "@/views/guild/case-system/appeals/chat/GuildAppealInspectChat.vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import InspectAppeal from "@/partials/inspect/appeal/InspectAppeal.vue";
import CreateAppealVerdict from "@/partials/inspect/appeal/CreateAppealVerdict.vue";
import TabbedListInspect from "@/partials/layouts/TabbedListInspect.vue";
import GuildAppealsInspectEmpty from "@/views/guild/case-system/appeals/GuildAppealsInspectEmpty.vue";
import {computed, onBeforeUnmount, onMounted, ref, provide} from "vue";
import {UI} from "@/storage/UICache";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import AppealList from "@/partials/inspect/AppealList.vue";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import {handleErr} from "@/script/convert";
import CaseService from "@/services/CaseService";
import {GUILD_OPS, hook, unhook} from "@/ws/utils";
import IndexedDBStore from "@/storage/IndexedDB";
import InspectMemberDialog from "@/partials/inspect/guild-member/InspectMemberDialog.vue";

provide("isPersonal", false);
const router = useRouter();
const route = useRoute();
const ui = UI();
const idbStore = IndexedDBStore();
const appeals = CaseAppealBrowser();
const memberModal = ref(false);
const initialized = ref(false);
const error = ref({
	label: "",
	texts: [],
});
const panels = computed(() => {
	return {
		none: !route.params.appealId, // no resource is inferred ofc
		appealOnly: !!route.params.appealId && !route.params.resource,
		both: !!route.params.appealId && !!route.params.resource
	}
});

const handlers = {
	close: () => {
		appeals.currentItem = null;
		router.push({
			name: route.name,
			params: {
				guildId: route.params.guildId
			},
			query: route.query,
		});
	},
	closeResource: () => {
		appeals.currentResource = null;
		router.push({
			name: route.name,
			params: {
				guildId: route.params.guildId,
				appealId: route.params.appealId,
			},
			query: route.query,
		});
	},
	inspect: () => {
		memberModal.value = !memberModal.value;
	},
	/**
	 * Fetches an appeal by its DB ID
	 * @param {string} id
	 * @returns {Promise<null|Object>} `null` if not found
	 */
	fetchItem: async (id) => {
		const result = await CaseService.fetchGuildAppeal(
			route.params.guildId,
			id,
		);
		if (!result.appeal) return null;
		await idbStore.setUsers(result.users);
		return result.appeal;
	},
	/**
	 * Sets a non-verdict status of this appeal
	 * @param {string} newStatus
	 * @returns {Promise<void>}
	 */
	setStatus: async (newStatus) => {
		const id = appeals.currentId;
		appeals.addLoading("appealStatus", id);
		try {
			await CaseService.setState(appeals.currentItem.guildId, id, newStatus);
		} catch(e) {
			error.value = handleErr(e?.body ?? e, "Could not change status");
		} finally {
			appeals.removeLoading("appealStatus", id);
		}
	},
	/**
	 * Assign or un-assign a staff member from this appeal
	 * @param {UserID} userId
	 * @param {boolean} unassign If true, will unassign the input user instead of assign
	 * @returns {Promise<boolean>} Success
	 */
	setAssignedStaff: async (userId, unassign) => {
		const id = appeals.currentId;
		appeals.addLoading("appealAssigned", id);

		try {
			if (unassign) {
				await CaseService.unAssignStaff(appeals.currentItem.guildId, id, userId);
			} else {
				await CaseService.assignStaff(appeals.currentItem.guildId, id, userId);
			}
		} catch(e) {
			error.value = handleErr(e?.body ?? e, "Could change assignment");
			return false;
		} finally {
			appeals.removeLoading("appealAssigned", id);
		}

		return true;
	},
	/**
	 * Handles staff creating a verdict for this appeal
	 * @returns {Promise<*>}
	 */
	createVerdict: async (statement, verdict, extraAction) => {
		if (verdict && extraAction) return handlers.archiveCase(statement);

		const id = appeals.currentId;
		appeals.addLoading("appealStatus", id);

		const {status, body} = await CaseService.createAppealVerdict(
			route.params.guildId,
			id,
			verdict ? "approved" : "rejected",
			statement,
			{denyFurther: extraAction && !verdict, withStatus: true},
		);

		if (status!==200) {
			error.value = handleErr(body, "Could create verdict");
		}

		appeals.removeLoading("appealStatus", id);
	},
	/**
	 * Archives the original case, which implies that the appeal was also approved
	 * @param {string} statement
	 * @returns {Promise<boolean>} success state
	 */
	async archiveCase(statement) {
		const id = appeals.currentId;
		appeals.addLoading("appealStatus", id);

		const {body, status} = await CaseService.archiveCase(
			route.params.guildId,
			appeals.currentItem.case.sid,
			statement,
			true,
			{withStatus: true},
		);

		if (status !== 200) {
			error.value = handleErr(body, "Could not archive case");
		}

		appeals.removeLoading("appealStatus", id);

		return true;
	},
	/**
	 * Fetches and populates the chat messages
	 * @returns {Promise<void>}
	 */
	async fetchMessages(guildId, appealId) {
		const messageRecords = await CaseService.fetchChatMessages(guildId, appealId);
		appeals.currentResource = messageRecords.messages;
	},
	/**
	 * Sends a message to the chat
	 * @param {string} textContent
	 */
	async sendMessage(textContent) {
		if (!appeals.currentItem) return;

		const payload = {
			textContent,
			id: Math.random().toString(36).slice(2)+Math.random().toString(36).slice(2)+Math.random().toString(36).slice(2),
		};
		await CaseService.sendChatMessage(appeals.currentItem.guildId, route.params.appealId, payload);
	},
	/**
	 * Handles an incoming Appeals chat message
	 * @param {NewAppealChatMessage} payload
	 */
	async handleIncomingMessage(payload) {
		const id = appeals.currentId;
		if (payload.appealId !== id) return;
		if (payload.guildId !== appeals.currentItem.guildId) return;
		if (payload.message.t !== "message") return; // TODO Feature for events too

		await idbStore.setUsers(payload.users);
		if (appeals.currentResource) appeals.currentResource.push(payload.message);
	},
	/**
	 * Handles an appeal being updated
	 * @param {{_id: string}} event
	 * @returns {Promise<*>}
	 */
	handleAppealUpdated: async ({_id}) => {
		// Ignore if ID is not the one we're inspecting
		if (appeals.currentId !== _id) return;

		// Update appeal
		const theAppeal = await handlers.fetchItem(_id);
		if (!theAppeal || appeals.currentId !== _id) return;
		appeals.currentItem = theAppeal;
	},
};

onMounted(async () => {
	/**
	 * Messages are loaded when you open the message panel.
	 * @type {IAppealMessage[]}
	 */
	appeals.currentResource = [];

	if (route.params.appealId) {
		appeals.currentItem = await handlers.fetchItem(route.params.appealId);
	}

	hook([
		GUILD_OPS.APPEAL_UPDATE,
	], handlers.handleAppealUpdated);
	hook([
		GUILD_OPS.NEW_APPEAL_CHAT_MESSAGE,
	], handlers.handleIncomingMessage);

	initialized.value = true;
});
onBeforeRouteUpdate(async (to, from, next) => {
	if (to.name !== route.name) return next();

	if (to.params.appealId && to.params.appealId !== from.params.appealId) {
		const theAppeal = await handlers.fetchItem(to.params.appealId);
		if (!theAppeal) return next(false);
		appeals.currentResource = [];
		appeals.currentItem = theAppeal;

		// Scroll to the top
		const main = document.getElementById("main");
		if (main) main.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	} else if (!to.params.appealId) {
		appeals.currentItem = null;
		appeals.currentResource = [];
	}

	return next();
});
onBeforeUnmount(()=>{
	appeals.$reset();

	unhook([
		GUILD_OPS.APPEAL_UPDATE,
	], handlers.handleAppealUpdated);
	unhook([
		GUILD_OPS.NEW_APPEAL_CHAT_MESSAGE,
	], handlers.handleIncomingMessage);
});
</script>

<template>
	<TabbedListInspect
		class="self-stretch min-h-0 min-w-0 flex"
		:divider-classes="{
			'hidden xl:block': panels.appealOnly,
			'hidden 2xl:block': panels.both,
		}"
	>
		<template #list>
			<div v-if="!initialized" class="flex justify-center items-center h-full">
				<FancyLoader label="Fetching latest appeals&hellip;" />
			</div>
			<AppealList
				v-else-if="initialized && !error.label"
				:id="ui.setAnchor('mainList')"
				tabindex="-1"
				:current-appeal="appeals.currentItem"
				:handlers="handlers"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.appealOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both
				}"
				class="overflow-y-auto w-xs flex-col"
			/>
			<ErrorCallout
				v-else-if="error.label"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.appealOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both,
				}"
				:label="error.label"
				:texts="error.texts"
				class="m-4 w-max"
			/>
		</template>

		<template #default>
			<InspectMemberDialog
				v-model="memberModal"
				:user-id="appeals.currentItem?.userId"
				:guild-id="appeals.currentItem?.guildId"
			/>

			<div class="overflow-y-auto max-h-full h-full">
				<!-- TODO We should get to know somehow if there are cases the user can view, for the has-cases -->
				<GuildAppealsInspectEmpty
					v-if="!appeals.currentItem"
					:id="ui.setAnchor('main')"
					:has-records="false"
					tabindex="-1"
					by-label="Case by"
					has-records-title="Inspect an Appeal"
					has-records-description="On the left side you can see a list of all appeals. Click on one to inspect it."
					no-records-title="Appeal Overview"
					no-records-description="On this page you will be able to find new appeals in the future. From there you will be able to click on them to inspect and perform additional actions."
				/>

				<div
					v-else
					class="p-4 h-full"
					:class="{'grid lg:grid-cols-2 gap-4': panels.both}"
				>
					<!-- Main inspect window -->
					<InspectAppeal
						:id="ui.setAnchor('main')"
						tabindex="-1"
						:appeal="appeals.currentItem"
						:set-status="handlers.setStatus"
						:set-assigned-staff="handlers.setAssignedStaff"
						:error="error"
						class="border border-neutral-700"
						:class="{'hidden lg:block': panels.both}"
						@close="handlers.close"
						@inspect="handlers.inspect"
					/>

					<template v-if="panels.both">
						<!-- Other side-panels (resources) -->
						<CreateAppealVerdict
							v-if="route.params?.resource==='verdict' && route.params?.resourceId === 'new'"
							:id="ui.setAnchor('mainSecondary')"
							tabindex="-1"
							class="border border-neutral-700"
							:appeal="appeals.currentItem"
							:create-verdict="handlers.createVerdict"
							:has-verdict="!!appeals.currentItem.verdict.date"
							:error="error"
							@close="handlers.closeResource"
						/>
						<!-- TODO: ="handlers" here must be updated to contain all necessary handlers, e.g. send message -->
						<GuildAppealInspectChat
							v-else-if="route.params?.resource==='chat'"
							:id="ui.setAnchor('mainSecondary')"
							tabindex="-1"
							class="border border-neutral-700"
							:handlers="handlers"
							:messages="appeals.currentResource ?? []"
							:appeal="appeals.currentItem"
							@close="handlers.closeResource"
						/>
					</template>
				</div>
			</div>
		</template>
	</TabbedListInspect>
</template>

"use strict";
export default class Collection extends Map {
    baseObject;
    limit;
    _keyArray;
    /**
     * Construct a Collection
     * @param baseObject The base class for all items
     * @param [limit] Max number of items to hold
     */
    constructor(baseObject, limit) {
        super();
        this.baseObject = baseObject;
        this.limit = limit;
        this._keyArray = [];
    }
    /**
     * Alias of Map.size
     */
    get length() {
        return this.size;
    }
    /**
     * Returns true if all elements satisfy the condition
     * @param func A function that takes an object and returns true or false
     * @returns {} Whether or not all elements satisfied the condition
     */
    every(func) {
        for (const item of this.values()) {
            if (!func(item)) {
                return false;
            }
        }
        return true;
    }
    /**
     * Return all the objects that make the function evaluate true
     * @param func A function that takes an object and returns true if it matches
     * @returns {} An array containing all the objects that matched
     */
    filter(func) {
        const arr = [];
        for (const item of this.values()) {
            if (func(item)) {
                arr.push(item);
            }
        }
        return arr;
    }
    /**
     * Return the first object to make the function evaluate true
     * @param func A function that takes an object and returns true if it matches
     * @returns {} The first matching object, or undefined if no match
     */
    find(func) {
        for (const item of this.values()) {
            if (func(item)) {
                return item;
            }
        }
        return undefined;
    }
    /**
     * Return an array with the results of applying the given function to each element.
     * @param {Function} func A function that takes an object and returns something
     */
    map(func) {
        const arr = [];
        for (const item of this.values()) {
            arr.push(func(item));
        }
        return arr;
    }
    /**
     * Get a random object from the Collection
     * @returns {} The random object, or undefined if there is no match
     */
    random() {
        const index = Math.floor(Math.random() * this.size);
        const iter = this.values();
        for (let i = 0; i < index; ++i) {
            iter.next();
        }
        return iter.next().value;
    }
    /**
     * Returns a value resulting from applying a function to every element of the collection
     * @param {Function} func A function that takes the previous value and the next item and returns a new value
     * @param {any} [initialValue] The initial value passed to the function
     * @returns {any} The final result
     */
    reduce(func, initialValue) {
        const iter = this.values();
        let val = null;
        let result = initialValue === undefined
            ? iter.next().value
            : initialValue;
        while ((val = iter.next().value) !== undefined) {
            result = func(result, val);
        }
        return result;
    }
    /**
     * Returns true if at least one element satisfies the condition
     * @param func A function that takes an object and returns true or false
     * @returns {} Whether or not at least one element satisfied the condition
     */
    some(func) {
        for (const item of this.values()) {
            if (func(item)) {
                return true;
            }
        }
        return false;
    }
    toString() {
        return `[Collection<${this.baseObject.name}>]`;
    }
    /**
     * Converts the map to a POJO.
     * Also attempts to call `.toObject()` on the value, if it has it available
     * and can be executed without an error.
     *
     * <b>Quirk</b>: If it has .toObject() function, but calling it fails,
     * it will disable using this method for the remainder of the loop.
     */
    toJSON() {
        const json = {};
        let useToObject = true;
        for (const [key, value] of this.entries()) {
            // @ts-ignore - We know it might not exist, hence the try/catch and if checks
            if (useToObject && value.hasOwnProperty("toObject") && typeof (value.toObject) === "function") {
                try {
                    // @ts-ignore - We know it might not exist, hence the try/catch and if checks
                    json[key] = value.toObject();
                    continue;
                }
                catch (_) {
                    useToObject = false;
                }
            }
            json[key] = value;
        }
        return json;
    }
    /**
     * Sets a map value
     * @param key
     * @param val
     */
    set(key, val) {
        // this._array = null;
        this._keyArray = null;
        return super.set(key, val);
    }
    /**
     * Deletes a map value
     * @param key
     */
    delete(key) {
        // this._array = null;
        this._keyArray = null;
        return super.delete(key);
    }
    /**
     * Obtains the first key(s) in this collection.
     * @param [amount] Amount of keys to obtain from the beginning
     * @returns {} A single key if no amount is provided or an array of keys, starting from the end if
     * amount is negative
     */
    firstKey(amount) {
        if (typeof amount === "undefined")
            return this.keys().next().value;
        if (amount < 0)
            return this.lastKey(amount * -1);
        amount = Math.min(this.size, amount);
        const iter = this.keys();
        return Array.from({ length: amount }, () => iter.next().value);
    }
    /**
     * Returns the first item in the collection
     */
    first() {
        return this.values().next().value;
    }
    /**
     * Obtains the last key(s) in this collection. This relies on Collection#keyArray, and thus the caching
     * mechanism applies here as well.
     * @param [amount] Amount of keys to obtain from the end
     * @returns {} A single key if no amount is provided or an array of keys, starting from the start if
     * amount is negative
     */
    lastKey(amount) {
        const arr = this.keyArray();
        if (amount === undefined) {
            return arr.at(-1);
        }
        if (amount < 0)
            return this.firstKey(amount * -1);
        if (!amount)
            return [];
        return arr.slice(-amount);
    }
    /**
     * Returns the last item in the collection
     */
    last() {
        return this.get(this.lastKey());
    }
    /**
     * Creates an ordered array of the keys in this collection, and caches it internally.
     * The array will only be reconstructed if an item is added to or removed from the collection,
     * or if you change the length of the array itself.
     * If you don't want this caching behavior, use `[...collection.keys()]` or
     * `Array.from(collection.keys())` instead.
     */
    keyArray() {
        if (!this._keyArray || this._keyArray.length !== this.size)
            this._keyArray = [...this.keys()];
        return this._keyArray;
    }
}

<template>
	<div
		role="alert"
		class="error-callout rounded-md bg-red-800/30 border border-red-400 border-t-red-300 p-4 text-red-200 shadow-inner shadow-red-400/20"
	>
		<div class="flex gap-4">
			<DynamicIcon
				icon="ErrorFilled"
				class="text-red-200 shrink-0"
				stroke="0"
				size="24"
			/>
			<div class="flex flex-col gap-2">
				<h4 class="text-red-200 text-sm leading-5 font-semibold">
					{{ label }}
				</h4>
				<p v-if="!Array.isArray(texts) || texts.length === 1" class="text-sm">
					{{ Array.isArray(texts) ? texts[0] : texts }}
				</p>
				<ul v-else-if="texts">
					<li v-for="text in texts" :key="text">
						{{ text }}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

import DynamicIcon from "@/components/icon/DynamicIcon.vue";

export default {
	name: "ErrorCallout",
	components: {DynamicIcon},
	props: {
		"label": {
			type: String,
			required: true,
		},
		"texts": {
			type: [Array, String],
			default: () => null,
		}
	},
};
</script>

<style scoped>
li {
	list-style: disc;
}

.error-callout {
	box-shadow: inset 0 -30px 20px -20px rgba(121, 8, 8, 0.3);
}
</style>
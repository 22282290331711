<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.5 18.998V7.503C11.5 6.813 11.145 6.172 10.56 5.807L6.56 3.307C5.228 2.475 3.5 3.432 3.5 5.003V16.497C3.5 17.187 3.855 17.828 4.44 18.193L8.44 20.693C9.772 21.526 11.5 20.568 11.5 18.998Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21.5 11H15.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.5 9L15.5 11L17.5 13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.5 19H15.5C16.605 19 17.5 18.105 17.5 17V16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.5 6V5C17.5 3.895 16.605 3 15.5 3H5.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconExit"
};
</script>

const normalStuff = {
    CASES: {
        /**
         * Access to read cases on self
         */
        READ() {
            return "personal.cases.read";
        },
    },
    APPEALS: {
        /**
         * Access to read appeals they have created themselves
         */
        READ() {
            return "personal.cases.appeals.read";
        },
        /**
         * Access to create appeals
         */
        CREATE() {
            return "personal.cases.appeals.create";
        }
    },
    GUILDS: {
        /**
         * Access to read guilds they are staff in
         */
        READ() {
            return "personal.guilds.read";
        },
        /**
         * Access to add Archivian to new guilds
         * @constructor
         */
        CREATE() {
            return "personal.guilds.create";
        },
    },
};
/**
 * Related to experimental stuff
 */
const EXPERIMENTS = {
    /**
     * Grants access to any and all experiment / experimental features
     * @constructor
     */
    ANY() {
        return "personal.experiments.**";
    }
};
/**
 * Returns a default list of permissions a user should have
 */
export const DEFAULT = () => [
    normalStuff.CASES.READ(),
    normalStuff.APPEALS.READ(),
    normalStuff.APPEALS.CREATE(),
    normalStuff.GUILDS.READ(),
    normalStuff.GUILDS.CREATE(),
];

<script setup>
const props = defineProps({
	"items": {
		type: Array,
		/**
		 * The object value is any arbitrary data, it can be whatever you want.
		 * However, we assume you will have max up to 12 columns, and it automatically
		 * makes a column per key.
		 */
		default: () => [
			{label: "Item One", created: true, updated: true, verdict: false},
			{type: "row", label: "Row One"},
			{label: "Item Two", created: false, updated: false, verdict: true},
		]
	},
	"headers": {
		type: Array,
		/**
		 * The `key` will target a property in the `items` object, and display the value in that column.
		 * Based on the default, you will see the key is "label". This means that `items[N].label` will be shown in this column.
		 */
		default: () => [
			{label: "Greeting", key: "label", columns: 2},
			{label: "Created", key: "created", width: "10rem"},
			{label: "Updated", key: "updated", clickableCell: true},
			{label: "Concluded", key: "verdict"}
		],
		validator(value) {
			// Each header must have at least a "key" property with a string value
			return value.every(header => typeof(header.key)==="string");
		},
	},
	// Add a horizontal divider after each row
	"separator": {
		type: Boolean,
		default: () => false,
	},
	// When enabled, all columns will auto-adjust instead of being fixed
	"autoWidth": {
		type: Boolean,
		default: () => false,
	},
	// List of CSS classes for all body rows
	"rowClasses": {
		type: Array,
		default: () => []
	},
	// List of CSS classes for the header row
	"headerClasses": {
		type: Array,
		default: () => []
	},
	// Make the header always stay at the top
	"stickyHeader": {
		type: Boolean,
		default: () => false,
	},
});

const getStyle = (index) => {
	if (props.headers[index].width) return {width: props.headers[index].width};
	return {};
}
</script>

<template>
	<table
		class="DataTable border-collapse min-w-full divide-y divide-neutral-700 w-full"
		:class="{
			'table-fixed': !autoWidth
		}"
	>
		<thead>
			<tr
				:class="{
					[headerClasses.join(' ')]: headerClasses,
					'sticky top-0 backdrop-blur-2xl': stickyHeader,
				}"
			>
				<th
					v-for="(header,i) in headers"
					:key="header.key"
					class="whitespace-nowrap text-left py-2"
					:class="{
						[header.class]: header.class,
						[header.colClass]: header.colClass,
					}"
					:style="getStyle(i)"
				>
					<!-- E.g.: <template #[`header.created`]="{ header }"}> ... </template> -->
					<slot :name="`header.${header.key}`" :header="header">
						<slot name="header" :header="header">
							<p v-if="header.label">
								{{ header.label }}
							</p>
						</slot>
					</slot>
				</th>
			</tr>
		</thead>
		<tbody
			:class="{
				'divide-y divide-neutral-700/50': separator
			}"
		>
			<tr
				v-for="(item,i) in items"
				:key="i"
				:class="rowClasses"
			>
				<template v-if="item.type !== 'row'">
					<td
						v-for="(header,headerIndex) in headers"
						:key="header.key"
						class="whitespace-nowrap"
						:class="{
							'cursor-pointer': header.clickableCell,
							[header.colClass]: header.colClass,
						}"
						:style="getStyle(headerIndex)"
					>
						<slot
							:name="`item.${header.key}`"
							:item="item"
							:index="i"
							:column="header.key"
						>
							<!-- E.g.: <template #item="{ item, column }"> <input type="checkbox" :checked="item[column]"> </template> -->
							<slot
								name="item"
								:item="item"
								:index="i"
								:column="header"
							>
								{{ item[header.key] }}
							</slot>
						</slot>
					</td>
				</template>
				<template v-else>
					<td :colspan="headers.length">
						<slot
							name="custom-row"
							:item="item"
							:index="i"
						>
							<!-- E.g.: <template #item="{ item, column }"> <input type="checkbox" :checked="item[column]"> </template> -->
							<slot
								name="custom-item"
								:item="item"
								:index="i"
							>
								{{ item?.label || "custom row" }}
							</slot>
						</slot>
					</td>
				</template>
			</tr>
		</tbody>
	</table>
</template>

<template>
	<g>
		<path
			d="M8 14L12 18L16 14"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16 10L12 6L8 10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconChevronsVertical",
};
</script>
<template>
	<g>
		<path
			d="M5 13H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 16H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16 19.316V19.5C16 20.3284 15.3284 21 14.5 21H3.5C2.67157 21 2 20.3284 2 19.5V4.5C2 3.67157 2.67157 3 3.5 3H14.5C15.3284 3 16 3.67157 16 4.5V14"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16 9H17.2918C17.7575 9 18.2169 9.10843 18.6334 9.31671L19.2874 9.64371C19.7544 9.87719 20.1523 10.2285 20.4419 10.6629L22 13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="6.5"
			cy="7.5"
			r="1.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16 14L16.0019 13.997L13.6229 11.4763C13.3413 11.1778 12.9508 11.0061 12.5405 11.0002C12.1302 10.9942 11.735 11.1546 11.4448 11.4448V11.4448C10.9335 11.9561 10.8536 12.757 11.254 13.3592L14.2411 17.8526C14.7341 18.5943 15.4605 19.1502 16.3052 19.4322L21 21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.5 14H16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconAppeal"
};
</script>

<template>
	<g>
		<path
			d="M8 8L16 16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16 8L8 16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconClose"
};
</script>
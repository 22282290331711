<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import CaseEntryHeaderView from "@/partials/inspect/case/inspectEntry/CaseEntryHeaderView.vue";
import CaseEntryMainView from "@/partials/inspect/case/inspectEntry/CaseEntryMainView.vue";
import {computed, ref, watch} from "vue";
import CaseEntryFooter from "@/partials/inspect/case/inspectEntry/CaseEntryFooter.vue";
import {flagsToObject} from "@/script/convert";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const props = defineProps({
	"entry": {
		type: Object,
		required: true,
	},
	"theCase": {
		type: Object,
		required: true,
	},
	"handlers": {
		type: Object,
		default: () => ({}),
	},
	"allowArchive": {
		type: Boolean,
		default: false,
	},
	"allowDelete": {
		type: Boolean,
		default: false,
	},
});

function calcVisibility(p) {
	const flags = flagsToObject("entry", p.entry.flags, {system: p.entry.system});
	if (flags.visibleToTarget && !!p.entry.archived?.by) flags.visibleToTarget = false;
	return flags.visibleToTarget;
}

/**
 * @type {ComputedRef<EntryFlags & {archived:boolean}>}
 */
const pills = computed(() => {
	const flags = flagsToObject("entry", props.entry.flags, {system: props.entry.system});

	// Archived entry is hidden from user. Display as hidden.
	if (flags.visibleToTarget && !!props.entry.archived?.by) flags.visibleToTarget = false;

	return {
		...flags,
		archived: !!props.entry.archived?.by,
	};
});

const cases = CaseAppealBrowser();
const newVisibility = ref(pills.value.visibleToTarget);
const newContent = ref(props.entry.content);
watch(() => props?.entry?.content, (newVal) => {
	if (!newVal) return null;

	newContent.value = newVal;
	newVisibility.value = calcVisibility(props);
});

function saveChanges() {
	if (newContent.value === props.entry.content && newVisibility.value === pills.value.visibleToTarget) {
		// No changes
		return;
	}

	return props.handlers.editEntry({
		content: newContent.value,
		visible: newVisibility.value,
	});
}
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<CaseEntryHeaderView
				:close="handlers.closeResource"
				:created-at="entry?.createdAt"
				:entry-sid="entry?.sid"
				:creator-id="entry?.creatorId"
				:pills="pills"
			/>
		</template>

		<template #main>
			<CaseEntryMainView
				v-model:entry-content="newContent"
				v-model:visibility-enabled="newVisibility"
				:pills="pills"
				:creator-id="entry.creatorId"
				:last-editor-id="entry.lastEditorId"
				:case-creator-id="theCase.creatorId"
				:case-type="theCase.caseType"
				:target-user-id="theCase.targetId"
				:content="entry.content"
				:is-editing="cases.editing.entry"
				:entry-sid="entry?.sid"
			/>
		</template>

		<template #footer>
			<CaseEntryFooter
				:case-type="theCase.caseType"
				:pills="pills"
				:close="handlers.closeResource"
				:case-creator-id="theCase.creatorId"
				:creator-id="entry.creatorId"
				:handle-save="saveChanges"
				:allow-archive="allowArchive"
				:allow-delete="allowDelete"
				:is-editing="cases.editing.entry"
				:entry-sid="entry?.sid"
				@edit="cases.editing.entry = $event"
			/>
		</template>
	</InspectResourceLayout>
</template>
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M8.89393 19.0739L3.46393 9.42191C2.12693 7.04591 3.84393 4.10791 6.57093 4.10791H17.4289C20.1569 4.10791 21.8729 7.04391 20.5359 9.42191L15.1059 19.0739C13.7459 21.4979 10.2569 21.4979 8.89393 19.0739Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.999 11.521V8.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.9968 15.13C11.9078 15.13 11.8348 15.203 11.8368 15.292C11.8368 15.381 11.9098 15.454 11.9988 15.454C12.0878 15.454 12.1588 15.381 12.1588 15.292C12.1588 15.203 12.0878 15.13 11.9968 15.13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconAlarm"
};
</script>
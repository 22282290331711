<script setup>
import GenericSearch from "@/components/form/search/GenericSearch.vue";
import {computed, ref} from "vue";
import GenericSearchResult from "@/components/form/search/GenericSearchResult.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {debounce} from "@/script/convert";
import ToolTip from "@/components/badges/ToolTip.vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import backend from "@/api/backend";
import {isValidSnowflake} from "archivian-utils";
import IndexedDBStore from "@/storage/IndexedDB";


const props = defineProps({
	"id": {
		type: String,
		default: () => null,
	},
	"guildId": {
		type: String,
		required: true,
	},
	"modelValue": {
		type: [String, Number, Object, Array],
		default: () => null,
	},
	"maxResults": {
		type: Number,
		default: () => 4,
	},
	"excludeIds": {
		type: Array,
		default: () => [],
	},
	"autoReset": {
		type: Boolean,
		default: () => true,
	},
	"simple": {
		type: Boolean,
		default: () => false,
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	// The v-model will give you an ID instead of an object
	"bindUsingId": {
		type: Boolean,
		default: false,
	},
});
const idbStore = IndexedDBStore();
const tooltip = "The search does not use Discord directly, so results may be limited. " +
	"If you enter a Discord user ID we will search in Discord.";
const emit = defineEmits(["update:modelValue"]);
const searchValue = ref("");
const results = ref([]);
const loading = ref(false);

const debouncedSearch = debounce(doSearch, 400, () => {
	loading.value = true;
});

async function doSearch(event) {
	results.value = [];
	loading.value = true;

	if (!event?.target?.value) return loading.value = false;

	const isId = isValidSnowflake(event.target.value.trim());
	const query = {limit: props.maxResults + props.excludeIds.length};
	if (isId) query.userId = event.target.value.trim();
	else query.username = event.target.value.trim();

	const {body, status} = await backend(`/public/guilds/${props.guildId}/discord/users/search`, "GET", {
		query
	});

	if (status !== 200) {
		alert("Unable to search backend");
		return console.error(status, body);
	}

	await idbStore.setUsers(body);
	const str = event.target.value.trim().toLowerCase();
	results.value = body
		.filter(user => {
			if (props.excludeIds.includes(user.id)) return false;
			if (props.modelValue?.id === user.id) return false;

			if (user.id.includes(str)) return true;
			if (user?.global_name?.toLowerCase().includes(str)) return true;
			return (user.username || "Unknown User").toLowerCase().includes(str);
		});

	loading.value = false;
}

function handleSelect(event) {
	if (props.bindUsingId && event?.id) event = event.id;

	emit("update:modelValue", event);
	if (props.autoReset) {
		searchValue.value = "";
		results.value = [];
	}
}

const selected = computed(() => {
	if (!props.modelValue) return null;
	if (typeof props.modelValue === "string" || typeof props.modelValue === "number") {
		return Object.values(idbStore.users).find(user => user.id === props.modelValue);
	}

	return props.modelValue;
});
</script>

<template>
	<GenericSearch
		v-model:search-value="searchValue"
		:selected="selected"
		search-icon="Discord"
		:max-results="maxResults"
		placeholder-blur="Select a Discord User"
		placeholder-focus="Search by username or Discord ID"
		:results="results"
		:loading="loading"
		:disabled="disabled"
		remove-title="Unselect this user"
		@update:selected="handleSelect"
		@input="debouncedSearch"
	>
		<template v-if="!simple" #footer>
			<DynamicIcon
				icon="LightBulb"
				size="18"
			/>
			<p>Try Discord user ID if you can’t find someone</p>
			<ToolTip class="text-left ml-2" :description="tooltip" />
		</template>

		<template #selectedBlur="{ value }">
			<!-- Avatar gets size from h-13 + its extras -->
			<UserAvatar
				:user-id="value.id"
				:avatar="value.avatar"
				size="27.8333"
			/>
			<p class="m-0 p-0 leading-none text-inherit">
				{{ value.global_name ?? value.username }}
			</p>
		</template>

		<template #selectedFocus="{ value }">
			<!-- Avatar gets size from h-13 + its extras -->
			<UserAvatar
				:user-id="value.id"
				:avatar="value.avatar"
				size="27.8333"
			/>
			<p class="m-0 p-0 leading-none text-inherit">
				{{ value.global_name ?? value.username }}
			</p>
		</template>

		<template #result-item="{ result }">
			<GenericSearchResult>
				<UserAvatar :user-id="result.id" :avatar="result.avatar" />
				<p class="text-inherit">
					{{ result.global_name ?? result.username }}
				</p>
			</GenericSearchResult>
		</template>
	</GenericSearch>
</template>
<template>
	<g>
		<path
			d="M11.5 12L7.99854 8.49854"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.39738 5.89744C2.51298 9.02926 2.21954 13.7544 4.69428 17.2191C7.16902 20.6837 11.7339 21.9386 15.632 20.2258C19.53 18.5131 21.6931 14.3019 20.8149 10.1357C19.9368 5.96959 16.2578 2.98986 12.0001 2.99623V6.9979"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconTimer"
};
</script>
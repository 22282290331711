<script setup>
import SummaryContainer from "@/components/lists/summary-container/SummaryContainer.vue";
import {computed} from "vue";
import {useRoute} from "vue-router";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const props = defineProps({
	"appeals": {
		type: Array,
		default: () => [],
	},
});

const state = {
	"open": "Open",
	"reviewing": "Reviewing",
	"investigating": "Investigating",
	"deliberating": "Deliberating",
	"awaiting": "Awaiting Reply",
	"rejected": "Rejected",
	"approved": "Approved",
	"caseArchived": "Case Archived",
};
const icon = {
	"open": "Document",
	"reviewing": "Visible",
	"investigating": "Search",
	"deliberating": "Loading",
	"awaiting": "Timer",
	"rejected": "Close",
	"approved": "Check",
	"caseArchived": "Lock",
};

const route = useRoute();
const cases = CaseAppealBrowser();

const appealItems = computed(() => {
	return props.appeals.map((e, i) => {
		// Used in Guild View to mark that last appeal was rejected
		if (i === props.appeals.length - 1 && e.state === "rejected") {
			cases.states.setLastAppealRejected = true;
		}

		return {
			label: state[e.state],
			icon: icon[e.state],
			description: e.appealContent.content,
			chevron: true,
			to: {
				name: (props.personal || route.meta.isPersonal)
					? "my-appeals"
					: "guild-appeals",
				params: {
					...route.params,
					appealId: e._id,
				}
			}
		};
	});
});

</script>

<template>
	<section>
		<div id="appeals">
			<div class="flex justify-between items-baseline">
				<!-- Label -->
				<p class="label">
					Appeals
				</p>
			</div>
			<SummaryContainer
				:items="appealItems"
			/>
		</div>
	</section>
</template>
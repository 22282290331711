<script setup>
import GenericSearch from "@/components/form/search/GenericSearch.vue";
import { ref } from "vue";
import GenericSearchResult from "@/components/form/search/GenericSearchResult.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import UserCache from "@/storage/UserCache";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import IndexedDBStore from "@/storage/IndexedDB";

const props = defineProps({
	"id": {
		type: String,
		default: () => null,
	},
	// The selected staff member object
	"modelValue": {
		type: Object,
		default: () => null,
	},
	"maxResults": {
		type: Number,
		default: () => 4,
	},
	// User IDs, not staff IDs. Make necessary changes if needed.
	"excludeIds": {
		type: Array,
		default: () => [],
	},
	// In some cases you might need to fill out space for the right side icon to avoid layout shifts. Other it's the other way around.
	"iconSpaceFiller": {
		type: Boolean,
		default: () => false,
	},
});
const emit = defineEmits(["update:modelValue"]);
const searchValue = ref("");
const results = ref([]);
const loading = ref(false);
const users = UserCache();
const idbStore = IndexedDBStore();

async function doSearch(event) {
	results.value = [];
	if (!event?.target?.value) return;

	const str = event.target.value.trim().toLowerCase();

	// idbStore.staff should always be an object with the current guild's staffs.
	// The equivalent Discord users should also exist.
	const staffs = Object.values(idbStore.staff);

	results.value = staffs
		.filter(user => {
			// Exclude using user IDs
			if (props.excludeIds.includes(user.userId)) return false;
			// Exclude selected user by comparing staff IDs
			if (props.modelValue?.id === user.id) return false;

			if (user.userId.includes(str)) return true;

			const username = users.username(user.userId, false) || "Unknown Staff";
			return username.toLowerCase().includes(str);
		})
		.slice(0, props.maxResults);

	loading.value = false;
}
const handleUpdateSearch = () => {
	if (!props.modelValue) return;
	doSearch({ target: { value: searchValue.value } });
}
</script>

<template>
	<GenericSearch
		v-model:search-value="searchValue"
		:selected="modelValue"
		search-icon="Group"
		:max-results="4"
		placeholder-blur="Select a staff member"
		placeholder-focus="Search by username or Discord ID"
		:results="results"
		:loading="loading"
		:icon-space-filler="iconSpaceFiller"
		remove-title="Unselect this staff member"
		@update:selected="emit('update:modelValue', $event)"
		@input="doSearch"
		@focus="handleUpdateSearch"
	>
		<template #footer>
			<DynamicIcon
				icon="LightBulb"
				size="18"
			/>
			<p>Try reloading the page if you can’t find someone</p>
		</template>

		<template #selectedBlur="{ value }">
			<!-- Avatar gets size from h-13 + its extras -->
			<UserAvatar
				:user-id="value.userId"
				:avatar="users.avatarHashOf(value.userId)"
				size="27.8333"
			/>
			<p class="m-0 p-0 leading-none text-inherit">
				{{ users.username(value.userId) }}
			</p>
		</template>

		<template #selectedFocus="{ value }">
			<!-- Avatar gets size from h-13 + its extras -->
			<UserAvatar
				:user-id="value.userId"
				:avatar="users.avatarHashOf(value.userId)"
				size="27.8333"
			/>
			<p class="m-0 p-0 leading-none text-inherit">
				{{ users.username(value.userId) }}
			</p>
		</template>

		<template #result-item="{ result }">
			<GenericSearchResult>
				<UserAvatar
					:user-id="result.userId"
					:avatar="users.avatarHashOf(result.userId)"
				/>
				<p class="text-inherit text-left">
					{{ users.username(result.userId) }}
					<template v-if="users.titleOf(result.userId, false)">
						- {{ users.titleOf(result.userId) }}
					</template>
				</p>
			</GenericSearchResult>
		</template>
	</GenericSearch>
</template>
/**
 * The (current or final) state of the appeal
 * @typedef {("open"|"reviewing"|"investigating"|"deliberating"|"awaiting"|"rejected"|"approved"|"caseArchived")} AppealState
 */
import { permissionsApi } from "@/script/APICalls";
import { CaseFileMasks, CaseEntryMasks, CaseMasks } from "@archivian/constants";

export const AnnouncementTargetTypes = {
	/**
	 * Send a copy of announcement to the designated Discord channel
	 */
	"DiscordChannel": 1,
	/**
	 * Send this announcement to the entire guild (all staff members)
	 */
	"Guild": 2,
	/**
	 * Send this announcement to all staffs with the designated title ID
	 */
	"Title": 3,
	/**
	 * Send this announcement to all Teams/Team members with the designated title ID
	 */
	"Team": 4,
	/**
	 * Send this announcement to every everyone
	 */
	"Public": 5,
	/**
	 * To any guild that uses a bot by the designated ID
	 */
	"Bot": 6,
	/**
	 * To a specific user staff by ID (guildId:userId) to show only that user in that guild
	 */
	"Staff": 7,
	/**
	 * To a specific user by ID (shown anywhere)
	 */
	"User": 8,
	/**
	 * Targets all guilds across the board
	 */
	"Guilds": 9,
	/**
	 * Targets all personal accounts, across the board
	 */
	"Users": 10,
};

/**
 * @typedef {(16|24|32|48|64|80|96|128|160|240|256|512|1024|2048|4096)} DiscordImageSize
 */

const timeUnits = {
	year: 24 * 60 * 60 * 1000 * 365,
	month: 24 * 60 * 60 * 1000 * 365 / 12,
	week: 24 * 60 * 60 * 1000 * 7,
	day: 24 * 60 * 60 * 1000,
	hour: 60 * 60 * 1000,
	minute: 60 * 1000,
	second: 1000
};

/**
 * Deconstructs document cookies into an object
 * @return {Object<string, string>}
 */
export function parseCookies() {
	const obj = {};
	for (const row of document.cookie.split("; ")) {
		const [key, value] = row.split("=");
		obj[key] = value;
	}
	return obj;
}

/**
 * @author https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 * Format bytes as human-readable text.
 *
 * @param {number} bytes Number of bytes.
 * @param {boolean} [si=false] True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param {number} [dp] Number of decimal places to display.
 *
 * @return {string} Formatted string.
 */
export function fileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + " B";
	}

	const units = si
		? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
		: ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return bytes.toFixed(dp) + " " + units[u];
}

/**
 * Convert a date to a display date for the UI, using browser's preferred language
 * @param {Date|String} date
 * @param {boolean} [showDate=true]
 * @param {boolean} [showTime=false]
 * @returns {String}
 * @example
 * console.log(displayTime(new Date(), true, true); // Aug 31, 2022, 2:37 PM
 * console.log(displayTime(new Date()); // Aug 31, 2022
 * console.log(displayTime(new Date(), false, true); // 2:37 PM
 */
export function displayTime(date, showDate = true, showTime = false) {
	if (!date) return "";
	if (typeof (date) === "string") date = new Date(date);
	const opt = Object();

	if (showDate) {
		Object.assign(opt, {
			year: "numeric",
			month: "short",
			day: "numeric",
		});
	}
	if (showTime) {
		Object.assign(opt, {
			hour: "numeric",
			minute: "numeric",
		});
	}

	return new Intl.DateTimeFormat(navigator.language || "en-US", opt).format(date);
}

/**
 * Convert a future/past date to a relative time from now/relativeTo param
 * @param {string|Date} date
 * @param {string|Date} [relativeTo=new Date()] The time it is relative to
 * @returns {string}
 * @author https://stackoverflow.com/a/53800501
 */
export function timeLeft(date, relativeTo = new Date()) {
	if (typeof (date) === "string") date = new Date(date);
	if (typeof (relativeTo) === "string") relativeTo = new Date(relativeTo);

	const elapsed = date - relativeTo;
	const rtf = new Intl.RelativeTimeFormat(navigator.language || "en", {
		numeric: "auto"
	});

	const relative = (elapsed) => {
		for (const unit in timeUnits) {
			if (Math.abs(elapsed) > timeUnits[unit] || unit === "second") {
				return rtf.format(Math.round(elapsed / timeUnits[unit]), unit);
			}
		}
	};

	return relative(elapsed);
}

/**
 * Returns the named color for a given case type
 * @param {("note"|"warning"|"timeout"|"kick"|"ban")} caseType
 * @returns {("grey"|"yellow"|"sky"|"purple"|"red")}
 * @throws {Error} On unknown case type
 */
export function caseColor(caseType) {
	switch (caseType) {
		case "note":
			return "gray";
		case "warning":
			return "yellow";
		case "timeout":
			return "sky";
		case "kick":
			return "purple";
		case "ban":
			return "red";
		default:
			throw new Error(`Unknown case type '${caseType}'`);
	}
}

/**
 * Returns the name of the color for a given state of an appeal
 * @param {AppealState} appealState
 * @returns {string}
 */
export function appealColor(appealState) {
	switch (appealState) {
		case "open":
			return "gray";
		case "approved":
			return "green";
		case "rejected":
			return "red";
		case "awaiting":
			return "orange";
		case "investigating":
		case "deliberating":
		case "reviewing":
			return "purple";
		case "caseArchived":
			return "green";
		default:
			throw new Error(`Unknown appeal state '${appealState}'`);
	}
}

/**
 * Capitalize input string. Each word is capitalized, except some keywords, like "of", "the" (unless first word), etc.
 * @param {string} string The input string
 * @returns {string}
 * @example
 * console.log("the lord of the rings"); // The Lord of the Rings
 * console.log("the lOrD OF tHe RiNgS") // The LOrD OF tHe RinGs
 */
export function capitalize(string) {
	const ignore = ["at", "off", "by", "on", "down", "onto", "for", "over", "from", "past", "in", "to", "into", "upon", "near", "with", "of", "and", "so", "as", "than", "but", "that", "till", "if", "when", "nor", "yet", "once", "or", "a", "an", "the"];
	return string
		.split(/ +/g)
		.map((word, i) => {
			if (i && [ignore].includes(word.toLowerCase())) return word;
			return word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(" ");
}

/**
 * @typedef {Object} PermissionRoleFlags
 * @prop {boolean} support This role is made for Archivian Staff in order to provide support
 * @prop {boolean} owner Role is made for Guild owner
 */
/**
 * @typedef {Object} CaseFlags
 * @prop {boolean} demo Case created while bot was in demo mode
 * @prop {boolean} showCase Case is visible to the target
 */
/**
 * @typedef {Object} EntryFlags
 * @prop {boolean} system This was a generated entry
 * @prop {boolean} expireEntry This entry was crated as part of an expiry. E.g. manual unban, automatic unmute, etc.
 * @prop {boolean} archiveEntry This entry was created as part of a case archivation
 * @prop {boolean} showEntry This entry is visible to the case target
 */

/**
 * @typedef {Object} FileFlags
 * @prop {boolean} showFile We can display link to file to the target
 * @prop {boolean} public The file can be publicly accessed
 */
/**
 * Convert bitflags to a true/false object
 * @param {("case"|"entry"|"file"|"fileVisibility"|"permissionRole")} flagsFor What these flags are for
 * @param {number} flags The bitflags
 * @param {?{demo?: boolean; system?: boolean}} opts
 * @returns {CaseFlags|EntryFlags|FileFlags|PermissionRoleFlags}
 */
export function flagsToObject(flagsFor, flags, {demo, system} = {}) {
	// TODO Update flags, they're outdated. Load them from @archivian/constants -> Masks
	switch (flagsFor) {
		case "case":
			return {
				// Case is visible to the target
				showCase: !!(flags & CaseMasks.CaseFlagsMask.showCase),
				// Case created while bot was in demo mode
				demo: !!demo,
				// Show the creators of the case/creators
				showCreators: !!(flags & CaseMasks.CaseFlagsMask.showCreators),
				// Deny user from making appeals on this case
				denyAppeals: !!(flags & CaseMasks.CaseFlagsMask.denyAppeals),
			};
		case "entry":
			return {
				// This was a generated entry
				system: !!system,
				// This entry was crated as part of an expiry. E.g. timeout expired, tempban expired, etc.
				expireEntry: !!(flags & CaseEntryMasks.CaseEntryFlagMask.expireEntry),
				// This entry was created as part of a case archivation
				archiveEntry: !!(flags & CaseEntryMasks.CaseEntryFlagMask.archiveEntry),
				// This entry was created as part of undoing a case (manual unban or lift timeout)
				undoEntry: !!(flags & CaseEntryMasks.CaseEntryFlagMask.undoEntry),
				// This entry is visible to the case target
				visibleToTarget: !!(flags & CaseEntryMasks.CaseEntryFlagMask.visibleToTarget),
			};
		case "file":
			// File has no flags right now
			return {
			};
		case "fileVisibility":
			return {
				// We can display link to file to the target
				visibleToTarget: !!(flags & CaseFileMasks.CaseFileVisibilityMask.visibleToTarget),
				// The file is publicly accessible via resource link
				public: !!(flags & CaseFileMasks.CaseFileVisibilityMask.public),
			};
		case "permissionRole":
			return {
				// This role is made for Archivian Staff in order to provide support
				support: !!(flags & 1 << 0),
				// Role is made for Guild owner
				owner: !!(flags & 1 << 1),
			};
	}
}

/**
 * Collect all unique user ID's in a case into an array
 * @param {ICaseData} theCase
 * @returns {Array<string>}
 */
export function collectIds(theCase) {
	const u = new Set();

	if (theCase.creatorId) u.add(theCase.creatorId);
	if (theCase.targetId) u.add(theCase.targetId);
	if (theCase.contributors) {
		theCase.contributors.forEach(c => u.add(c));
	}
	if (theCase.linked?.users) {
		theCase.linked?.users.forEach(c => {
			if (c.by) u.add(c.by);
			if (c.userId) u.add(c.userId);
		});
	}
	if (theCase.linked?.cases) {
		theCase.linked?.cases.forEach(c => {
			if (c.by) u.add(c.by);
		});
	}
	if (theCase.undone && theCase.undone.by) {
		u.add(theCase.undone.by);
	}
	if (theCase.files) {
		theCase.files.forEach(c => {
			if (c.creatorId) u.add(c.creatorId);
			if (c.archived.by) u.add(c.archived.by);
		});
	}
	if (theCase.entries) {
		theCase.entries.forEach(c => {
			if (c.creatorId) u.add(c.creatorId);
			if (c.lastEditorId) u.add(c.lastEditorId);
		});
	}

	return Array.from(u);
}

/**
 * Converts a mime into the appropriate icon name
 * @param {string} mime
 * @param {string} [ext] File extension, if any
 * @returns {string}
 */
export function fileIcon(mime, ext) {
	if (!mime) return "Document";
	if (/; ?charset=/.test(mime)) {
		mime = mime.split(";")[0];
	}

	// TODO add PDF here: 'application/pdf'
	const [type, app] = mime.split("/");
	if (type === "image") return "Image";
	if (type === "video") return "Video";
	const zip = ["x-bzip", "x-bzip2", "gzip", "zip", "x-7z-compressed", "vnd.rar", "x-tar", "x-zip-compressed", "x-gzip", "x-rar-compressed"];
	if (zip.includes(app)) return "FileZip";
	if (type.startsWith("audio")) return "FileAudio";
	const dataTable = ["csv", "vnd.ms-excel", "vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
	if (dataTable.includes(app)) return "FileTable";
	const exec = ["java-archive", "vnd.microsoft.portable-executable", "x-sh", "x-shockwave-flash", "bat", "x-bat", "x-msdos-program"];
	if (exec.includes(app)) return "FileWarning";
	if (app.startsWith("x-msdownload")) return "FileWarning";
	if (["bat", "cmd", "btm", "sh", "py", "jar", "dmg", "vbs", "vbe", "msc"].includes(ext)) return "FileWarning";
	if (type.startsWith("text")) return "FileText";
	if (
		type.startsWith("application") &&
		["x-httpd-php", "json", "jsonld", "jsx", "html", "xml", "htm", "rtf", "svg+xml"].includes(app)
	) {
		return "FileText";
	}

	return "Document";
}

/**
 * Reads an input file as Base64
 * @param {File} file
 * @throws {Error}
 * @returns {Promise<string>}
 */
export function fileToBase64(file) {
	const fr = new FileReader();
	return new Promise((r, rj) => {
		fr.onloadend = () => {
			return r(fr.result);
		};
		fr.onerror = rj;
		fr.readAsDataURL(file);
	});
}

/**
 * Returns the login via Discord URL with possibility for a redirect link after login
 * @param {string} [redirect]
 * @return {string}
 */
export function apiDiscordLoginUrl(redirect) {
	return `${process.env.VUE_APP_API_ENDPOINT}/public/accounts/login${redirect ? `?redirect=${redirect}` : ""}`;
}

/**
 * Convert a Discord discriminator to a default avatar URL
 * @param {UserID} userId
 * @returns {string}
 * @example
 * const avatarUrl = user.avatarUrl ? user.avatarUrl : defaultAvatar(user.discriminator);
 */
export function defaultAvatarUrl(userId) {
	const index = Math.abs((userId >> 22) % 6);

	// Did Discord turn off the ability to link to these images outside of Discord???
	// const originalCDN = `https://cdn.discordapp.com/embed/avatars/${index}.png`;

	const backupCDN = [
		"https://i.thevirt.us/04/0.png",
		"https://i.thevirt.us/04/1.png",
		"https://i.thevirt.us/04/2.png",
		"https://i.thevirt.us/04/3.png",
		"https://i.thevirt.us/04/4.png",
		"https://i.thevirt.us/04/5.png",
	];

	return backupCDN[index];
}

/**
 * Creates a debouncing version of a function you provide
 * @param {() => void} callback Your function that may be executed many times a second
 * @param {number} [time=100] Time in MS after last call of the function to run the function
 * @param {() => void} [onStart] A function to execute when the timer starts
 * @returns {(function(*): void)|*}
 */
export function debounce(callback, time = 100, onStart) {
	let timer;
	return args => {
		clearTimeout(timer);
		if (onStart) onStart();
		timer = setTimeout(() => callback(args), time);
	};
}

/**
 * Return a display version of the case type.
 * @param {CaseType} caseType
 * @return {("Note"|"Warning"|"Timeout"|"Kick"|"Ban")}
 */
export function displayCase(caseType) {
	return capitalize(caseType);
}

/**
 * Generate a CDN of the user's avatar
 * @param {UserID} userId
 * @param {string|null} avatarHash Leave null + provide options->discriminator to get the default avatar URL
 * @param {{size?:DiscordImageSize, format?:("auto"|"jpeg"|"webp"|"gif"|"png")}} [options={size:32, format:"auto", discriminator:null}]
 *     Additional options for the avatar CDN
 * @return {string|null} Full CDN link to the avatar / default avatar if discriminator was provided and avatar hash was null
 * @example
 * // Guarantees some URL at size 32; Animated if animated, else webp, else default avatar if none
 * const avatarUrl = discordAvatar(user.id, user.avatar, {size: 16});
 *
 * // Can be null or avatar URL
 * const avatarUrl = discordAvatar(user.id, may_be_null_or_avatar_hash);
 */
export function discordAvatar(userId, avatarHash, {size = 32, format = "auto"} = {}) {
	if (!avatarHash) return defaultAvatarUrl(userId);

	const animated = avatarHash.startsWith("a_");
	let ext = "webp";
	if (format === "auto") {
		if (animated) ext = "gif";
	} else if (format !== "auto") ext = format;

	return `https://cdn.discordapp.com/avatars/${userId}/${avatarHash}.${ext}?size=${size}`;
}

/**
 * Generate a CDN of the role's icon
 * @param {string} roleId
 * @param {string|null} iconHash If null, returns null
 * @param {{size?:DiscordImageSize, format?:("auto"|"jpeg"|"webp"|"gif"|"png")}} [options={size:32, format:"auto"}] Additional options for
 *     the icon CDN
 * @return {string|null} Full CDN link to the icon, or null if no icon available
 * @example
 * // Gives some URL at size 32; Animated if animated, else webp
 * const iconUrl = roleIcon(role.id, role.icon);
 */
export function roleIcon(roleId, iconHash, {size = 32, format = "auto"} = {}) {
	if (!iconHash) return null;

	const animated = iconHash.startsWith("a_");
	let ext = "webp";
	if (format === "auto") {
		if (animated) ext = "gif";
	} else if (format !== "auto") ext = format;

	return `https://cdn.discordapp.com/role-icons/${roleId}/${iconHash}.${ext}?size=${size}`;
}

/**
 * Copy content to clipboard
 * @param {string} text
 * @returns {Promise<boolean>} True if success, false if no success
 * @author https://stackoverflow.com/a/30810322
 */
export async function textToClipboard(text) {
	if (!navigator.clipboard) return fallbackCopyTextToClipboard(text);

	return navigator.clipboard.writeText(text).then(() => {
		return true;
	}, () => {
		return false;
	});
}

function fallbackCopyTextToClipboard(text) {
	const textArea = document.createElement("textarea");
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = "0";
	textArea.style.left = "0";
	textArea.style.position = "fixed";

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	let r = false;
	try {
		r = document.execCommand("copy");
		// eslint-disable-next-line no-empty
	} catch (_) {
	}

	document.body.removeChild(textArea);
	return r;
}

/**
 * Selects the text in a given HTML node
 * @param {Node} node
 */
export function selectText(node) {
	if (document.body.createTextRange) {
		const range = document.body.createTextRange();
		range.moveToElementText(node);
		range.select();
	} else if (window.getSelection) {
		const selection = window.getSelection();
		const range = document.createRange();
		range.selectNodeContents(node);
		selection.removeAllRanges();
		selection.addRange(range);
	}
}

export const permissionEditor = {
	/**
	 * Check if a title has any changes in it. Remove fields that are identical
	 * @param {ShallowRef<Object>} changes
	 * @param {string} titleId
	 * @return {boolean}
	 */
	hasChanges(changes, titleId) {
		const fields = changes.value[titleId];
		if (!fields) return false;
		for (const [key, value] of fields.entries()) {
			if (value.old === value.new) fields.delete(key);
		}

		return !!fields.size;
	},
	/**
	 * @typedef {Object} NodeChange
	 * @prop {string} id
	 * @prop {string|null} old The node value currently in the database, if any
	 * @prop {string|null} new The new value this node will be. Null to delete, old being null to add, both to change
	 */
	/**
	 * Handles tracking changes of nodes
	 * @param {ShallowRef<Object>} changes
	 * @param {string} titleId
	 * @param {NodeChange} update
	 */
	handleUpdate(changes, titleId, update) {
		if (!changes.value[titleId]) changes.value[titleId] = new Map();
		changes.value[titleId].set(update.id, {old: update.old, new: update.new});
		if (!this.hasChanges(changes, titleId)) delete changes.value[titleId];
	},
	/**
	 * Resets by removing changes tracker and toggling reset trigger
	 * @param {ShallowRef<Object>} changes
	 * @param {ShallowRef<boolean>} resetTrigger
	 */
	reset(changes, resetTrigger) {
		resetTrigger.value = !resetTrigger.value;
		changes.value = {};
	},
	/**
	 * Saves the changes preset in the change-tracker, and emits changes to parent if needed
	 * @param {ShallowRef<Object>} changes
	 * @param {string} guildId
	 * @param {function} [emitFn]
	 * @return {Promise<void>}
	 */
	async save(changes, guildId, emitFn) {
		const obj = [];

		for (const titleId in changes.value) {
			const c = {
				titleId,
				remove: [],
				add: [],
			};

			for (const [, change] of changes.value[titleId]) {
				if (!change.old) {
					c.add.push(change.new);
				} else if (!change.new) {
					c.remove.push(change.old);
				} else {
					c.add.push(change.new);
					c.remove.push(change.old);
				}
			}

			obj.push(c);
		}

		const r = await permissionsApi.updateTitlePermissions(guildId, obj);

		if (emitFn) {
			emitFn("updateCache", {
				field: "permissions",
				value: r,
			});
		}
	}
};

/**
 * Performs various checks to see if the ID provided is possible to be a valid User ID.
 * @param {string} usernameNicknameOrId
 * @returns {boolean}
 */
export function canByUserId(usernameNicknameOrId) {
	if (usernameNicknameOrId.length >= 20) return false;
	if (usernameNicknameOrId.length < 17) return false;
	if (/^\d+$/.test(usernameNicknameOrId) === false) return false;

	// Lowest ID is CTO's Snowflake. Anything lower is invalid

	// eslint-disable-next-line no-undef
	const lowest = BigInt("21154535154122752") >> 22n;
	// eslint-disable-next-line no-undef
	return (BigInt(text) >> 22n) >= lowest;
}

/**
 * Convert an Object ID to a timestamp
 * @param {string} objectId
 * @returns {Date}
 */
export function objectIdToDate(objectId) {
	return new Date(parseInt(objectId.slice(0, 8), 16) * 1000);
}

/**
 * Generates a full link to a file belonging to a case
 * @param {string} guildId
 * @param {number|string} caseSid
 * @param {string} fileId Without extension
 * @return {string}
 */
export function caseFileLink(guildId, caseSid, fileId) {
	return `${process.env.VUE_APP_API_ENDPOINT}/public/guilds/${guildId}/cases/${caseSid}/files/${fileId}/file`;
}

export const CaseBehaviorMask = {
	// The case is visible to the target
	showCase: 1 << 0,
	// Case was made while in demo mode
	demo: 1 << 1,
	// Show the creators of the case/creators in resources (case, entries, files, links)
	showCreators: 1 << 2,
	// Deny user from making appeals on this case
	denyAppeals: 1 << 3,
};

/**
 * Handles converting error to our re-usable structure
 * @param {string|Error|Object<"message", string>|Array<string>} err
 * @param {string} [label="An unknown error occurred"] The title of the error
 * @param {boolean} [log=false] Whether we want to log this error in console and possibly Sentry
 * @return {{texts: string[], label: string}}
 */
export function handleErr(err, label = "An unknown error occurred", log = false) {
	const obj = {
		label,
		texts: []
	};

	if (err?.message) {
		obj.texts = [err.message];
	} else if (typeof (err) === "string") {
		obj.texts = [err];
	} else if (Array.isArray(err)) {
		obj.texts = err;
	} else {
		obj.texts = [err.toString()];
	}

	if (log) {
		console.error(`%c${obj.label}: %c${obj.texts.join("\n")}`, "font:bold", "font:400");
	}

	return obj;
}

/**
 * Generates the server icon URL at given size
 * @param {string} guildId
 * @param {string} hash
 * @param {DiscordImageSize|string} size
 */
export function serverIcon(guildId, hash, size) {
	/**
	 * Special handling for dummies, used in JoyRides etc.
	 * 00000000000000000000 - should not have guild icon
	 * 11111111111111111111 - uses a static Archivian image
	 */
	if (guildId === "11111111111111111111") {
		return "https://i.thevirt.us/10/Archivian_server_image.webp";
	}

	const ext = hash.startsWith("a_") ? "gif" : "webp";
	return `https://cdn.discordapp.com/icons/${guildId}/${hash}.${ext}?size=${size}`;
	// https://cdn.discordapp.com/icons/490893428905738260/a_f53b3c0423feb67f3bf4cbbbc6c2668f.gif?size=1024
}

/**
 * Gives a deterministic color of a guild by its ID
 * @param {string} guildId
 * @return {string[]}
 */
export function guildBGColor(guildId) {
	return [
		["#f97316", "#ffedd5"],
		["#ec4899", "#fce7f3"],
		["#06b6d4", "#cffafe"],
		["#8b5cf6", "#ede9fe"],
		["#22c55e", "#dcfce7"],
	][guildId.slice(-4) % 5];
}

/**
 * Creates a CDN url for Guild Splash images (background on ask to join in browser), if it has a hash
 * @param {string} guildId
 * @param {string|null} [splashHash]
 * @param {DiscordImageSize|string} size
 * @return {null|string}
 */
export function splashUrl(guildId, splashHash, size = 4096) {
	return splashHash ? `https://cdn.discordapp.com/splashes/${guildId}/${splashHash}.jpg?size=${size}` : null;
}

/**
 * Creates a CDN url for banner images (top corner of server), if it has a hash
 * @param {string} guildId
 * @param {string|null} [bannerHash]
 * @param {DiscordImageSize|string} size
 * @return {null|string}
 */
export function bannerUrl(guildId, bannerHash, size = 4096) {
	return bannerHash ? `https://cdn.discordapp.com/banners/${guildId}/${bannerHash}.jpg?size=${size}` : null;
}

/**
 * Creates a CDN url for Guild Discovery Splash images (cover in discovery), if it has a hash
 * @param {string} guildId
 * @param {string|null} [discoverySplashHash]
 * @param {DiscordImageSize|string} size
 * @return {null|string}
 */
export function discoverySplashUrl(guildId, discoverySplashHash, size = 4096) {
	return discoverySplashHash ? `https://cdn.discordapp.com/discovery-splashes/${guildId}/${discoverySplashHash}.jpg?size=${size}` : null;
}

/**
 * Checks an URL.
 * Determines if URL leads off-site or to an internal site.
 * Returns null if no link.
 * @param {string|null} [link]
 * @return {("external"|"internal")|null}
 */
export function checkLinkType(link) {
	if (!link) return null;
	if (link.startsWith("//")) return "external";
	if (link.startsWith("/")) return "internal";
	if (new URL(link).host === new URL(window.location).host) return "internal";
	return "external";
}

/**
 * Characters permissions are limited to
 * @type {Set<string>}
 */
export const permissionCharset = new Set("abcdefghijklmnopqrstuvwxyz.*".split(""));

/**
 * Keycodes we don't try to check against our {@link permissionCharset} in validation
 * @type {Set<string>}
 */
export const permissionInputSkipKeys = new Set([
	"ShiftLeft",
	"ShiftRight",
	"ControlLeft",
	"ControlRight",
	"Insert",
	"Delete",
	"Home",
	"End",
	"PageDown",
	"PageUp",
	"Control",
	"Shift",
	"Alt",
	"Enter",
	"Backspace",
	"ArrowLeft",
	"ArrowRight",
	"ArrowUp",
	"ArrowDown",
]);
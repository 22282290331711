<template>
	<g>
		<path
			d="M3.5 20L7.87 15.63"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M4.95597 11.294C4.56497 11.685 4.56497 12.318 4.95597 12.708L7.87397 15.626L10.792 18.544C11.183 18.935 11.816 18.935 12.206 18.544L13.381 17.369C13.569 17.181 13.674 16.927 13.674 16.662V12.726L16.574 9.826L18.883 9.949C19.166 9.964 19.443 9.858 19.643 9.658L20.217 9.084C20.608 8.693 20.608 8.06 20.217 7.67L15.831 3.284C15.44 2.893 14.807 2.893 14.417 3.284L13.843 3.858C13.642 4.059 13.536 4.335 13.552 4.618L13.675 6.927L10.775 9.827H6.83797C6.57297 9.827 6.31797 9.932 6.13097 10.12L4.95597 11.294V11.294Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.57 9.82999L13.67 6.92999"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconPin"
};
</script>
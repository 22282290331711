<template>
	<g>
		<path
			d="M5 6V18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13 6V18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 12H13"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18.5 18V13L17.25 14.25"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20 18H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconHeadingOne"
};
</script>
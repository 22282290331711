<template>
	<div class="nav-item-outer">
		<TipWrapper :label="tooltip">
			<RouterLink
				:aria-label="tooltip"
				:to="link"
				class="nav-item p-3 rounded-md hover:bg-neutral-700/50 border border-transparent hover:border-neutral-700 hover:border-t-neutral-600 transition-all text-gray-300 relative leading-none cursor-pointer flex items-center"
			>
				<!-- Red badge counter -->
				<CounterBadge
					v-if="p.badgeCount"
					:count="p.badgeCount"
					class="absolute -right-1 -top-2"
				/>

				<!-- Main icon -->
				<DynamicIcon :icon="p.icon" />

				<!-- Float-out tooltip -->
			</RouterLink>
		</TipWrapper>
	</div>
</template>

<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon";
import CounterBadge from "@/components/badges/CounterBadge";
import TipWrapper from "@/partials/navigation/main-nav/TipWrapper.vue";

const p = defineProps({
	"link": {
		type: Object,
		required: true,
	},
	"tooltip": {
		type: String,
		default: () => null,
	},
	"icon": {
		type: String,
		required: true,
	},
	"badgeCount": {
		type: Number,
		default: () => null,
	},
});
</script>

<style scoped>
</style>
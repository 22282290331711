import { EnableDisableInherit } from "../../types/index.js";
import { config } from "./messages.config.js";
// Higher number is more specific. A child cannot be less specific than or equal to the parent.
export const ancestry = {
    "channelArchetype": 0,
    "category": 1,
    "channelType": 2,
    "channel": 3,
    "role": 4,
    "user": 5,
};
/**
 * A target can be:
 * - a user by ID
 * - a role by ID
 * - a category by ID
 * - a channel by ID
 * - a channel type, using a Discord channel identifier
 *
 * A type can be:
 * - a user
 * - a role
 * - a category
 * - a specific channel
 * - a channel archetype (text or voice)
 * - a type of channel (text, voice, forum, announcements, rules, etc.)
 *
 * If there ever are conflicts, the first match will be used.
 * Since data is stored in arrays, this should be predictable and reproducible.
 *
 * Generally, a guild will always have at least two policies at the root level that cannot be removed.
 * These will be the two archetypes: text and voice.
 *
 * The types of siblings allowed are limited or nested.
 */
export default class MessagePolicy {
    targetId;
    targetType;
    trackingType;
    track;
    storeAuthor;
    ttl;
    alert;
    children;
    files;
    /**
     * These are not using `#X` because we use it in dashboard,
     * and Vue3 complains when it can't access that.
     *
     * The same goes for private methods.
     */
    /**
     * This property is also used outside the current instance (i.e., from the parent or child), so it cannot be fully private (#X)
     * @private
     */
    _parent = null;
    /**
     * @private
     */
    _globalDefaultChannelId = null;
    /**
     * When building UI, we might want to skip some validations while they make it
     * @private
     */
    _skipValidations = new Set();
    constructor(data, extras) {
        this.targetId = data?.targetId;
        this.targetType = data?.targetType;
        this.trackingType = data?.trackingType;
        this.track = data?.track;
        this.storeAuthor = data?.storeAuthor;
        this.ttl = data?.ttl;
        this.alert = {
            channelId: data?.alert?.channelId ?? "0",
            enabled: data?.alert?.enabled ?? EnableDisableInherit.INHERIT,
        };
        this.children = [];
        this.files = {
            storeFiles: data?.files?.storeFiles ?? EnableDisableInherit.INHERIT,
            ttl: data?.files?.ttl ?? config.maxFileCreatedTTL("free"),
            deletedTtl: data?.files?.deletedTtl ?? 24 * 7,
        };
        if (extras?.globalDefaultChannelId) {
            this._globalDefaultChannelId = extras.globalDefaultChannelId;
        }
        if (data?.children?.length) {
            data.children.forEach(child => this.add(child));
        }
    }
    /**
     * Gets the parent policy, if any
     */
    get parent() {
        return this._parent;
    }
    set globalDefaultChannelId(channelId) {
        this._globalDefaultChannelId = channelId;
    }
    /**
     * Checks if the current policy inherits the message tracking from parent
     */
    get trackIsInherited() {
        return this.track === EnableDisableInherit.INHERIT;
    }
    /**
     * Checks if the current file tracking policy inherits the setting from parent
     */
    get trackFileIsInherited() {
        return this.files.storeFiles === EnableDisableInherit.INHERIT;
    }
    /**
     * Checks if the current policy inherits the store author setting from parent
     */
    get storeAuthorIsInherited() {
        return this.storeAuthor === EnableDisableInherit.INHERIT;
    }
    /**
     * Checks if the current policy inherits the alerting enabled from parent
     */
    get alertEnabledIsInherited() {
        return this.alert.enabled === EnableDisableInherit.INHERIT;
    }
    /**
     * Checks if the current policy inherits the channel ID from a parent
     */
    get alertChannelIdIsInherited() {
        return this.alert.channelId === "0";
    }
    /**
     * Computes whether we want to store/track the messages of this tracking type
     */
    get doTrack() {
        let context = this;
        while (context.track === EnableDisableInherit.INHERIT && context.parent) {
            context = context.parent;
        }
        // If it is 'inherit' for whatever reason, should still be false then
        return context.track === EnableDisableInherit.ENABLED;
    }
    /**
     * Computes whether we want to store the author
     */
    get doStoreAuthor() {
        let context = this;
        while (context.storeAuthor === EnableDisableInherit.INHERIT && context.parent) {
            context = context.parent;
        }
        // If it is 'inherit' for whatever reason, should still be false then
        return context.storeAuthor === EnableDisableInherit.ENABLED;
    }
    /**
     * Computes whether we want to store files uploaded in this messages
     */
    get doStoreFiles() {
        let context = this;
        while (context.files?.storeFiles === EnableDisableInherit.INHERIT && context.parent) {
            context = context.parent;
        }
        // If it is 'inherit' for whatever reason, should still be false then
        return context.files?.storeFiles === EnableDisableInherit.ENABLED;
    }
    /**
     * Computes whether alerting is enabled
     */
    get alertEnabled() {
        let context = this;
        while (context.alert.enabled === EnableDisableInherit.INHERIT && context.parent) {
            context = context.parent;
        }
        // If it is 'inherit' for whatever reason, should still be false then
        return context.alert.enabled === EnableDisableInherit.ENABLED;
    }
    /**
     * Computes the channel ID to send alerts to
     */
    get channelId() {
        let context = this;
        while ((!context.alert.channelId || context.alert.channelId === "0") && context.parent) {
            context = context.parent;
        }
        // "0" is to inherit. It could be we want to inherit global alert channel
        if (context.alert.channelId === "0" && this._globalDefaultChannelId) {
            return this._globalDefaultChannelId;
        }
        return context.alert.channelId;
    }
    /**
     * Adds a policy as a child of this one
     */
    add(policy) {
        if (!(policy instanceof MessagePolicy)) {
            policy = new MessagePolicy(policy);
        }
        policy["_parent"] = this;
        this.children.push(policy);
        return policy;
    }
    toObject(options) {
        const obj = {
            targetId: this.targetId,
            targetType: this.targetType,
            trackingType: this.trackingType,
            track: this.track,
            storeAuthor: this.storeAuthor,
            ttl: this.ttl,
            alert: {
                channelId: this.alert.channelId,
                enabled: this.alert.enabled,
            },
            files: this.files,
        };
        if (options?.children !== false) {
            // @ts-ignore - We know it will not exist if children is false
            obj.children = this.children.map(ch => ch.toObject(options));
        }
        return obj;
    }
    // Builder pattern methods
    setTargetId(targetId) {
        this.targetId = targetId;
        return this;
    }
    setTargetType(targetType) {
        this.targetType = targetType;
        return this;
    }
    setTrackingType(trackingType) {
        this.trackingType = trackingType;
        return this;
    }
    setTrack(track) {
        this.track = track;
        return this;
    }
    enableTracking() {
        this.track = EnableDisableInherit.ENABLED;
        return this;
    }
    disableTracking() {
        this.track = EnableDisableInherit.DISABLED;
        return this;
    }
    inheritTracking() {
        this.track = EnableDisableInherit.INHERIT;
        return this;
    }
    enableFileTracking() {
        this.files.storeFiles = EnableDisableInherit.ENABLED;
        return this;
    }
    disableFileTracking() {
        this.files.storeFiles = EnableDisableInherit.DISABLED;
        return this;
    }
    inheritFileTracking() {
        this.files.storeFiles = EnableDisableInherit.INHERIT;
        return this;
    }
    setStoreAuthor(storeAuthor) {
        this.storeAuthor = storeAuthor;
        return this;
    }
    enableAuthorStorage() {
        this.storeAuthor = EnableDisableInherit.ENABLED;
        return this;
    }
    disableAuthorStorage() {
        this.storeAuthor = EnableDisableInherit.DISABLED;
        return this;
    }
    inheritAuthorStorage() {
        this.storeAuthor = EnableDisableInherit.INHERIT;
        return this;
    }
    setTTL(ttl) {
        this.ttl = ttl;
        return this;
    }
    setAlert(alert) {
        this.alert = alert;
        return this;
    }
    setAlertChannelId(channelId) {
        this.alert.channelId = channelId ? channelId : undefined; // undefined to avoid storing in dB
        return this;
    }
    setAlertChannelInherit() {
        this.alert.channelId = "0";
        return this;
    }
    setAlertEnabled(enabled) {
        if (typeof enabled === "boolean") {
            enabled = enabled ? EnableDisableInherit.ENABLED : EnableDisableInherit.DISABLED;
        }
        this.alert.enabled = enabled;
        return this;
    }
    setAlertInherit() {
        this.alert.enabled = EnableDisableInherit.INHERIT;
        return this;
    }
    setFileTracking(enabled, deletedTTL, createdTTL) {
        this.files.storeFiles = enabled;
        this.files.deletedTtl = deletedTTL;
        if (createdTTL)
            this.files.ttl = createdTTL;
        return this;
    }
    setChildren(children) {
        this.children = [];
        children.map(ch => this.add(ch));
        return this;
    }
    // Builder utilities
    skipSiblingIdCheck() {
        this._skipValidations.add("siblingIdCheck");
        return this;
    }
}

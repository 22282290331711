function IsResourceOwner(input) {
    if (typeof (input) === "boolean") {
        return input
            ? "own"
            : "others";
    }
    return input;
}
export { IsResourceOwner, };
/**
 * Resolves a {@see ResourceOwner} or {@see boolean} to a {@see ResourceOwner} string.
 * @param input Either the resource owner, or boolean.
 * True indicate "own".
 * False indicate "others".
 */ 

<script setup>
import GenericSearch from "@/components/form/search/GenericSearch.vue";
import { capitalize, ref } from "vue";
import GenericSearchResult from "@/components/form/search/GenericSearchResult.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import backend from "@/api/backend";
import IconContainer from "@/components/icon/IconContainer.vue";
import { debounce } from "@/script/convert";
import ToolTip from "@/components/badges/ToolTip.vue";
import IndexedDBStore from "@/storage/IndexedDB";


const props = defineProps({
	"id": {
		type: String,
		default: () => null,
	},
	"guildId": {
		type: String,
		required: true,
	},
	"modelValue": {
		type: [String, Number, Object, Array],
		default: () => null,
	},
	"maxResults": {
		type: Number,
		default: () => 4,
	},
	"excludeIds": {
		type: Array,
		default: () => [],
	},
});
const tooltip = "In order link this case with another, you need to have access to read both cases. " +
	"If a case does not show up, it may be because you don't have access to view it.";
const emit = defineEmits(["update:modelValue"]);
const searchValue = ref("");
const results = ref([]);
const loading = ref(false);
const idbStore = IndexedDBStore();

const debouncedSearch = debounce(doSearch, 400, () => {
	loading.value = true;
});
async function doSearch(event) {
	results.value = [];
	loading.value = true;

	const value = parseInt(event?.target?.value||"");
	if (isNaN(value)) return loading.value = false;

	/**
	 * Even if all results are excluded, we will be able to get at
	 * least {maxResults} more that should not be possible to be excluded.
	 */
	const count = props.excludeIds.length + props.maxResults;

	const {body, status} = await backend(`/public/guilds/${props.guildId}/cases/search/${value}?limit=${count}`);
	if (status!==200) return loading.value = false;

	await idbStore.setUsers(body.users);

	results.value = body.cases.filter(c => !props.excludeIds.includes(c.sid));
	loading.value = false;
}
</script>

<template>
	<GenericSearch
		v-model:search-value="searchValue"
		:selected="modelValue"
		search-icon="Cases"
		:max-results="4"
		placeholder-blur="Select a Case"
		placeholder-focus="Search by case number"
		:results="results"
		:loading="loading"
		remove-title="Unselect this case"
		@update:selected="emit('update:modelValue', $event)"
		@input="debouncedSearch"
	>
		<template #footer>
			<DynamicIcon
				icon="LightBulb"
				size="18"
			/>
			<p>Cases you do not have access to view will not show up</p>
			<ToolTip class="text-left ml-2" :description="tooltip" />
		</template>

		<template #selectedBlur="{ value }">
			<DynamicIcon :icon="value.caseType" />
			<p class="text-inherit">
				{{ capitalize(value.caseType) }} &bull; #{{ value.sid }}
			</p>
		</template>

		<template #selectedFocus="{ value }">
			<DynamicIcon :icon="value.caseType" />
			<p class="text-inherit">
				{{ capitalize(value.caseType) }} &bull; #{{ value.sid }}
			</p>
		</template>

		<template #result-item="{ result }">
			<GenericSearchResult>
				<IconContainer
					size="24"
					padding="2"
					:icon="result.caseType"
				/>
				<p class="text-inherit">
					{{ capitalize(result.caseType) }} &bull; #{{ result.sid }}
				</p>
			</GenericSearchResult>
		</template>
</GenericSearch>
</template>
<script setup>
import IdBadge from "@/components/badges/IdBadge";
import UploadedFile from "@/components/form/file-upload/UploadedFile.vue";
import UserHighlightPanel from "@/components/panels/UserHighlightPanel.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {appealColor, capitalize, displayTime} from "@/script/convert";
import {computed, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import LocalDate from "@/components/info/parts/LocalDate.vue";
import ToolTip from "@/components/badges/ToolTip.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import PermsCond from "@/components/PermsCond.vue";
import Nodes from "@/script/Nodes";
import EvalPerms from "@/script/EvalPerms";
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import SearchStaffMember from "@/components/form/search/SearchStaffMember.vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import UserCache from "@/storage/UserCache";
import {Account} from "@/storage/AccountCache";
import IndexedDBStore from "@/storage/IndexedDB";
import LiveBlinking from "@/components/icon/LiveBlinking.vue";
import {UI} from "@/storage/UICache";
import ServerIcon from "@/components/icon/ServerIcon.vue";
import DarkBadge from "@/components/badges/DarkBadge.vue";

// Re-add this tool-tip when we can fix the broken positioning issue it can have
//const statusTooltip = "Statues are mostly just indicators for both parties. The 'Awaiting' status will highlight to the appealing user that action is required."
const router = useRouter();
const route = useRoute();
const idbStore = IndexedDBStore();
const users = UserCache();
const account = Account();
const ui = UI();
const emit = defineEmits(["updateCache", "close", "inspect"]);
const props = defineProps({
	"appeal": {
		type: Object,
		required: true,
	},
	"personal": { // Personal view
		type: Boolean,
		default: () => false,
	},
	"guild": { // Required in personal view
		type: Object,
		default: () => null,
	},
	"setStatus": {
		type: Function,
		default: () => () => {},
	},
	"setAssignedStaff": {
		type: Function,
		default: () => () => {},
	},
	// Indicate we're saving changes
	"saving": {
		type: Boolean,
		default: () => false,
	},
	"error": {
		type: Object,
		default: () => ({label: "", texts: []}),
	},
});
const state = ref(props.appeal.state);
const verdictUserTitle = computed(()=>{
	if (props.personal || !users.titleOf(props.appeal.verdict.by)) return "Verdict creator";
	return `Verdict creator • ${users.titleOf(props.appeal.verdict.by)}`
});
const singleMetaPanel = computed(()=>{
	return !(
		props.appeal.verdict.by
		&& (!props.personal && props.appeal.meta.assigned.length)
		&& (!props.personal && account.can(Nodes.CaseAppeals.APPEAL.ASSIGNMENT.CREATE(props.appeal.case.caseType, props.appeal.case.creatorId===account.id)))
	)
});
const sidePanelOpen = computed(() => {
	return !!route.params.resource;
});
const assignStaff = ref(null);
const assignedStaff = ref({
	staff: null, // User object of the assigned user
	saving: false,
	unAssignConfirmed: false,
	regretTimer: null,
});

// Assignment bypass aside; permission to navigate (read) the parent case
const gotoCasePerm = props.appeal && !props.personal && props.appeal?.case?.isArchived
	? Nodes.Cases.CASE.ARCHIVED.READ(props.appeal.case.caseType, props.appeal?.case?.creator === account.id)
	: Nodes.Cases.CASE.READ(props?.appeal.case.caseType, props.appeal.case.creator === account.id);

async function removeAssigned() {
	clearTimeout(assignedStaff.value.regretTimer);
	if (!assignedStaff.value.staff) {
		assignedStaff.value.staff = idbStore.getStaff(props.appeal.meta.assigned[0]);

		if (!assignedStaff.value.staff) return;
	}

	if (!assignedStaff.value.unAssignConfirmed) {
		assignedStaff.value.regretTimer = setTimeout(() => {
			assignedStaff.value.unAssignConfirmed = false;
		}, 3000);
		return assignedStaff.value.unAssignConfirmed = true;
	}

	assignedStaff.value.saving = true;
	const currentId = assignedStaff.value.staff.userId;

	// Remove staff
	await props.setAssignedStaff(currentId.toString(), true);
	assignedStaff.value.saving = false;

	// Emit removed staff
	emit("updateCache", {target: "assigned.remove", id: currentId, value: currentId});
	assignedStaff.value.staff = null;
}

async function setAssigned() {
	if (!assignedStaff.value.staff || assignedStaff.value.saving) return;

	const wasSuccessful = await props.setAssignedStaff(assignedStaff.value.staff.userId);
	if (assignStaff.value && wasSuccessful) assignStaff.value.close();
}
</script>

<template>
	<InspectResourceLayout v-if="appeal">
		<template #head>
			<div class="flex items-center w-full justify-between">
				<h2>Appeal for case #{{ appeal.case.sid }}</h2>

				<div class="AppealActionButtons">
					<CloseButton
						label="Close all panels"
						@click="emit('close')"
					/>
				</div>
			</div>

			<p class="text-slate-500 text-sm pt-1 inline items-center">
				Created
				<LocalDate
					:date="appeal.dates.createdAt"
					time
					dynamic
				/>
				<template v-if="personal">
					in
					<ServerIcon
						class="inline-block mx-0.5"
						:server-id="guild.id"
						:name="guild.name"
						:icon="guild.icon"
						:size="16"
					/>
					<strong>{{ guild.name }}</strong>
					<IdBadge :label="guild.id" class="hidden lg:inline-block text-xs mx-1" />
				</template>
			</p>
			<div class="flex gap-4 justify-between">
				<div class="w-min flex gap-2 mt-2 items-center">
					<DarkBadge
						v-if="appeal.state==='caseArchived'"
						label="Case Archived"
						icon-class="fill-green-500"
						dot
					/>
					<DarkBadge
						v-else
						:label="capitalize(appeal.state)"
						:icon-class="`fill-${appealColor(appeal.state)}-500`"
						dot
					/>

					<!-- Case: linked -->
					<RouterLink
						v-if="!personal && EvalPerms(gotoCasePerm, account.permissions).access"
						class="flex gap-2 items-center"
						:to="{name:'guild-cases', params:{...route.params, caseSid: appeal.case.sid}}"
					>
						<DarkBadge
							:label="capitalize(appeal.case.caseType)"
							:icon-class="{
								'text-note-normal': appeal.case.caseType === 'note',
								'text-warning-normal': appeal.case.caseType === 'warning',
								'text-timeout-normal': appeal.case.caseType === 'timeout',
								'text-kick-normal': appeal.case.caseType === 'kick',
								'text-ban-normal': appeal.case.caseType === 'ban',
							}"
							:icon="capitalize(appeal.case.caseType)"
						/>
						<span class="uppercase text-xs font-bold text-sky-400">Go&nbsp;to&nbsp;case</span>
					</RouterLink>
					<!-- Case: no linking -->
					<DarkBadge
						v-else
						:label="capitalize(appeal.case.caseType)"
						:icon-class="{
								'text-note-normal': appeal.case.caseType === 'note',
								'text-warning-normal': appeal.case.caseType === 'warning',
								'text-timeout-normal': appeal.case.caseType === 'timeout',
								'text-kick-normal': appeal.case.caseType === 'kick',
								'text-ban-normal': appeal.case.caseType === 'ban',
							}"
						:icon="capitalize(appeal.case.caseType)"
					/>
				</div>

				<!-- Todo disable when open -->
				<RouterLink
					:to="{ name: route.name, params: {...route.params, resource: 'chat' }}"
				>
					<BasicButton
						label="Chat"
						:type="appeal.verdict.date ? 'light' : 'default'"
					>
						<LiveBlinking
							v-if="ui.makeChecker('appealChat', appeal._id)"
							color="dark-sky"
						/>
					</BasicButton>
				</RouterLink>
			</div>
		</template>

		<template #main>
			<ErrorCallout
				v-if="error.label || error.texts?.length"
				:label="error.label"
				:texts="error.texts"
			/>

			<div class="meta-grid" :class="{'meta-halved': sidePanelOpen,'single': singleMetaPanel}">
				<!-- The appealing user -->
				<UserHighlightPanel
					class="justify-center"
					:username="users.username(appeal.userId)"
					title="Appealing User"
					:user-id="appeal.userId"
					:avatar-url="users.avatarOf(appeal.userId, 128)"
					@inspect="emit('inspect')"
				/>

				<UserHighlightPanel
					v-if="appeal.verdict.by"
					:username="users.username(appeal.verdict.by)"
					:title="verdictUserTitle"
					:user-id="appeal.verdict.by"
					:avatar-url="users.avatarOf(appeal.verdict.by, 128)"
					:users-label="appeal.meta.assigned.length ? `${appeal.meta.assigned.length} reviewer${appeal.meta.assigned.slice(1).length ? 's' : ''}` : undefined"
					:users="appeal.meta.assigned.length ? appeal.meta.assigned.map(id => users.avatarOf(id)) : undefined"
				/>
				<!-- Reviewer(s) -->
				<div v-else-if="!personal && appeal.meta.assigned.length">
					<UserHighlightPanel
						:username="users.username(appeal.meta.assigned[0], true)"
						:title="users.titleOf(appeal.meta.assigned[0])"
						:avatar-url="users.avatarOf(appeal.meta.assigned[0])"
						:users-label="appeal.meta.assigned.length!==1 ? `+ ${appeal.meta.assigned.length-1} more reviewer${appeal.meta.assigned.slice(1).length ? 's' : ''}` : undefined"
						:users="appeal.meta.assigned.slice(1).length ? appeal.meta.assigned.slice(1).map(id => users.avatarOf(id)) : undefined"
					>
						<PermsCond :needs="Nodes.CaseAppeals.APPEAL.ASSIGNMENT.DELETE(appeal.case.caseType)">
							<BasicButton
								:label="assignedStaff.unAssignConfirmed ? 'Are you sure?' :'Un-assign' "
								type="danger"
								:disabled="assignedStaff.saving"
								@click="removeAssigned"
							/>
						</PermsCond>
					</UserHighlightPanel>
				</div>
				<!-- Suggest assigning reviewers -->
				<PermsCond v-else-if="!personal" :needs="Nodes.CaseAppeals.APPEAL.ASSIGNMENT.CREATE(appeal.case.caseType, appeal?.case?.creatorId===account.id)">
					<div
						class="p-4 bg-neutral-700/50 border-details rounded-xl lg:flex gap-2 justify-between items-center flex-col-halved"
					>
						<div>
							<p class="inline">
								Want to delegate some responsibility? Assign a staff member to handle this appeal.
							</p>
							<ToolTip
								v-if="false"
								class="inline ml-1"
								:description="`Assigned staff will get access to this appeal. They will also receive full read access to case #${appeal.case.sid}.`"
							/>
						</div>
						<div class="flex gap-2 items-center">
							<BasicButton
								label="Assign staff"
								type="light"
								class="shadow-sm border border-neutral-200"
								@click="assignStaff.showModal()"
							/>
						</div>

						<dialog ref="assignStaff" class="modal overflow-visible">
							<div class="flex flex-col gap-4 p-4">
								<h1>Assign staff</h1>
								<div
									class="p-4 bg-neutral-700/40 border-details text-sm rounded-lg gap-2 justify-between items-center"
								>
									<p>In addition to the staff's current permissions:</p>
									<ul class="mt-2">
										<li class="flex">
											<DynamicIcon icon="Check" class="mr-1" />
											<p>
												Gives full <strong>read</strong> access to case #{{ appeal.case.sid }} while
												assigned
											</p>
										</li>

										<li class="flex">
											<DynamicIcon icon="Check" class="mr-1" />
											<p>
												Gives ability to <strong>archive</strong> case #{{ appeal.case.sid }} while
												assigned
											</p>
										</li>

										<!-- Special permission: lift timeout -->
										<li v-if="appeal.case.caseType==='timeout'" class="flex">
											<DynamicIcon icon="Check" class="mr-1" />
											<p>
												Staff can <strong>lift timeout</strong> of afflicted user by granting the
												appeal
											</p>
										</li>
										<!-- Special permission: unban -->
										<li v-if="appeal.case.caseType==='ban'" class="flex">
											<DynamicIcon icon="Check" class="mr-1" />
											<p>Staff can <strong>unban</strong> afflicted user by granting the appeal</p>
										</li>

										<li class="flex">
											<DynamicIcon icon="Check" class="mr-1" />
											<p>Gives access to <strong>manage this appeal</strong> while assigned…</p>
										</li>

										<li class="flex">
											<DynamicIcon icon="Close" class="mr-1" />
											<p>… except the ability to modify assignees in this appeal</p>
										</li>

										<li class="flex">
											<DynamicIcon icon="Close" class="mr-1" />
											<p>
												Does not modify pre-existing permissions to this appeal or case
												#{{ appeal.case.sid }}
											</p>
										</li>
									</ul>
								</div>

								<SearchStaffMember
									v-model="assignedStaff.staff"
									:max-results="2"
								/>

								<div class="flex gap-2 justify-end">
									<BasicButton
										label="Cancel"
										type="link"
										:disabled="assignedStaff.saving"
										@click="assignStaff.close()"
									/>
									<BasicButton
										label="Assign"
										:disabled="!assignedStaff.staff || assignedStaff.saving"
										@click="setAssigned"
									/>
								</div>
							</div>
						</dialog>
					</div>
				</PermsCond>
			</div>

			<!-- Appeal verdict box -->
			<div v-if="appeal.verdict.date">
				<div class="flex items-center gap-2">
					<h3 class="mb-1">
						Verdict Statement
					</h3>
					<ToolTip
						:description="`Verdict was reached ${displayTime(appeal.verdict.date, true, true)}`"
					/>
				</div>
				<p>{{ appeal.verdict.message }}</p>
			</div>

			<!-- Appeal message -->
			<div id="message">
				<h3 class="mb-1">
					Appeal Message <span v-if="appeal.appealContent.lastEdited">(edited {{
						displayTime(appeal.appealContent.lastEdited)
					}})</span>
				</h3>
				<p>{{ appeal.appealContent.content }}</p>
			</div>

			<!-- Files -->
			<div
				v-if="appeal.files.length"
				id="files"
				class="flex gap-2"
			>
				<!-- Drop field -->
				<!--				<DropFileField class="self-stretch" />-->
				<!-- Available files -->
				<UploadedFile
					v-for="file in appeal.files"
					:id="file.fileId"
					:key="file.fileId"
					:file-size="file.meta.size"
					:file-name="`${file.fileId}.${file.meta.ext}`"
					:mime="file.meta.mime"
					:background-image="file.meta.mime.startsWith('image') ? `https://files.archivian.net/guilds/${appeal.guild}/appeals/${appeal.id}/files/${file.fileId}` : undefined"
				/>
			</div>
		</template>

		<template #footer>
			<!-- Closed appeal footer -->
			<div v-if="personal || appeal.verdict.by" class="flex gap-2 w-full justify-end items-center">
				<BasicButton
					label="Close"
					type="link"
					:disabled="saving"
					@click="emit('close')"
				/>
			</div>
			<!-- Open appeal footer -->
			<div v-else-if="!personal" class="flex gap-2 w-full justify-end items-center">
				<!-- Status change -->
				<PermsCond :needs="Nodes.CaseAppeals.APPEAL.STATE(appeal.case.caseType, '*')">
					<p>Change status to</p>
					<select
						id="status"
						v-model="state"
						name="status"
						class="px-4 py-3 bg-neutral-700/50 border-details shadow rounded"
					>
						<option value="open">
							Open
						</option>
						<option value="reviewing">
							Reviewing
						</option>
						<option value="investigating">
							Investigating
						</option>
						<option value="deliberating">
							Deliberating
						</option>
						<!-- TODO: For 'awaiting', if user submit anything, have state automatically change to reviewing, alert Staff -->
						<option value="awaiting">
							Awaiting
						</option>
					</select>

					<PermsCond :needs="Nodes.CaseAppeals.APPEAL.VERDICT(appeal.case.caseType, '*')">
						<p v-if="state===appeal.state">
							or
						</p>
					</PermsCond>
				</PermsCond>

				<!-- Create verdict -->
				<PermsCond v-if="state===appeal.state" :needs="Nodes.CaseAppeals.APPEAL.VERDICT(appeal.case.caseType, '*')">
					<BasicButton
						label="Create verdict"
						@click="router.push({
							name: 'guild-appeals',
							params: {
								appealId: appeal._id,
								resource: 'verdict',
								resourceId: 'new'
							}
						})"
					/>
				</PermsCond>

				<!-- Save/cancel state changes -->
				<template v-else>
					<BasicButton
						label="Cancel"
						type="link"
						:disabled="saving"
						@click="state=appeal.state"
					/>
					<BasicButton
						:label="saving ? 'Saving&hellip;' : 'Save status'"
						:disabled="saving"
						@click="setStatus(state)"
					/>
				</template>
			</div>
		</template>
	</InspectResourceLayout>
</template>

<style scoped>
.meta-grid {
	@apply grid gap-2
}
.meta-grid:not(.meta-halved) {
	@apply grid-rows-2 md:grid-rows-1 md:grid-cols-2;
}
.meta-halved .flex-col-halved {
	@apply flex-col items-start;
}

.meta-grid.meta-halved {
	@apply 3xl:grid-rows-2 3xl:grid-cols-1;
}
.meta-grid.single {
	@apply grid-rows-1 grid-cols-1;
}
</style>
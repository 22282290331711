<template>
	<g>
		<path
			d="M17 16.5C15.4123 17.1489 13.7152 17.4883 12 17.5C10.2848 17.4883 8.58773 17.1489 7 16.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<ellipse
			cx="15.3333"
			cy="11.6675"
			rx="1.5"
			ry="1.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<ellipse
			cx="8.66663"
			cy="11.6675"
			rx="1.5"
			ry="1.75"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.30234 16.951C7.83737 18.016 7.27656 19.0365 6.62682 20C5.05757 19.503 3.56513 18.79 2.19242 17.8815H2.19427C1.555 13.6513 2.49082 9.33361 4.82418 5.74769C6.15824 5.16098 7.55987 4.74189 8.99702 4.5C9.10248 4.85075 9.18725 5.20991 9.27188 5.56913C10.174 5.42237 11.0861 5.34524 12 5.33842C12.914 5.34524 13.826 5.42237 14.7282 5.56913C14.8128 5.20991 14.8976 4.85075 15.003 4.5C16.4402 4.74189 17.8418 5.16098 19.1759 5.74769C21.5092 9.33361 22.445 13.6513 21.8058 17.8815H21.8076C20.4349 18.79 18.9425 19.503 17.3732 20C16.7235 19.0365 16.1627 18.016 15.6977 16.951"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconDiscord"
};
</script>
<template>
	<button
		:aria-label="`Goto dashboard for server ${name}`"
		class="p-2 rounded hover:bg-neutral-800 hover:border-sky-400 border transition flex items-center gap-2 w-full"
		:class="{'bg-neutral-900/80 border-neutral-700': active, 'border-transparent': !active}"
		@click="loading = !active"
	>
		<ServerIcon
			:server-id="id"
			:name="name"
			:icon="icon"
			:size="24"
			class="shrink-0"
		/>
		<p class="inline-block truncate">
			{{ name }}
		</p>
		<LoadingEllipsis v-if="loading" class="inline-block" />
	</button>
</template>

<script setup>
import ServerIcon from "@/components/icon/ServerIcon.vue";
import {ref} from "vue";
import LoadingEllipsis from "@/components/icon/LoadingEllipsis.vue";

const loading = ref(false);

defineProps({
	"id": {
		type: String,
		required: true,
	},
	"name": {
		type: String,
		required: true,
	},
	"icon": {
		type: String,
		default: () => null,
	},
	"active": {
		type: Boolean,
		default: () => false,
	}
});
</script>

<style scoped>

</style>
<script setup>
import {capitalize} from "vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import StackedAvatars from "@/components/icon/StackedAvatars.vue";
import ServerIcon from "@/components/icon/ServerIcon.vue";
import UserCache from "@/storage/UserCache";
import {Account} from "@/storage/AccountCache";
import IdBadge from "@/components/badges/IdBadge.vue";
import TableCard from "@/components/panels/TableCard.vue";
import {UI} from "@/storage/UICache";
import LiveBlinking from "@/components/icon/LiveBlinking.vue";

defineProps({
	"isPersonal": {
		type: Boolean,
		required: true,
		default: false,
	},
	"appeal": {
		type: Object,
		required: true,
	},
	"hasUnreadChat": {
		type: Array,
		default: () => [],
	}
});

const handlers = {

	/**
	 * Returns a UI color given an appeal's state and whether we're in personal view or not
	 * @param {string} state
	 * @param {boolean} isPersonal
	 * @returns {string}
	 */
	colors: (state, isPersonal) => {
		switch (state) {
			case "rejected":
				return "label-red";
			case "approved":
			case "caseArchived":
				return "label-green";
			case "open":
				if (!isPersonal) return "label-orange";
				return "label-gray";
			case "awaiting":
				if (isPersonal) return "label-orange";
				return "label-gray";
			default:
				return "label-gray";
		}
	},
	/**
	 * Returns a display label given a case state
	 * @param {string} state
	 * @returns {Capitalize<string>|string}
	 */
	stateLabel: state => {
		if (state === "caseArchived") return "Case Archived";
		return capitalize(state);
	},
}
const account = Account();
const users = UserCache();
const ui = UI();
</script>

<template>
	<TableCard
		class="hover:border-neutral-600 transition-all cursor-pointer"
	>
		<template #content>
			<UserAvatar
				v-if="!isPersonal"
				:user-id="appeal.userId"
				:avatar="users.user(appeal.userId)?.avatar"
				size="48"
				class="shrink-0"
			/>
			<ServerIcon
				v-else
				:server-id="appeal.guildId"
				:name="account.getGuild(appeal.guildId).name"
				:icon="account.getGuild(appeal.guildId).icon"
				size="48"
			/>
			<div class="flex flex-col truncate">
				<p class="font-bold truncate">
					#{{ appeal.case.sid }}&nbsp;&bull;
					<template v-if="!isPersonal">
						{{ users.username(appeal.userId) ?? "Unknown User" }}
					</template>
					<template v-else>
						{{ account.getGuild(appeal.guildId, false)?.name ?? `Guild ID ${appeal.guildId}` }}
					</template>
				</p>
				<div class="flex items-center gap-2">
					<p :class="handlers.colors(appeal.state, isPersonal)">
						{{ handlers.stateLabel(appeal.state) }}
					</p>
					<IdBadge v-if="!isPersonal" :label="appeal.userId" />
					<IdBadge
						v-else
						:label="appeal.guildId"
						class="w-fit"
						title="The ID of the guild this appeal was made in"
					/>
				</div>
			</div>
		</template>

		<template #footer-left>
			<DynamicIcon
				:icon="capitalize(appeal.case.caseType)"
				:class="[appeal.case.caseType, 'shrink-0']"
			/>
			<p :class="appeal.case.caseType">
				{{ capitalize(appeal.case.caseType) }}
			</p>

			<p v-if="ui.makeChecker('appealChat', appeal._id)" class="text-xs text-nowrap text-red-100 rounded-md px-2 py-1.5 leading-none font-semibold">
				<LiveBlinking />
				New Message
			</p>
		</template>

		<template #footer-right>
			<template v-if="isPersonal || !appeal.meta?.assigned?.length">
				<template v-if="appeal.case?.creatorId">
					<p class="text-sm">
						Case by
						{{
							users.username(appeal.case.creatorId) ?? (isPersonal ? "Redacted User" : "Unknown User")
						}}
					</p>
					<UserAvatar
						:user-id="appeal.case.creatorId ?? '00000000000000000000'"
						:avatar="users.user(appeal.case.creatorId)?.avatar"
						size="24"
						class="shrink-0"
					/>
				</template>
			</template>

			<template v-else>
				<template v-if="appeal.meta?.assigned?.length === 1">
					<p class="text-sm">
						Assigned to
						{{ users.username(appeal.meta.assigned[0]) ?? "Unknown User" }}
					</p>
					<UserAvatar
						:user-id="appeal.meta.assigned[0] ?? '00000000000000000000'"
						:avatar="users.user(appeal.meta.assigned[0])?.avatar"
						size="24"
						class="shrink-0"
					/>
				</template>
				<template v-else-if="appeal.meta?.assigned?.length">
					<p class="text-sm pr-1">
						Assigned to
					</p>
					<StackedAvatars
						:user-ids="appeal.meta.assigned"
					/>
				</template>
			</template>
		</template>
	</TableCard>
</template>

<style scoped>
.ban {
	@apply text-ban-normal;
}

.kick {
	@apply text-kick-normal;
}

.timeout {
	@apply text-timeout-normal;
}

.warning {
	@apply text-warning-normal;
}

.note {
	@apply text-note-light;
}

[class^="label-"] {
	@apply px-2 py-1.5 rounded-md text-xs leading-none;
}

.label-red {
	@apply bg-red-500/30 text-red-300;
}

.label-orange {
	@apply bg-orange-500/30 text-orange-300;
}

.label-gray {
	@apply bg-gray-500/30 text-gray-300;
}

.label-green {
	@apply bg-green-500/30 text-green-300;
}
</style>
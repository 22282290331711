{
<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {computed} from "vue";

const props = defineProps({
	"to": {
		type: Object, // If not defined, the other must be
		default: () => null,
	},
	"href": {
		type: String, // If not defined, the other must be
		default: () => null,
	},
	"label": {
		type: String,
		default: () => "Read more",
	},
	// Explicitly include the `noreferrer` when `href` is pointing to outside Archivian
	"allowReferrer": {
		type: Boolean,
		default: () => false,
	},
});

const relPolicy = computed(() => {
	if (!props.href) return null;

	try {
		const u = new URL(props.href);
		const urls = JSON.parse(process.env.VUE_APP_TRUSTED_HOSTS);

		if (!urls.includes(u.host)) {
			if (props.allowReferrer) return "noopener";
			return "noopener noreferrer";
		}

		return null;
	} catch(e) {
		console.error(e);
		return "noopener noreferrer";
	}
});
</script>

<template>
	<RouterLink
		v-if="to"
		:to="to"
		class="styled-link ReadMore"
	>
		{{ label }}
		<DynamicIcon icon="ArrowDiagonalUp" size="18" />
	</RouterLink>
	<a
		v-else
		:href="href"
		:rel="relPolicy"
		target="readMore"
		class="styled-link ReadMore"
	>
		{{ label }}
		<DynamicIcon icon="ArrowDiagonalUp" size="18" />
	</a>
</template>
<style>
.ReadMore {
	@apply flex gap-1 items-center max-w-fit hover:backdrop-blur-sm;
}
</style>}
<script setup>
import {fileSize} from "@/script/convert";
import ToolTip from "@/components/badges/ToolTip.vue";
import SwitchBox from "@/components/form/SwitchBox.vue";
import UserHighlightPanel from "@/components/panels/UserHighlightPanel.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import UserCache from "@/storage/UserCache";
import {inject, ref} from "vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import JoyrideBox from "@/components/joyrides/JoyrideBox.vue";

defineProps({
	"pills": {
		type: Object,
		default: () => ({}),
	},
	"file": {
		type: Object,
		required: true,
	},
	"handleUpdateVisibility": {
		type: Function,
		default: () => () => {},
	},
	"fileLink": {
		type: String,
		required: true,
	},
	"targetId": {
		type: String,
		default: () => null,
	},
	"caseSid": {
		type: [Number, String],
		required: true,
	},
});
const personal = inject("isPersonal");
const users = UserCache();
const cases = CaseAppealBrowser();
const states = ref({
	previewFailed: false,
});
const hostedFileToolTip = "This is a direct link to a copy of the file, hosted by us. Who can use the direct file link depend on the settings for it.";
const discordLinkTip = "Note that this link is from an external party, meaning we have no control over when it stops working.\n" +
	"This is why we keep a permanent copy of the file ourselves.";
</script>

<template>
	<div class="flex gap-4">
		<UserHighlightPanel
			v-if="file.creatorId"
			:class="{ 'basis-1/2': !personal }"
			:username="users.username(file.creatorId)||'Unknown Staff'"
			:title="['File Uploader',users.titleOf(file.creatorId)].filter(Boolean).join(' • ')"
			:avatar-url="users.avatarOf(file.creatorId, 128)"
			:user-id="file.creatorId"
		/>
		<div
			v-if="!personal"
			id="visibility"
			class="switch-container grow"
		>
			<SwitchBox
				id="visibility"
				label="Show to user"
				:saving="cases.isLoading('fileVisibility', file.fileId)"
				:enabled="pills.visibleToTarget && !pills.archived"
				:disabled="pills.archived"
				class="max-w-min"
				@toggle="handleUpdateVisibility"
			/>
			<div class="flex items-center gap-2">
				<p>
					Whether <span v-if="targetId" class="font-bold">{{
						users.username(targetId) || "the target user"
					}}</span><span v-else>the user</span> can see this file.
				</p>
				<ToolTip
					description="When visible, user can preview/download the file, see file information, dates, and creator."
				/>
			</div>
		</div>
	</div>


	<div>
		<dl>
			<dt class="label">
				Filetype
			</dt>
			<dd>{{ file.meta?.mime }}</dd>
			<dt class="label">
				Filesize
			</dt>
			<dd>{{ fileSize(file.meta?.size) }}</dd>
			<dt class="label flex items-center gap-2">
				Original URL
				<ToolTip
					v-if="!file.original.startsWith('https://cdn.discordapp.com')"
					class="font-normal"
					:description="discordLinkTip"
				/>
			</dt>
			<dd>
				<JoyrideBox
					v-if="file.original.startsWith('https://cdn.discordapp.com')"
					joyride-id="cases.files.discordLinksExpire"
				>
					<template #description>
						<h4 class="text-sky-400">
							Discord link will expire
						</h4>
						<p class="text-sky-400">
							This file originated from Discord, and they use a token in the URL that is valid for a limited time.
							This means the original URL may stop working after a while, or when the file is deleted.
							Don't worry though, we're hosting a copy of the file for you ("Direct link").
						</p>
					</template>
				</JoyrideBox>

				<code class="break-all">{{ file.original }}</code>
				<a
					:href="file.original"
					:target="`${file.fileId}_original`"
					rel="external nofollow noopener"
					referrerpolicy="strict-origin-when-cross-origin"
					class="text-blue-500"
				>
					<DynamicIcon icon="ArrowDiagonalUp" />
				</a>
			</dd>
			<dt class="label flex items-center gap-2">
				Direct link
				<ToolTip class="font-normal" :description="hostedFileToolTip" />
			</dt>
			<dd>
				<a
					:href="fileLink"
					:target="file.fileId"
					class="text-blue-500"
				>
					{{ file.fileId }}{{ file.meta?.ext ? "." + file.meta.ext : "" }}
					<DynamicIcon icon="ArrowDiagonalUp" />
				</a>
			</dd>
		</dl>
	</div>

	<div
		v-if="file.meta?.mime?.startsWith('image') && !states.previewFailed"
		id="preview"
		class="w-full h-96 rounded-md bg-neutral-700/50 border-details"
	>
		<img
			:src="fileLink"
			:alt="`Preview of file '${file.fileId}' from case ${caseSid}`"
			class="object-contain w-full h-96 rounded-md chessboard"
			@error="states.previewFailed = true"
		>
	</div>
</template>

<style scoped>
.switch-container {
	@apply p-4 bg-neutral-800/50 border border-neutral-700 border-t-neutral-600 shadow rounded-xl flex flex-col gap-2 grow
	basis-1/2;
}

dd {
	@apply border-b border-neutral-700/50 mb-2;
}
</style>
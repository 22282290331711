<script setup>
import DropDownTrigger from "@/components/form/dropdown/DropDownTrigger.vue";
import DropDownContent from "@/components/form/dropdown/DropDownContent.vue";
import {nextTick, onBeforeMount, onMounted, provide, ref} from "vue";
import positionMover from "@/script/positionMover";

const p = defineProps({
	"label": {
		type: String,
		default: "Dropdown",
	},
	"icon": {
		type: String,
		default: "Chevron",
	},
	"side": {
		type: String,
		enum: ["left", "right", "center"],
		default: () => "left",
	},
	"vertical": {
		type: String,
		enum: ["top", "overlay", "bottom"],
		default: () => "bottom",
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	"teleportTo": {
		type: String,
		default: () => "body",
	},
});

const dropdownId = Math.random().toString(36).slice(2);
const dropdownWrapper = ref(null);
const container = ref(null);
const active = ref(false);
provide("dropdownActive", active);
provide("alignTo", p.side);

function toggle(forceState) {
	if (p.disabled) return;

	const newState = forceState !== undefined ? forceState : !active.value
	if (!newState) {
		const elm = document.getElementById(dropdownId);
		if (elm) elm.close();
	}

	active.value = newState;

	if (active.value) {
		nextTick(() => {
			const elm = document.getElementById(dropdownId);
			elm.show();

			return positionMover(
				elm,
				dropdownWrapper.value,
				{
					side: p.side,
					vertical: p.vertical,
				}
			);
		});
	}
}

function close() {
	toggle(false);
}

onMounted(() => {
	document.addEventListener("key-escape", close);
});
onBeforeMount(() => {
	document.removeEventListener("key-escape", close);
});
</script>

<template>
	<div
		ref="dropdownWrapper"
		class="dropdown w-fit"
		:class="{
			'opacity-60': disabled,
		}"
	>
		<slot
			name="trigger"
			:toggle="() => toggle()"
			:active="active"
			:disabled="disabled"
		>
			<DropDownTrigger
				:active="active"
				:label="label"
				:icon="icon"
				:class="{
					'cursor-not-allowed': disabled,
				}"
				@click="() => toggle()"
			/>
		</slot>

		<Teleport :to="teleportTo">
			<DropDownContent
				v-if="active"
				:id="dropdownId"
				ref="container"
				v-click-away="close"
				class="overflow-hidden w-fit m-0"
			>
				<slot
					:toggle="() => toggle()"
					:disabled="disabled"
				/>
			</DropDownContent>
		</Teleport>
	</div>
</template>

<style scoped>
.dropdown {
	@apply relative;
}
</style>
/**
 * Operations that target a specific guild
 */
export const GUILD_OPS = {
    /**
     * A staff member was updated, removed, or added in the guild you're browsing
     */
    STAFF_UPDATE: 1,
    /**
     * Discord related information was updated for the guild
     */
    GUILD_DISCORD_UPDATE: 2,
    /**
     * Many details about the guild you're in were updated (staff, teams, titles, etc.)
     */
    GUILD_UPDATE: 3,
    /**
     * Information about a specific title in a guild was updated
     */
    TITLE_UPDATE: 4,
    /**
     * Information about a specific team in a guild was updated
     */
    TEAM_UPDATE: 5,
    /**
     * Tells the user that their permissions in a given guild were updated
     */
    PERMISSIONS_UPDATE: 6,
    /**
     * A specific case was updated
     */
    CASE_UPDATE: 7,
    /**
     * Cases were updated (added / removed)
     * @deprecated `CASE_CREATED` and `CASE_DELETED` should be used instead
     */
    CASES: 8,
    /**
     * An appeal was updated
     */
    APPEAL_UPDATE: 11,
    /**
     * Appeals were updated (added / removed)
     * @deprecated `APPEAL_UPDATE` and `APPEAL_CREATED` should be used instead
     */
    APPEALS: 12,
    /**
     * Guild Config was altered, specifically, the settings for types of cases ({@link IGuildConfigCase})
     */
    CASE_SETTINGS: 15,
    /**
     * A guild channel was added, edited, or removed
     */
    CHANNEL: 16,
    /**
     * A guild role was added, edited, or removed
     */
    ROLE: 17,
    /**
     * A guild member was added, edited, or removed
     */
    MEMBER: 18,
    /**
     * An appeal had a new chat message added
     */
    NEW_APPEAL_CHAT_MESSAGE: 19,
    /**
     * A case was created
     */
    CASE_CREATED: 21,
    /**
     * A case was permanently deleted
     */
    CASE_DELETED: 22,
    /**
     * An appeal was created
     */
    APPEAL_CREATED: 25,
};
/**
 * Operations that target a user's account
 */
export const USER_OPS = {
    /**
     * Your account was updated
     */
    ACCOUNT_UPDATE: 0,
    /**
     * A case on you was updated
     */
    CASE_UPDATE: 9,
    /**
     * Cases on you were updated (added / removed)
     * @deprecated `CASE_CREATED` and `CASE_DELETED` should be used instead
     */
    CASES: 10,
    /**
     * An appeal was updated
     */
    APPEAL_UPDATE: 13,
    /**
     * Appeals were updated (added / removed)
     * @deprecated `APPEAL_UPDATE` and `APPEAL_CREATED` should be used instead
     */
    APPEALS: 14,
    /**
     * An appeal had a new chat message added
     */
    NEW_APPEAL_CHAT_MESSAGE: 20,
    /**
     * A new case on you was created
     */
    CASE_CREATED: 23,
    /**
     * A case on you was permanently deleted, archived, or hidden from the user.
     */
    CASE_REMOVED: 24,
    /**
     * An appeal was created
     */
    APPEAL_CREATED: 26,
};

import { AutoModAuditAlertBooleans, AutoModSubscriptionMode } from "../modules/automod/index.js";
/**
 * A special type of boolean that can tell you if you want to enable, disable, or inherit
 */
export var EnableDisableInherit;
(function (EnableDisableInherit) {
    /**
     * Use value from parent or other
     */
    EnableDisableInherit[EnableDisableInherit["INHERIT"] = 0] = "INHERIT";
    /**
     * Explicitly enabled
     */
    EnableDisableInherit[EnableDisableInherit["ENABLED"] = 1] = "ENABLED";
    /**
     * Explicitly disabled
     */
    EnableDisableInherit[EnableDisableInherit["DISABLED"] = 2] = "DISABLED";
})(EnableDisableInherit || (EnableDisableInherit = {}));

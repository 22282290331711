<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7 6H12.875C14.5319 6 15.875 7.34315 15.875 9V9C15.875 10.6569 14.5319 12 12.875 12H7V6Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M7 12H14C15.6569 12 17 13.3431 17 15V15C17 16.6569 15.6569 18 14 18H7V12Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconBold"
};
</script>
<script setup>
import IndexedDBStore from "@/storage/IndexedDB";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {iconForChannelType} from "@/script/constants";

defineProps({
	// The ID of the channel
	"channelId": {
		type: String,
		//required: true,
		default: () => "1235203132510830623"
	},
	// The guild the channel belongs to
	"guildId": {
		type: String,
		required: true,
	},
	"small": {
		type: Boolean,
		default: false,
	},
});

const idbStore = IndexedDBStore();
</script>

<template>
	<div
		class="MentionChannel pt-0.5 pb-1 pl-0.5 pr-1 rounded bg-neutral-700/10 border border-neutral-700/50 w-fit inline"
		:title="channelId"
	>
		<DynamicIcon
			:icon="iconForChannelType(idbStore.getChannel(channelId)?.type)"
			:size="small ? 14 : 16"
		/>
		<span
			:class="{
				'italic text-neutral-500': !idbStore.getChannel(channelId)?.name,
				'text-xs': small
			}"
		>
			{{ idbStore.getChannel(channelId)?.name ?? `unknown channel` }}
		</span>
	</div>
</template>

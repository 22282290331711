<script setup>
import {ref} from "vue";

const emit = defineEmits(["sendMessage", "update:modelValue"]);
const props = defineProps({
	"modelValue": {
		type: String,
		required: true,
	},
	"disabled": {
		type: Boolean,
		default: false,
	},
});

const textbox = ref(null);
function handleTextareaKeyup(e) {
	e.target.style.height = "auto";
	e.target.style.height = `${e.target.scrollHeight}px`;

	if (e.key !== "Enter" || e.shiftKey) return;
	if (!props.modelValue?.trim()?.length) return;
	const msg = props.modelValue.trim();
	emit("update:modelValue", "");
	emit("sendMessage", msg);
}
</script>

<template>
	<textarea
		id="chatInput"
		ref="textbox"
		name="chatInput"
		class="text-area border-details input-focus"
		type="text"
		maxlength="512"
		placeholder="Send a chat message"
		:value="modelValue"
		:disabled="disabled"
		@input="$emit('update:modelValue', $event.target.value)"
		@keyup="handleTextareaKeyup"
	/>
</template>

<style scoped>
.text-area {
	@apply disabled:cursor-not-allowed  disabled:opacity-60;
	@apply bg-neutral-600/40 px-2 py-1 shadow-md rounded w-full resize-none max-h-24;
}
</style>
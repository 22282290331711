import {defineStore} from "pinia";

/**
 * A dedicated short-lived store used for browsing cases and appeals from your personal dashboard and inside guilds
 */
export default defineStore("CaseAppealBrowser", {
	state: () => {
		return {
			/**
			 * Whether we're currently in the personal dashboard or a guild dashboard
			 */
			isPersonal: false,
			/**
			 * Which field in `currentItem` would hold the key to use
			 */
			itemIdKey: "_id",
			/**
			 * Whether you're currently editing a resource inside the case/appeal
			 */
			editing: {
				/**
				 * Whether you're editing an entry inside a case
				 */
				entry: false,
				/**
				 * Whether you're editing the label of a linked user
				 */
				linkedUser: false,
				/**
				 * Whether you're editing the label of a linked case
				 */
				linkedCase: false,
			},
			states: {
				/**
				 * For cases: holds boolean for you, letting you know if the last case was rejected.
				 */
				lastAppealRejected: false,
			},
			/**
			 * The list of cases/appeals currently active in the list view
			 */
			itemList: [],
			/**
			 * The currently inspected case/appeal
			 */
			currentItem: null,
			/**
			 * The currently inspected case/appeal resource
			 * @type {object|null|Array}
			 */
			currentResource: null,
			/**
			 * Full item objects that are cached for faster browsing on cases/appeals you have already visited
			 */
			cachedFullItems: [],
			/**
			 * A list of actions that are loading.
			 * Saving, creating, deleting, etc.
			 */
			loading: {
				// Cases
				showToUser: [],
				allowAppeals: [],
				archiveCase: [],
				restoreCase: [],
				deleteCase: [],

				// Entries
				entryAdd: [],
				entryDelete: [],
				entryEdit: [],
				entryArchive: [],
				entryRestore: [],
				entryVisibility: [],

				// Files
				fileDelete: [],
				fileArchive: [],
				fileRestore: [],
				fileVisibility: [],

				// Linked user/cases (all IDs are unique, so array is merged)
				addLinked: [],
				renameLinked: [],
				deleteLinked: [],

				// Appeals
				appealCreate: [], // caseIDs
				appealStatus: [], // + verdict
				appealAssigned: [], // appealId
			},
		};
	},
	getters: {
		/**
		 * Get the ID of the currently inspected case/appeal
		 * @returns {string|number|null} `null` if not being inspected
		 */
		currentId(state) {
			if (!state.currentItem) return null;

			return state.currentItem[this.itemIdKey];
		},
		/**
		 * Gets the ID of the currently inspected resource
		 * @returns {string|number|null} `null` if not being inspected
		 */
		resourceId(state) {
			if (!state.currentResource) return null;

			// TODO A way to figure out the ID here
			return state.currentResource._id;
		},
		/**
		 * Returns a function that will tell you if the current item is loading
		 * @returns {(type: LoadingActionIdentifier, id: string|number) => boolean}
		 */
		isLoading(state) {
			return (type, id) => {
				const prefix = this.currentId;
				return state.loading[type].includes(`${prefix}:${id}`);
			};
		}
	},
	actions: {
		/**
		 * @typedef {"appealAssigned"|"appealCreate"|"appealStatus"|"showToUser"|"allowAppeals"|"archiveCase"|"restoreCase"|"deleteCase"|"entryAdd"|"entryDelete"|"entryEdit"|"entryArchive"|"entryRestore"|"entryVisibility"|"fileDelete"|"fileArchive"|"fileRestore"|"fileVisibility"|"addLinked"|"renameLinked"|"deleteLinked"} LoadingActionIdentifier
		 */
		/**
		 * Adds an item to the loading state
		 * @param {LoadingActionIdentifier} type
		 * @param {string|number} id
		 */
		addLoading(type, id) {
			const itemId = `${this.currentId}:${id}`;
			if (!this.loading[type].includes(itemId)) {
				this.loading[type].push(itemId);
			}
		},
		/**
		 * Removes an item from the loading state
		 * @param {LoadingActionIdentifier} type
		 * @param {string|number} id
		 */
		removeLoading(type, id) {
			const itemId = `${this.currentId}:${id}`;
			const idx = this.loading[type].indexOf(itemId);
			if (idx !== -1) {
				this.loading[type].splice(idx, 1);
			}
		},
	}
});

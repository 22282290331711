<template>
	<g>
		<path
			d="M11 15H7C4.79086 15 3 16.7909 3 19V20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="11"
			cy="7"
			r="4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<circle
			cx="17.4442"
			cy="16.5556"
			r="3.55556"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.9303 19.0703L13 21.0006"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconSearchUser"
};
</script>
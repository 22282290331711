<script setup>
import {computed} from "vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import NewLinkedCaseView from "@/partials/inspect/case/inspectLinkedCase/NewLinkedCaseView.vue";
import InspectLinkedCaseView from "@/partials/inspect/case/inspectLinkedCase/InspectLinkedCaseView.vue";
import {AppState} from "@/storage/AppState";

const props = defineProps({
	"handlers": {
		type: Object,
		default: () => ({}),
	},
	"resourceId": {
		type: String,
		required: true,
	},
	"theCase": {
		type: Object,
		required: true,
	},
});
const app = AppState();
const linked = computed(() => {
	if (props.resourceId === "new") return null;
	return props.theCase?.linked?.cases?.find(l => l.caseSid === parseInt(props.resourceId));
});
const linkedCase = computed(()=>{
	if (props.resourceId === "new") return null;
	return app.cases.get(parseInt(props.resourceId));
});
</script>

<template>
	<NewLinkedCaseView
		v-if="resourceId === 'new'"
		:close="handlers.closeResource"
		:handle-save="handlers.createCaseLink"
		:guild-id="theCase.guildId"
		:exclude-case-sids="[theCase.sid]"
	/>
	<InspectLinkedCaseView
		v-else-if="linked"
		:close="handlers.closeResource"
		:handle-delete="handlers.deleteLinkedCase"
		:handle-save="handlers.renameLinkedCase"
		:last-user-id="linked.by"
		:label="linked.label"
		:target-case="linkedCase || {
			sid: props.resourceId,
			caseType: 'Unknown Case Type'
		}"
	/>
	<div v-else class="p-4 rounded-lg">
		<ErrorCallout
			label="This case is not linked."
			:texts="['It could be that the linked case has been removed, or that it was never linked to begin with.']"
		/>
	</div>
</template>
<template>
	<g>
		<path
			d="M13.7797 4L10.2197 20"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18 8L22 12L18 16"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6 16L2 12L6 8"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCode"
};
</script>
<template>
	<g>
		<path
			d="M12 17.6191V20.9991"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.01965 15.9805L5.63965 18.3605"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.38 12H3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.63965 5.64062L8.01965 8.02063"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 6V3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16.7695 7.23063L18.3595 5.64062"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 12H19.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18.3597 18.3607L17.8297 17.8307"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconLoading"
};
</script>
<template>
	<div class="select-none py-1 px-0 rounded-md shadow whitespace-nowrap cursor-pointer">
		<slot />
	</div>
</template>

<script>
export default {
	name: "DialogOptionList"
};
</script>

<style scoped>

</style>
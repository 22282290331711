<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {computed, ref} from "vue";
import {useRoute} from "vue-router";
import DropDown from "@/components/form/dropdown/DropDown.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";

const emit = defineEmits(["goto"]);
const props = defineProps({
	// The current page you are on
	"page": {
		type: Number,
		required: true,
		default: () => 1,
	},
	// Total count of pages
	"totalPages": {
		type: Number,
		required: true,
		default: () => 1,
	},
	// Total count of items
	"totalItems": {
		type: Number,
		required: true,
		default: () => 0,
	},
	// How many items we're supposed to show per page
	"limit" :{
		type: Number,
		required: true,
		default: () => 25,
	},
	// How many items we're displaying on this page
	"currentItems": {
		type: Number,
		required: true,
		default: () => 0,
	},
	// Fake-disable buttons. Usually done while loading
	"disabled": {
		type: Boolean,
		default: false,
	},
});

const route = useRoute();
const pages = computed(()=>{
	const nums = [];
	for (let i=0;i<props.totalPages;i++) nums.push(i+1);
	return nums;
});

const cursor = computed(()=>{
	if (props.page <= 2) return pages.value.slice(0, 3);
	if (props.page + 1 > props.totalPages - 1) return pages.value.slice(props.totalPages - 3, props.totalPages);

	return pages.value.slice(props.page - 2, props.page + 1);
});
const showFirstPage = computed(()=>{
	return props.page >= 3 && props.totalPages > 5;
});
const showLastPage = computed(()=>{
	return props.page + 1 < props.totalPages && props.totalPages > 5;
});
const showFirstEllipsis = computed(()=>{
	return props.page >= 4 && props.totalPages > 5;
});
const showLastEllipsis = computed(()=>{
	return props.page + 2 < props.totalPages && props.totalPages > 5;
});
const disableNext = computed(()=>{
	return props.page >= props.totalPages;
});
const disablePrevious = computed(()=>{
	return props.page <= 1;
});

const paginationItems = computed(()=>{
	const items = [];

	if (showFirstPage.value) items.push({label: 1});
	if (showFirstEllipsis.value) items.push({label: "first", separator: true});

	items.push(...cursor.value.map(label => ({ label })));

	if (showLastEllipsis.value) items.push({label: "last", separator: true});
	if (showLastPage.value) items.push({label: props.totalPages});

	return items;
});

const isActiveItem = page => {
	return props.page === page;
}
const skipToPage = ref(null);
const handleGotoPage = (toggleDropdown) => {
	toggleDropdown(false);
	emit("goto", skipToPage.value);
}
</script>

<template>
	<div class="flex items-center justify-between">
		<p v-if="!currentItems && page === 1" class="text-xs pt-2 text-neutral-500 text-center w-full">
			No items found.
		</p>
		<template v-else>
			<div class="flex flex-1 justify-between sm:hidden">
				<a
					:class="{'cursor-progress opacity-50': disabled}"
					href="#"
					class="relative inline-flex items-center rounded-md border border-neutral-700 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-neutral-700"
				>
					Previous
				</a>
				<a
					:class="{'cursor-progress opacity-50': disabled}"
					href="#"
					class="relative ml-3 inline-flex items-center rounded-md border border-neutral-700 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-neutral-700"
				>
					Next
				</a>
			</div>
			<div class="hidden flex-col sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
						<!-- Previous -->
						<RouterLink
							:to="{name: route.name, params: route.params, query: {page: Math.max(page-1, 1)}}"
							class="relative inline-flex items-center rounded-l-md px-2 py-2 text-neutral-400 ring-1 ring-inset ring-neutral-700 hover:bg-neutral-700 focus:z-20 focus:outline-offset-0"
							:class="{
								'cursor-progress opacity-50 pointer-events-none': disabled || disablePrevious,
							}"
							:tabindex="disabled || disablePrevious ? -1 : 0"
						>
							<span class="sr-only">Previous</span>
							<DynamicIcon icon="Chevron" class="rotate-90 h-5 w-5" />
						</RouterLink>

						<template v-for="item in paginationItems">
							<DropDown
								v-if="item.separator"
								:key="item.label"
								:class="{'cursor-progress opacity-50': disabled}"
								class="hover:bg-neutral-700 relative hover:text-neutral-400 inline-flex items-center text-sm font-semibold text-neutral-600 ring-1 ring-inset ring-neutral-700 focus:outline-offset-0"
								:disabled="disabled"
							>
								<template #trigger="{ toggle }">
								<span class="px-4 py-2 cursor-pointer relative" @click="toggle">
									&hellip;
								</span>
								</template>

								<template #default="{ toggle }">
									<div class="p-4 flex gap-1">
										<NumberInput
											id="paginator-page"
											v-model="skipToPage"
											aria-label="Skip to page by number"
											class="w-32"
											placeholder="5"
											:max="totalPages"
											:min="1"
										/>
										<BasicButton
											label="Go"
											type="light"
											@click="() => handleGotoPage(toggle)"
										/>
									</div>
								</template>
							</DropDown>

							<RouterLink
								v-else
								:key="item.label"
								:to="{name: route.name, params: route.params, query: {page: item.label}}"
								:aria-current="isActiveItem(item.label) ? 'page' : null"
								class="relative inline-flex items-center px-4 py-2 text-sm font-semibold"
								:class="{
									'text-white z-10 bg-sky-400 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-400': isActiveItem(item.label),
									'text-neutral-400 ring-1 ring-inset ring-neutral-700 hover:bg-neutral-700 focus:z-20 focus:outline-offset-0': !isActiveItem(item.label),
									'cursor-progress opacity-50': disabled,
								}"
							>
								{{ item.label }}
							</RouterLink>
						</template>

						<!-- Next -->
						<RouterLink
							:to="{name: route.name, params: route.params, query: {page: Math.min(page + 1, totalPages+1)}}"
							class="relative inline-flex items-center rounded-r-md px-2 py-2 text-neutral-400 ring-1 ring-inset ring-neutral-700 hover:bg-neutral-700 focus:z-20 focus:outline-offset-0"
							:class="{
								'cursor-progress opacity-50 pointer-events-none': disabled || disableNext,
							}"
							:tabindex="disabled || disableNext ? -1 : 0"
						>
							<span class="sr-only">Next</span>
							<DynamicIcon icon="Chevron" class="-rotate-90 h-5 w-5" />
						</RouterLink>
					</nav>
				</div>
				<div>
					<p class="text-xs pt-2 text-neutral-500">
						Showing
						{{ ' ' }}
						<span class="font-medium">{{ (page - 1) * limit + 1 }}</span>
						{{ ' ' }}
						to
						{{ ' ' }}
						<span class="font-medium">{{ (page - 1) * limit + currentItems }}</span>
						{{ ' ' }}
						of
						{{ ' ' }}
						<span class="font-medium">{{ totalItems }}</span>
						{{ ' ' }}
						results
					</p>
				</div>
			</div>
		</template>
	</div>
</template>
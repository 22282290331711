<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17 21H7C5.895 21 5 20.105 5 19V11C5 9.895 5.895 9 7 9H17C18.105 9 19 9.895 19 11V19C19 20.105 18.105 21 17 21Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 17.09V14.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.5303 13.2197C12.8232 13.5126 12.8232 13.9875 12.5303 14.2804C12.2374 14.5733 11.7626 14.5733 11.4697 14.2804C11.1768 13.9875 11.1768 13.5126 11.4697 13.2197C11.7626 12.9268 12.2374 12.9268 12.5303 13.2197"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8 9V7V7C8 4.791 9.791 3 12 3V3C14.209 3 16 4.791 16 7V7V9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconLock"
};
</script>
import { defineStore } from "pinia";
const SkipAnchorTypes = {
	/**
	 * This is the main content of the current page.
	 */
	main: "main",
	/**
	 * In some instances, there may be some "main" list of items you
	 * can choose from, for example, a list of cases.
	 */
	mainList: "mainList",
	/**
	 * In some instances, there may be some secondary panel open that is
	 * equally or only slightly less important than the main content--but it is
	 * not as low as <aside>.
	 * This is what this is for.
	 */
	mainSecondary: "mainSecondary",
	/**
	 * A dedicated type for the server switcher
	 */
	serverSwitcher: "server-switcher",
};

/**
 * This store keeps track of UI state.
 * That is to say, your preferences and customizations you have made to the UI,
 * as well as internal states needed for the UI related to accessibility.
 *
 * Part of the data is persistent. It should not hold temporary app data states,
 * for example, a reactive dataset for cases we loaded for this page, etc.
 */
export const UI = defineStore("UI", {
	state: () => {
		const loaded = loadUIData();

		return {
			switcher: {
				// Whether the Server Switcher is open
				open: false,
			},
			skipAnchors: new Map(),
			dismissed: loaded.dismissed ?? {},
			announcements: [],
			guides: {
				guildSpeedSetup: false,
			},
			lastChecked: {
				/**
				 * @typedef {"appealChat"} CheckbleType
				 */

				// Key: Appeal ID, value: [Last Checker: UNIX, Last Message: UNIX]
				appealChat: loaded.lastChecked.appealChat,
			}
		};
	},
	getters: {
		/**
		 * Makes a reactive checker to see if a check-able item has any un-checked changes
		 * @returns {(type: CheckbleType, childKey?: string) => boolean}
		 */
		makeChecker() {
			return function(type, childKey) {
				return this.hasUnchecked(type, childKey);
			}
		},
	},
	actions: {
		/**
		 * Check if a UI element is dismissed or not
		 * @param {string|("ServerSwitcher"|"announcements"|"joyride")} uiElement
		 * @param {string} [id] An optional ID to identify this particular instance of the UI element, e.g.
		 * announcements
		 * @returns {boolean}
		 */
		isDismissed(uiElement, id) {
			if (!(uiElement in this.dismissed)) {
				console.debug(`Unknown UI element '${uiElement}'`);
				return false;
			}

			if (!id) return !!this.dismissed[uiElement];
			return !!this.dismissed[uiElement]?.includes(id);
		},
		/**
		 * Dismisses (and saves) that a user dismissed a UI element
		 * @param {string|("ServerSwitcher"|"announcements")} uiElement
		 * @param {string} [id] An optional ID to identify this particular instance of the UI element, e.g.
		 * announcements
		 */
		dismiss(uiElement, id) {
			if (!(uiElement in this.dismissed)) {
				console.debug(`Unknown UI element '${uiElement}'`);
			}

			if (!id) {
				this.dismissed[uiElement] = true;
				return this.save("ui");
			}

			if (!this.dismissed[uiElement]) {
				this.dismissed[uiElement] = [];
			} else if (!Array.isArray(this.dismissed[uiElement])) throw new Error(`UI element '${uiElement}' is not an array`);

			if (this.dismissed[uiElement].includes(id)) return;
			this.dismissed[uiElement].push(id);

			return this.save();
		},
		/**
		 * Sets an anchor for a skip link on this element.
		 * Remember that it also needs to have a tabindex of 0 or -1.
		 * @param {SkipLinkType} type
		 * @example
		 * <main :id="setAnchor('main')" tabindex="-1"> ... </main>
		 */
		setAnchor(type) {
			const found = SkipAnchorTypes[type];
			if (!found) throw new Error(`Unknown skip link type '${type}'`);
			return found;
		},
		/**
		 * Marks a check-able thing as checked just now
		 * @param {CheckbleType} type
		 * @param {string} [childKey] If this type has multiple children, the child key to mark as checked
		 */
		markChecked(type, childKey) {
			this._ensureExist(type, childKey);
			if (childKey) {
				this.lastChecked[type][childKey][0] = Date.now();
			} else {
				this.lastChecked[type][0] = Date.now();
			}
			this.save();
		},
		/**
		 * Mark a check-able thing as updated
		 * @param {CheckbleType} type
		 * @param {string} [childKey] If this type has multiple children, the child key to mark as checked
		 * @param {any} [markedValue=Date.now()] The value to set in the "last updated" field
		 */
		markUpdated(type, childKey, markedValue = Date.now()) {
			this._ensureExist(type, childKey);

			if (childKey) {
				this.lastChecked[type][childKey][1] = markedValue;
			} else {
				this.lastChecked[type][1] = markedValue;
			}
			this.save();
		},
		/**
		 * Ensures there's a key/value for the type and child key.
		 * Used internally.
		 * @param {CheckbleType} type
		 * @param {?string} [childKey]
		 * @private
		 */
		_ensureExist(type, childKey) {
			if (childKey) {
				if (!this.lastChecked?.[type]) this.lastChecked[type] = {};
				if (!this.lastChecked?.[type]?.[childKey]) this.lastChecked[type][childKey] = [];
			} else {
				if (!this.lastChecked?.[type]) this.lastChecked[type] = [];
			}
		},
		/**
		 * Check if a check-able thing has anything new since the last check compared to now or a given date
		 * @param {CheckbleType} type
		 * @param {string} [childKey] If this type has multiple children, the child key to mark as checked
		 * @returns {boolean}
		 */
		hasUnchecked(type, childKey) {
			this._ensureExist(type, childKey);
			const [lastChecked, lastUpdated] = childKey
				? this.lastChecked[type][childKey]
				: this.lastChecked[type];

			if (!lastChecked && lastUpdated) return true;
			if (lastChecked && !lastUpdated) return false;
			if (!lastChecked && !lastUpdated) return false;
			if (lastChecked === lastUpdated) return false;
			return lastChecked < lastUpdated;
		},
		/**
		 * Runs automated maintenance on all anchors.
		 * If an anchor is found in the DOM, it will add it to the list.
		 * If an anchor was not found in the DOM, it will remove it from the list.
		 */
		maintainSkipMenuAnchors() {
			for (const key in SkipAnchorTypes) {
				const found = document.getElementById(SkipAnchorTypes[key]);

				if (!found) this.skipAnchors.delete(key);
				else this.skipAnchors.set(key, SkipAnchorTypes[key]);
			}
		},
		/**
		 * Focuses an anchor for a skip link
		 * @param {SkipLinkType} type
		 */
		focusAnchor(type) {
			const anchor = this.skipAnchors.get(type);
			const element = document.getElementById(anchor);
			if (!element) throw new Error(`Unknown skip link anchor '${anchor}'`);

			element.focus();
		},
		save() {
			window.localStorage.setItem("uiData", JSON.stringify(this.$state));
		}
	}
});

/**
 * @typedef {"main"|"mainList"|"serverSwitcher"|"mainSecondary"} SkipLinkType
 */

/**
 * @typedef {Object} DismissedUIData
 * @prop {boolean} ServerSwitcher The switcher that allows you to switch between servers/personal dashboard, and logout
 * @prop {string[]} joyride The joyride boxes
 */

/**
 * @typedef {Object} UIData
 * @prop {DismissedUIData} dismissed
 * @prop {string[]} announcements The announcements shown on the dashboard
 * @prop {Object.<CheckbleType, [number, number] | Record<string, [number, number]>>} lastChecked
 */
/**
 * @return {UIData}
 */
function loadUIData() {
	const uiData = window.localStorage.getItem("uiData");
	const emptyState = {
		dismissed: {
			ServerSwitcher: false,
			joyride: [],
		},
		announcements: [],
		lastChecked: {},
	};

	if (!uiData) {
		return emptyState;
	}

	return Object.assign(emptyState, JSON.parse(uiData));
}
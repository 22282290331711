<template>
	<div :style="`width: ${p.size}px; height: ${p.size}px`" class="aspect-square">
		<img
			:key="userId"
			class="rounded-full object-contain w-full h-full overflow-hidden text-xs"
			:src="fallbackUrl || discordAvatarUrl"
			:alt="ariaLabel || `Avatar of user by ID ${p.userId}`"
			:style="[
				`width: ${size}px; height: ${size}px`
			]"
			@error="handleError"
		>
	</div>
</template>

<script setup>
import {discordAvatar} from "@/script/convert";
import {computed, ref, watch} from "vue";

const p = defineProps({
	// Avatar hash
	"avatar": {
		type: [String, null],
		default: () => null,
	},
	"userId": {
		type: String,
		required: true,
	},
	/**
	 * @type {DiscordImageSize}
	 */
	"size": {
		type: [String, Number],
		default: () => 32
	},
	"ariaLabel": {
		type: String,
		default: () => null
	},
});

const fallbackUrl = ref(null);
function handleError(err) {
	console.error(`A user's (${p.userId}) profile picture failed to load. It will be substituted with a default avatar.`, err);
	fallbackUrl.value = discordAvatar(
		p.userId,
	null,
	{
		size: p.size * 2,
	});
}
const discordAvatarUrl = computed(()=>{
	if (fallbackUrl.value || !p.avatar) {
		discordAvatar(
			p.userId,
		null,
		{
			size: p.size * 2,
		});
	}

	return discordAvatar(
		p.userId,
		p.avatar,
	{
		size: p.size * 2,
	});
});
watch(() => p.userId, ()=>{
	fallbackUrl.value = null;
});
</script>
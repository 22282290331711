<template>
	<div class="flex gap-2 font-medium">
		<RouterLink
			v-for="tab in p.tabs"
			:key="tab.label"
			:to="tab.to"
		>
			<TabItem
				:id="tab.label"
				:label="tab.label"
				:badge="tab.badge"
			/>
		</RouterLink>
	</div>
</template>

<script setup>
import TabItem from "@/components/tab/TabItem";

const p = defineProps({
	"tabs": {
		type: Object,
		required: true,
	},
});
</script>
<script>
export default {
	name: "IconTune"
}
</script>

<template>
	<g>
		<path
			d="M3 5.5H6.07"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.3238 3.73223C11.3001 4.70854 11.3001 6.29145 10.3238 7.26776C9.34748 8.24407 7.76457 8.24407 6.78826 7.26776C5.81195 6.29145 5.81195 4.70854 6.78826 3.73223C7.76457 2.75592 9.34748 2.75592 10.3238 3.73223"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.06 5.5H21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.3238 3.73223C11.3001 4.70854 11.3001 6.29145 10.3238 7.26776C9.34748 8.24407 7.76457 8.24407 6.78826 7.26776C5.81195 6.29145 5.81195 4.70854 6.78826 3.73223C7.76457 2.75592 9.34748 2.75592 10.3238 3.73223"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 12H17.94"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12.94 12H3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.2118 10.2322C18.1881 11.2085 18.1881 12.7914 17.2118 13.7677C16.2355 14.744 14.6526 14.744 13.6763 13.7677C12.7 12.7914 12.7 11.2085 13.6763 10.2322C14.6526 9.25589 16.2355 9.25589 17.2118 10.2322"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.06 18.5H21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3 18.5H6.06"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.3238 16.7322C11.3001 17.7085 11.3001 19.2914 10.3238 20.2677C9.34748 21.244 7.76457 21.244 6.78826 20.2677C5.81195 19.2914 5.81195 17.7085 6.78826 16.7322C7.76457 15.7559 9.34748 15.7559 10.3238 16.7322"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

// Flags for case files
export const CaseFileMask = {};
/**
 * Flags pertaining to the visibility of this file
 */
export const CaseFileVisibilityMask = {
    // We can display a link to file to the target
    visibleToTarget: 1 << 0,
    // The file is publicly accessible via a resource link
    public: 1 << 1,
};

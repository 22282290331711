export default {
	/**
	 * Whether we're connected and can use IndexedDB
	 */
	connected: false,
	/**
	 * Whether the DB is ready to perform/accept operations
	 */
	ready: false,
	/**
	 * Whether the upgrade has been handled
	 */
	upgraded: false,
	/**
	 * @property {IDBDatabase}
	 */
	db: null,
	/**
	 * A list of {@type IDBModels} that are attached to the DB
	 * @property {Record<string, IDBModel>}
	 */
	models: {},

	/**
	 * Opens a connection to the DB.
	 * Initializes the DB if it has never been set up before, or if an upgrade is required.
	 * @return {Promise<void>}
	 */
	connect() {
		return new Promise((r, rj) => {
			console.debug("[IndexedDB] Connecting");
			const req = indexedDB.open("archivian", 5);

			req.onblocked = (arg) => {
				console.debug("[IndexedDB] Storage upgrade blocked", arg);
				alert("The internal storage system was upgraded. Please close all other Archivian tabs and reload this tab.");
				return rj();
			}

			req.onsuccess = () => {
				console.debug("[IndexedDB] Connected");
				this.db = req.result;
				this.connected = true;
				this.ready = true;
				this.upgraded = true;

				return this.postInit().then(r);
			};

			req.onerror = (e) => {
				console.error("[IndexedDB]", e);
				this.connected = false;
				alert(
					"Request for opening a local IndexedDB was denied. " +
					"This may impact your experience negatively as we cannot access cached data."
				);

				return rj();
			};

			req.onupgradeneeded = (e) => {
				console.debug("[IndexedDB] Upgrading database");
				try {
					this.db = e.target.result;

					for (const key in this.models) {
						this.models[key].attachModelToDB(this.db);
					}

					// When upgrade is needed, each model must create its own store
					for (const key in this.models) {
						// This check assumes the store is identical with no changes
						if (this.db.objectStoreNames.contains(this.models[key].name)) continue;

						this.models[key].createStore(this.db);
					}

					this.upgraded = true;
				} catch(e) {
					return rj(e);
				}
			};
		});
	},

	/**
	 * Attach a {@link IDBModel} to this database
	 * @param {IDBModel} model
	 */
	addModel(model) {
		this.models[model.name] = model;
	},

	/**
	 * Executed after IndexedDB is set up / upgraded / ready for use
	 */
	async postInit() {
		try {
			console.debug("[IndexedDB] Executing post-init");
			for (const key in this.models) {
				this.models[key].attachModelToDB(this.db);
			}

			/**
			 * We'll set up some placeholder guilds for fallback purposes
			 */
			if (this.models.guilds) {
				await Promise.all([
					this.models.guilds.upsert({
						id: "00000000000000000000",
						name: "Unknown Server",
						icon: null,
					}),
					this.models.guilds.upsert({
						id: "11111111111111111111",
						name: "Example Server",
						icon: null,
					})
				]);
			}
		} catch(e) {
			console.error(e);
		}
	},

	purgeAll() {
		const transaction = this.db.transaction(this.db.objectStoreNames, "readwrite");

		transaction.onerror = function(event) {
			// Handle errors!
			console.error("Transaction error: ", event.target.error);
		};

		for (let i = 0; i < this.db.objectStoreNames.length; i++) {
			const storeName = this.db.objectStoreNames[i];
			const objectStore = transaction.objectStore(storeName);
			const request = objectStore.clear();

			request.onsuccess = () => {
				console.log(`Cleared data in object store ${storeName}`);
			};

			request.onerror = function(event) {
				console.error(`Error clearing data in object store ${storeName}: `, event.target.error);
			};
		}

		return new Promise((resolve, reject) => {
			transaction.oncomplete = () => {
				console.log("All data cleared successfully");
				resolve();
			};

			transaction.onerror = (event) => {
				console.error("Error clearing all data: ", event.target.error);
				reject(event.target.error);
			};
		});
	}
};
/**
 * Permission set Auto Mod settings
 */
export { AUTOMOD as SETTINGS } from "./Settings.perms.js";
/**
 * Permissions related to Auto Mod violations
 */
export const VIOLATIONS = {
    /**
     * Permission to anything auto mod violations related
     */
    ANY: () => {
        return "module.automod.violations.**";
    },
    /**
     * Read the Auto Mod violation logs and records
     */
    READ: () => {
        return "module.automod.violations.read";
    },
};
/**
 * Anything related to the Auto-Mod Rule Template Library
 */
export const TEMPLATES = {
    /**
     * Full access to anything related to the Guild's Auto-Mod Templates and Rules
     */
    ANY() {
        return "module.automod.template.**";
    },
    /**
     * Access to read the Auto-Mod templates the server has.
     * Includes the ability to read the Discord Auto Mod rules, and see which templates are active.
     */
    READ() {
        return "module.automod.template.read";
    },
    /**
     * Access to deactivate a managed Auto-Mod rule
     * Allows unsubscribing / deactivating the rule used in their guild.
     */
    DEACTIVATE() {
        return "module.automod.rule.delete";
    },
    /**
     * Anything related to private Auto-Mod templates.
     */
    PRIVATE: {
        /**
         * Full access to anything related to private Auto-Mod templates.
         */
        ANY() {
            return "module.automod.template.private.**";
        },
        /**
         * Access to create a new private template on behalf of the server.
         */
        CREATE() {
            return "module.automod.template.private.create";
        },
        /**
         * Access to modify a private Auto-Mod template.
         * Does not include publishing related permissions.
         */
        UPDATE() {
            return "module.automod.template.private.update";
        },
        /**
         * Access to delete a private Auto-Mod template.
         * Does not give access to unpublish, which is required if the private template is published.
         */
        DELETE() {
            return "module.automod.template.delete";
        },
    },
    /**
     * Anything related to published Auto-Mod templates.
     */
    PUBLIC: {
        /**
         * Full access to anything related to published Auto-Mod templates.
         */
        ANY() {
            return "module.automod.template.public.**";
        },
        /**
         * Access to publish a private Auto-Mod template on behalf of the server.
         * This includes the ability to update an already published one.
         */
        PUBLISH() {
            return "module.automod.template.public.create";
        },
        /**
         * Access to delete a private Auto-Mod rule.
         * Public rules require require unpublish permission to be removed.
         */
        UNPUBLISH() {
            return "module.automod.template.public.delete";
        },
    },
    /**
     * Anything related to the templates/rules that is managed by the system.
     */
    MANAGED: {
        /**
         * Access to activate a managed instance of an Auto-Mod rule.
         * Required: Access to create a new private template.
         */
        ACTIVATE() {
            return "module.automod.managed.create";
        },
        /**
         * Access to deactivate a managed instance of an Auto-Mod rule.
         */
        DEACTIVATE() {
            return "module.automod.managed.delete";
        },
    },
};

import { dashboard } from "./endpoints.js";
export const sessionCookie = {
    maxSessionDurationMS: 60 * 60 * 24 * 30 * 1000,
    maxRefreshCount: 3,
    refreshThreshold: 10 * 60, // 10 min
    cookieName: "session",
    signedCookie: true,
    path: "/",
    /**
     * Instead of letting Fastify do this, we should have the auth do it.
     * We don't use Fastify on other services that also read cookie,
     * so it needs to be valid for those too.
     */
    signed: false,
    httpOnly: true,
    secure: process.env.NODE_ENV !== "development",
    maxAge: null,
    sameSite: null,
};
export const loginCookie = {
    scopes: ["identify", "guilds", "guilds.members.read"],
    responseType: "code",
    loginUrlPath: "/public/accounts/login",
    callbackUrlPath: "/public/accounts/login/callback",
    cookieConfig: {
        name: "loginState",
        maxAge: 120, // 2 min
        sameSite: "lax",
        signed: false,
        httpOnly: true,
        secure: true,
    },
    discordLoginUrl: (searchParams) => `https://discord.com/api/oauth2/authorize?${searchParams.toString()}`,
    defaultRedirectUrl: dashboard.PersonalDashboard(),
    maxSessionDuration: 172800,
};

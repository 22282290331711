<script>
export default {
	name: "IconTrash"
}
</script>

<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M15.5429 21.0037H8.4571C7.2809 21.0037 6.30301 20.0982 6.21279 18.9254L5.24719 6.37265H18.7528L17.7872 18.9254C17.697 20.0982 16.7191 21.0037 15.5429 21.0037V21.0037Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20.0033 6.37264H3.99664"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.18632 2.99625H14.8137C15.4352 2.99625 15.9391 3.50014 15.9391 4.12172V6.37265H8.06085V4.12172C8.06085 3.50014 8.56474 2.99625 9.18632 2.99625Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13.9696 10.8745V16.5019"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.0304 10.8745V16.5019"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>
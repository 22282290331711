<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14.163 21.0001L18.607 20.0121C19.421 19.8321 20 19.1101 20 18.2771V5.76614C20 4.93314 19.421 4.21114 18.608 4.03114L14.164 3.04314C13.053 2.79614 12 3.64114 12 4.77914V19.2651C12 20.4021 13.053 21.2471 14.163 21.0001V21.0001Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15 10.9805V12.9805"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4 16V18.042C4 19.147 4.895 20.042 6 20.042H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4 8V6C4 4.895 4.895 4 6 4H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M4 12H9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 14L9 12L7 10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconKick"
};
</script>

// Bitflag mask for Case Settings -> Behavior
import { CaseAppealSettingsAlertingMask } from "./CaseAppealSettings.mask.js";
export const CaseSettingsBehaviorMask = {
    // Try to notify user in DMs
    notifyUser: 1 << 0,
    // Fallback to notifying user in-channel if DM fails. Not used for Kick/Ban
    notifyFallback: 1 << 1,
    // Show reason only in the notification to the user
    showReason: 1 << 2,
    // Show creator only in the notification to the user
    showCreator: 1 << 3,
    // Show the custom message (if any) when notifying the user
    showCustomMessage: 1 << 4,
    // Capture native/other bots' ban/kick/timeouts
    capture: 1 << 5,
};
// Bitflag mask for Case Settings -> Visibility
export const CaseSettingsVisibilityMask = {
    // By default, show the case (per case: must be enabled for appeals)
    showCase: 1 << 0,
    // By default, the first entry (that shows reason) in a case is by default visible
    showFirstEntry: 1 << 1,
    // By default, ALL entries should be visible (overrides 'showFirst' to true)
    showAllEntries: 1 << 2,
    // By default, new files should be visible
    showFiles: 1 << 3,
    // By default, show creators of resources (case/entries/files/links, etc.)
    showCreators: 1 << 4,
};
// Bitflag mask for Case Settings -> Alerting
export const CaseSettingsAlertingMask = {
    "cases": {
        // New case is created
        created: 1 << 0,
        // Case updated in any way (includes Archived/Restored, excludes undone)
        updated: 1 << 1,
        // Any case is permanently deleted
        deleted: 1 << 2,
        // Applicable only for Timeout and Ban
        undone: 1 << 3,
    },
    "entries": {
        // New entry is created
        created: 1 << 0,
        // Entry is updated (content/visibility)
        updated: 1 << 1,
        // An entry is permanently deleted
        deleted: 1 << 2,
    },
    "files": {
        // New file is created
        created: 1 << 0,
        // File is updated (visibility)
        updated: 1 << 1,
        // File is permanently deleted
        deleted: 1 << 2,
    },
    "links": {
        // New link (user/case) is created
        created: 1 << 0,
        // Link is updated (label)
        updated: 1 << 1,
        // Link is removed
        deleted: 1 << 2,
    },
    // Bitflag mask for Case Settings -> Alerting
    "appeals": CaseAppealSettingsAlertingMask.appeals,
};
// Bitflag mask for Case Settings -> Appearance
export const CaseSettingsAppearanceMask = {
    // Send a success message as ephemeral
    ephemeral: 1 << 0,
};
/**
 * Bitflag mask for Case Settings -> Tips
 * {@link ICaseResultConfigFlagBooleans}
 */
export const CaseSettingsResultMask = {
    // Explain what files are
    explainFiles: 1 << 0,
    // Explain what entries are
    explainEntries: 1 << 1,
    // Explain linking of users
    explainUserLink: 1 << 2,
    // Explain linking of cases
    explainCaseLink: 1 << 3,
    // Show the most recent cases on user after creation
    recentCases: 1 << 4,
    // Whether to show the custom tip or not
    showCustomTip: 1 << 5,
    // Whether to show the result of the DM notification attempt, if DM notification is enabled
    dmResult: 1 << 6,
    // A tip dedicated to explain tips themselves (ability to add custom ones, and how to turn off)
    explainTips: 1 << 7,
};
export { CaseAppealSettingsMask } from "./CaseAppealSettings.mask.js";

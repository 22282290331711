<script setup>

import FancyLoader from "@/components/misc/FancyLoader.vue";
import GuildInspectCaseView from "@/views/guild/case-system/cases/GuildInspectCaseView.vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import IdBadge from "@/components/badges/IdBadge.vue";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import TabbedListInspect from "@/partials/layouts/TabbedListInspect.vue";
import MyCasesNewAppeal from "@/views/account/case-system/cases/aside/MyCasesNewAppeal.vue";
import TableCard from "@/components/panels/TableCard.vue";
import GuildCaseFile from "@/views/guild/case-system/cases/aside/GuildCaseFile.vue";
import ServerIcon from "@/components/icon/ServerIcon.vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import GuildCaseInspectEntry from "@/views/guild/case-system/cases/aside/GuildCaseInspectEntry.vue";
import CaseList from "@/partials/inspect/CaseList.vue";
import {computed, onBeforeUnmount, onMounted, provide, ref} from "vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import {UI} from "@/storage/UICache";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import {createCaseAppeal, fetchPersonalCase} from "@/services/CaseService";
import {handleErr} from "@/script/convert";
import {CaseMasks} from "@archivian/constants";
import {hook, unhook, USER_OPS} from "@/ws/utils";
import {Account} from "@/storage/AccountCache";
import IndexedDBStore from "@/storage/IndexedDB";

const router = useRouter();
const route = useRoute();
const idbStore = IndexedDBStore();
const cases = CaseAppealBrowser();
const ui = UI();
const account = Account();
provide("isPersonal", true);
const initialized = ref(false);
const error = ref({
	label: "",
	texts: [],
});
const panels = computed(() => {
	return {
		none: !route.params.caseId, // no resource is inferred ofc
		appealOnly: !!route.params.caseId && !route.params.resource,
		both: !!route.params.caseId && !!route.params.resource
	}
});
const selectedGuild = computed(() => {
	if (!cases.currentItem) return null;
	return account.getGuild(cases.currentItem.guildId);
});

const handlers = {
	/**
	 * Closes all panels
	 *
	 */
	close: () => {
		router.push({name: route.name, params: {}});
	},
	/**
	 * Closes the side-panel / resource
	 * @returns {Promise<void>}
	 */
	closeResource: () => {
		router.push({name: route.name, params: {caseId: route.params.caseId}});
	},
	/**
	 * Checks to see if we'll allow user to appeal this case or not
	 * @param {Object} theCase
	 * @returns {boolean}
	 */
	canCreateAppeal(theCase) {
		// If note case, you cannot appeal at all
		if (theCase.caseType === "note") return false;

		// Check that the case does not have the "deny appeals" flag enabled
		// noinspection JSBitwiseOperatorUsage
		if (theCase.flags & CaseMasks.CaseFlagsMask.denyAppeals) return false;

		// Appeals enabled, and no appeals to consider
		if (!theCase.appeals?.length) return true;

		// If any is open, user cannot apply, no matter what
		if (theCase.appeals.some(appeal => appeal.state === "open")) return false;

		// Lastly, last must be rejection to allow appealing
		return theCase.appeals.at(-1).state === "rejected";
	},
	/**
	 * Loads more details about a given case, such as appeals it might have
	 * @param {string} caseId
	 * @returns {Promise<Object | null>} The case
	 */
	async loadCaseFull(caseId) {
		const result = await fetchPersonalCase(caseId);
		if (!result.case) return null;

		await Promise.all([
			idbStore.setUsers(result.users),
			result.guild && idbStore.setGuilds([result.guild])
		]);

		if (result.appeals) {
			result.case.appeals = result.appeals;
		}

		return result.case;
	},

	/**
	 * Creates a new appeal with the content.
	 * SUBSCRIBE ARGUMENT IS NOT YET IMPLEMENTED.
	 * @param {string} content
	 * @param {boolean} subscribe
	 * @returns {Promise<void>}
	 */
	async createAppeal(content, subscribe) {
		if (!cases.currentId) return;
		cases.addLoading("appealCreate", cases.currentId);

		const result = await createCaseAppeal(cases.currentId, content, subscribe);
		if (!result.appeal) {
			error.value = handleErr("An unknown error occurred trying to create the appeal", "Could not create appeal");
			return;
		}

		await Promise.all([
			idbStore.setUsers(result.users),
			idbStore.setGuild(result.guild),
		]);

		return router.push({
			name: "my-appeals",
			params: {
				appealId: result.appeal._id,
			},
			query: route.query,
		});
	},
	/**
	 * Handles a case that was updated
	 * @param {string} _id
	 */
	async handleCaseUpdated({ _id }) {
		// If it is not the case the user is inspecting, ignore it
		if (cases.currentId !== _id) return;

		// Load the case again
		const theCase = await handlers.loadCaseFull(_id);
		// In case of race condition
		if (cases.currentId === _id) cases.currentItem = theCase;
	},
	/**
	 * Handles a case that was archived, deleted, or became hidden from the user
	 * @param {string} _id
	 */
	handleCaseRemoved({ _id }) {
		// If it is not the case the user is inspecting, ignore it
		if (cases.currentId !== _id) return;
		return handlers.close();
	},
};
provide("handlers", handlers);

onMounted(async ()=>{
	if (route.params.caseId) {
		cases.currentItem = await handlers.loadCaseFull(route.params.caseId);
	}

	hook([
		USER_OPS.CASE_UPDATE,
	], handlers.handleCaseUpdated);

	hook([
		USER_OPS.CASE_REMOVED,
	], handlers.handleCaseRemoved);

	initialized.value = true;
});
onBeforeRouteUpdate(async (to, from, next) => {
	if (to.name !== route.name) return next();

	if (to.params.caseId) {
		if (to.params.caseId !== from.params.caseId) {
			cases.currentItem = await handlers.loadCaseFull(to.params.caseId);
			if (!cases.currentItem) return next(false);
		}
	} else {
		cases.currentItem = null;
	}

	if (to.params?.resource && to.params?.resourceId) {
		switch (to.params.resource) {
			case "entries":
				cases.currentResource = cases.currentItem?.entries.find(entry => entry.sid === parseInt(to.params.resourceId)) ?? null;
				break;
			case "files":
				cases.currentResource = cases.currentItem?.files.find(file => file.fileId === to.params.resourceId) ?? null;
				break;
			default:
				cases.currentResource = null;
		}
	} else {
		cases.currentResource = null;
	}

	return next();
});
onBeforeUnmount(()=>{
	cases.$reset();

	unhook([
		USER_OPS.CASE_UPDATE,
	], handlers.handleCaseUpdated);

	unhook([
		USER_OPS.CASE_REMOVED,
	], handlers.handleCaseRemoved);
});
</script>

<template>
	<TabbedListInspect
		class="self-stretch min-h-0 min-w-0 flex"
		:divider-classes="{
			'hidden xl:block': panels.caseOnly,
			'hidden 2xl:block': panels.both,
		}"
	>
		<template #list>
			<div v-if="!initialized" class="flex justify-center items-center h-full">
				<FancyLoader label="Fetching cases on you&hellip;" />
			</div>
			<CaseList
				v-if="initialized && !error.label"
				:id="ui.setAnchor('mainList')"
				tabindex="-1"
				class="overflow-y-auto w-xs flex-col"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.appealOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both
				}"
				:current-case="cases.currentItem"
			/>
			<ErrorCallout
				v-else-if="initialized && error.label"
				:label="error.label"
				:texts="error.texts"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.caseOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both
				}"
			/>
		</template>

		<template #default>
			<div class="overflow-y-auto max-h-full h-full">
				<div v-if="!cases.currentItem" class="hidden lg:flex w-full h-full flex-col gap-2 items-center justify-center overflow-hidden">
					<div class="py-4 rounded-full relative">
						<div
							class="z-0 w-52 h-52 rounded-full absolute bg-sky-500/50 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
						/>
						<TableCard class="shadow-2xl relative z-10 bg-neutral-800">
							<template #content>
								<ServerIcon
									server-id="00000000000000000000"
									name="Example Server"
									size="48"
									class="shrink-0"
								/>
								<div class="flex flex-col truncate">
									<p class="font-bold truncate">
										#10&nbsp;&bull;&nbsp;Example Server
									</p>
									<IdBadge label="12345678901234567890" class="w-fit" />
								</div>
							</template>

							<template #footer-left>
								<DynamicIcon
									icon="warning"
									class="text-warning-normal"
								/>
								<p class="text-warning-normal">
									Warning
								</p>
							</template>

							<template #footer-right>
								<p class="text-sm">
									By staff.user
								</p>
								<UserAvatar
									user-id="11111111111111111111"
									size="24"
									class="shrink-0"
								/>
							</template>
						</TableCard>
					</div>

					<div class="mt-12">
						<h2>Case Overview</h2>
						<p class="max-w-md">
							Here you will be able to find cases made on your in any server that uses Archivian.
							From there you can see case details, and potentially even appeal the case directly.
							<br>
							<span class="text-xs italic opacity-80">
								<DynamicIcon
									icon="InfoFilled"
									size="16"
									stroke="0"
								/>
								You can only see a case if the server allows you to see it, and the details within
								may also be limited.
							</span>
						</p>
					</div>
				</div>
				<div
					v-else
					class="p-4"
					:class="{'grid lg:grid-cols-2 gap-4': panels.both}"
				>
					<GuildInspectCaseView
						:id="ui.setAnchor('main')"
						class="border border-neutral-700"
						:the-case="cases.currentItem"
						:appeals="cases.currentItem.appeals"
						:can-appeal="handlers.canCreateAppeal(cases.currentItem)"
						:handlers="handlers"
						:guild="selectedGuild"
						:class="{'hidden lg:block': panels.both}"
						tabindex="-1"
					/>

					<template v-if="panels.both">
						<MyCasesNewAppeal
							v-if="route.params?.resource==='appeals' && route.params?.resourceId === 'new'"
							:id="ui.setAnchor('mainSecondary')"
							tabindex="-1"
							class="border border-neutral-700"
							:create-appeal="handlers.createAppeal"
							:close="handlers.closeResource"
							:appeals="cases.currentItem.appeals"
							:can-appeal="handlers.canCreateAppeal(cases.currentItem)"
							:error="error"
						/>
						<GuildCaseInspectEntry
							v-else-if="route.params?.resource==='entries' && route.params?.resourceId !== 'new'"
							:id="ui.setAnchor('mainSecondary')"
							tabindex="-1"
							class="border border-neutral-700"
							:close="handlers.closeResource"
							:entry="cases.currentResource"
							:the-case="cases.currentItem"
							:handlers="handlers"
						/>
						<GuildCaseFile
							v-else-if="route.params?.resource==='files'"
							:id="ui.setAnchor('mainSecondary')"
							tabindex="-1"
							class="border border-neutral-700"
							:the-case="cases.currentItem"
							:resource-id="route.params?.resourceId"
						/>
					</template>
				</div>
			</div>
		</template>
	</TabbedListInspect>
</template>

<template>
	<g>
		<path
			d="M16 21H17C18.6569 21 20 19.6569 20 18V8.50931C20 7.71366 19.6839 6.95061 19.1213 6.388L16.612 3.87868C16.0494 3.31607 15.2863 3 14.4907 3H7C5.34315 3 4 4.34315 4 6V9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20 8.5H16.5C15.3954 8.5 14.5 7.60457 14.5 6.5V3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6 12H11C12.1046 12 13 12.8954 13 14V19C13 20.1046 12.1046 21 11 21H6C4.89543 21 4 20.1046 4 19V14C4 12.8954 4.89543 12 6 12Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M13 16.5H4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8.5 12V21"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconFileTable"
};
</script>
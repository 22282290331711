<template>
	<!-- Main View has top bar and main side navigation -->
	<div class="w-full h-screen flex">
		<!-- Main side-bar navigation -->
		<MainNav :sidebar="sidebar">
			<div class="relative">
				<button
					id="server-switcher"
					aria-label="Open server switcher"
					@click="ui.switcher.open = !ui.switcher.open"
				>
					<ServerIcon
						:server-id="currentGuild.id"
						:name="currentGuild.name || 'Unknown Server'"
						:icon="currentGuild?.icon?.replace('a_','')"
						:size="64"
						class="cursor-pointer w-14 h-14"
					/>
				</button>
				<ServerSwitcher
					v-if="ui.switcher.open"
					v-click-away="() => ui.switcher.open = false"
					:guilds="account.staffedGuilds"
					@select-guild="selectGuild"
					@close="ui.switcher.open = false"
				/>
			</div>
		</MainNav>

		<!-- Anything right of main sidebar -->
		<div class="relative z-10 grow w-full flex flex-col px-2.5 min-w-0">
			<!-- Container for routes -->
			<div class="h-full w-full overflow-y-auto flex flex-col">
				<!-- Actual content -->
				<slot />
			</div>
		</div>
	</div>
</template>

<script setup>
import MainNav from "@/partials/navigation/main-nav/MainNav";
import {computed, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import ServerIcon from "@/components/icon/ServerIcon.vue";
import ServerSwitcher from "@/partials/navigation/main-nav/ServerSwitcher.vue";
import {Account} from "@/storage/AccountCache";
import {UI} from "@/storage/UICache";
import "@/ws/WebSocket";
import {socket} from "@/ws/WebSocket";
import {Permissions} from "@archivian/constants";

socket.connect();
const route = useRoute();
const account = Account();
const ui = UI();
const router = useRouter();
/*
const cache = AppCache();
// Check if all announcements are dismissed
const allDismissed = computed(() => {
	return cache.announcements.every(a => cache.isDismissed("announcements", a.id));
});

// Calculate appropriate row classes based on count of announcements left
const announcementGrid = computed(() => {
	const visible = cache.announcements.reduce((n, a) => {
		return cache.isDismissed("announcements", a.id) ? n : n + 1;
	}, 0);

	if (!visible || visible === 1) return "";
	if (visible === 2) return "lg:grid-cols-2";
	return "lg:grid-cols-2 2xl:grid-cols-3";
});
*/

const switcher = ref({
	loadingGuild: false,
});

const currentGuild = computed(() => {
	const id = route.params?.guildId ?? null;
	if (!id) return null;
	return account.getGuild(id);
});

// Top sidebar items
const sidebar = computed(() => {
	/*
	if (process.env.VUE_APP_IS_DEV === "1") {
		categories.push(...[
			{
				link: {
					name: "global-settings",
				},
				tooltip: "Global Settings",
				icon: "Cog",
			},
			{
				link: {
					name: "guild-tools",
				},
				tooltip: "Server Tools",
				icon: "Wrench",
			},
			{
				link: {name: "dev-main"},
				tooltip: "For development purposes",
				icon: "Archivian",
			},
			{
				link: {name: "editor"},
				tooltip: "ProseMirror Editor",
				icon: "Note",
			}
		]);
	}
	 */

	return [
		{
			link: {
				name: "guild-home",
			},
			tooltip: "Home",
			icon: "Home",
		},
		{
			link: {
				name: "case-system",
			},
			tooltip: "Cases & Appeals",
			icon: "Cases",
			// badgeCount: 3,
			permission: [
				Permissions.Cases.CASE.READ_ANY(),
				Permissions.CaseAppeals.APPEAL.READ("*", "*", "*"),
			],
		},
		{
			link: {
				name: "staff-management",
			},
			tooltip: "Staff Management",
			icon: "ShieldUser",
		},
		{
			link: {
				name: "guild-channels",
			},
			tooltip: "Server Channels",
			icon: "Hash",
			permission: [
				Permissions.Messages.Policies.READ(),
				Permissions.Messages.Collections.READ(),
				Permissions.Messages.DeletedAttachments.READ(),
			],
		},
		{
			link: {
				name: "members",
			},
			tooltip: "Server Members",
			icon: "Group",
			permission: Permissions.WarningSystem.EarlyWarnings.SETTINGS.READ(),
		},
		{
			link: {
				name: "auto-mod",
			},
			tooltip: "Auto Moderation",
			icon: "ShieldRobot",
			permission: Permissions.WarningSystem.EarlyWarnings.SETTINGS.READ(),
		}
	].filter(item => {
		if (!item.permission) return true;
		if (Array.isArray(item.permission)) return item.permission.some(p => account.can(p));
		return account.can(item.permission);
	});
});

async function selectGuild(guildId) {
	if (switcher.value.loadingGuild || guildId === route.params.guildId) return;
	switcher.value.loadingGuild = true;

	if (guildId === null) return router.push({name: "me"});
	router.push({
		name: "guild-landing",
		params: {guildId: guildId},
	});

	switcher.value.loadingGuild = false;
}

</script>
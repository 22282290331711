import IDBModel from "@/db/IDBModel";

/**
 * Sets up the IndexedDB models
 */
export default function() {
	// No key-path, since the value will just be an array. Key must always explicitly be passed.
	const permissions = new IDBModel("permissions", {});

	const guilds = new IDBModel("guilds", {keyPath: "id"});

	const users = new IDBModel("users", {keyPath: "id"});

	const staff = new IDBModel("staff", {keyPath: "id"})
		.addIndex("guildId", { unique: false });

	const teams = new IDBModel("teams", {keyPath: "id"})
		.addIndex("guildId", { unique: false });

	const titles = new IDBModel("titles", {keyPath: "id"})
		.addIndex("guildId", { unique: false });

	const channels = new IDBModel("channels", {keyPath: "id"})
		.addIndex("guild_id", { unique: false });

	const roles = new IDBModel("roles", {keyPath: "id"})
		.addIndex("guild_id", { unique: false });

	// ID = user ID
	const members = new IDBModel("members", {keyPath: "id"})
		.addIndex("guildId", { unique: false });

	/**
	 * Caches data needed to populate various pages.
	 * Acts as a faster way to populate the dashboard with last known data.
	 * ID is an abstract page identifier, e.g., it can be the URL path.
	 * Guild ID can be set to `null` to indicate personal dashboard.
	 *
	 * [id (url path)]: {id: string, guildId: string|null, data: any}
	 */
	const pageDataCache = new IDBModel("pageDataCache", {keyPath: "id"})
		.addIndex("guildId", { unique: false });

	return [
		permissions,
		guilds,
		users,
		staff,
		teams,
		titles,
		channels,
		roles,
		members,
		pageDataCache,
	];
}
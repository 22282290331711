<template>
	<div class="ToolTip relative transition-all">
		<button
			class="cursor-help"
			@click="forceToggle"
			@mouseover="show(true)"
			@mouseout="show(false)"
		>
			<span
				:class="{'hover:opacity-100 opacity-50': !visible.clicked}"
				class="leading-none text-xs w-4 h-4 bg-neutral-900 text-white rounded-full flex items-center justify-center"
			>
				?
			</span>
		</button>
		<dialog
			ref="tipBox"
			:class="{
				'right-0': visible.outOfBounds,
			}"
			class="tooltip-dialog"
		>
			<div class="p-2 backdrop-blur-2xl relative rounded-md" :class="{'bg-neutral-900': solidBg}">
				<p class="font-normal">
					{{ description }}
				</p>
			</div>

			<template v-if="toLearnMore">
				<hr class="border-neutral-700">
				<div class="p-2">
					<RouterLink :to="toLearnMore">
						<div class="flex gap-1 text-sky-400">
							<p class=" text-sky-400">
								Learn more
							</p>
							<DynamicIcon icon="ArrowDiagonalUp" size="22" />
						</div>
					</RouterLink>
				</div>
			</template>
		</dialog>
	</div>
</template>

<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {ref} from "vue";

// TODO Tooltip suffers from being limited by the parent's overflow hidden/scroll.
defineProps({
	"description": {
		type: String,
		required: true,
	},
	"toLearnMore": {
		type: [String, Object],
		required: false,
		default: () => null,
	},
	"solidBg": {
		type: Boolean,
		required: false,
		default: () => false,
	},
});

const visible = ref({
	clicked: false,
	outOfBounds: false,
});
const tipBox = ref(null);

function forceToggle() {
	visible.value.clicked = !visible.value.clicked;
	show(visible.value.clicked);
}

function show(visibility) {
	if (visible.value.clicked) return;

	if (visibility) tipBox.value.show();
	else tipBox.value.close();

	setTimeout(() => {
		visible.value.outOfBounds = false;
		if (visibility) visible.value.outOfBounds = outOfBounds();
	}, 0);
}

// TODO Move box so it doesn't display outside the screen
/**
 * Return if the box is ending up outside the bounds of the document
 * @returns {boolean}
 */
function outOfBounds() {
	if (!tipBox.value) return false;
	const {right} = tipBox.value.getBoundingClientRect();
	return right > document.body.clientWidth;
}

</script>

<style scoped>
.tooltip-dialog {
	@apply text-sm bg-neutral-900/40 backdrop-blur-2xl border border-neutral-700/40 shadow-xl rounded-md text-white w-64
	absolute z-50 mt-1 focus:outline-none;
}
</style>
import backend from "@/api/backend";
/**
 * @typedef {Object} BaseAPIOptions Base options available for most or all service methods
 * @property {?boolean} [withStatus] Return {@link BackendResult} instead of just the data from body
 */

/**
 * Fetches all the message policies a guild has
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<null | IMessagePolicy[] | BackendResult>} `null` if request failed for some reason, e.g., permissions
 */
export async function fetchMessagePolicies(guildId, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/messages/policies`);

	if (r.status !== 200 && !options.withStatus) return null;
	return options.withStatus ? r : r.body;
}

/**
 * Fetches all the message policies a guild has
 * @param {string} guildId
 * @param {IMessagePolicy[]} policies All policies. Will overwrite the DB document
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<null | IMessagePolicy[] | BackendResult>} `null` if request failed for some reason, e.g., permissions
 */
export async function setMessagePolicies(guildId, policies, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/messages/policies`, "PUT", {
		body: policies,
	});

	if (r.status !== 200 && !options.withStatus) return null;
	return options.withStatus ? r : r.body;
}

/**
 * @typedef {Object} GetEarlyWarningSettingsResult
 * @property {IGuildConfigEarlyWarningSettings} settings
 * @property {{score: number, v: number}[]} stats.guild
 * @property {number} stats.global
 */
/**
 * Fetches all the message policies a guild has
 * @param {string} guildId
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<null | GetEarlyWarningSettingsResult | BackendResult>} `null` if request failed for some reason, e.g., permissions
 */
export async function getEarlyWarningSettings(guildId, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/warning-system/early-warning/settings`);

	if (r.status !== 200 && !options.withStatus) return null;
	return options.withStatus ? r : r.body;
}

/**
 * Fetches all the message policies a guild has
 * @param {string} guildId
 * @param {IGuildConfigEarlyWarning} settings All the new settings. Will fully overwrite the old.
 * @param {?(BaseAPIOptions)} [options]
 * @returns {Promise<null | IGuildConfigEarlyWarning | BackendResult>} `null` if request failed for some reason, e.g., permissions
 */
export async function updateEarlyWarningSettings(guildId, settings, options = {}) {
	const r = await backend(`/public/guilds/${guildId}/warning-system/early-warning/settings`, "PUT", {
		body: settings,
	});

	if (r.status !== 200 && !options.withStatus) return null;
	return options.withStatus ? r : r.body;
}


export default {
	fetchMessagePolicies,
	setMessagePolicies,
	getEarlyWarningSettings,
	updateEarlyWarningSettings
};
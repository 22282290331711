<template>
	<div
		class="table-card border-details rounded"
	>
		<div class="flex gap-2 items-center pr-4 bg-neutral-800/50 rounded-t">
			<slot name="main">
				<div class="flex gap-2 items-center p-4 grow min-w-0 truncate">
					<slot name="content">
						<!-- <template #content> -->
					</slot>
				</div>

				<div class="relative">
					<slot name="aside">
						<!-- <template #aside> -->
					</slot>
				</div>
			</slot>
		</div>

		<div
			class="grid grid-cols-3 gap-4 shrink bg-neutral-700/50 border-t border-t-neutral-700 px-4 py-2"
		>
			<slot name="footer">
				<div class="flex gap-2 col-span-1 items-center">
					<slot name="footer-left">
						<!-- <template #footer-left> -->
					</slot>
				</div>

				<div class="flex gap-2 justify-end col-span-2 items-center">
					<slot name="footer-right">
						<!-- <template #footer-right> -->
					</slot>
				</div>
			</slot>
		</div>
	</div>
</template>
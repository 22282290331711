<template>
	<div :style="`width: ${size}px; height: ${size}px`" class=" shrink-0">
		<img
			v-if="icon && !iconFailed"
			:style="[
				bgColor && `background-color: ${guildBGColor(serverId)[0]}`,
				`width: ${size}px; height: ${size}px`
			]"
			class="rounded-full object-contain w-full h-full"
			:src="serverIcon(
               serverId,
               icon,
               size * 2,
       		)"
			:alt="`Icon of server by ID ${serverId}`"
			@error="iconFailed = true"
			@load="(e) => bgColor = e.isLoaded"
		>
		<div
			v-else
			class="overflow-hidden rounded-full w-full h-full flex items-center justify-center font-medium select-none"
			:style="`background-color: ${guildBGColor(serverId)[0]};color:${guildBGColor(serverId)[1]}`"
		>
			<p :style="`font-size: ${size/2}px`">
				{{ acronym(name) }}
			</p>
		</div>
	</div>
</template>

<script setup>
import {ref} from "vue";
import {guildBGColor, serverIcon} from "@/script/convert";

defineProps({
	"icon": {
		type: String,
		default: () => null,
	},
	"name": {
		type: String,
		required: true,
	},
	"serverId": {
		type: String,
		required: true,
	},
	/**
	 * @type {DiscordImageSize}
	 */
	"size": {
		type: [Number, String],
		default: () => 32
	},
});
const iconFailed = ref(false);
const bgColor = ref(true);

/**
 * Creates an acronym of the server
 * @param name
 * @return {*|string}
 */
function acronym(name) {
	if (!name) return "??";
	const x = name.split(/[ _-]+/).filter(Boolean);
	if (x.length === 1) return x[0].toUpperCase().slice(0, 2);
	return x.map(w => w.charAt(0).toUpperCase()).slice(0, 2).join("");
}
</script>

<script setup>
import InspectResourceLayout from "@/partials/layouts/InspectResourceLayout.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import LoadingEllipsis from "@/components/icon/LoadingEllipsis.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import NewAppealMainView from "@/partials/inspect/appeal/newAppeal/NewAppealMainView.vue";
import {computed, ref} from "vue";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

const props = defineProps({
	"createAppeal": {
		type: Function,
		default: () => () => {},
	},
	"close": {
		type: Function,
		default: null,
	},
	"cancel": {
		type: Function,
		default: null,
	},
	"error": {
		type: Object,
		default: () => ({label: "", texts: []}),
	},
	"appeals": {
		type: Array,
		default: () => [],
	},
	"canAppeal": {
		type: Boolean,
		default: () => true,
	},
});

const cases = CaseAppealBrowser();
const create = () => props.createAppeal(appealText.value, subscribe.value);
const appealText = ref("");
const subscribe = ref(false);
const tooShortText = computed(()=>{
	return (appealText.value?.trim()?.length || "") < 32;
});
</script>

<template>
	<InspectResourceLayout class="bg-neutral-800/50">
		<template #head>
			<div class="flex items-center w-full justify-between">
				<h2>Create Appeal</h2>

				<div v-if="close" class="CaseActionButtons">
					<CloseButton label="Close appeal creation panel" @click="close" />
				</div>
			</div>
		</template>

		<template #main>
			<ErrorCallout
				v-if="error.label"
				:label="error.label"
				:texts="error.texts"
			/>
			<ErrorCallout
				v-if="!canAppeal"
				label="You cannot create an appeal on this case. Common reasons are&hellip;"
				:texts="[
					'you already have an appeal without a verdict',
					'appeals are disabled for this case',
					'your last appeal was approved.',
				]"
			/>
			<NewAppealMainView
				v-model:content="appealText"
				v-model:subscribe="subscribe"
				:is-loading="cases.isLoading('appealCreate', cases.currentId)"
				:disabled="!canAppeal"
			/>
		</template>

		<template #footer>
			<div class="flex gap-2 w-full justify-end items-center">
				<p v-if="canAppeal" class="italic">
					You cannot edit after submitting.
				</p>

				<BasicButton
					v-if="cancel || close"
					:label="cases.isLoading('appealCreate', cases.currentId) ? 'Close' : 'Cancel'"
					type="link"
					@click="() => cancel ? cancel() : close()"
				/>

				<BasicButton
					class="flex"
					:label="!cases.isLoading('appealCreate', cases.currentId) ? 'Submit Appeal' : 'Submitting'"
					:disabled="!canAppeal || tooShortText || cases.isLoading('appealCreate', cases.currentId)"
					@click="create"
				>
					<LoadingEllipsis v-if="cases.isLoading('appealCreate', cases.currentId)" />
				</BasicButton>
			</div>
		</template>
	</InspectResourceLayout>
</template>
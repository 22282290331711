/**
 * © 2021 - Martin Haslien <martin@haslien.no>
 * All the code in this file is copyrighted, and may not be used, copied, or modified by other parties.
 */
/**
 * The result of a permission evaluation
 * @typedef {Object} EvalResult
 * @prop {boolean} access If evaluation returned ture or false
 * @prop {String} [node] The permission node user had that caused a true/false. Is 'null' if no matches.
 * @prop {String} needed The permission node needed for this to be true
 * @prop {Array<string>} has An array of permission nodes the user has
 */
/**
 * Evaluate permissions based on what is required and what is provided
 * @param {String} required The required permission string
 * @param {String|Array<string>} has The permissions this entity has
 * @param {("any"|"all")} [mode="any"] The checking mode for permissions. Returns 'true' or 'false' if [required] is true for [mode] nodes
 *     of [has].
 * @returns {EvalResult}
 */
export default function (required, has, mode = "any") {
	if (!required || !has) {
		return {
			access: has?.includes("**"),
			needed: null,
			node: has?.includes("**") ? "**" : null,
			has
		};
	}

	if (!Array.isArray(has)) has = [has];

	/**
	 * The required node split up
	 * @type {Array<string>}
	 */
	const requires = required.split(".").filter(Boolean);

	/**
	 * If mode 'all', counter that need to be 0
	 * @type {number}
	 */
	let requiredMatches = has.length;

	/**
	 * Function that structures the returned result
	 * @param {boolean} bool The result of the evaluation
	 * @param {String} currentNode The current node that warranted a true/false
	 * @param {String} required The node needed
	 * @param {Array<string>} has The permission nodes they have
	 * @returns {EvalResult}
	 */
	const r = function (bool, currentNode, required, has) {
		const result = {
			access: bool,
			needed: required,
			node: currentNode,
			has
		};

		// If any was match, return result. If it was a 'no' when 'all', then return
		if ((mode === "any") && bool || (mode === "all" && !bool)) return result;

		// Subtract needed matches and continue
		if (mode === "all") {
			requiredMatches--;
			if (!requiredMatches) return result;
		}
	};

	for (let k = 0; k < has.length; k++) {
		if (has[k] === required) {
			const f = r(true, has[k], required, has);
			if (f !== undefined) return f;
			continue;
		}

		/**
		 * The permission node split up
		 * @type {Array<string>}
		 */
		const perm = has[k].split(".").filter(Boolean);

		/**
		 * The index of the current requirement sub-node
		 * @type {number}
		 */
		let reqIndex = 0;

		/**
		 * The index of the current permission sub-node
		 * @type {number}
		 */
		let permIndex = 0;

		/**
		 * If we're using any kind of extender
		 */
		const extenders = {
			req: false,
			perm: false
		};

		/**
		 * Flag to exit the while loop
		 * @type {boolean}
		 */
		let concluded = false;

		while (!concluded) {
			// Check if last, and either ends with extender '**'
			if (requires[reqIndex] === "**" && reqIndex === requires.length - 1) {
				const f = r(true, has[k], required, has);
				if (f !== undefined) return f;
				concluded = true;
				break;
			}
			if (perm[permIndex] === "**" && permIndex === perm.length - 1) {
				const f = r(true, has[k], required, has);
				if (f !== undefined) return f;
				concluded = true;
				break;
			}

			// If we're using extender, check for end of matching
			if (extenders.req || extenders.perm) {
				// Both have extender, disable the longest of both if equal
				if (extenders.req && extenders.perm) {
					if (perm.slice(permIndex).length === requires.slice(reqIndex).length) {
						extenders.req = false;
						extenders.perm = false;
					} else {
						extenders[perm.slice(permIndex).length > requires.slice(reqIndex).length ? "perm" : "req"] = false;
					}
				}

				// Either is ending with .**
				if (extenders.req && reqIndex === requires.length) {
					const f = r(requires[reqIndex - 1] === "**", has[k], required, has);
					if (f !== undefined) return f;
					concluded = true;
					break;
				} else if (extenders.perm && permIndex === perm.length) {
					const f = r(perm[permIndex - 1] === "**", has[k], required, has);
					if (f !== undefined) return f;
					concluded = true;
					break;
				}

				if (extenders.req && perm[permIndex]) permIndex++;
				if (extenders.perm && requires[reqIndex]) reqIndex++;
			}

			// Check if we reached either end or array, make verdict
			if (reqIndex >= requires.length) {
				// End if extender is still active, and req was deciding factor
				if (extenders.req && reqIndex < permIndex) {
					const f = r(true, has[k], required, has);
					if (f !== undefined) return f;
					concluded = true;
					break;
				}

				// Ends with *
				if (requires[reqIndex] === "*" || requires[reqIndex] === "**" || extenders.req) {
					const f = r(true, has[k], required, has);
					if (f !== undefined) return f;
					concluded = true;
					break;
				}

				// Final check if last bit is identical
				const f = r(perm[permIndex] === requires[reqIndex], has[k], required, has);
				if (f !== undefined) return f;
				concluded = true;
				break;
			}
			if (permIndex >= perm.length) {
				// End if extender is still active, and permIndex was deciding factor
				if (extenders.perm && reqIndex > permIndex) {
					const f = r(true, has[k], required, has);
					if (f !== undefined) return f;
					concluded = true;
					break;
				}

				// Ends with *
				if (perm[permIndex] === "*" || perm[permIndex] === "**" || extenders.perm) {
					const f = r(true, has[k], required, has);
					if (f !== undefined) return f;
					concluded = true;
					break;
				}

				// Doesn't end with *|** and there's more left of 'req'
				// Final check if last bit is identical
				const f = r(perm[permIndex] === requires[reqIndex], has[k], required, has);
				if (f !== undefined) return f;
				concluded = true;
				break;
			}

			// Extender was found
			if (perm[permIndex] === "**" || requires[reqIndex] === "**") {
				// Counts as identical sub-node, if not end
				if (perm[permIndex] === requires[reqIndex]) {
					if (reqIndex === requires.length - 1) {
						const f = r(requires[reqIndex] === "**", has[k], required, has);
						if (f !== undefined) return f;
						concluded = true;
					} else if (permIndex === perm.length - 1) {
						const f = r(perm[permIndex] === "**", has[k], required, has);
						if (f !== undefined) return f;
						concluded = true;
					}

					extenders.perm = false;
					extenders.req = false;
				} else {
					extenders.perm = perm[permIndex] === "**";
					extenders.req = requires[reqIndex] === "**";
				}
				permIndex++;
				reqIndex++;
				continue;
			}

			// Assume true
			if (perm[permIndex] === "*" || requires[reqIndex] === "*") {
				if (!extenders.perm) permIndex++;
				if (!extenders.req) reqIndex++;
				// If both have, it counts as identical sub-node match
				if (perm[permIndex] === requires[reqIndex]) {
					extenders.perm = false;
					extenders.req = false;
				}
				continue;
			}

			// Identical sub-nodes
			if (perm[permIndex] === requires[reqIndex]) {
				permIndex++;
				reqIndex++;
				extenders.perm = false;
				extenders.req = false;
				continue;
			}

			if (extenders.req || extenders.perm) continue;

			// Should never be reached unless mismatch in sub-node
			concluded = true;
			break;
		}
	}

	return {
		access: false,
		needed: required,
		node: null,
		has
	};
}
import { defineStore } from "pinia";
import { discordAvatar } from "@/script/convert";
import IndexedDBStore from "@/storage/IndexedDB";

/**
 * Holds information on staff, titles, and teams.
 * To make it more useful for staff related information,
 * it also holds the necessary Discord user records for the staff.
 */
export default defineStore("UserCache", {
	state: () => {
		/**
		 * State is loaded from IndexedDB into this on load
		 */
		return {
			/**
			 * An index that lets you go UserID -> Staff ID
			 * @type {Map<UserID, string>}
			 */
			_userStaffIndex: new Map(),
			/**
			 * When the staff list was last updated
			 * @type {Date|null}
			 */
			_staffLastUpdated: null, // Date.now()
		};
	},
	actions: {
		/**
		 * Get user from cache
		 * @param {UserID|string} userId
		 * @param {boolean} [dummyFallback=true] If user is not found, return a dummy object instead of null
		 * @returns {null|{id:string, username: string, avatar: (string|null)}}
		 */
		user(userId, dummyFallback = true) {
			const user = IndexedDBStore().users[userId];
			return user ?? (dummyFallback ? { id: userId, username: "Unknown User", avatar: null } : null);
		},
		/**
		 * Gets the username of a user by ID
		 * @param {UserID|string} userId
		 * @param {?boolean} [fallback=false] If user is not found, return "Unknown User" or so
		 * @param {?boolean} [useDisplayName=true] If we have a guild nickname or global display name for this user,
		 * use that instead
		 * @returns {string|null}
		 */
		username(userId, fallback = false, useDisplayName = true) {
			if (userId === "00000000000000000000") return "Unknown User";
			if (userId === "11111111111111111111") return "Example User";

			const user = IndexedDBStore().users[userId];
			if (!user) return fallback ? "Unknown User" : null;

			// ATM we don't have properties for guild nickname, but we do have global display name
			if (user.global_name && useDisplayName) return user.global_name;

			return user.username;
		},
		/**
		 * Gets the title of a staff member by their User ID
		 * @param {UserID|string} userId
		 * @param {boolean} [fallbackToRetired=true] If staff is not found, return title as "retired"
		 * @returns {string|null|"Retired Staff"}
		 */
		titleOf(userId, fallbackToRetired = true) {
			if (userId === "00000000000000000000") {
				return fallbackToRetired ? "Unknown" : null;
			}
			if (userId === "11111111111111111111") {
				return fallbackToRetired ? "Example Title" : null;
			}

			const idbStore = IndexedDBStore();
			const staff = idbStore.staff[userId];
			if (!staff) return fallbackToRetired ? "Retired Staff" : null;

			// Staff member might not have title assigned
			if (!staff.titleId) return fallbackToRetired ? "" : null;

			const title = idbStore.titles[staff.titleId];
			return title?.name ?? (fallbackToRetired ? "Unknown Title" : null);
		},
		/**
		 * Get the URL to the avatar of a user by ID, or return fallback default avatar icon
		 * @param {UserID} userId
		 * @param {DiscordImageSize} [size=32]
		 * @returns {string} Full URL to avatar
		 */
		avatarOf(userId, size = 32) {
			if (userId === "00000000000000000000") return "https://cdn.discordapp.com/embed/avatars/0.png";

			const user = this.user(userId);
			return discordAvatar(userId, user.avatar, { size });
		},
		/**
		 * Returns the avatar hash of a given user, if they have any
		 * @param {UserID} userId
		 * @returns {string|null}
		 */
		avatarHashOf(userId) {
			const user = this.user(userId);
			return user.avatar ?? null;
		},
	},
});
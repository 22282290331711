<template>
	<SkipMenu />
	<RouterView />
</template>

<script setup>
import {onMounted} from "vue";
import {apiDiscordLoginUrl} from "@/script/convert";
import {useSanityClient} from "vue-sanity";
import {validateSessionInBackground} from "@/views/routeHandlers";
import SkipMenu from "@/partials/navigation/skip-menu/SkipMenu.vue";

useSanityClient({
	projectId: "bq3sxzdk",
	dataset: "production",
	useCdn: process.env.VUE_APP_IS_DEV !== "1",
	apiVersion: "v2021-10-21",
});

onMounted(async () => {
	document.addEventListener("login", () => {
		return (window.location.href = apiDiscordLoginUrl(
			window.location.pathname
		));
	});
	document.addEventListener("updateMe", () => {
		return validateSessionInBackground();
	});
	document.addEventListener("keyup", (e) => {
		switch (e.key) {
			case "Escape":
				return document.dispatchEvent(new Event("key-escape"));
			case "Tab":
				return document.dispatchEvent(new Event("key-tab"));
		}
	});
});
</script>

<style scoped>
dialog::backdrop {
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(16px);
}
</style>

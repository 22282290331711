import { toRaw } from "vue";
/**
 * A database query IDBModel records.
 * Flat objects only. Multiple keys act as an AND condition.
 * Consider always using indexed fields in your query.
 * @typedef {Record<string, any>} IDBModelFilterQuery
 */
/**
 * @typedef {Object} IDBModelOptions
 * @property {?string} keyPath Define where in the data stored the primary key can be found, if applicable
 * @property {?boolean} autoIncrement Generate primary key on the fly using auto-incrment. Ignored if keyPath is set.
 */
/**
 * @template ModelType
 */
export default class IDBModel {
	/**
	 * @type {string[]}
	 */
	#indexes = [];
	/**
	 * @type {IDBDatabase}
	 */
	#db = null;
	/**
	 * @type {IDBPObjectStore<any, ArrayLike<StoreNames<any>>, string, "versionchange"> | IDBObjectStore}
	 */
	#store = null;
	/**
	 * A 2D array of indexes to create once we're connected to the database
	 * @type {[string | string[], ?IDBIndexParameters][]}
	 */
	#toIndex = [];
	/**
	 * @type {IDBModelOptions}
	 */
	#options = {keyPath: null, autoIncrement: false};

	constructor(name, options) {
		this.name = name;
		if (options.keyPath) {
			this.#indexes.push(options.keyPath);
		}
		this.#options = options;
	}

	/**
	 * Returns name of the primary key.
	 * In an IDBModel, the property should also be found in the data.
	 * @returns {string}
	 */
	get #primaryKey() {
		return this.#indexes[0];
	}

	/**
	 * Attaches an IndexedDB instance to the model
	 * @param {IDBDatabase} idb
	 * @returns {IDBModel}
	 * @private
	 */
	attachModelToDB(idb) {
		if (!idb) {
			throw new Error(`Missing IDB: ${idb}`)
		}

		console.debug("[IndexedDB Store] [%s] DB Attached", this.name);
		this.#db = idb;
	}

	createStore(idb) {
		this.#store = (this.#db || idb).createObjectStore(this.name, this.#options);

		while(this.#toIndex.length) {
			const [indexOnKey, indexOptions] = this.#toIndex.shift();
			this.addIndex(indexOnKey, indexOptions);
		}

		return this;
	}

	/**
	 * Creates an additional index for this model
	 * @param {string | string[]} indexOnKey The key in the object we want to create index of
	 * @param {?IDBIndexParameters} [indexOptions]
	 * @returns {IDBModel}
	 */
	addIndex(indexOnKey, indexOptions) {
		// If DB is not attached or ready, queue it for later
		if (!this.#db) {
			this.#toIndex.push([indexOnKey, indexOptions]);
			return this;
		}

		let indexName = indexOnKey;

		if (Array.isArray(indexOnKey)) {
			indexName = indexOnKey.join(".");
			this.#indexes.push(...indexOnKey);
		} else {
			this.#indexes.push(indexOnKey);
		}


		this.#store.createIndex(indexName, indexOnKey, indexOptions);

		return this;
	}

	/**
	 * Finds the first indexed key, if any.
	 * Automatically logs an info message that an index should be used if not found.
	 * @param {IDBModelFilterQuery} query
	 * @param {?boolean} [primaryOnly=false] If true, only the primary key is considered an index
	 * @param {?boolean} [log=true] If true, logs a recommendation to the console
	 * @returns {null | string}
	 */
	#getIndexKey(query, primaryOnly  = false, log = true) {
		if (primaryOnly) {
			const indexFound = Object.values(query).find(key => this.#primaryKey === key);
			if (!indexFound && log) {
				console.debug(`[IndexedDB Store] [${this.name}] This query method can only leverage the primary key. Try including the primary key if possible`, query);
			}
			return indexFound; // index key or null
		}

		const indexedKey = Object.values(query).find(key => this.#indexes.includes(key));
		if (!indexedKey && log) {
			console.debug(`[IndexedDB Store] [${this.name}] Your query is not using an index key. Consider creating an index for this query.`, query);
		}

		return indexedKey;
	}

	/**
	 * Finds the first record in a DB by its ID
	 * @param {string} value The ID of the record to retrieve
	 * @returns {Promise<ModelType|null>} The found record, if any
	 */
	findOneById(value) {
		return new Promise((r, rj) => {
			const tr = this.#db.transaction(this.name, "readonly");
			const req = tr.objectStore(this.name).get(value || "");

			req.onerror = rj;
			req.onsuccess = () => r(req.result ?? null);
		});
	}

	/**
	 * Find all records that have a value between `fromValue` and `toValue`, including those two records.
	 * @param {any} fromValue
	 * @param {any} toValue
	 * @param {?string} [usingIndex] The index to use. Defaults to the main `keyPath` / the primary key.
	 * @returns {Promise<ModelType[]>}
	 */
	findByIdRange(fromValue, toValue, usingIndex) {
		if (!usingIndex) usingIndex = this.#primaryKey;
		else if (!this.#indexes.includes(usingIndex)) {
			throw new Error(`[IDB ${this.name}] There is no index for '${usingIndex}'`);
		}

		return new Promise((r, rj) => {
			const tr = this.#db.transaction(this.name, "readonly");
			const range = IDBKeyRange(fromValue, toValue);
			const req = usingIndex === this.#primaryKey
				? tr.objectStore(this.name).get(range)
				: tr.objectStore(this.name).index(usingIndex).get(range);

			req.onerror = rj;
			req.onsuccess = () => r(req.result ?? []);
		});
	}

	/**
	 * Whenever a filter is used and you get results, this function will do the final filtering to return
	 * only the matching records.
	 * @param {IDBModelFilterQuery} query
	 * @param {ModelType} result
	 * @returns {boolean}
	 */
	#recordMatchesQuery(query, result) {
		return Object
			.keys(query)
			.every(key => result[key] === query[key])
	}

	/**
	 * Finds the first record that matches a query
	 * @param {IDBModelFilterQuery} query
	 * @returns {Promise<null | ModelType>} Null if not found, else the record
	 */
	findOne(query) {
		const indexedKey = this.#getIndexKey(query);

		if (indexedKey) {
			return new Promise((r, rj) => {
				const tr = this.#db.transaction(this.name, "readonly");
				const req = indexedKey === this.#primaryKey
					? tr.objectStore(this.name).get(indexedKey)
					: tr.objectStore(this.name).index(indexedKey).get(indexedKey)

				req.onerror = rj;
				req.onsuccess = () => {
					return r(
						this.#recordMatchesQuery(query, req.result)
							? req.result
							: null
					);
				}
			});
		}

		return new Promise((r, rj) => {
			/**
			 * Fallback to the less efficient method of scanning records.
			 * We must use openCursor() to obtain the values for each record and perform a check on each one.
			 */
			const tr = this.#db.transaction(this.name, "readonly");
			/**
			 * The first value is null because we already checked if we could use a key.
			 *
			 * We then want to iterate backwards from the end, because most likely you are looking
			 * up the most recent records.
			 */
			const req = tr.objectStore(this.name).openCursor(null, "prev");

			req.onerror = rj;
			/**
			 * The iteration of the cursor is a bit weird.
			 * You know you reached the end when the `req.result` (the cursor) is null.
			 * Else call .continue() to re-execute the `onsuccess` handler to obtain next record.
			 */
			req.onsuccess = () => {
				const cursor = req.result;
				if (!cursor) return r(null);
				if (this.#recordMatchesQuery(query, cursor.value)) {
					return r(cursor.value);
				}
				return cursor.continue();
			}
		});
	}

	/**
	 * Find many records that match the query
	 * @param {IDBModelFilterQuery} query
	 * @param {?number} [limit] Limit the count of records found
	 */
	findMany(query, limit) {
		if (!this.#db) throw new Error("Missing DB");

		if (!Object.keys(query).length) {
			return new Promise((r, rj) => {
				const tr = this.#db.transaction(this.name, "readonly");
				const req = tr.objectStore(this.name).getAll(null, limit);

				req.onerror = rj;
				req.onsuccess = () => r(req.result);
			});
		}

		const indexedKey = this.#getIndexKey(query);
		if (indexedKey) {
			/**
			 * If we use an index and the limit, we get up to LIMIT records.
			 * The problems is our query might filter OUT stuff more, so we get LESS than the limit,
			 * when in reality, there could have been more records that matched the FULL query if we
			 * included more in the initial results.
			 */

			const indexIsTheOnlyFilter = Object.values(query).length === 1;

			// If we use limit and we only use index filter, it's pretty easy; all resulted records are would match the full query.
			if (indexIsTheOnlyFilter && limit) {
				return new Promise((r, rj) => {
					const tr = this.#db.transaction(this.name, "readonly");
					const req = indexedKey === this.#primaryKey
						? tr.objectStore(this.name).getAll(indexedKey, limit)
						: tr.objectStore(this.name).index(indexedKey).getAll(indexedKey, limit)

					req.onerror = rj;
					req.onsuccess = () => r(req.result);
				});
			}

			/**
			 * Else we have to get all records that matches the index, then we filter out.
			 * We can open a cursor and iterate through matched index records only.
			 * Then we can stop when we reach the limit.
			 */
			return new Promise((r, rj) => {
				const tr = this.#db.transaction(this.name, "readonly");
				/**
				 * openCursor with a query lets us limit the items being iterated to only records that match the query (index),
				 * making the iteration of records faster.
				 */
				const req = indexedKey === this.#primaryKey
					? tr.objectStore(this.name).openCursor(indexedKey)
					: tr.objectStore(this.name).index(indexedKey).openCursor(indexedKey)

				const collectedMatches = [];

				req.onerror = rj;
				req.onsuccess = () => {
					const cursor = req.result;

					// No more records, return what we have
					if (!cursor) return r(collectedMatches);

					// We found a record that is a match
					if (this.#recordMatchesQuery(query, cursor.value)) {
						// Collect the match
						collectedMatches.push(cursor.value);
						// If we have enough, stop and return the results
						if (limit && limit === collectedMatches.length) {
							return r(collectedMatches);
						}
					}

					// Else, let's check the next record
					return cursor.continue();
				}
			});
		}

		/**
		 * Fallback when we are not using any indexed key. Implementation is similar to the findOne,
		 * except we collect up results instead of returning on first, or stop on LIMIT matches.
		 */
		return new Promise((r, rj) => {
			const tr = this.#db.transaction(this.name, "readonly");
			const req = tr.objectStore(this.name).openCursor();

			const collectedMatches = [];
			req.onerror = rj;
			req.onsuccess = () => {
				const cursor = req.result;
				if (!cursor) return r(collectedMatches);

				if (this.#recordMatchesQuery(query, cursor.value)) {
					collectedMatches.push(cursor.value);

					// Limit + collected enough to satisfy it
					if (limit && limit === collectedMatches.length) {
						return r(collectedMatches);
					}
				}

				return cursor.continue();
			}
		});
	}

	/**
	 * Inserts a new record into the database.
	 * @param {ModelType} data The data to insert
	 * @param {?string|number|boolean|null} [key] Explicitly defines the key to use. Useful if your `data` does not have the primary key in it, e.g. if you store an array
	 * @param {?{onConflictIgnore: boolean}} [options] Options for the insert
	 * @throws {Error} Throws if record with same ID already exists. Swallows error if set to ignore conflict in options.
	 * @returns {Promise<null | (string|number)>} Undefined if error ignored, else the key of the inserted record
	 */
	insert(data, key, options = {}) {
		return new Promise((r,rj) => {
			const tr = this.#db.transaction(this.name, "readwrite");
			const req = tr.objectStore(this.name).add(toRaw(data), key);

			req.onerror = options?.onConflictIgnore
				? () => r(null)
				: rj;
			req.onsuccess = () => r(req.result);
		});
	}

	/**
	 * Insert or overwrite a record in the database.
	 * NOTE: It will NOT only overwrite the fields you provide, but the entire object! To merge, specify the mergeData option! It is slower though!
	 * @param {ModelType} data The data to insert
	 * @param {?string|number|boolean|null} [key] Explicitly defines the key to use. Useful if your `data` does not have the primary key in it, e.g. if you store an array
	 * @param {?{mergeData?: boolean, shallowMerge?: boolean}} [options] Options for the insert
	 */
	async upsert(data, key , options = {}) {
		// Normal overwrite
		if (!options.mergeData) {
			return new Promise((r,rj) => {
				const tr = this.#db.transaction(this.name, "readwrite");
				const req = tr.objectStore(this.name).put(toRaw(data), key);

				req.onerror = rj;
				req.onsuccess = () => r(req.result);
			});
		}

		// Very crude, but should be good enough
		const merge = (a, b, shallow) => {
			if (shallow) return {...a, ...b};

			const merged = {...a};
			Object.keys(b).forEach(key => {
				if (typeof b[key] === "object" && Array.isArray(b[key])) {
					merged[key] = merge(a[key], b[key], shallow);
				} else {
					merged[key] = b[key];
				}
			});
			return merged;
		}

		// Else we need to get the record, merge it, then overwrite
		return new Promise((r,rj) => {
			const pk = key || Object.keys(data).find(key => this.#indexes.includes(key));
			if (!pk) {
				throw new Error(`[IDB ${this.name}] Cannot merge data without a key to find the record to merge with`);
			}

			const tr = this.#db.transaction(this.name, "readwrite");
			const req = tr.objectStore(this.name).get(key || data[pk]);

			req.onerror = rj;
			req.onsuccess = () => {
				// This merge is shallow! If you have nested objects, they will be overwritten!
				const merged = req.result
					? merge(req.result, toRaw(data), options.shallowMerge)
					: toRaw(data);

				const followUpReq = tr.objectStore(this.name).put(merged, key);

				followUpReq.onerror = rj;
				followUpReq.onsuccess = () => r(followUpReq.result);
			}
		});
	}

	/**
	 * Returns all the records in this model
	 * @returns {Promise<ModelType[]>}
	 */
	getAll() {
		return new Promise((r, rj) => {
			const tr = this.#db.transaction(this.name, "readonly");
			const req = tr.objectStore(this.name).getAll();

			req.onerror = rj;
			req.onsuccess = () => r(req.result);
		});
	}

	/**
	 * Deletes records that match the query
	 * @param {IDBModelFilterQuery} query
	 */
	deleteMany(query) {
		/**
		 * The deletion only works on the primary key (keyPath), it does NOT work with other indexes!
		 */
		const indexedKey = this.#getIndexKey(query, true);
		const indexIsTheOnlyFilter = Object.values(query).length === 1;

		/**
		 * Here we face the same problem as with findMany, where the full filter query might change which records
		 * are ACTUAL matches or not. If we only use the primary key, we can do it directly.
		 */
		if (indexedKey && indexIsTheOnlyFilter) {
			return new Promise((r, rj) => {
				const tr = this.#db.transaction(this.name, "readwrite");
				const req = tr.objectStore(this.name).delete(indexedKey);

				req.onerror = rj;
				req.onsuccess = r;
			});
		}

		/**
		 * For all other methods, we have to iterate with a cursor either way.
		 * In this case, we could maybe speed it up by using any other available indexes.
		 */
		return new Promise((r, rj) => {
			const foundIndexKey = this.#getIndexKey(query, false, false);
			if (!foundIndexKey) {
				console.debug(`[IndexedDB Store] [${this.name}] deleteMany should be ideally be used with primary key, or in the very least any key. Due to this lacking, all records will have to be examined.`, query);
			}

			const tr = this.#db.transaction(this.name, "readwrite");
			// Do not pass it directly in to the query argument, `null` can be a key.
			const req = foundIndexKey
				? tr.objectStore(this.name).openCursor(foundIndexKey)
				: tr.objectStore(this.name).openCursor();

			const collectedMatches = [];
			req.onerror = rj;
			req.onsuccess = () => {
				const cursor = req.result;
				if (!cursor) return r(collectedMatches);

				if (this.#recordMatchesQuery(query, cursor.value)) {
					collectedMatches.push(cursor.value);
					cursor.delete();
				}

				return cursor.continue();
			}
		});
	}
}
/**
 * Permissions related to guild titles
 */
export const TITLE = {
    /**
     * Permission to anything title related in a guild
     */
    ANY: () => {
        return "permissions.title.**";
    },
    /**
     * Ability to create, edit, and delete titles.
     * <h1>WARNING</h1>
     * In the current state of the Archivian, this will also give the user
     * full access to assign any permissions to any title, include this one.
     * They can also remove their own permission!
     *
     * It is essentially almost the same as the ANY permission.
     * The goal is that this might get changed later at some point.
     * @constructor
     */
    ADMINISTRATE: () => {
        return "permissions.title.admin";
    },
    /**
     * Ability to revoke/assign a title to a guild staff member
     */
    SET_TITLE: () => {
        return "permissions.title.staff.update.set";
    }
};
/**
 * Permissions related to global titles
 */
export const GLOBAL_TITLE = {
    /**
     * Permission to anything title related globally
     */
    ANY: () => {
        return "internal.title.**";
    },
    /**
     * Ability to create, edit, and delete titles
     */
    ADMINISTRATE: () => {
        return "internal.title.admin";
    },
    /**
     * Ability to revoke/assign a title to a global staff member
     */
    SET_TITLE: () => {
        return "internal.title.staff.update.set";
    }
};

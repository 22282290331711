<template>
	<component
		:is="component"
		class="button"
		:class="{
			[`button-${type}`]: true,
			'icon': !!icon,
			[size] : true,
			'flex-row-reverse': reverse,
			'no-label': !label,
		}"
		:disabled="disabled"
		:to="!externalLink && to"
	>
		{{ label }}
		<slot />
		<DynamicIcon
			v-if="icon"
			:icon="icon"
			class="cursor-inherit"
			:stroke="size === 'medium' ? 2 : 1.5"
			:size="size === 'medium' ? 18 : 24"
		/>
	</component>
</template>

<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {computed, h} from "vue";
import {RouterLink} from "vue-router";

const props = defineProps({
	"label": {
		type: String,
		default: null,
	},
	"type": {
		type: String,
		enum: ["danger", "link", "default", "light", "basic", "link-basic", "link-muted"],
		default: () => "default"
	},
	"disabled": {
		type: Boolean,
	},
	"icon": {
		type: String,
		default: () => null,
	},
	// Reverse slot/icon side order
	"reverse": {
		type: Boolean,
		default: () => false,
	},
	"size": {
		type: String,
		enum: ["medium", "large"],
		default: () => "large",
	},
	"to": {
		type: [String, Object],
		default: () => null,
	},
	"externalLink": {
		type: Boolean,
		default: () => false,
	},
});
const component = computed(()=>{
	if (!props.to || props.disabled) return h("button");
	if (props.externalLink) return h("a", {href: props.to, target: "_blank", rel: "noopener noreferrer"});
	return RouterLink;
});
</script>

<style>
.button {
	@apply rounded-md font-medium whitespace-nowrap
	disabled:drop-shadow-none disabled:cursor-not-allowed
	flex items-center gap-2 justify-center;
}
.button.icon:not(.no-label) {
	@apply pr-3;
}
.button.large {
	@apply  px-4 py-2;
}
.button.large.no-label {
	@apply  px-2 py-2;
}
.button.medium {
	@apply px-3 py-1 text-sm;
}
.button.medium.no-label {
	@apply px-1 py-1 text-sm;
}
</style>
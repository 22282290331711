/**
 * Discord's EPOCH.
 * Considered inception of Discord as well.
 */
const EPOCH = 1420070400000n;
const lowestPossibleSnowflake = BigInt("21154535154122752") >> 22n;
/**
 * Performs various checks to see if the input is a valid Discord snowflake
 */
export function isValidSnowflake(text) {
    if (!text)
        return false;
    if (text.length >= 20)
        return false;
    if (text.length < 17)
        return false;
    if (/^\d+$/.test(text) === false)
        return false;
    // Lowest ID is CTO's Snowflake. Anything lower is invalid
    // eslint-disable-next-line no-undef
    const lowest = BigInt("21154535154122752") >> 22n;
    // eslint-disable-next-line no-undef
    return (BigInt(text) >> 22n) >= lowest;
}
/**
 * Recursively flatten a JavaScript object
 * @param object
 * @param [noPrototype=false] Create a faster object that has no prototypes
 * @param [prefix=""] A value to put before each key
 */
export function flattenObject(object, noPrototype = false, prefix = "") {
    const result = noPrototype
        ? Object.create(null)
        : Object();
    for (const key in object) {
        if (object[key] === undefined)
            continue;
        if (typeof (object[key]) === "object"
            && !Array.isArray(object[key])
            && object[key] !== null
            && !(object[key] instanceof Date)) {
            const subObject = flattenObject(object[key]);
            for (const subKey in subObject) {
                result[prefix + key + "." + subKey] = subObject[subKey];
            }
        }
        else if (Array.isArray(object[key])) {
            for (let i = 0; i < object[key].length; i++) {
                const subObject = flattenObject({ [i]: object[key][i] });
                for (const subKey in subObject) {
                    result[prefix + key + "." + subKey] = subObject[subKey];
                }
            }
        }
        else {
            result[prefix + key] = object[key];
        }
    }
    return result;
}
/**
 * Recursively un-flattens a JavaScript object, where dot ('.') is used to indicate a child
 * @param object
 * @param [noPrototype=false] Create a faster object that has no prototypes
 */
export function unFlattenObject(object, noPrototype = false) {
    const result = noPrototype
        ? Object.create(null)
        : {};
    for (const key in object) {
        const keys = key.split(".");
        keys.reduce((obj, childKey, i) => {
            return obj[childKey]
                || (obj[childKey] = isNaN(Number(keys[i + 1]))
                    ? (keys.length - 1 === i
                        ? object[key]
                        : {})
                    : []);
        }, result);
    }
    return result;
}
/**
 * Deep merge two objects.
 * NB: Will cause infinite recursion if using circular dependency!
 * Should be used for deconstructing JSON data.
 * @author Salakar
 */
export function deepMergeObject(target, ...sources) {
    const isObject = (item) => (item && typeof item === "object" && !Array.isArray(item));
    if (!sources.length)
        return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, { [key]: {} });
                deepMergeObject(target[key], source[key]);
            }
            else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return deepMergeObject(target, ...sources);
}
/**
 * Capitalizes the first character of an input
 */
export function capitalize(input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
}
/**
 * Extracts anything it deems as valid Discord Snowflakes from a string.
 * Benchmarked and optimized to be fast.
 * Optionally can also de-duplicate the IDs for you while collecting
 * @param input The input paragraph
 * @param deduplicated Automatically deduplicate the snowflakes for you
 */
export function extractSnowflakes(input, deduplicated = true) {
    const now = Date.now() + 15_000; // + 15 second buffer window
    input += " "; // Hack to include last if it ends with valid ID
    const ids = deduplicated ? new Set() : [];
    let accumulator = "";
    for (let i = 0; i < input.length; i++) {
        if ("1234567890".includes(input[i])) {
            accumulator += input[i];
            continue;
        }
        if (!accumulator)
            continue;
        if (accumulator.length > 20) {
            accumulator = "";
            continue;
        }
        if (accumulator.length < 17) {
            accumulator = "";
            continue;
        }
        const b = BigInt(accumulator) >> 22n;
        if (b + EPOCH > now) {
            accumulator = "";
            continue;
        }
        if (b >= lowestPossibleSnowflake) {
            // @ts-ignore - Incompatible types, but we know what we are doing
            if (deduplicated)
                ids.add(accumulator);
            // @ts-ignore - Incompatible types, but we know what we are doing
            else
                ids.push(accumulator);
        }
        accumulator = "";
    }
    // @ts-ignore - We know it will be array of strings
    return Array.from(ids);
}
// TODO Test mentions and see how they look in a raw message payload
/**
 * Extracts likely global username mentions, for text that would @virtus to mention for example.
 * Looks for @ to start matching against valid username.
 * Benchmarked and optimized for speed.
 * <h2>WARNING</h2>
 * You may get a lot of false positives here:
 * - email domains, e.g. hello@[gmail.com]
 * - role mentions, e.g. @[moderator]
 * - incorrect usernames because first few chars were valid, then we cut off when it began to be invalid, e.g. @[rotting]InHell
 * - might be @{UserID} because the user's client could not load the user (which has real username)
 * - might contain `@unknown` for `@unknown-user` when a user mention is invalid
 * - most likely going to be a display name and not their username
 * - might be (*full or the first valid part*) guild nickname, which may be using a real username someone else owns https://i.thevirt.us/06/b627E.png
 * @param input The input paragraph
 * @param deduplicated Automatically deduplicate the usernames for you
 * @returns An array of usernames, without the @ prefix
 */
function extractUserGlobalNames(input, deduplicated = true) {
    input += " "; // Hack to include last if it ends with valid username
    const names = deduplicated ? new Set() : [];
    let accumulator = "";
    for (let i = 0; i < input.length; i++) {
        // Start collecting on @
        if (!accumulator && input[i] !== "@") {
            continue;
        }
        else if (input[i] === "@") {
            accumulator = "@";
            continue;
        }
        if ("abcdefghijklmnopqrstuvwxyz._0123456789".includes(input[i])) {
            /**
             * Cannot have two consecutive .
             * But what if username ends with . and you add full stop?
             */
            // If it is not ., add and carry on
            if (input[i] !== ".") {
                accumulator += input[i];
                continue;
            }
            else if (!accumulator.endsWith(".")) {
                // If it doesn't already end with ., add and carry on
                accumulator += input[i];
                continue;
            }
            // If we reach this, username ends with . and we're at another .,
            // so check if accumulated is valid username
        }
        // These are definitely reserved as not usernames.
        if (["@here", "@everyone"].includes(accumulator)) {
            accumulator = "";
            continue;
        }
        // 32 + @ prefix
        if (accumulator.length > 33) {
            accumulator = "";
            continue;
        }
        // 2 + @ prefix
        if (accumulator.length < 3) {
            accumulator = "";
            continue;
        }
        // @ts-ignore - No overlapping methods, but we know what we are doing
        if (deduplicated)
            names.add(accumulator);
        // @ts-ignore - No overlapping methods, but we know what we are doing
        else
            names.push(accumulator);
        accumulator = "";
    }
    // @ts-ignore - We know it will be array of strings
    return Array.from(names).map(name => name.slice(1));
}
export function extractMentions(input, types) {
    const mentions = {
        user: [],
        channel: [],
        role: [],
        time: []
    };
    let type = ""; // Currently accumulating type
    let acc = ""; // ID Accumulator
    for (let i = 0; i < input.length; i++) {
    }
    return mentions;
}

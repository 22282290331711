<script setup>
import UserAvatar from "@/components/icon/UserAvatar.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import MentionChannel from "@/components/misc/MentionChannel.vue";
import LocalDate from "@/components/info/parts/LocalDate.vue";
import DarkBadge from "@/components/badges/DarkBadge.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import {computed, ref} from "vue";
import UserCache from "@/storage/UserCache";
import {snowflakeToDate} from "archivian-utils";
import ButtonLike from "@/components/panels/ButtonLike.vue";
import InspectMemberDialog from "@/partials/inspect/guild-member/InspectMemberDialog.vue";

const props = defineProps({
	"msg": {
		type: Object,
		required: true,
	},
	"showChannel": {
		type: Boolean,
		default: () => true,
	},
	"memberInspect": {
		type: Boolean,
		default: () => true,
	}
});

const users = UserCache();
const modal = ref(null);
const inspected = ref({
	type: "",
	value: null,
});
const memberDialog = ref(false);
const toggle = () => memberDialog.value = !memberDialog.value;
const msgCreated = computed(()=>{
	return snowflakeToDate(props.msg._id).date;
});
const handlers = {
	inspect(type, value) {
		if (type === false) {
			modal.value.close();
			return inspected.value = {
				type: "",
				value: null,
			};
		}

		inspected.value = {
			type,
			value,
		};
		return modal.value.showModal();
	},
}
</script>

<template>
	<div>
		<div class="flex items-center gap-x-3">
			<UserAvatar
				:user-id="msg.userId || msg.authorId"
				:avatar="users.avatarHashOf(msg.userId || msg.authorId)"
				:aria-label="`Avatar of ${users.username(msg.userId || msg.authorId)}`"
				:size="24"
			/>
			<div class="flex-auto flex gap-2">
				<h3 class="truncate text-sm font-semibold leading-6 text-neutral-300">
					<ButtonLike
						desc="Inspect this user"
						:class="{
							'hover:text-sky-400': memberInspect,
							'cursor-text': !memberInspect,
						}"
						:disabled="!memberInspect"
						@click="toggle"
					>
						{{ users.username(msg.userId || msg.authorId) }}
					</ButtonLike>
				</h3>
				<DarkBadge
					v-if="msg.isBot || msg.authorIsBot"
					label="Bot"
					icon-class="fill-indigo-500"
					dot
				/>
				<span v-if="showChannel">
					in
					<MentionChannel
						:guild-id="msg.guildId"
						:channel-id="msg.channelId"
						small
					/>
				</span>
			</div>
			<LocalDate
				:date="msgCreated"
				class="flex-none text-xs text-neutral-500"
				time
			/>
		</div>
		<p v-if="msg.content" class="mt-3 text-sm text-neutral-300 whitespace-pre-wrap">
			{{ msg.content }}
		</p>
		<p v-if="msg.hasAttachments" class="p-1 rounded border-details bg-neutral-800 w-fit">
			File Attachment(s)
		</p>
		<div class="flex gap-1">
			<BasicButton
				v-if="msg.embedsJSON"
				class="mt-2"
				label="View Embeds"
				size="medium"
				type="light"
				@click="() => handlers.inspect('embeds', JSON.parse(msg.embedsJSON))"
			/>
		</div>

		<!-- Inspected items (embeds, attachments) -->
		<dialog ref="modal" class="modal">
			<div class="flex justify-end px-4 pt-4">
				<CloseButton
					label="Close modal"
					@click="() => handlers.inspect(false)"
				/>
			</div>
			<div class="p-4">
				<pre
					v-if="inspected"
					class="break-words overflow-x-auto"
				>{{ JSON.stringify(inspected, null, 4) }}</pre>
			</div>
		</dialog>
		<!-- Inspect member overlay -->
		<InspectMemberDialog
			v-model="memberDialog"
			:user-id="msg.userId || msg.authorId"
			:guild-id="msg.guildId"
		/>
	</div>
</template>

<script setup>
import { displayTime } from "@/script/convert";
import {onBeforeUnmount, ref, watch} from "vue";

const props = defineProps({
	"date": {
		type: [String, Date],
		required: true,
	},
	"time": {
		type: Boolean,
		default: () => false,
	},
	"dynamic": {
		type: Boolean,
		default: false,
	}
});

const n = ref(0);
const unit = ref(null);
const lang = navigator.language || navigator.userLanguage || "en";
const rft = new Intl.RelativeTimeFormat(lang, {
	numeric: "auto",
	style: "short",
	localeMatcher: "best fit"
});
function dateToUIString(date) {
	if (!date) return "";

	if (typeof date === "string") date = new Date(date);
	if (!props.dynamic) return displayTime(props.date, true, props.time);

	unit.value = null;
	let value = date.getTime() - Date.now();

	// Less than a minute: seconds
	if (Date.now() - date.getTime() < 60_000) {
		unit.value = "second";
		value = Math.floor(value / 1000);
	} else if (Date.now() - date.getTime() < 3_600_000) {
		unit.value = "minute";
		value = Math.floor(value / 60_000);
	} else if (Date.now() - date.getTime() < 86_400_000) {
		unit.value = "hour";
		value = Math.floor(value / 3_600_000);
	} else if (Date.now() - date.getTime() < 604_800_000) {
		unit.value = "day";
		value = Math.floor(value / 86_400_000);
	} else {
		return date.toLocaleDateString(lang, {year: "numeric", month: "short", day: "numeric"});
	}

	// Less than an hour: minutes
	// Less than a day: hours
	// Less than a week: days
	// Otherwise: date

	return rft.format(value, unit.value);
}

let interval = null;
watch(unit, ()=>{
	clearInterval(interval);
	if (unit.value === null) {
		return;
	}

	const ms = {
		"second": 1000,
		"minute": 60_000,
		"hour": 3_600_000,
		"day": 86_400_000,
	};

	interval = setInterval(()=>{
		n.value++;
	}, ms[unit.value]);
});

onBeforeUnmount(()=> {
	clearInterval(interval);
});
</script>

<template>
	<time
		:key="n"
		:datetime="date"
		:title="date"
	>
		{{ dateToUIString(date) }}
	</time>
</template>

<template>
	<g>
		<path
			d="M7 12L10.5 15.5L17 9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconCheck"
};
</script>
<script setup>
/**
 * This component is wrapper around anything that behaves like a <button>.
 *
 * However, since it is not semantically correct to wrap <button> around anything you want,
 * we have this instead. It implements the necessary accessibility features to make it
 * behave like a button.
 *
 * Buttons should only be used when you have some phrase associated with it, or a single piece of
 * information, for example, "Open server switcher" where we have a button wrapped around the
 * avatar of the user/current guild.
 *
 * This is unlike cards and some other items, which may contain title, description, and other
 * types of content---but we still want it to act like a button of sorts.
 *
 * TODO Implement support for :disabled
 */
const props = defineProps({
	"desc": {
		type: String,
		required: true,
	},
	"disabled": {
		type: Boolean,
		default: false,
	},
});
const emit = defineEmits(["click"]);
const handleClick = (e) => {
	if (props.disabled) return;
	return emit('click', e);
}
const ku = (e) => {
	if (props.disabled) return;
	return emit("click", e);
};
</script>

<template>
	<div
		class="ButtonLike"
		:tabindex="disabled ? -1 : 0"
		role="button"
		:aria-disabled="disabled"
		:aria-label="desc"
		:title="desc"
		@click="handleClick"
		@keydown.enter="() => ku($event)"
		@keydown.space="() => ku($event)"
	>
		<slot />
	</div>
</template>

<style>
.ButtonLike {
	@apply focus-visible:outline-2 focus-visible:outline focus-visible:outline-sky-400;
}
</style>
<script>
export default {
	name: "IconRocket",
}
</script>

<template>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M17.0978 12.1235L11.6988 16.4427C11.3008 16.7612 10.7269 16.7294 10.3665 16.3689L7.63109 13.6336C7.27064 13.2731 7.23886 12.6993 7.5573 12.3012L11.8765 6.90221C13.8515 4.43341 16.8417 2.99625 20.0034 2.99625V2.99625C20.5559 2.99625 21.0038 3.44415 21.0038 3.99666V3.99666C21.0038 7.15828 19.5666 10.1485 17.0978 12.1235Z"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M16.0017 13.0004V17.3843C16.0017 17.7632 15.7876 18.1096 15.4486 18.279L13.0317 19.4875C12.7723 19.6172 12.4694 19.628 12.2015 19.517C11.9335 19.406 11.7269 19.1842 11.6352 18.9091L10.9996 17.0021"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M6.99793 13.0004L5.09098 12.3647C4.81585 12.273 4.59406 12.0665 4.48307 11.7985C4.37209 11.5306 4.38284 11.2277 4.51254 10.9683L5.72104 8.55134C5.89049 8.21244 6.23687 7.99832 6.61577 7.99832H10.9996"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M5.74746 20.5904L2.99622 21.0037L3.40954 18.2525C3.54362 17.36 4.24424 16.6594 5.13675 16.5253V16.5253C5.7817 16.4284 6.43437 16.6433 6.89553 17.1045C7.3567 17.5656 7.57163 18.2183 7.47472 18.8633V18.8633C7.34061 19.7558 6.63997 20.4564 5.74746 20.5904V20.5904Z"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</template>

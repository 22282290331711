<template>
	<g>
		<path
			d="M8 10L12 14L16 10"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconChevron"
};
</script>
<template>
	<div class="select-none ring ring-theme-active/50 rounded-md border border-neutral-700 shadow">
		<!-- Or ish h-32 -->
		<div class="h-full rounded bg-cover bg-center z-0" :style="`background-image: url('${backgroundImage}')`">
			<div
class="w-full h-full flex flex-col justify-between rounded"
				 :class="{
					'bg-gradient-to-t from-black to-transparent': backgroundImage,
					'bg-neutral-100': !backgroundImage
			}"
>
<div>
					<!-- Upload bar: container -->
					<div class="w-full bg-white/40 backdrop-blur h-1.5 rounded-t overflow-hidden">
						<!-- Upload bar: progress -->
						<div
class="bg-theme-active h-full rounded-r"
							 :style="`width: ${progress*100}%;transition: width ease .5s`"
/>
					</div>

					<!-- Context menu -->
					<div class="relative flex items-center self-end mr-3 mt-1.5 shrink-0 gap-1 float-right">
						<!-- Abort upload -->
						<span
							class="bg-white hover:bg-red-100 text-gray-400 hover:text-red-700 flex items-center justify-center w-6 h-6 rounded-full cursor-pointer"
							@click="emit('abort', id)"
>
							<DynamicIcon icon="Close" size="20" />
						</span>
					</div>
				</div>

				<!-- Text + optional:icon -->
				<div
class="mx-3 mb-3 text-xs self-stretch justify-self-stretch"
					 :class="{'h-full text-center': !backgroundImage}"
>
					<DynamicIcon
v-if="!backgroundImage"
:icon="icon"
class="mb-1"
/>
					<p class="font-medium text-base truncate" :class="{'text-white': backgroundImage}">
						{{ fileName }}
					</p>
					<p :class="{'text-white': backgroundImage}">
						({{ fs(fileSize) }})
					</p>
				</div>
</div>
		</div>
	</div>
</template>

<script setup>
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import { fileSize as fs } from "@/script/convert.js";

const emit = defineEmits(["abort"]);

defineProps({
	// ID for uniquely identifying this file
	"id": {
		type: String,
		required: true,
	},
	// Full mime-type of the file
	"mime": {
		type: String,
		required: true,
	},
	// Full name, including any extension
	"fileName": {
		type: String,
		required: true,
	},
	// Size in bytes, we cast to unit automatically
	"fileSize": {
		type: Number,
		required: true,
	},
	// File is visible to target or not
	"visible": {
		type: Boolean,
		default: () => false,
	},
	// An image URL to show behind the file. Else uses icon
	"backgroundImage": {
		type: String,
		default: () => null,
	},
	// Overwrite the icon to use. Is not used if background image is set
	"icon": {
		type: String,
		default: () => "Document"
	},
	// Percentage done (0 to 1) as a percentage factor
	"progress": {
		type: Number,
		default: () => 0,
	}
});

</script>
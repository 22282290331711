/**
 * Returns how many more policies this tree can have given a known max limit.
 * Will stop and return -1 if it is over capacity.
 * @returns {number} `-1` if over capacity. `0` if at capacity, else the capacity left given your input
 */
export function policyCapacityLeft(policy, max) {
    max = max - 1; // Current policy.
    if (max < 0)
        return -1; // Early check for deep nests
    for (const child of policy.children) {
        max = policyCapacityLeft(child, max);
        if (max < 0)
            return -1;
    }
    return max;
}

<script>
export default {
	name: "IconBugFocus"
}
</script>

<template>
	<g>
		<path
			d="M18 7L17 9L15.667 9.917"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 12H8.11"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.89 12H19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6 3H5C3.895 3 3 3.895 3 5V6"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18 21H19C20.105 21 21 20.105 21 19V18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M3 18V19C3 20.105 3.895 21 5 21H6"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M21 6V5C21 3.895 20.105 3 19 3H18"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6 7L7 9L8.333 9.917"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.444 17H11.555C9.89799 17 8.55499 15.657 8.55499 14V11C8.55499 9.895 9.44999 9 10.555 9H13.444C14.549 9 15.444 9.895 15.444 11V14C15.444 15.657 14.101 17 12.444 17Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6 17L7 15L8.333 14.083"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M18 17L17 15L15.667 14.083"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M14 9H10V7C10 6.448 10.448 6 11 6H13C13.552 6 14 6.448 14 7V9Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

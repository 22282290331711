import MessagePolicy, { ancestry } from "./MessagePolicy.model.js";
import { EnableDisableInherit } from "../../types/index.js";
import { tierSwitch } from "app-core";
/**
 * A constructor for creating the default set of schemas a guild should have.
 */
export const defaultPolicies = () => {
    return [
        new MessagePolicy()
            .setTargetId("text")
            .setTrackingType("created")
            .setTargetType("channelArchetype")
            .setTrack(EnableDisableInherit.ENABLED)
            .setStoreAuthor(EnableDisableInherit.ENABLED)
            .setTTL(60)
            .setFileTracking(EnableDisableInherit.ENABLED, 24 * 7, (24 * 365) / 2)
            .setAlert({
            channelId: "0",
            enabled: EnableDisableInherit.DISABLED,
        }),
        new MessagePolicy()
            .setTargetId("text")
            .setTrackingType("edited")
            .setTargetType("channelArchetype")
            .setTrack(EnableDisableInherit.ENABLED)
            .setStoreAuthor(EnableDisableInherit.ENABLED)
            .setTTL(60)
            .setFileTracking(EnableDisableInherit.ENABLED, 24 * 7, config.maxFileCreatedTTL("free"))
            .setAlert({
            channelId: "0",
            enabled: EnableDisableInherit.ENABLED,
        }),
        new MessagePolicy()
            .setTargetId("text")
            .setTrackingType("deleted")
            .setTargetType("channelArchetype")
            .setTrack(EnableDisableInherit.ENABLED)
            .setStoreAuthor(EnableDisableInherit.ENABLED)
            .setTTL(60)
            .setFileTracking(EnableDisableInherit.DISABLED, 24 * 7, config.maxFileCreatedTTL("free"))
            .setAlert({
            channelId: "0",
            enabled: EnableDisableInherit.ENABLED,
        })
    ];
};
export const config = {
    /**
     * Return the max number of message polies a guild can have.
     * There will always be a minimum of 3 the user cannot remove (the root ones).
     * The count is including these, so user can make e.g. at least 27 more policies.
     */
    maxPolicies(premiumTier) {
        return tierSwitch(premiumTier, [30, 150, 300]);
    },
    /**
     * Max duration in seconds a current message will stay in the database.
     * TTL is reset any time the user edits the message.
     *
     * After this time it will not guarantee to be stored anymore, and may cease to exist.
     * Use `0` for no limit.
     */
    maxMessageCreatedTTL(premiumTier) {
        // NOT ENFORCED AT THE MOMENT. FINISH IMPLEMENTATION WHEN IT IS.
        return tierSwitch(premiumTier, [0, 0, 0]);
    },
    /**
     * Max duration in seconds an old message state is stored.
     * This applies to the snapshot records in the Discord Message Edits collection.
     *
     * After this time it will not guarantee to be stored anymore, and may cease to exist.
     * Use `0` for no limit.
     */
    maxMessageEditedTTL(premiumTier) {
        // NOT ENFORCED AT THE MOMENT. FINISH IMPLEMENTATION WHEN IT IS.
        return tierSwitch(premiumTier, [0, 0, 0]);
    },
    /**
     * Max duration in seconds a deleted message record is stored.
     * This applies to the record in the Discord Deleted Messages collection.
     *
     * After this time it will not guarantee to be stored anymore, and may cease to exist.
     * Use `0` for no limit.
     */
    maxMessageDeletedTTL(premiumTier) {
        // NOT ENFORCED AT THE MOMENT. FINISH IMPLEMENTATION WHEN IT IS.
        return tierSwitch(premiumTier, [0, 0, 0]);
    },
    /**
     * Max hours the deleted files will be kept for.
     * After that, it is not guaranteed to be stored anymore, and may cease to exist within the next 30 minutes or so.
     */
    maxFileDeletedTTL(premiumTier) {
        // 1 month, half a year, 1 year
        return tierSwitch(premiumTier, [24 * 30, 24 * 183, 24 * 365]);
    },
    /**
     * Max hours to store a file before it is deleted, regardless of it being deleted or not in Discord.
     * If a file is deleted right before this, the timer "resets" to using the MaxFileDeletedTTL from the
     * time of deletion.
     */
    maxFileCreatedTTL(premiumTier) {
        // 3 months, 1 year, 3 years
        return tierSwitch(premiumTier, [24 * 30 * 3, 24 * 365, 24 * 365 * 3]);
    },
};
/**
 * Finds the best matching policy among all the provided policies.
 *
 * Benchmarked and optimized for best speed possible.
 * Order of if statements are based on likelihood of it being the type checked.
 * @param context The context the message evaluation is working with
 * @param policies All root policies to evaluate
 */
export function findBestMessagePolicy(context, policies = []) {
    let bestSoFar = null;
    let highestPrecisionSoFar = -1;
    let policy = null;
    for (let i = 0, len = policies.length; i < len; i++) {
        policy = policies[i];
        if (policy.trackingType !== context.messageEventType)
            continue;
        else if (policy.targetType === "channel" && policy.targetId !== context.channelId)
            continue;
        else if (policy.targetType === "channelType" && policy.targetId !== context.channelType)
            continue;
        else if (policy.targetType === "user" && policy.targetId !== context.authorId)
            continue;
        else if (policy.targetType === "category" && policy.targetId !== context.categoryId)
            continue;
        else if (policy.targetType === "role" && !context.roleIds.includes(policy.targetId))
            continue;
        const precision = ancestry[policy.targetType];
        if (policy.children.length) {
            const bestChild = findBestMessagePolicy(context, policy.children);
            if (bestChild) {
                const childPrecision = ancestry[bestChild.targetType];
                if (childPrecision > highestPrecisionSoFar) {
                    bestSoFar = bestChild;
                    highestPrecisionSoFar = childPrecision;
                }
            }
        }
        if (precision > highestPrecisionSoFar) {
            bestSoFar = policy;
            highestPrecisionSoFar = precision;
        }
    }
    return bestSoFar;
}

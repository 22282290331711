<script setup>
import Nodes from "@/script/Nodes";
import SwitchBox from "@/components/form/SwitchBox.vue";
import ToolTip from "@/components/badges/ToolTip.vue";
import {computed, inject, ref} from "vue";
import {Account} from "@/storage/AccountCache";
import UserCache from "@/storage/UserCache";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";
import RadialCapacityIndicator from "@/components/info/RadialCapacityIndicator.vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
import IdBadge from "@/components/badges/IdBadge.vue";
import LocalDate from "@/components/info/parts/LocalDate.vue";
import BasicButton from "@/components/buttons/BasicButton.vue";
import {useRoute} from "vue-router";
import ServerIcon from "@/components/icon/ServerIcon.vue";
import IndexedDBStore from "@/storage/IndexedDB";
import DynamicIcon from "@/components/icon/DynamicIcon.vue";
import {timeLeftUntil} from "archivian-utils";
import TimeTickerUtil from "@/components/misc/TimeTickerUtil.vue";
import ButtonLike from "@/components/panels/ButtonLike.vue";

const emit = defineEmits(["inspect"]);
const props = defineProps({
	"caseType": { // Both
		type: String,
		required: true,
	},
	"creatorId": { // Both - can be 00000000000000000000
		type: String,
		required: true,
	},
	"guildId": { // Personal view
		type: String,
		default: () => null,
	},
	"targetId": { // Guild view
		type: String,
		required: true,
	},
	"contributorIds": { // Both
		type: Array,
		default: () => [],
	},
	"pills": { // Both
		type: Object,
		default: () => ({
			"showCase": false,
			"denyAppeals": false,
			"archived": false,
		})
	},
	"appeals": { // Personal view
		type: Array,
		default: () => [],
	},
	"appealable": { // Personal view
		type: Boolean,
		default: false,
	},
	"createdAt": { // Both
		type: Date,
		required: true,
	},
	"expireAt": { // Both
		type: [Date, null],
		default: () => null,
	}
});

const users = UserCache();
const account = Account();
const cases = CaseAppealBrowser();
const idbStore = IndexedDBStore();
const handlers = inject("handlers");
const personal = inject("isPersonal");
const route = useRoute();
const expireTick = ref(0);

const sidebarOpen = computed(()=>{
	return !!route.params.resourceId;
});
const additionalContributors = computed(() => {
	return props.contributorIds.filter(id => id !== props.creatorId);
});
const userTryAppealToggle = () => {
	if (!props.pills.showCase) {
		alert("User cannot appeal the case if they cannot see it. Enable \"Show to user\" first");
	}
	if (props.pills.archived) {
		alert("This case is archived, so there is no point in appealing this case—the user can also never see archived cases.");
	}
	if (!account.can(Nodes.CaseAppeals.APPEAL.LOCK.FURTHER_APPEAL(props.caseType))) {
		alert("Insufficient permissions. You are not allowed to change this setting.");
	}
};
const highlight = computed(()=>{
	const contributors = `+ ${props.contributorIds.length - 1} more staff${(props.contributorIds.length - 1)===1?'':'s'}`;

	if (props.pills.system && props.creatorId === process.env.VUE_APP_BOT_ID) return {
		avatarUrl: "logo",
		username: "Archivian",
		title: "Case Creator • System",
		usersLabel: contributors,
	};

	return {
		avatarUrl: users.avatarOf(props.creatorId, 96),
		username: users.username(props.creatorId) || "Unknown Staff",
		title: ['Case Creator', users.titleOf(props.creatorId)].filter(Boolean).join(' • '),
		usersLabel: contributors,
	};
});
const expireData = computed(()=>{
	if (!props.expireAt) return {
		_ticker: expireTick.value, // Force re-computation once ticker changes

		pCompleted: 0,
		nLeft: "0 sec",
		totalLength: "0 sec",
		isExpired: true,
	};

	// How close we are to the expireAt date compared to the createdAt
	const now = new Date();
	const expire = new Date(props.expireAt);
	const created = new Date(props.createdAt);
	const total = expire - created;
	const left = expire - now;
	const percentage = (left / total) * 100;
	const isExpired = now > expire;

	return {
		_ticker: expireTick.value, // Force re-computation once ticker changes

		pCompleted: Math.max(0, Math.min(100, percentage)),
		nLeft: isExpired ? "0 sec" : timeLeftUntil(expire, now, {highestValueOnly: true}).string,
		totalLength: timeLeftUntil(expire, created, {highestValueOnly: true}).string,
		isExpired,
	}
});
const hasDuration = computed(() => {
	if (!["timeout","ban"].includes(props.caseType)) return false;
	return !!props.expireAt;
});
</script>

<template>
	<div
		:class="{
			'grid-normal': !sidebarOpen,
			'grid-halved': sidebarOpen,
			'noExtras': !hasDuration,
		}"
	>
		<!-- Case target -->
		<div class="target-panel user-panel switch-container border-details">
			<div class="h-full w-full flex gap-2 items-center">
				<!-- View: Guild - Show target user -->
				<template v-if="!personal">
					<UserAvatar
						:user-id="targetId"
						:avatar="users.avatarHashOf(targetId)"
						:size="64"
					/>
					<div>
						<h3>
							<ButtonLike
								v-if="!personal"
								desc="Inspect this user"
								class="w-fit"
								:class="{'hover:text-sky-400': !personal}"
								:disabled="personal"
								@click="emit('inspect')"
							>
								{{ users.username(targetId) }}
							</ButtonLike>
							<template v-else>
								{{ users.username(targetId) }}
							</template>
						</h3>
						<p class="text-slate-500">
							Case target
						</p>
						<IdBadge :label="targetId" class="w-fit" />
					</div>
				</template>
				<!-- View: Personal - Show guild -->
				<template v-else>
					<ServerIcon
						:icon="idbStore.getGuild(guildId).icon"
						:server-id="guildId"
						:name="idbStore.getGuild(guildId)?.name || 'Unknown/Deleted Server'"
						:size="64"
					/>
					<div>
						<h3>
							{{ idbStore.getGuild(guildId)?.name || 'Unknown/Deleted Server' }}
						</h3>
						<p class="text-slate-500">
							Discord server
						</p>
						<IdBadge :label="guildId" class="w-fit" />
					</div>
				</template>
			</div>
		</div>

		<!-- Case creator (staff) -->
		<div class="creator-panel user-panel switch-container border-details">
			<div class="h-full w-full flex gap-2 items-center">
				<UserAvatar
					:user-id="creatorId"
					:avatar="users.avatarHashOf(creatorId)"
					:size="64"
				/>
				<!-- View: Guild -->
				<template v-if="!personal">
					<div>
						<h3>
							{{ users.username(creatorId) }}
						</h3>
						<p class="text-slate-500 text-sm">
							{{ highlight.title }}
						</p>
						<IdBadge :label="creatorId" class="w-fit" />
					</div>
				</template>
				<!-- View: Personal -->
				<template v-else>
					<div>
						<h3>
							{{
								creatorId === "00000000000000000000"
									? "Undisclosed User"
									: users.username(creatorId)
							}}
						</h3>
						<p class="text-slate-500 text-sm">
							Case Creator
						</p>
						<IdBadge :label="creatorId" class="w-fit" />
					</div>
				</template>
			</div>
		</div>

		<!-- Contributors -->
		<div
			class="contributors-panel contributors switch-container border-details overflow-y-auto"
			:class="{'justify-center': !additionalContributors.length}"
		>
			<p
				class="text-slate-500 cursor-help"
				title="Other staff members that have made modifications to the case"
			>
				Contributors
			</p>
			<div
				v-for="userId in additionalContributors"
				:key="userId"
				class="flex gap-2 items-center"
			>
				<UserAvatar
					:user-id="userId"
					:avatar="users.avatarHashOf(userId)"
				/>
				{{ users.username(userId) }}
			</div>
			<span
				v-if="!additionalContributors.length"
				class="italic text-neutral-500 text-xs text-center h-full flex items-center"
			>
				{{ personal ? 'No other contributors / Undisclosed users' : 'No other contributors.' }}
			</span>
		</div>

		<!-- Visibility -->
		<div class="visibility-panel switch switch-container border-details justify-center">
			<!-- View: Guild - Appeal controls -->
			<template v-if="!personal">
				<SwitchBox
					id="visibility"
					label="Show to user"
					:saving="cases.isLoading('showToUser', cases.currentId)"
					:disabled="!!pills.archived
						|| !account.can(Nodes.Cases.CASE.VISIBILITY(caseType,creatorId===account.id))"
					:enabled="pills.showCase"
					class="max-w-min"
					@toggle="handlers.updateVisibility"
				/>
				<div class="flex items-center gap-2">
					<p class="inline">
						Let <span class="font-bold">
						{{ users.username(targetId) ?? "the user" }}
						</span> see that this case exist on them.
					</p>
					<ToolTip
						class="inline"
						description="Must be enabled to allow appealing to this case. User can see creation date, case type, and case ID&mdash;and more if enabled in global case settings."
					/>
				</div>
			</template>
			<!-- View: Personal - Visibility disclosure -->
			<template v-else>
				<p>
					<DynamicIcon icon="Info" />
					Certain details of this case may be hidden from your view.
				</p>
			</template>
		</div>

		<!-- Appeals -->
		<div class="appeals-panel switch switch-container border-details justify-center">
			<!-- View: Guild - Appealable controls -->
			<template v-if="!personal">
				<template v-if="caseType!=='note'">
					<SwitchBox
						id="appeals"
						label="Allow appeals"
						:saving="cases.isLoading('showToUser', cases.currentId)"
						:disabled="!!pills.archived
							|| !pills.showCase
							|| !account.can(Nodes.CaseAppeals.APPEAL.LOCK.FURTHER_APPEAL(caseType))"
						:enabled="!pills.denyAppeals && pills.showCase"
						class="max-w-min"
						@toggle="handlers.updateAppealable"
						@click="userTryAppealToggle"
					/>
					<div class="flex items-center gap-2" :class="{'opacity-70': !pills.showCase}">
						<p>
							Let <span class="font-bold">{{ users.username(targetId) ?? "the user" }}</span> be able to
							appeal this case.
						</p>
					</div>
				</template>
				<template v-else>
					<p class="italic text-neutral-500">
						Appeals are not available for Note cases.
					</p>
				</template>
			</template>

			<!-- View: Personal - Appealable status / details -->
			<template v-else>
				<p v-if="!appealable" class="italic text-neutral-400">
					<DynamicIcon icon="Close" />
					This case is not appealable.
				</p>
				<div v-else-if="!appeals.length">
					<DynamicIcon icon="Check" />
					You can appeal this case.
				</div>
				<div v-else>
					<DynamicIcon icon="Check" />
					You have appealed this case.
				</div>
			</template>
		</div>

		<!-- Controls panel -->
		<div class="controls-panel controlPanel switch-container border-details justify-center">
			<div
				v-if="expireAt"
				class="flex gap-4 items-center"
			>
				<TimeTickerUtil
					direction="towards"
					:date="expireAt"
					constant
					@tick="expireTick = $event"
				>
					<RadialCapacityIndicator
						:key="expireTick"
						:model-value="expireData.pCompleted"
						size="128"
						:stroke="16"
						title="How long left of the timeout"
					>
						<div class="flex flex-col items-center justify-center h-full">
							<p class="text-sm font-bold text-neutral-400">
								{{ expireData.nLeft }}
							</p>
							<p class="text-xs text-neutral-400">
								Time left
							</p>
						</div>
					</RadialCapacityIndicator>
				</TimeTickerUtil>
				<div>
					<p class="text-gray-400 text-sm">
						Timeout Duration
					</p>
					<p class="font-bold uppercase tracking-wide text-white">
						{{ expireData.totalLength }}
					</p>
					<p class="text-gray-400 text-xs">
						{{ expireData.isExpired ? 'Ended' : 'Ends' }}
						<LocalDate :date="expireAt" time />
					</p>
				</div>
			</div>
			<div class="flex gap-2 items-center">
				<!-- View: Guild - Quick duration controls - DISABLED UNTIL IMPLEMENTED -->
				<template v-if="!personal && false">
					<BasicButton
						label="End now"
						size="medium"
						type="danger"
						class="w-full"
					/>
					<BasicButton
						label="Extend"
						size="medium"
						type="basic"
						class="w-full"
					/>
					<BasicButton
						label="Reduce"
						size="medium"
						type="basic"
						class="w-full"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped>
.switch-container {
	@apply p-4 bg-neutral-800/50 shadow rounded-xl flex flex-col gap-2 grow basis-1/2;
}
/*////////////////////////////*/
.grid-halved,
.grid-normal {
	@apply flex flex-col gap-2;
}
.noExtras .controls-panel {
	display: none;
}
@media (min-width: 768px) {
	.grid-normal {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			"target target target"
			"creator creator creator"
			"contributors visibility visibility"
			"contributors appeals appeals"
			"controls controls controls";
	}
}
@media (min-width: 1024px) {
	.grid-normal {
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			"target creator contributors"
			"visibility appeals contributors"
			"controls controls controls";
	}
}
@media (min-width: 1280px) {
	.grid-normal {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			"target contributors"
			"creator contributors"
			"visibility controls"
			"appeals controls";
	}
	.grid-halved {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			"target target target"
			"creator creator creator"
			"contributors visibility visibility"
			"contributors appeals appeals"
			"controls controls controls";
	}
	.grid-normal.noExtras {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			"target contributors"
			"creator contributors"
			"visibility appeals";
	}
}
@media (min-width: 1536px) {
	.grid-normal {
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			"target target contributors"
			"creator creator contributors"
			"visibility controls controls"
			"appeals controls controls";
	}
	.grid-halved {
		display: flex;
	}
}
@media (min-width: 1792px) {
	.grid-normal {
		grid-template-columns: repeat(6, 1fr);
		grid-template-areas:
			"target target contributors visibility controls controls"
			"creator creator contributors appeals controls controls";
	}
	.grid-halved {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			"target target contributors"
			"creator creator contributors"
			"visibility controls controls"
			"appeals controls controls";
	}
	.grid-normal.noExtras {
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas:
			"target target contributors visibility"
			"creator creator contributors appeals";
	}
	.grid-halved.noExtras {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas:
			"target contributors"
			"creator contributors"
			"visibility appeals";
	}
}

/*////////////////////////////*/
.creator-panel {
	grid-area: creator;
}
.target-panel {
	grid-area: target;
}
.visibility-panel {
	grid-area: visibility;
}
.appeals-panel {
	grid-area: appeals;
}
.contributors-panel {
	grid-area: contributors;
}
.controls-panel {
	grid-area: controls;
}
</style>
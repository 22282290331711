<script setup>
import {nextTick, onMounted, ref, watch} from "vue";
import InspectCaseLayout from "@/partials/layouts/InspectCaseLayout.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import TextBoxArea from "@/views/guild/case-system/appeals/chat/TextBoxArea.vue";
import ChatMessage from "@/views/guild/case-system/appeals/chat/ChatMessage.vue";
import {Account} from "@/storage/AccountCache";
import FancyLoader from "@/components/misc/FancyLoader.vue";
import {UI} from "@/storage/UICache";

const emit = defineEmits(["close"]);
const props = defineProps({
	// (IAppealMessage | IAppealMessageEvent)[]
	"messages": {
		type: Array,
		required: true,
		default: () => [],
	},
	"handlers": {
		type: Object,
		required: true,
	},
	"appeal": {
		type: Object,
		required: true,
	},
});
//const queuedMessages = ref([]);
const account = Account();
const ui = UI();
const chatHistory = ref(null); // DOM Element ref
const textContent = ref("");
const loading = ref(true);
function handleSendMessage(textContent) {
	//queuedMessages.value.push({
	//	id: Math.random().toString(36).slice(2),
	//	textContent,
	//});
	props.handlers.sendMessage(textContent);
}
watch(() => props.messages, () => {
	ui.markChecked("appealChat", props.appeal._id);
	nextTick(()=>{
		if (!chatHistory.value) return;
		chatHistory.value.scrollTop = chatHistory.value.scrollHeight;
	});
}, {deep: true});

onMounted(async () => {
	loading.value = true;

	/**
	 * Assume `messages.length` means we have already loaded messages.
	 * If empty, we either check again any time they open the side panel, which would be fast with no records anyway.
	 */
	if (!props.messages?.length) {
		await props.handlers.fetchMessages(props.appeal.guildId, props.appeal._id);
	}

	loading.value = false;

	return nextTick(() => {
		if (!props.messages?.length) return;
		chatHistory.value.scrollTop = chatHistory.value.scrollHeight;
		ui.markChecked("appealChat", props.appeal._id);
	});
});
</script>

<template>
	<InspectCaseLayout
		class="flex flex-col"
		main-classes="h-full"
		:padded="false"
	>
		<template #head>
			<div class="p-6 flex items-center w-full justify-between">
				<h2>Appeal Chat</h2>

				<div class="AppealActionButtons">
					<CloseButton
						label="Close chat panel"
						@click="emit('close')"
					/>
				</div>
			</div>
		</template>

		<template #main>
			<div v-if="loading" class="flex flex-col justify-center items-center grow">
				<FancyLoader
					label="Loading messages&hellip;"
				/>
			</div>
			<div
				v-else-if="messages?.length"
				id="chatHistory"
				ref="chatHistory"
				class="flex flex-col grow h-full overflow-y-auto"
			>
				<ChatMessage
					v-for="message in messages"
					:id="message.id"
					:key="message.id"
					:message="message"
					:appealing-user-id="appeal.userId"
					:account-id="account.id"
					class="p-2"
				/>
			</div>
			<div v-else class="flex flex-col justify-center items-center grow">
				<p class="italic text-neutral-500 text-center">
					There are no messages yet.
					<br>
					This chat can be used to communicate during the appeal process.
				</p>
			</div>
		</template>

		<template #footer>
			<div v-if="!appeal.verdict?.date" class="p-2">
				<TextBoxArea
					v-model="textContent"
					@send-message="handleSendMessage"
				/>
				<p class="text-xs text-neutral-500">
					Enter to send. Shift+Enter for new-line.
				</p>
			</div>
			<div v-else class="p-4">
				<p class="italic text-center text-neutral-600">
					Verdict has been reached. Chat is closed.
				</p>
			</div>
		</template>
	</InspectCaseLayout>
</template>

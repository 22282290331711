<template>
	<span class="CounterBadge py-0.5 px-3 bg-red-600 text-white rounded-full h-min leading-none min-w-min">
		{{ props.count }}
	</span>
</template>

<script setup>
const props = defineProps({
	"count": {
		type: [Number, String],
		required: true,
		default: () => 0
	}
});
</script>
import { createApp } from "vue";
import { createPinia } from "pinia";
import VueClickAway from "vue3-click-away";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./views/routes";
import App from "@/App";
import "./main.css";
import * as Sentry from "@sentry/vue";
import { validateSession } from "@/api/auth";
import { Account } from "@/storage/AccountCache";
import IndexedDB from "@/db/Database";
import { UI } from "@/storage/UICache";
import backend from "@/api/backend";
import IndexedDBStore from "@/storage/IndexedDB";
import IndexedDBModels from "@/db/models";

let idbStore = null;
let account = null;
let ui = null;
let webSocket = null;

const skipTick = () => new Promise((resolve) => setImmediate(resolve));

async function init() {
	console.time("[Main] Pre-Main Initializing");

	// Set up the IndexedDB Service
	const models = IndexedDBModels();
	for (const model of models) {
		IndexedDB.addModel(model);
	}
	await IndexedDB.connect();

	return {models};
}

async function main(passOn) {
	console.time("[Main] Main Initialization");
	const router = createRouter({
		history: createWebHistory(),
		routes,
		scrollBehavior(to) {
			if (to.hash) {
				/**
				 * Because Vue-Router does not actually navigate, and the DOM might be generated AFTER page load,
				 * we might not be able to use the CSS :target in combination with anchor to highlight stuff.
				 * So instead, we defer a bit and check if the targeted element can be found, then add a
				 * dataset attribute which will manually trigger the CSS highlighting.
				 * Will work in some cases, but not 100%, especially if hard-refresh/load.
				 */
				return new Promise(r => {
					setImmediate(()=>{
						const elm = document.getElementById(to.hash.slice(1)); // #asd -> asd
						if (elm) elm.dataset.anchor = "true";

						return r({
							el: to.hash,
							behavior: "smooth",
						});
					});
				});
			}

			// always scroll to top
			return { top: 0 };
		},
	});
	if (!webSocket) {
		const { socket } = await import("./ws/WebSocket");
		webSocket = socket;
		await webSocket.connect();
	}

	router.beforeEach(async (to, from) => {
		// Vue router executes even before pinia and everything else.
		while (!IndexedDB.ready) {
			await skipTick();
		}

		console.time("[Route Hook] Route.beforeEach");
		if (!idbStore) idbStore = IndexedDBStore();
		if (!account) account = Account();

		if (to.meta.isGuild) {
			if (to.params.guildId !== from.params?.guildId) {
				idbStore.wipe();
				await idbStore.init(to.params.guildId);
			}
		} else if (to.meta.isPersonal) {
			if (idbStore.guildId !== "") {
				idbStore.wipe();
				await idbStore.init(null);
			}
		}

		if (to.meta.isAdmin) {
			await idbStore.wipe();
			//await idbStore.init("");
			//if (!account.can("**")) {
			//	return { name: "me" };
			//}
		}

		if (to.meta.authSession && account.itsTimeToValidateSession) {
			/**
			 * Before going to each secured route, we will check if the session is valid.
			 * If not, we will redirect them to log in, which redirects back to the requested page.
			 * However, this does not ensure user is staff in the guild, has permissions, etc.,
			 * for that we'll just rely on backend outright 403-ing the HTTP requests.
			 */
			const valid = await validateSession();
			if (!valid) return { name: "login", query: { redirect: to.fullPath } };

			if (!account.isPopulated) {
				await account.fetchAccountData();
			}

			// Update last validation date
			account.sessionLastValidatedAt = new Date();
			account.save();
		}

		if (to.meta.ensureStaff) {
			// Denied if guild ID is passed in authed route, but user is not staff in that guild
			if (to.params.guildId && !account.isStaffIn(to.params.guildId)) {
				/**
				 * Since this is an exception to normal navigation,
				 * we will fetch the data to see if maybe they now are staff.
				 */
				await account.fetchAccountData();

				// Now we check again...
				if (!account.isStaffIn(to.params.guildId)) {
					// If they still aren't staff, deny access
					return { name: "me" };
				}
				// ... else allow
			}
		}
		console.timeEnd("[Route Hook] Route.beforeEach");
	});

	router.afterEach(async (to, from) => {
		if (!idbStore) idbStore = IndexedDBStore();
		if (!ui) ui = UI();
		if (!account) account = Account();

		console.time("[Route Hook] Route.afterEach");
		console.debug("[Route Hook] Route After Each:", from.params);
		ui.maintainSkipMenuAnchors();
		webSocket.updateContext();

		/**
		 * Handles changing dataset if you navigate to a guild that needs you to be authorized
		 * Skips this step if you're not navigating to a different guild
		 */
		if (to.params.guildId && to.meta.ensureStaff && to.params.guildId !== from.params.guildId) {
			await account.getGuildStash(to.params.guildId);
			await idbStore.init(to.params.guildId || "")

			if (!idbStore.permissionNodes?.length) {
				const r = await backend(`/public/guilds/${to.params.guildId}/staff/me/permissions`);
				if (r.status === 200) {
					idbStore.permissionNodes = await idbStore.setPermissions(to.params.guildId || "", r.body);
				}
			}
		}

		console.timeEnd("[Route Hook] Route.afterEach");
	});

	const theApp = createApp(App)
		.use(createPinia())
		.use(router)
		.use(VueClickAway);

	if (process.env.NODE_ENV !== "development") {
		setupSentry(theApp, router);
	}

	passOn.theApp = theApp;
	return passOn;
}

init()
	.then(passOn => main(passOn))
	.then(async (passOn) => {
	console.time("[Main] Post-Main Initialization");
	const idbStore = await setupIndexedDB(passOn.models);
	if (idbStore.guildId) {
		await idbStore.fetchAll(idbStore.guildId);
	}

	const account = Account();
	await Promise.all([
		/**
		 * The base account data (userId & staffed guild IDs) is stored in Session Storage.
		 * But if the user hard-reloads (i.e. not on navigation!), we want to re-fetch the data.
		 * However, the data is already populated in SessionStorage, so it would not be re-fetched.
		 *
		 * We put this here in main instead, which is executed once when you land on the page.
		 */
		account.fetchAccountData(true),
	]);

	console.timeEnd("[Main] Loaded initial data");

	return passOn.theApp.mount("#app");
}).catch(console.error);

function setupSentry(theApp, router) {
	Sentry.init({
		environment: process.env.NODE_ENV,
		app: theApp,
		dsn: "https://a7d416e8b3384274ba2f8bba0503ec1f@o1284087.ingest.sentry.io/4505035339464705",
		integrations: [
			new Sentry.Replay({
				// Additional SDK configuration goes in here, for example:
				maskAllText: false,
				blockAllMedia: false,
			}),
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracePropagationTargets: ["127.0.0.1", "localhost", "*.archivian.net", /^\//],
			}),
		],
		/**
		 * Set tracesSampleRate to 1.0 to capture 100%
		 * of transactions for performance monitoring.
		 * We recommend adjusting this value in production
		 */
		tracesSampleRate: 1.0,
		// Screen recording
		/**
		 * This sets the sample rate to be 10%. You may want this to be 100% while
		 * in development and sample at a lower rate in production
		 */
		replaysSessionSampleRate: 0.1,
		/**
		 * If the entire session is not sampled, use the below sample rate to sample
		 * sessions when an error occurs.
		 */
		replaysOnErrorSampleRate: 1.0,
	});
}

async function setupIndexedDB(models) {
	// Set up the store
	const idbStore = IndexedDBStore();
	window.idbStore = idbStore; // For debugging
	for (const model of models) {
		idbStore.populateModel(model);
	}

	// Initialize the IndexedDB Store (pinia)
	await idbStore.init(null);
	return idbStore;
}

/**
 * @typedef {Object} DisplayUser
 * @property {UserID} id
 * @property {string} username
 * @property {null|string} global_name
 * @property {null|string} avatar
 * @property {null|number} public_flags
 */
<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19 8H15C14.448 8 14 7.552 14 7V3"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.2104 15.9816C10.7618 16.533 10.7618 17.427 10.2104 17.9785C9.65898 18.5299 8.76495 18.5299 8.21353 17.9785C7.66211 17.427 7.66211 16.533 8.21353 15.9816C8.76495 15.4302 9.65898 15.4302 10.2104 15.9816"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.3864 15.0406C15.9378 15.592 15.9378 16.4861 15.3864 17.0375C14.835 17.5889 13.9409 17.5889 13.3895 17.0375C12.8381 16.4861 12.8381 15.592 13.3895 15.0406C13.9409 14.4892 14.835 14.4892 15.3864 15.0406"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.624 16.98V13.26C10.624 12.875 10.858 12.529 11.216 12.386L14.429 11.101C15.086 10.838 15.8 11.322 15.8 12.029V16.039"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconFileAudio"
};
</script>
<template>
	<g>
		<path
			d="M20.7923 9.52416C21.5824 10.3142 21.5824 11.5951 20.7923 12.3851C20.0023 13.1751 18.7214 13.1751 17.9314 12.3851C17.1414 11.5951 17.1414 10.3142 17.9314 9.52416C18.7214 8.73413 20.0023 8.73413 20.7923 9.52416"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M14.2024 5.91236C15.4189 7.12884 15.4189 9.10115 14.2024 10.3176C12.9859 11.5341 11.0136 11.5341 9.79713 10.3176C8.58065 9.10116 8.58065 7.12885 9.79713 5.91236C11.0136 4.69588 12.9859 4.69588 14.2024 5.91236"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M6.06871 9.52416C6.85874 10.3142 6.85874 11.5951 6.06871 12.3851C5.27868 13.1751 3.99779 13.1751 3.20776 12.3851C2.41773 11.5951 2.41773 10.3142 3.20776 9.52416C3.99779 8.73413 5.27868 8.73413 6.06871 9.52416"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M23.0002 19.0003V17.9043C23.0002 16.5233 21.8812 15.4043 20.5002 15.4043H19.6992"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M1 19.0003V17.9043C1 16.5233 2.119 15.4043 3.5 15.4043H4.301"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.3392 18.9994V17.3984C17.3392 15.4654 15.7722 13.8984 13.8392 13.8984H10.1602C8.22716 13.8984 6.66016 15.4654 6.66016 17.3984V18.9994"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconGroup"
};
</script>
const apiDomain = process.env.API_DOMAIN || "localhost";
const apiBase = process.env.API_BASE_URL || `http://localhost:${process.env.HTTP_PORT}`;
const dashboardDomain = process.env.DASHBOARD_DOMAIN || "localhost";
const dashboardBase = process.env.DASHBOARD_BASE_URL || `http://localhost:8081`;
export const api = {
    /**
     * Full URL, with protocol and domain/port, without a trailing slash
     * E.g. `https://api.archivian.net` or `http://localhost:3000`
     */
    base: apiBase,
    /**
     * The main domain for Archivian.
     * E.g. `archivian.net` or `localhost`
     */
    domain: apiDomain,
    /**
     * URI Path that should be used for login
     */
    loginPath: "/public/accounts/login",
    /**
     * URI Path that should be used for login callback
     */
    loginCallbackPath: "/public/accounts/login/callback",
    /**
     * URI for serving the raw, hosted files in a case
     */
    toCaseFile: (guildId, sid, fileId) => {
        return `/public/guilds/${guildId}/cases/${sid}/files/${fileId}/file`;
    }
};
export const dashboard = {
    /**
     * The main domain for Archivian
     * E.g. `archivian.net` or `localhost`
     */
    domain: dashboardDomain,
    /**
     * Full URL, with protocol and domain/port, without a trailing slash
     * E.g. `https://archivian.net` or `http://localhost:8080`
     */
    base: dashboardBase,
    /**
     * Personal:
     * Full URL to the user's personal dashboard
     */
    PersonalDashboard: () => `${dashboardBase}/me`,
    /**
     * Guild:
     * Full URL to inspect a case for the guild staff
     */
    GuildInspectCase: (guildId, caseSid) => `${dashboardBase}/guilds/${guildId}/case-system/cases/${caseSid}`,
    /**
     * Personal:
     * Full URL to inspect a case made on oneself
     */
    PersonalInspectCase: (caseId) => `${dashboardBase}/me/case-system/cases/${caseId}`,
    /**
     * Guild:
     * Full URL to browsing an appeal you created
     */
    PersonalInspectAppeal: (appealId) => `${dashboardBase}/me/case-system/appeals/${appealId}`,
    /**
     * Guild:
     * Full URL to browsing an appeal someone created
     */
    GuildInspectAppeal: (guildId, appealId) => `${dashboardBase}/guilds/${guildId}/case-system/appeals/${appealId}`,
};

import {nextTick} from "vue";

/**
 * Check how far off the screen (edge) the provided element is.
 * Returns a delta object with the top and left values indicating how many PX off the left/top it is.
 * @param {HTMLElement} elm
 * @param {MovePositionOptions} options
 * @return {{top: number, left: number}}
 */
function isOffScreenBy(elm, options={}) {
	const rect = elm.getBoundingClientRect();
	const scrollbarSize = 16; // 16px extra in case of scrollbar on X or Y axis
	const overBoundsRight = rect.right - (window.innerWidth - scrollbarSize);
	const overBoundsBottom = rect.bottom - (window.innerHeight - scrollbarSize);

	const delta = {
		left: 0,
		top: 0,
	}

	if (overBoundsRight > 0) {
		delta.left = parseInt(overBoundsRight + options.edgeMargin);
	}

	if (overBoundsBottom > 0) {
		delta.top = parseInt(overBoundsBottom + options.edgeMargin);
	}

	return delta;
}

/**
 * Applies off-screen/edge corrections to the provided element
 * @param {HTMLElement} targetElement
 * @param {{top: number, left: number}} delta
 */
function applyOffScreenCorrections(targetElement, delta) {
	if (targetElement && delta.left) targetElement.style.left = `${targetElement.offsetLeft - delta.left}px`;
	if (targetElement && delta.top) targetElement.style.top = `${targetElement.offsetTop - delta.top}px`;
}

/**
 * Make corrections to the input rects based on the dialog element it is in
 * @param {DOMRect} rects
 * @param {HTMLElement | null} dialogElm
 * @return {DOMRect}
 */
function subtractDialogBoundingRects(rects, dialogElm) {
	if (!dialogElm) return rects;

	const dialogRect = dialogElm.getBoundingClientRect();
	return Object.freeze({
		top: rects.top - dialogRect.top,
		bottom: rects.bottom - dialogRect.top,

		x: rects.x - dialogRect.x,
		y: rects.y - dialogRect.y,

		right: rects.right - dialogRect.left,
		left: rects.left - dialogRect.left,
	})
}

/**
 * Places the input element relative to the provided relative element, with additional corrections for placement
 * @param {HTMLElement} targetElement
 * @param {HTMLElement} relativeElement
 * @param {MovePositionOptions} options
 */
function moveElement(targetElement, relativeElement, options={}) {
	const dropdown = targetElement.getBoundingClientRect();
	const parent = subtractDialogBoundingRects(
		relativeElement.getBoundingClientRect(),
		options.inDialog,
	);

	let xPos = 0;
	let yPos = 0;
	if (options.vertical === "bottom") yPos = parent.bottom;
	else if (options.vertical === "overlay") yPos = parent.bottom - dropdown.height
	else if (options.vertical === "top") yPos = parent.top - dropdown.height;

	if (options.side === "right") xPos = parent.right - dropdown.width;
	else if (options.side === "left") xPos = parent.x;
	else if (options.side === "center") xPos = parent.x + parent.width / 2 - dropdown.width / 2;

	targetElement.style.top = yPos + "px";
	targetElement.style.left = xPos + "px";
}

/**
 * Checks whether the target element is inside a dialog.
 * If that's the case, the getBoundingClientRects is off
 * @param {HTMLElement} elm
 * @returns {null | HTMLElement} `null` or the found Dialog element
 */
function isInsideDialog(elm) {
	let parent = elm.parentElement;
	while (parent) {
		if (parent.tagName === "DIALOG") return parent;
		parent = parent.parentElement;
	}
	return null;
}

/**
 * @typedef {Object} MovePositionOptions
 * @property {"left" | "right" | "center"} side
 * @property {"top" | "overlay" | "bottom"} vertical
 * @property {number} edgeMargin
 * @property {?HTMLElement} [inDialog] If the target element is inside a given Dialog element.
 * Will automatically detect this for you.
 */
/**
 * Moves the target element relative to the relative element, with additional off-screen corrections
 * @param {string | HTMLElement} targetElement Either the HTML element to move or a valid `querySelector` string
 * @param {string | HTMLElement} relativeElement Either the HTML element to be relative to or a valid `querySelector` string
 * @param {MovePositionOptions} options
 * @return {Promise<Awaited<void>>}
 */
export default function(targetElement, relativeElement, options={}) {
	options = Object.assign({
		side: "left",
		vertical: "bottom",
		edgeMargin: 16,
	}, options);

	const target = typeof targetElement === "string"
		? document.querySelector(targetElement)
		: targetElement;
	const relative = typeof relativeElement === "string"
		? document.querySelector(relativeElement)
		: relativeElement;

	if (!target || !relative) {
		console.log("No elements found.", target, relative);
		return Promise.resolve();
	}

	options.inDialog = isInsideDialog(target);

	moveElement(target, relative, options);
	return nextTick(()=>{
		const delta = isOffScreenBy(target, options);
		applyOffScreenCorrections(target, delta);
	});
}
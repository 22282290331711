<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 14.7766V13.3599C11.9999 13.0085 12.2203 12.6949 12.5508 12.5758L13.8217 12.1183C14.2596 11.9606 14.7388 11.9606 15.1767 12.1183L16.4492 12.5766C16.7795 12.6956 16.9997 13.0088 17 13.3599V14.7766C17 15.8374 15.5158 16.6224 14.8333 16.9282C14.6214 17.0239 14.3786 17.0239 14.1667 16.9282C13.4842 16.6224 12 15.8357 12 14.7766Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 8.5H17"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 12.5H8.82353"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M7 16.5H8.82353"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<rect
			x="3.06836"
			y="3.21875"
			width="18"
			height="18"
			rx="5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconReport"
};
</script>
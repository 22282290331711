<template>
	<g>
		<rect
			x="2.99634"
			y="2.99625"
			width="18.0075"
			height="18.0075"
			rx="4"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.2057 10.112L11.2016 14.1137L8.79443 11.7137"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconBoxChecked"
};
</script>

export const APIEndpoint = () => process.env.VUE_APP_API_ENDPOINT;
import RequestMerger from "@/api/RequestMerger";
const requestMerger = new RequestMerger(performHTTPRequest);

/**
 * Make a call to the API
 * @param {string} path Path in endpoint to use
 * @param {("GET"|"POST"|"PUT"|"PATCH"|"DELETE")} [method="GET"]
 * @param {{body?: Object, query?: Object, loginOn401?: boolean, headers?: Record<string, string|number|boolean>}} [data={loginOn401:true}] The data to send. Set
 *     'data.loginOn401' to {@link false} in order to not automatically trigger re-login prompt
 * @returns {Promise<BackendResult>}
 */
export default async function (path, method = "GET", data) {
	const requestId = requestMerger.makeIdentifier(path, method, data);
	return requestMerger.queue(requestId, path, method, data);
}

/**
 * The actual function that makes the HTTP request
 * @param {string} path
 * @param {("GET"|"POST"|"PUT"|"PATCH"|"DELETE")} [method="GET"]
 * @param {{body?: Object, query?: Object, loginOn401?: boolean, headers?: Record<string, string|number|boolean>}} [data={loginOn401:true}] The data to send. Set
 * @param {string} requestId The request identifier, order to perform cleanup in request manager
 * @returns {Promise<BackendResult>}
 */
async function performHTTPRequest(path, method = "GET", data, requestId) {
	const options = {
		method,
		credentials: "include",
		headers: {
			"Content-Type": ["DELETE", "GET"].includes(method) || !data?.body ? "text/plain" : "application/json",
			...data?.headers,
		},
		timeout: 10_000,
	};

	let dest = APIEndpoint() + path;

	if (data?.query) {
		const queryArray = [];

		for (const key in data.query) {
			if (!Array.isArray(data.query[key])) continue;

			queryArray.push({ key, value: data.query[key] });
			data.query[key] = undefined;
			delete data.query[key];
		}

		let query = new URLSearchParams(data?.query).toString();

		queryArray.forEach(item => {
			item.value.forEach(arrayItem => {
				query += `&${item.key}=${arrayItem}`;
			});
		});

		if (query.startsWith("&")) query = query.slice(1);
		dest += ("?" + query);
	}

	if (data?.body) {
		options.body = JSON.stringify(data.body);
	}

	let response;
	try {
		response = await fetch(dest, options);
	} catch (err) {
		console.error(err, `For destination: ${dest} with payload`, options);
		requestMerger.cleanup(requestId);
		return {
			status: 500,
			message: "API timed out",
			body: {
				message: "API timed out",
			}
		};
	}

	// 204 will have no content to consume
	if (response.status === 204) {
		requestMerger.cleanup(requestId);
		return {
			status: 204,
			body: null,
		};
	}

	const type = response.headers.get("content-type");
	if (type && type.startsWith("application/json")) {
		try {
			requestMerger.cleanup(requestId);
			return {
				body: await response.json(),
				status: response.status
			};
		} catch (_) {
			requestMerger.cleanup(requestId);
			console.error(_);
			return {
				status: 500,
				body: {
					message: "API returned invalid JSON",
				}
			};
		}
	}

	requestMerger.cleanup(requestId);
	return {
		status: response.status,
		body: await response.blob()
	};
}
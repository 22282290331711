<script setup>
import MovingShapes from "@/components/misc/MovingShapes.vue";
import UserAvatar from "@/components/icon/UserAvatar.vue";
const tech = { name: 'about', params: { category: 'technology' } };
</script>

<template>
	<article class="container mx-auto max-w-7xl flex flex-col gap-8">
		<div class="overflow-hidden max-w-7xl py-4">
			<div class="mx-auto max-w-7xl rounded-3xl overflow-hidden flex flex-col py-64 border-details">
				<MovingShapes
					class="max-w-7xl absolute top-0 overflow-hidden"
					:count="20"
					:blur="100"
				/>

				<div class="relative z-10 px-8">
					<h1>People Behind the Curtains</h1>
					<p class="py-4">
						A peek into who is behind Archivian.
					</p>
				</div>
			</div>
		</div>

		<section class="z-10 relative max-w-xl mx-auto">
			<h2 class="py-2">
				A Solo Project (<em>for now</em>)
			</h2>
			<p class="pt-4">
				Archivian is currently a solo-project where I have to do everything; writing, research, design, development, testing, and infrastructure.
			</p>
			<p>
				This means everything takes a while.
				I'd love to have some skilled people I can trust join the project!
			</p>

			<h2 class="py-2">
				Creator
			</h2>

			<div class="flex gap-4">
				<img
					class="rounded-full aspect-square object-cover w-24"
					src="@/assets/people/Virtus.jpg"
					alt="A blonde male in suit smiling at the camera, whom is the creator of Archivian"
				>
				<div class="py-2">
					<strong>Martin "Virtus" Haslien</strong>
					<p>
						Former graphic designer turned full-stack engineer from Norway, living in Germany.
					</p>
				</div>
			</div>
		</section>
		<section class="z-10 relative max-w-xl mx-auto">
			<h2 class="py-2">
				Developer
			</h2>

			<div class="flex gap-4 pb-4">
				<UserAvatar
					user-id="00000000000000000000"
					aria-label="An unspecified user"
					size="96"
				/>
				<div class="py-2">
					<strong>You?</strong>
					<p>
						Want volunteer for the Archivian project?
						<RouterLink :to="tech" class="styled-link">
							Check out the technologies
						</RouterLink>
						and see if you'd be interested in joining.
					</p>
				</div>
			</div>
		</section>
		<section class="z-10 relative max-w-xl py-8 mx-auto">
			<h2>Shoutouts</h2>
			<div class="flex flex-col gap-4">
				<div>
					<h3>My girlfriend</h3>
					<p>
						Constantly dealing with me being busy with my project, but also being supportive of it,
						providing feedback and help on design, feedback, and testing. I ❤ you!
					</p>
				</div>
				<div>
					<h3>Hanna & the Elysium Community</h3>
					<p>
						Shout to Hanna for mentoring, teaching, and creating the Elysium community, and the people in it for helping me out when I began my fullstack journey.
					</p>
				</div>
				<div>
					<h3>Lars "HeadTrixZ"</h3>
					<p>
						Someone to talk to about coding, providing DevOps and tooling code for me to steal, testing my stuff.
					</p>
				</div>
				<div>
					<h3>Allfather / Tzar</h3>
					<p>
						Helping and providing feedback on the earliest versions with architecture, database, and brainstorming the permissions evaluator with me.
					</p>
				</div>
				<div>
					<h3>Freud & the Graphic Design community</h3>
					<p>
						Testing, giving feedback and ideas, and being the earliest (<em>large too!</em>) user of the bot.
					</p>
				</div>
			</div>
		</section>
	</article>
</template>

<style scoped>
blockquote {
	@apply shadow rounded border border-neutral-700 bg-neutral-800 p-4;
}
p {
	@apply py-1;
}
h3 {
	@apply text-gray-400 pb-1 pt-3;
}
h4 {
	@apply text-sky-200 pb-1 pt-3;
}
</style>
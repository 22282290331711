<template>
	<g>
		<path
			d="M8 18H14C15.6569 18 17 16.6569 17 15V15"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19 12H5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M16 6H11C9.34315 6 8 7.34315 8 9V9"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconStrikethrough"
};
</script>
import * as CaseAppeals from "./CaseAppeals.perms.js";
import * as Cases from "./Cases.perms.js";
import * as Settings from "./Settings.perms.js";
import * as Staff from "./Staff.perms.js";
import * as Titles from "./Titles.perms.js";
import * as PermissionSets from "./PermissionSets.perms.js";
import * as Messages from "./Messages.perms.js";
import * as Personal from "./Personal.perms.js";
import * as Admin from "./Admin.perms.js";
import * as WarningSystem from "./WarningSystem.perms.js";
import * as AutoMod from "./AutoMod.perms.js";
export { CaseAppeals, Cases, Settings, Staff, Titles, PermissionSets, Messages, Personal, Admin, WarningSystem, AutoMod, };
/**
 * The Permission set categories possible
 * - max length 32
 * - pascal case (preferably single words only)
 * - a-z-A-Z-0-1 only, no spaces, dashes, underscores, etc.
 */
export const CATEGORIES = {
    // Internal system sets, e.g., for library ones, special titles, etc.
    "internal": {
        value: "internal",
        showInLibrary: false,
        name: "Internal",
        description: null,
    },
    // For title permissions from the earlier system
    "legacyV1": {
        value: "legacyV1",
        showInLibrary: false,
        name: "Legacy Set",
        description: null,
    },
    // This set is dedicated to a specific title, holding its custom permissions
    "titleCustom": {
        value: "titleCustom",
        showInLibrary: false,
        name: "Title Custom Set",
        description: null,
    },
    // Related to staff members
    "staff": {
        value: "staff",
        showInLibrary: true,
        name: "Staff",
        description: null,
    },
    // Related to any kind of settings
    "setting": {
        value: "setting",
        showInLibrary: true,
        name: "Settings",
        description: null,
    },
    // Related to cases and case appeals (despite appeals also having their own category)
    "cases": {
        value: "cases",
        showInLibrary: true,
        name: "Cases",
        description: null,
    },
    // Related to Auto Moderation
    "autoMod": {
        value: "autoMod",
        showInLibrary: true,
        name: "Auto Moderation",
        description: null,
    },
    // Related to titles
    "title": {
        value: "title",
        showInLibrary: true,
        name: "Titles",
        description: null,
    },
    // Related to permissions and permission sets
    "permission": {
        value: "permission",
        showInLibrary: true,
        name: "Permissions",
        description: null,
    },
    // Related to case appeals
    "appeal": {
        value: "appeal",
        showInLibrary: true,
        name: "Case Appeals",
        description: null,
    },
    // Related to administrative permissions
    "admin": {
        value: "admin",
        showInLibrary: true,
        name: "Administration",
        description: null,
    },
    // A Discord moderation related permission
    "moderation": {
        value: "moderation",
        showInLibrary: true,
        name: "Moderation",
        description: null,
    },
    // Related to Discord text channels
    "text": {
        value: "text",
        showInLibrary: true,
        name: "Text Channel",
        description: null,
    },
    // Related to Discord voice channels
    "voice": {
        value: "voice",
        showInLibrary: true,
        name: "Voice Channel",
        description: null,
    },
    // Related to Discord members
    "members": {
        value: "members",
        showInLibrary: true,
        name: "Server Members",
        description: null,
    },
    // Related to messages policies
    // TODO Add permission sets for this category
    "messagePolicies": {
        value: "messagePolicies",
        showInLibrary: true,
        name: "Message Policies",
        description: null,
    },
    // Related to (Discord & Archivian) messages in general
    "messages": {
        value: "messages",
        showInLibrary: true,
        name: "Messages",
        description: null,
    },
    // Related to (Discord & Archivian) Auto Moderation
    "automod": {
        value: "automod",
        showInLibrary: true,
        name: "Auto Mod",
        description: null,
    },
};

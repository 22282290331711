<script setup>
import Nodes from "@/script/Nodes";
import InfoCallout from "@/components/info/callouts/InfoCallout.vue";
import InspectCaseMetaGrid from "@/partials/inspect/case/inspectCase/components/InspectCaseMetaGrid.vue";
import { inject, computed } from "vue";
import InspectCaseFileSection from "@/partials/inspect/case/inspectCase/components/InspectCaseFileSection.vue";
import InspectCaseEntrySection from "@/partials/inspect/case/inspectCase/components/InspectCaseEntrySection.vue";
import InspectCaseLinkedUserSection from "@/partials/inspect/case/inspectCase/components/InspectCaseLinkedUserSection.vue";
import InspectCaseLinkedCaseSection from "@/partials/inspect/case/inspectCase/components/InspectCaseLinkedCaseSection.vue";
import InspectCaseAppealsSection from "@/partials/inspect/case/inspectCase/components/InspectCaseAppealsSection.vue";
import { Account } from "@/storage/AccountCache";

const emit = defineEmits(["inspect"]);
const props = defineProps({
	"theCase": {
		type: Object,
		required: true,
	},
	"pills": {
		type: Object,
		default: () => ({
			"archived": false,
		})
	},
	"appeals": {
		type: Array,
		default: () => [],
	},
	"guild": {
		type: Object,
		default: () => ({ id: "00000000000000000000", name: "Unknown Server", icon: null })
	},
	// Personal view use only: used to conditionally display Appeal button
	"canAppeal": {
		type: Boolean,
		default: () => true,
	},
});
const account = Account();
const personal = inject("isPersonal");
const canViewFiles = computed(() => {
	if (personal && !props.theCase.files?.length) return false;

	// The checks here aren't really thorough, but it's fine for now
	if (!account.can(Nodes.Cases.FILE.ANY())) return false;
	return true;
});
const canViewEntries = computed(() => {
	if (personal && !props.theCase.entries?.length) return false;

	// A case always requires at least 1 active entry, so if none, it means no read access.
	// Does not mean no creation access though, so check for that
	const createEntries = Nodes.Cases.ENTRY.CREATE(props.theCase.caseType, account.userId === props.theCase.creatorId);
	if (!props.theCase.entries?.length && !account.can(createEntries)) return false;

	return true;
});
const expiryDate = computed(() => {
	if (!props.theCase.expireAt) return null;
	return new Date(props.theCase.expireAt);
});
</script>

<template>
	<InfoCallout
		v-if="pills.archived && !personal"
		label="This case is archived. Any archived resource (and anything within it) cannot be edited unless it's restored first."
	/>

	<!-- For users, if all content is hidden -->
	<div
		v-if="personal && !theCase.creatorId && !theCase.files.length && !theCase.entries.length"
		class="text-center mx-auto my-4 max-w-xs"
	>
		<p>
			<span class="italic">
				All details of this case are hidden&hellip; Not much to see here
			</span> 🙃
		</p>
	</div>

	<InspectCaseMetaGrid
		v-if="!personal || theCase.creatorId"
		:target-id="theCase.targetId"
		:creator-id="theCase.creatorId"
		:guild-id="theCase.guildId"
		:case-type="theCase.caseType"
		:contributor-ids="theCase.contributors"
		:pills="pills"
		:appealable="canAppeal"
		:appeals="appeals"
		:created-at="new Date(theCase.createdAt)"
		:expire-at="expiryDate"
		@inspect="emit('inspect')"
	/>

	<InspectCaseFileSection
		v-if="canViewFiles"
		:sid="theCase.sid"
		:creator-id="theCase.creatorId"
		:case-type="theCase.caseType"
		:guild-id="theCase.guildId"
		:files="theCase.files"
		:pills="pills"
	/>

	<InspectCaseEntrySection
		v-if="canViewEntries"
		:case-id="theCase._id"
		:creator-id="theCase.creatorId"
		:case-type="theCase.caseType"
		:pills="pills"
		:entries="theCase.entries"
	/>

	<InspectCaseLinkedUserSection
		v-if="!personal
		&& (theCase.linked.users.length || account.can(Nodes.Cases.LINKED.USER.CREATE()) )"
		:linked-users="theCase.linked.users"
		:pills="pills"
	/>

	<InspectCaseLinkedCaseSection
		v-if="!personal
		&& (theCase.linked.cases.length || account.can(Nodes.Cases.LINKED.CASE.CREATE()) )"
		:linked-cases="theCase.linked?.cases"
		:pills="pills"
	/>

	<InspectCaseAppealsSection
		v-if="appeals.length"
		:appeals="appeals"
	/>
</template>
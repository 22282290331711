<template>
	<g>
		<path
			d="M12 18.9999C11.158 18.9999 10.315 18.8219 9.496 18.5049"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M20.882 12.468C18.99 15.967 15.495 19 12 19"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.079 8.92102C19.77 9.73002 20.384 10.612 20.882 11.533C21.039 11.824 21.039 12.177 20.882 12.468"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5 19L19 5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.77299 14.227C8.54299 12.997 8.54299 11.002 9.77299 9.77199C11.003 8.54199 12.998 8.54199 14.228 9.77199"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.044 6.956C15.497 5.759 13.748 5 12 5C8.50499 5 5.00999 8.033 3.11799 11.533C2.96099 11.824 2.96099 12.177 3.11799 12.468C4.06399 14.217 5.40999 15.849 6.95599 17.045"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconInvisible"
};
</script>
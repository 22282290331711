<template>
	<g>
		<path
			d="M16.9498 7.05078L7.0498 16.9508"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10 7L16.95 7.049L17 14"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconArrowDiagonalUp"
};
</script>
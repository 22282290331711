import AppCore from "./domain/AppCore.js";
import Plugin from "./domain/Plugin.js";
export * from "./types.js";
export { Plugin, AppCore, };
/**
 * An easy way to return X, Y, or Z value based on tier name, using array
 * @example
 * const tuple: UnknownTuple<number> = [10, 20, 30];
 * const tier: PremiumTier = "pro";
 * const x = tierSwitch(tier, tuple);
 * console.log(x); // 30
 */
export function tierSwitch(tierName, options) {
    switch (tierName.toLowerCase()) {
        case "free":
            return options[0];
        case "plus":
            return options[1];
        case "pro":
            return options[2];
        default:
            throw new Error(`Unknown tier name '${tierName}'`);
    }
}

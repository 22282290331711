<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.9107 12.5093L18.8857 6.5343C19.5981 5.8219 20.7532 5.8219 21.4656 6.5343V6.5343C22.178 7.24671 22.178 8.40174 21.4656 9.11415L15.4906 15.0891C15.1081 15.4716 14.6119 15.7198 14.0764 15.7962L12.6941 15.9937C12.5046 16.0208 12.3135 15.9571 12.1781 15.8217C12.0428 15.6864 11.979 15.4952 12.0061 15.3057L12.2036 13.9235C12.2801 13.388 12.5282 12.8918 12.9107 12.5093V12.5093Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9 16H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9 12.5H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M12 9H7"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19 6.42983V5C19 3.89543 18.1046 3 17 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H17C18.1046 21 19 20.1046 19 19V11.5798"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconNote"
};
</script>
<template>
	<div
		:id="id"
		tabindex="0"
		role="button"
		:aria-label="computedAriaLabel"
		:title="computedAriaLabel"
		:class="{
			'is-saving': saving,
			'opacity-60 cursor-not-allowed': disabled,
			'cursor-pointer opacity-100': !disabled
		}"
		@click.stop="toggle"
		@keydown.enter.stop="toggle"
		@keydown.space.stop="toggle"
	>
		<div class="flex items-center w-full break-words">
			<div
				:id="`${id}-inner`"
				class="button-bg flex rounded-full p-1 w-12 shrink-0 relative"
				:class="{
					'toggle-on': enabled && !saving,
					'toggle-off':!enabled && !saving,
					'saving': saving
				}"
			>
				<span
					class="h-5 w-5 rounded-full bg-white shadow"
					:class="{'saving-ball': saving}"
				/>
			</div>

			<label
				v-if="label"
				:for="id"
				class="font-semibold text-sm truncate px-2"
			>
				{{ label }}
			</label>
		</div>
	</div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
	"id": {
		type: String,
		default: () => Math.random().toString(36).slice(2)
	},
	"label": {
		type: String,
		default: () => null,
	},
	"ariaLabel": {
		type: String,
		default: () => "Toggle",
	},
	"saving": {
		type: Boolean,
		default: () => false,
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
	"enabled": {
		type: Boolean,
		default: () => false,
	},
});

const computedAriaLabel = computed(()=>{
	console.log(props.ariaLabel, props.label)
	if (props.ariaLabel) return props.ariaLabel;
	if (!props.label) return "Toggle";
	return `Set ${props.label} to ${props.enabled ? 'off' : 'on'}`;
});

const emitter = defineEmits(["toggle"]);

function toggle(value) {
	if (typeof value !== "boolean") value = undefined;

	if (props.saving || props.disabled) return;
	emitter("toggle", value !== undefined ? !!value : !props.enabled);
}

</script>

<style scoped>
.button-bg {
	background-color: rgba(0, 0, 0, .5);
	border-color: transparent;
	box-shadow: inset 0 0 0 transparent;
}

.toggle-on {
	box-shadow: inset 0 1px 3px #95deff;
	background-color: var(--theme-active);
	border-color: var(--theme-active);
	transition: all .2s ease-out;
}

.toggle-on span {
	transition: all .2s ease-out;
	transform: translateX(calc(100%));
}

.toggle-off span {
	transition: all .2s ease-out;
	transform: translateX(0%);
}

.toggle-off {
	box-shadow: inset 0 -1px 3px theme('colors.neutral.700');
	transition: all .2s ease-out;
}

.saving-ball {
	transform: translateX(calc(50%));
	transition: all .2s ease-out;
	background-color: rgba(255, 255, 255, .7);
}

.is-saving {
	transition: all .2s ease-out;
	cursor: progress;
	opacity: .8
}
</style>
<script setup>
import Nodes from "@/script/Nodes";
import PermsCond from "@/components/PermsCond.vue";
import SummaryContainer from "@/components/lists/summary-container/SummaryContainer.vue";
import {computed, inject} from "vue";
import {Account} from "@/storage/AccountCache";
import {CaseEntryMasks} from "@archivian/constants";
import {useRoute} from "vue-router";

const props = defineProps({
	"caseId": {
		type: String,
		required: true,
	},
	"caseType": {
		type: String,
		required: true,
	},
	"creatorId": {
		type: String,
		default: () => null,
	},
	"entries": {
		type: Array,
		default: () => [],
	},
	"pills": {
		type: Object,
		default: () => ({
			"archived": false,
		}),
	},
});
const route = useRoute();
const personal = inject("isPersonal");
const account = Account();
const entryItems = computed(() => {
	return props.entries.map(e => ({
		label: `Entry #${e.sid}`,
		icon: e.archived?.at
			? "Archived"
			: e.flags & CaseEntryMasks.CaseEntryFlagMask.visibleToTarget
				? "Visible"
				: "Invisible",
		lowerOpacity: !!e.archived?.at,
		description: e.content,
		chevron: true,
		to: {
			name: personal ? "my-cases" : "guild-cases",
			params: {
				... route.params,
				resource: "entries",
				resourceId: e.sid,
				caseId: props.caseId, // for Personal
			}
		}
	}));
});

</script>

<template>
	<section>
		<div id="entries">
			<!-- Top bar -->
			<div class="flex justify-between items-baseline">
				<p class="label">
					Entries
				</p>
				<PermsCond
					v-if="!personal && !pills.archived"
					:needs="Nodes.Cases.ENTRY.CREATE(caseType,
					creatorId===account.id)"
				>
					<RouterLink
						:to="{
							name: 'guild-cases',
							params: { resource: 'entries', resourceId: 'new', caseSid: route.params.caseSid }
						}"
					>
						<span class="uppercase text-xs font-bold text-sky-400">Add entry</span>
					</RouterLink>
				</PermsCond>
			</div>
			<!-- List of entries -->
			<SummaryContainer
				v-if="entries.length"
				:items="entryItems"
			/>
			<p v-else class="italic text-slate-500">
				Entry/entries are hidden due to insufficient permissions.
			</p>
		</div>
	</section>
</template>
<script setup>
import NewLinkedUserView from "@/partials/inspect/case/inspectLinkedUser/NewLinkedUserView.vue";
import InspectLinkedUserView from "@/partials/inspect/case/inspectLinkedUser/InspectLinkedUserView.vue";
import {computed} from "vue";
import UserCache from "@/storage/UserCache";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";

const props = defineProps({
	"handlers": {
		type: Object,
		default: () => ({}),
	},
	"resourceId": {
		type: String,
		required: true,
	},
	"theCase": {
		type: Object,
		required: true,
	},
});
const linked = computed(() => {
	if (props.resourceId === "new") return null;
	return props.theCase?.linked?.users?.find(l => l.userId === props.resourceId);
});
const users = UserCache();
</script>

<template>
	<NewLinkedUserView
		v-if="resourceId === 'new'"
		:close="handlers.closeResource"
		:handle-save="handlers.createUserLink"
		:guild-id="theCase.guildId"
	/>
	<InspectLinkedUserView
		v-else-if="linked"
		:close="handlers.closeResource"
		:handle-delete="handlers.deleteLinkedUser"
		:handle-save="handlers.renameLinkedUser"
		:last-user-id="linked.by"
		:label="linked.label"
		:user="users.user(linked.userId)"
	/>
	<div v-else class="p-4 rounded-lg">
		<ErrorCallout
			label="This user is not linked."
			:texts="['It could be that the linked user has been removed, or that they were never linked to begin with.']"
		/>
	</div>
</template>
import EarlyWarningSystemContainer
	from "@/views/guild/members/early-warning/EarlyWarningSystemContainer.vue";
import MemberModerationContainer from "@/views/guild/moderation-channels/members/MemberModerationContainer.vue";
import PersonalAppealsContainer from "@/views/account/case-system/appeals/PersonalAppealsContainer.vue";
import GuildCases from "@/views/guild/case-system/cases/GuildCases.vue";
import PersonalCases from "@/views/account/case-system/cases/PersonalCases.vue";
import GuildAppeals from "@/views/guild/case-system/appeals/GuildAppeals.vue";
import CheckBannedContainer from "@/views/account/guilds/check-banned/CheckBannedContainer.vue";
import AboutWrapper from "@/views/public/about/AboutWrapper.vue";

const AdminWrapper = () => import("@/views/admin/AdminWrapper.vue");
const AdminDashboard = () => import("@/views/admin/dashboard/AdminDashboard.vue");
const AdminManageBotsContainer = () => import("@/views/admin/bots/AdminManageBotsContainer.vue");
const GuildStaffGeneralContainer = () => import("@/views/guild/staff-management/general/GuildStaffGeneralContainer.vue");
const GuildCaseSettingsAppealsContainer = () => import("@/views/guild/case-system/settings/tabs/appeals/GuildCaseSettingsAppealsContainer.vue");
const MainView = () => import("@/views/public/MainView.vue");
const AccountHomeWrapper = () => import("@/views/account/home/AccountHomeWrapper.vue");
const AccountHomeOverviewContainer = () => import("@/views/account/home/tabs/AccountHomeOverviewContainer.vue");
const AccountHomeCommunitiesContainer = () => import("@/views/account/home/tabs/AccountHomeCommunitiesContainer.vue");
const AddToServerContainer = () => import("@/views/setup-new-server/AddToServerContainer.vue");
const GuildHomeContainer = () => import("@/views/guild/home/GuildHomeContainer.vue");
const CaseSystemWrapper = () => import("@/views/guild/case-system/CaseSystemWrapper.vue");
const StaffManagementWrapper = () => import("@/views/guild/staff-management/StaffManagementWrapper.vue");
const GuildStaffContainer = () => import("@/views/guild/staff-management/staff/GuildStaffContainer.vue");
const GuildTitlesContainer = () => import("@/views/guild/staff-management/titles/browse/GuildTitlesContainer.vue");
const GuildNewTitleContainer = () => import("@/views/guild/staff-management/titles/new/GuildNewTitleContainer.vue");
//const GuildAppealsContainer = () => import("@/views/guild/case-system/appeals/GuildAppealsContainer.vue");
const MyCaseSystemWrapper = () => import("@/views/account/case-system/MyCaseSystemWrapper.vue");
const GuildInspectTitleContainer = () => import("@/views/guild/staff-management/titles/inspect/GuildInspectTitleContainer.vue");
const GuildInspectStaffWithTitleView = () => import("@/views/guild/staff-management/titles/inspect/tabs/GuildInspectStaffWithTitleView.vue");
const GuildInspectTitleAutoGrantView = () => import("@/views/guild/staff-management/titles/inspect/tabs/GuildInspectTitleAutoGrantView.vue");
const GuildInspectTitlePermissionsView = () => import("@/views/guild/staff-management/titles/inspect/tabs/GuildInspectTitlePermissionsView.vue");
const GuildInspectTitleGeneralView = () => import("@/views/guild/staff-management/titles/inspect/tabs/GuildInspectTitleGeneralView.vue");
const GuildInspectTitleInfoView = () => import("@/views/guild/staff-management/titles/inspect/tabs/GuildInspectTitleInfoView.vue");
const GuildInspectTitlePermissions_basicView = () => import("@/views/guild/staff-management/titles/inspect/tabs/GuildInspectTitlePermissions_basicView.vue");
const GuildInspectTitlePermissions_advancedView = () => import("@/views/guild/staff-management/titles/inspect/tabs/GuildInspectTitlePermissions_advancedView.vue");
const EditTitlePermissionSetsContainer = () => import("@/views/guild/staff-management/titles/permission-sets/EditTitlePermissionSetsContainer.vue");
const GuildCaseSettingsContainer = () => import("@/views/guild/case-system/settings/GuildCaseSettingsContainer.vue");
const GuildCaseSettingsBehaviorContainer = () => import("@/views/guild/case-system/settings/tabs/behavior/GuildCaseSettingsBehaviorContainer.vue");
const GuildCaseSettingsPermissionsContianer = () => import("@/views/guild/case-system/settings/tabs/permissions/GuildCaseSettingsPermissionsContianer.vue");
const GuildCaseSettingsResultsContainer = () => import("@/views/guild/case-system/settings/tabs/results/GuildCaseSettingsResultsContainer.vue");
const GuildCaseSettingsVisibilityContainer = () => import("@/views/guild/case-system/settings/tabs/visibility/GuildCaseSettingsVisibilityContainer.vue");
const GuildCaseSettingsAppearanceContainer = () => import("@/views/guild/case-system/settings/tabs/appearance/GuildCaseSettingsAppearanceContainer.vue");
const GuildCaseSettingsAlertsContainer = () => import("@/views/guild/case-system/settings/tabs/alerts/GuildCaseSettingsAlertsContainer.vue");
const BlogArticle = () => import("@/views/public/posts/blog/BlogArticle.vue");
const RedirectLoginView = () => import("@/views/public/login/RedirectLoginView.vue");
const TermsOfService = () => import("@/views/public/legal/TermsOfService.vue");
const RefundPolicy = () => import("@/views/public/legal/RefundPolicy.vue");
const FeatureArticle = () => import("@/views/public/posts/feature/FeatureArticle.vue");
const ConfirmEmail = () => import("../../emails/emails/ConfirmEmail.vue");
const PublicMainContainer = () => import("@/views/public/MainContainer.vue");
const FeaturesOverview = () => import("@/views/public/features/FeaturesOverview.vue");
const PrivacyPolicy = () => import("@/views/public/legal/PrivacyPolicy.vue");
const SignUpContainer = () => import("@/views/public/login/SignUpContainer.vue");
const NotFound = () => import("@/views/public/error/NotFound.vue");
const NotStaff = () => import("@/views/public/error/NotStaff.vue");
const NotAllowed = () => import("@/views/public/error/NotAllowed.vue");
const AccountWrapper = () => import("@/views/account/AccountWrapper.vue");
const PricingView = () => import("@/views/public/prices/PricingView.vue");
const JoinAsStaffView = () => import("@/views/public/join/JoinAsStaffView.vue");

const endpoints = [
	{
		path: "/",
		component: PublicMainContainer,
		meta: {
			isPublic: true,
		},
		children: [
			{
				path: "",
				name: "index",
				component: MainView,
				props: true,
			},
			{
				path: "login",
				name: "login",
				component: RedirectLoginView,
				props: route => ({ redirect: route.query.redirect || null })

			},
			{
				path: "prices",
				name: "prices",
				component: PricingView,
			},
			{
				name: "about",
				path: "about/:category?",
				component: AboutWrapper,
			},
			{
				name: "emails",
				path: "emails",
				children: [
					{
						name: "email-confirm-email",
						path: "confirm-email",
						component: ConfirmEmail,
					}
				]
			},
			{
				path: "signup",
				name: "sign-up",
				component: SignUpContainer,
				props: true,
			},
			{
				path: "features",
				name: "features",
				redirect: { name: "overview" },
				children: [
					{
						name: "overview",
						path: "",
						component: FeaturesOverview,
					},

				]
			},
			{
				path: "articles",
				name: "articles",
				children: [
					{
						name: "feature-article",
						path: "features/:slug",
						component: FeatureArticle,
					},
					{
						name: "posts",
						path: "posts/:slug",
						component: BlogArticle,
					}
				]
			},
			{
				path: "legal",
				name: "legal",
				children: [
					{
						path: "privacy-policy",
						name: "privacy-policy",
						component: PrivacyPolicy,
					},
					{
						path: "terms-of-service",
						name: "terms-of-service",
						component: TermsOfService,
					},
					{
						path: "refund-policy",
						name: "refund-policy",
						component: RefundPolicy,
					}
				]
			},
			{
				name: "join-new-server",
				path: "join",
				component: JoinAsStaffView,
			},
			{
				name: "public-auto-mod-library",
				path: "auto-mod-rules/:templateId?",
				component: () => import("@/partials/auto-mod/PublicAutoModLibraryWrapper.vue"),
			},
			{
				path: "error",
				children: [
					{
						path: "not-allowed",
						name: "not-allowed",
						component: NotAllowed,
					}
				]
			},
		]
	},
	{
		name: "me",
		path: "/me",
		redirect: { name: "account-overview" },
		component: AccountWrapper,
		meta: {
			authSession: true,
			clearGuild: true,
			/**
			 * With this, we can ensure that the views/routes we're on
			 * is for personal dashboard.
			 */
			isPersonal: true,
		},
		children: [
			{
				path: "home",
				name: "account-home",
				component: AccountHomeWrapper,
				redirect: { name: "account-overview" },
				children: [
					{
						path: "overview",
						name: "account-overview",
						component: AccountHomeOverviewContainer,
					},
					{
						path: "communities",
						name: "account-overview-communities",
						component: AccountHomeCommunitiesContainer,
					},
				]
			},
			{
				path: "not-staff",
				name: "notStaff",
				component: NotStaff,
			},
			{
				name: "my-case-system",
				path: "case-system",
				component: MyCaseSystemWrapper,
				redirect: { name: "my-cases" },
				children: [
					{
						name: "my-cases",
						path: "cases/:caseId?/:resource?/:resourceId?",
						component: PersonalCases,
						props: (route) => ({
							caseId: route.params.caseId ?? null,
							resource: route.params.resource ?? null,
							resourceId: route.params.resourceId ?? null,
						}),
					},
					{
						name: "my-appeals",
						path: "appeals/:appealId?/:resource?/:resourceId?",
						component: PersonalAppealsContainer,
						props: (route) => ({
							appealId: route.params.appealId ?? null,
							resource: route.params.resource ?? null,
							resourceId: route.params.resourceId ?? null,
						}),
					},
				]
			},
			{
				name: "me-guilds",
				path: "guilds/:guildId",
				children: [
					{
						name: "check-banned",
						path: "check-banned",
						component: CheckBannedContainer,
					}
				]
			},
			{
				name: "my-auto-mod",
				path: "auto-mod",
				component: () => import("@/views/account/auto-mod/MyAutoModWrapper.vue"),
				redirect: { name: "my-auto-mod-templates" },
				children: [
					{
						name: "my-auto-mod-templates",
						path: "templates/:templateId?",
						component: () => import("@/views/account/auto-mod/MyAutoModContainer.vue"),
					},
					{
						name: "auto-mod-public-private",
						path: "public/:templateId?",
						component: () => import("@/partials/auto-mod/PublicAutoModLibraryWrapper.vue"),
					}
				]
			}
			/*
			 {
			 name: "my-cases-appeals",
			 path: "cases",
			 component: MyCaseContainerView,
			 redirect: {name: "browse-my-cases"},
			 children: [
			 {
			 name: "browse-my-cases",
			 path: "browse/:caseId?",
			 props: true,
			 components: {
			 caseList: MyCaseList,
			 default: InspectMyCaseContainer,
			 },
			 children: [
			 {
			 name: "new-appeal",
			 path: "appeal",
			 component: CreateAppeal,
			 props: true
			 },
			 {
			 name: "inspect-my-entry",
			 path: "entries/:entrySid",
			 component: InspectEntry,
			 props: true
			 },
			 {
			 name: "inspect-my-file",
			 path: "files/:fileId",
			 component: InspectFile,
			 props: true
			 },
			 ]
			 },
			 {
			 name: "browse-my-appeals",
			 path: "appeals/:appealId?",
			 props: true,
			 components: {
			 caseList: MyAppealsList,
			 default: InspectMyAppealContainer,
			 }
			 }
			 ]
			 }
			 */
		]
	},
	{
		path: "/guilds/:guildId",
		name: "guild-landing",
		redirect: { name: "guild-home" },
		meta: {
			authSession: true,
			ensureStaff: true,
			/**
			 * With this boolean we can be sure that we are in a guild,
			 * and we can rely on `:guildId` being that guild
			 */
			isGuild: true,
		},
		children: [
			{
				path: "home",
				name: "guild-home",
				component: GuildHomeContainer,
			},
			{
				name: "case-system",
				path: "case-system",
				component: CaseSystemWrapper,
				redirect: { name: "guild-cases" },
				children: [
					{
						name: "guild-cases",
						path: "cases/:caseSid?/:resource?/:resourceId?",
						component: GuildCases,
						props: (route) => ({
							caseSid: route.params.caseSid ?? null,
							resource: route.params.resource ?? null,
							resourceId: route.params.resourceId ?? null,
						}),
					},
					{
						name: "guild-appeals",
						path: "appeals/:appealId?/:resource?/:resourceId?",
						component: GuildAppeals,
						props: (route) => ({
							appealId: route.params.appealId ?? null,
							resource: route.params.resource ?? null,
							resourceId: route.params.resourceId ?? null,
						}),
					},
					{
						name: "guild-case-settings",
						path: "settings/:section?",
						component: GuildCaseSettingsContainer,
						props: (route) => ({
							section: route.params.section,
						}),
						redirect: { name: "guild-case-behavior-settings" },
						meta: {
							isCaseSettings: true, // Used for route guard if you made changes
						},
						children: [
							{
								name: "guild-case-behavior-settings",
								path: "behavior",
								component: GuildCaseSettingsBehaviorContainer,
							},
							{
								name: "guild-case-result-settings",
								path: "results",
								component: GuildCaseSettingsResultsContainer,
							},
							{
								name: "guild-case-appearance-settings",
								path: "appearance",
								component: GuildCaseSettingsAppearanceContainer,
							},
							{
								name: "guild-case-visibility-settings",
								path: "visibility",
								component: GuildCaseSettingsVisibilityContainer,
							},
							{
								name: "guild-case-permission-settings",
								path: "permissions",
								component: GuildCaseSettingsPermissionsContianer,
							},
							{
								name: "guild-case-alert-settings",
								path: "alerts",
								component: GuildCaseSettingsAlertsContainer,
							},
							{
								name: "guild-case-appeal-settings",
								path: "appeals",
								component: GuildCaseSettingsAppealsContainer,
							},
						]
					}
				]
			},
			{
				name: "staff-management",
				path: "staff-management",
				component: StaffManagementWrapper,
				redirect: { name: "guild-staff" },
				children: [
					{
						name: "guild-staff",
						path: "staff",
						component: GuildStaffContainer,
					},
					{
						name: "guild-titles",
						path: "titles",
						redirect: { name: "browse-guild-titles" },
						children: [
							{
								name: "browse-guild-titles",
								path: "browse",
								component: GuildTitlesContainer,
							},
							{
								name: "guild-new-title",
								path: "new",
								component: GuildNewTitleContainer,
							},
							{
								name: "guild-inspect-title",
								path: "inspect/:titleId",
								redirect: {name: "guild-inspect-title-general"},
								component: GuildInspectTitleContainer,
								children: [
									{
										name: "guild-inspect-title-general",
										path: "general",
										component: GuildInspectTitleGeneralView,
									},
									{
										name: "guild-inspect-title-staff",
										path: "staff",
										component: GuildInspectStaffWithTitleView,
									},
									{
										name: "guild-inspect-title-auto-grant",
										path: "auto-grant",
										component: GuildInspectTitleAutoGrantView,
									},
									{
										name: "guild-inspect-title-permissions",
										path: "permissions",
										redirect: { name : "guild-inspect-title-permissions-basic" },
										component: GuildInspectTitlePermissionsView,
										props: true,
										children: [
											{
												name: "guild-inspect-title-permissions-basic",
												path: "basic",
												component: GuildInspectTitlePermissions_basicView,
											},{
												name: "guild-inspect-title-permissions-advanced",
												path: "advanced",
												component: GuildInspectTitlePermissions_advancedView,
											}
										]
									},
									{
										name: "guild-inspect-title-info",
										path: "info",
										component: GuildInspectTitleInfoView,
									}
								]
							},
							{
								name: "guild-title-permission-sets",
								path: "permission-sets",
								redirect: { name: "guild-edit-title-permission-sets" },
								meta: {
									hideHeader: true,
								},
								children: [
									{
										name: "guild-edit-title-permission-sets",
										path: "edit/:titleId",
										component: EditTitlePermissionSetsContainer,
									}
								]
							}
						]
					},
					{
						name: "guild-staff-general",
						path: "general",
						redirect: { name: 'general-permissions' },
						children: [
							{
								name: "general-permissions",
								path: "permissions",
								component: GuildStaffGeneralContainer,
							}
						]
					}
				]
			},
			{
				name: "guild-channels",
				path: "channels",
				component: () => import("@/views/guild/channels/ChannelsView.vue"),
				children: [
					{
						name: "messages-tracking",
						path: "messages/tracking",
						component: () => import("@/views/guild/channels/messages/tracking/MessageTrackingView.vue"),
					},
					{
						name: "messages-deleted-attachments",
						path: "messages/attachments/deleted/:messageId?",
						component: () => import("@/views/guild/channels/messages/attachments/DeletedAttachmentsContainer.vue"),
					},
					{
						name: "messages-collections",
						path: "messages/collections/:collectionId?",
						component: () => import("@/views/guild/channels/messages/collections/MessageCollectionsContainer.vue"),
					},
				],
			},
			{
				name: "members",
				path: "members",
				component: () => import("@/views/guild/members/MembersView.vue"),
				redirect: { name: "early-warning-system" },
				children: [
					{
						name: "early-warning-system",
						path: "early-warning-system",
						component: EarlyWarningSystemContainer,
					},
				],
			},
			{
				name: "auto-mod",
				path: "auto-mod",
				redirect: { name: "auto-mod-violations" },
				component: () => import("@/views/guild/auto-mod/AutoModView.vue"),
				children: [
					{
						name: "auto-mod-violations",
						path: "violations",
						component: () => import("@/views/guild/auto-mod/violations/AutoModViolationsContainer.vue"),
					},
					{
						name: "auto-mod-templates-guild",
						path: "templates/:templateId?",
						component: () => import("@/views/guild/auto-mod/templates/GuildAutoModContainer.vue"),
					},
					{
						name: "auto-mod-public-guild",
						path: "public/:templateId?",
						component: () => import("@/partials/auto-mod/PublicAutoModLibraryWrapper.vue"),
					},
					{
						name: "auto-mod-settings",
						path: "settings/:tab?",
						component: () => import("@/views/guild/auto-mod/settings/AutoModSettingsContainer.vue"),
					},
				]
			},
			{
				name: "moderation",
				path: "moderation",
				component: MemberModerationContainer,
				redirect: { name: "early-warning-system" },
				children: [
					{
						name: "early-warning-system-old",
						path: "early-warning-system",
						component: EarlyWarningSystemContainer,
					}
				]
			}
		]
	},
	{
		path: "/admin",
		name: "admin",
		redirect: { name: "admin-dashboard" },
		component: AdminWrapper,
		meta: {
			isAdmin: true,
			clearGuild: true,
			isPersonal: true,
		},
		children: [
			{
				path: "dashboard",
				name: "admin-dashboard",
				component: AdminDashboard,
			},
			{
				path: "bots",
				name: "bot-management",
				component: AdminManageBotsContainer,
			},
			{
				path: "permissions",
				name: "admin-permissions",
				component: () => import("@/views/admin/permissions/PermissionsWrapper.vue"),
				redirect: { name: "admin-permission-sets" },
				children: [
					{
						name: "admin-permission-sets",
						path: "sets/manage",
						component: () => import("@/views/admin/permissions/AdminPermissionSets.vue"),
					},
					{
						name: "admin-test-user-permissions",
						path: "users",
						component: () => import("@/views/admin/permissions/AdminTestPermissions.vue"),
					},
				]
			},
		],
	},
	{
		path: "/add",
		name: "add",
		component: AddToServerContainer,
		meta: { authSession: true, isPersonal: true },
	},
	{
		name: "404",
		path: "/:pathMatch(.*)*",
		component: PublicMainContainer,
		meta: { isPublic: true },
		children: [{
			name: "not-found",
			path: "",
			component: NotFound,
		}],
	}
];

if (process.env.VUE_APP_IS_DEV === "1") {
	// endpoints.push(...)
}

export default endpoints;

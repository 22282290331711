<script setup>
import {useRoute, useRouter} from "vue-router";
import MissionStatementView from "@/views/public/about/MissionStatementView.vue";
import {onMounted} from "vue";
import AboutPeopleView from "@/views/public/about/AboutPeopleView.vue";
import AboutTechnologiesView from "@/views/public/about/AboutTechnologiesView.vue";

const router = useRouter();
const route = useRoute();
onMounted(()=>{
	if (!route.params.category) {
		return router.push({name: "about", params: {category: "people"}});
	}
});
</script>

<template>
	<AboutPeopleView
		v-if="route.params.category === 'people'"
	/>
	<MissionStatementView
		v-else-if="route.params.category === 'mission'"
	/>
	<AboutTechnologiesView
		v-else-if="route.params.category === 'technology'"
	/>
	<p v-else>
		That's not an about page I have ever heard of :|
	</p>
</template>

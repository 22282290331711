/**
 * Export events from server here, so you can rely on just one import
 */
import { ServerEvents } from "@archivian/constants";

export const { GUILD_OPS, USER_OPS } = ServerEvents;

const hooks = new Map();
window.addEventListener("wsOp", (e) => {
	const { op, data } = e.detail;

	if (!hooks.has(op)) return;

	for (const cb of hooks.get(op)) {
		cb(data);
	}
}, false);

/**
 * Sets up a listener hook for a custom operation
 * @param {OP_CODE[]} op One or more OP codes to listen for that will trigger this
 * @param {(data?: any) => Promise<void>|void} cb The function to trigger
 */
export function hook(op, cb) {
	for (const o of op) {
		if (!hooks.has(o)) hooks.set(o, new Set());
		hooks.get(o).add(cb);
	}
}

/**
 * Removes a hook listener for a custom operation
 * @param {OP_CODE[]} op One or more OP codes to listen for that will trigger this
 * @param {(data?: any) => Promise<void>|void} cb The function to remove
 */
export function unhook(op, cb) {
	/**
	 * Potential optimization:
	 * If the set is empty after removing hook, it might be beneficial to delete
	 * the map entry with the set entirely?
	 *
	 * Is it more performant to mutate and keep in memory long term than
	 * having to initialize and re-allocate memory for a new set?
	 */

	for (const o of op) {
		if (!hooks.has(o)) continue;
		hooks.get(o).delete(cb);
	}
}

/**
 * Emit a custom operation to the system
 * @param {OP_CODE[] | OP_CODE} op One or more OP codes to listen for that will trigger this
 * @param {any} [data] The associated data, if any
 */
export function emit(op, data) {
	if (!Array.isArray(op)) op = [op];

	for (const o of op) {
		window.dispatchEvent(new CustomEvent("wsOp", {
			detail: {
				op: o,
				data
			}
		}));
	}
}
<script setup>
import BasicButton from "@/components/buttons/BasicButton.vue";
import LoadingEllipsis from "@/components/icon/LoadingEllipsis.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

defineProps({
	"close": {
		type: Function,
		required: true
	},
	"create": {
		type: Function,
		required: true
	},
	"disabled": {
		type: Boolean,
		default: () => false,
	},
});
const cases = CaseAppealBrowser();
</script>

<template>
	<div class="flex gap-2 w-full justify-end">
		<BasicButton
			:label="cases.isLoading('entryAdd', cases.currentId) ? 'Close' : 'Cancel'"
			type="link"
			@click="close"
		/>
		<BasicButton
			class="flex"
			:label="!cases.isLoading('entryAdd', cases.currentId) ? 'Create' : 'Creating entry'"
			:disabled="disabled || cases.isLoading('entryAdd', cases.currentId)"
			@click="create"
		>
			<LoadingEllipsis v-if="cases.isLoading('entryAdd', cases.currentId)" />
		</BasicButton>
	</div>
</template>

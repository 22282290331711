<script setup>
import {computed} from "vue";
import UserCache from "@/storage/UserCache";

const props = defineProps({
	"users": {
		// Required if not userIds
		type: Array,
		default: () => null,
	},
	"userIds": {
		// Required if not users
		type: Array,
		default: () => [],
	},
	/**
	 * @type {DiscordImageSize}
	 */
	"size": {
		type: [String,Number],
		default: () => 24,
	},
	// Actually just a tailwind class
	"borderColor": {
		type: String,
		default: () => "border-neutral-700",
	},
});

const userCache = UserCache();
const userList = computed(() => {
	if (props.users) return props.users;
	return props.userIds.map(id => userCache.user(id, true));
});
const avatarImg = user => {
	return userCache.avatarOf(user.id, props.size);
}
const classSize = () => {
	switch(props.size) {
		case 16:
			return "w-4 h-4";
		case 24:
			return "w-6 h-6";
		case 32:
			return "w-8 h-8";
		case 40:
			return "w-10 h-10";
		case 48:
			return "w-12 h-12";
		default:
			return "w-6 h-6";
	}
}
</script>

<template>
	<div class="flex flex-row-reverse">
		<img
			v-for="user in userList"
			:key="user.id"
			:src="avatarImg(user)"
			:alt="`Avatar of ${user.username}`"
			:title="user.username"
			class="border-2 borderColor rounded-full w-6 h-6 -ml-2"
			:class="{[classSize()]: true, [borderColor]: true}"
		>
	</div>
</template>
/**
 * The type of template this is
 */
export var AutoModTemplateType;
(function (AutoModTemplateType) {
    /**
     * A guild or a user owns this template, and is a private version
     */
    AutoModTemplateType[AutoModTemplateType["PRIVATE"] = 0] = "PRIVATE";
    /**
     * A guild or user owns this template, and it is a published copy of a private version (unless the private one was deleted)
     */
    AutoModTemplateType[AutoModTemplateType["PUBLIC"] = 1] = "PUBLIC";
})(AutoModTemplateType || (AutoModTemplateType = {}));
export var AutoModConfigBooleans;
(function (AutoModConfigBooleans) {
    /**
     * Auto Moderation tracking is enabled
     */
    AutoModConfigBooleans[AutoModConfigBooleans["enabled"] = 1] = "enabled";
    /**
     * Archivian will send alerts to a channel
     */
    AutoModConfigBooleans[AutoModConfigBooleans["alert"] = 2] = "alert";
    /**
     * Show recent Auto Mod violations in the alert
     */
    AutoModConfigBooleans[AutoModConfigBooleans["recentAutoModViolations"] = 4] = "recentAutoModViolations";
    /**
     * Show recent cases by the user in the alert
     */
    AutoModConfigBooleans[AutoModConfigBooleans["recentCases"] = 8] = "recentCases";
})(AutoModConfigBooleans || (AutoModConfigBooleans = {}));
export var AutoModAuditAlertBooleans;
(function (AutoModAuditAlertBooleans) {
    /**
     * A server owned template was created
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["CREATED"] = 1] = "CREATED";
    /**
     * A server owned template was deleted
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["DELETED"] = 2] = "DELETED";
    /**
     * A server owned template’s triggers or metadata was edited
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["EDITED"] = 4] = "EDITED";
    /**
     * A server template was made public, so that anyone can copy and subscribe to it
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["PUBLISHED"] = 8] = "PUBLISHED";
    /**
     * A server owned template was unpublished. It will remain public anonymously if anyone subscribed to it
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["UNPUBLISHED"] = 16] = "UNPUBLISHED";
    /**
     * A server- or staff-owned template was put into use in Discord
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["MOUNTED"] = 32] = "MOUNTED";
    /**
     * A server- or staff-owned template was removed from use in Discord
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["UNMOUNTED"] = 64] = "UNMOUNTED";
    /**
     * A public template was subscribed to and put into use in Discord
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["SUBSCRIBED"] = 128] = "SUBSCRIBED";
    /**
     * A public template was unsubscribed from, and the Discord rule was removed
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["UNSUBSCRIBED"] = 256] = "UNSUBSCRIBED";
    /**
     * A public template subscription was changed, to or from automatic updates to manual updates
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["SUBSCRIPTION_CHANGED"] = 512] = "SUBSCRIPTION_CHANGED";
    /**
     * A subscribed template (public/user/server owned) was successfully updated, and applied to your Discord server
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["SUBSCRIPTION_UPGRADED"] = 1024] = "SUBSCRIPTION_UPGRADED";
    /**
     * A subscribed template (public/user/server owned) failed to be auto-updated
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["SUBSCRIPTION_UPGRADE_FAILED"] = 2048] = "SUBSCRIPTION_UPGRADE_FAILED";
    /**
     * A subscribed template (public/user/server owned) was manually updated, and the changes applied to your Discord server
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["SUBSCRIPTION_UPGRADED_MANUAL"] = 4096] = "SUBSCRIPTION_UPGRADED_MANUAL";
    /**
     * One of the server's subscribed templates has an update available
     */
    AutoModAuditAlertBooleans[AutoModAuditAlertBooleans["SUBSCRIPTION_UPGRADE_AVAILABLE"] = 8192] = "SUBSCRIPTION_UPGRADE_AVAILABLE";
})(AutoModAuditAlertBooleans || (AutoModAuditAlertBooleans = {}));
/**
 * The subscription mode for Discord auto-mod templates
 */
export var AutoModSubscriptionMode;
(function (AutoModSubscriptionMode) {
    /**
     * Manual updating required
     */
    AutoModSubscriptionMode[AutoModSubscriptionMode["MANUAL"] = 0] = "MANUAL";
    /**
     * Changes are applied automatically
     */
    AutoModSubscriptionMode[AutoModSubscriptionMode["AUTOMATIC"] = 1] = "AUTOMATIC";
})(AutoModSubscriptionMode || (AutoModSubscriptionMode = {}));
export const links = {
    /**
     * Generates a full link to a public template, no log-in needed.
     */
    publicTemplate: (templateId) => {
        return `${process.env.DASHBOARD_BASE_URL}/auto-mod-rules/${templateId}`;
    },
    /**
     * The main dashboard (tab) for managing the guild's Auto Mod templates.
     */
    templateManagement: (guildId) => {
        return `${process.env.DASHBOARD_BASE_URL}/guilds/${guildId}/auto-mod/templates`;
    }
};
/**
 * For our own bad link filtering
 */
export var BadLinkType;
(function (BadLinkType) {
    BadLinkType["PHISHING"] = "phishing";
    BadLinkType["MALWARE"] = "malware";
    BadLinkType["ADULT"] = "adult";
    BadLinkType["UNKNOWN"] = "unknown";
    BadLinkType["INVITE_BYPASS"] = "discordInviteBypass";
})(BadLinkType || (BadLinkType = {}));

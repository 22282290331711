<template>
	<g>
		<path
			d="M8.294 10.7046C8.294 10.5416 8.162 10.4106 8 10.4106C7.837 10.4116 7.706 10.5436 7.706 10.7056C7.706 10.8686 7.838 10.9996 8 10.9996C8.162 10.9996 8.294 10.8676 8.294 10.7046"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M8 10.411V5.5C8 4.119 9.119 3 10.5 3V3C11.881 3 13 4.119 13 5.5V6.072"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M10.1621 15.1833C10.9691 15.7413 11.9451 16.0723 13.0001 16.0723C13.6621 16.0723 14.2901 15.9373 14.8681 15.7033L17.8771 18.7083C18.0651 18.8953 18.3191 19.0003 18.5831 19.0003H20.0001C20.5521 19.0003 21.0001 18.5523 21.0001 18.0003V16.5883C21.0001 16.3223 20.8941 16.0683 20.7061 15.8803L17.6621 12.8403C17.8721 12.2893 18.0001 11.6973 18.0001 11.0723C18.0001 8.31127 15.7611 6.07227 13.0001 6.07227C12.2161 6.07227 11.4841 6.26827 10.8241 6.59027"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.00375 15.2671C5.44675 15.0241 4.92075 14.6851 4.46475 14.2291C2.51175 12.2761 2.51175 9.11111 4.46475 7.15811C6.41775 5.20511 9.58275 5.20511 11.5358 7.15811C13.4888 9.11111 13.4888 12.2761 11.5358 14.2291C11.0818 14.6831 10.5577 15.0201 10.0037 15.2631L10.0068 19.5001C10.0068 19.7661 9.90175 20.0201 9.71375 20.2081L8.71575 21.2061C8.32475 21.5971 7.69175 21.5971 7.30175 21.2061L6.29975 20.2041C6.11275 20.0171 6.00675 19.7631 6.00675 19.4981L6.00375 15.2671Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconKeys"
};
</script>
<script setup>
import {UI} from "@/storage/UICache";

const ui = UI();
</script>

<template>
	<!-- Tab index 1 to always select this first -->
	<dialog
		id="skip-menu"
		open
		tabindex="1"
		aria-label="Skip menu"
		class="z-50"
		@focus="ui.maintainSkipMenuAnchors"
	>
		<p class="text-center font-bold text-xl">
			Skip Menu
		</p>
		<nav aria-label="Skip Menu">
			<button
				v-if="ui.skipAnchors.has('main')"
				role="menuitem"
				aria-label="Main content"
				@focus="ui.maintainSkipMenuAnchors"
				@click="ui.focusAnchor('main')"
			>
				Skip to Main Content
			</button>

			<button
				v-if="ui.skipAnchors.has('mainList')"
				role="menuitem"
				aria-label="Main item list"
				@focus="ui.maintainSkipMenuAnchors"
				@click="ui.focusAnchor('mainList')"
			>
				Skip to Main Item List
			</button>

			<button
				v-if="ui.skipAnchors.has('mainSecondary')"
				role="menuitem"
				aria-label="Main secondary panel"
				@focus="ui.maintainSkipMenuAnchors"
				@click="ui.focusAnchor('mainSecondary')"
			>
				Skip to Main's secondary panel
			</button>

			<button
				role="menuitem"
				aria-label="Account and server switcher"
				@click="ui.focusAnchor('serverSwitcher')"
				@focus="ui.maintainSkipMenuAnchors"
			>
				Skip to Server Switcher
			</button>
		</nav>
	</dialog>
</template>

<style scoped>
#skip-menu {
	@apply p-4 ring-2 ring-sky-400 focus-visible:ring-2 mt-2 opacity-0
	-top-1/2;
}

#skip-menu:focus-within {
	@apply top-0 opacity-100;
}

nav {
	@apply flex flex-col gap-1;
}

nav > * {
	@apply p-2 focus-visible:ring-2 focus-visible:ring-sky-400 outline-none rounded-md;
}
</style>
<script setup>
import TabbedListInspect from "@/partials/layouts/TabbedListInspect.vue";
import {computed, onBeforeUnmount, onMounted, provide, ref} from "vue";
import {handleErr} from "@/script/convert";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import ErrorCallout from "@/components/info/callouts/ErrorCallout.vue";
import GuildCasesInspectEmpty from "@/views/guild/case-system/cases/GuildCasesInspectEmpty.vue";
import GuildInspectCaseView from "@/views/guild/case-system/cases/GuildInspectCaseView.vue";
import backend from "@/api/backend";
import {Account} from "@/storage/AccountCache";
import GuildCaseInspectEntry from "@/views/guild/case-system/cases/aside/GuildCaseInspectEntry.vue";
import GuildCaseNewEntry from "@/views/guild/case-system/cases/aside/GuildCaseNewEntry.vue";
import GuildLinkedUser from "@/views/guild/case-system/cases/aside/GuildLinkedUser.vue";
import GuildLinkedCase from "@/views/guild/case-system/cases/aside/GuildLinkedCase.vue";
import {UI} from "@/storage/UICache";
import {hook, unhook, GUILD_OPS} from "@/ws/utils";
import GuildCaseFile from "@/views/guild/case-system/cases/aside/GuildCaseFile.vue";
import IndexedDBStore from "@/storage/IndexedDB";
import FancyLoader from "@/components/misc/FancyLoader.vue";
import { CaseFileMasks } from "@archivian/constants";
import CaseService from "@/services/CaseService";
import CaseList from "@/partials/inspect/CaseList.vue";
import CaseAppealBrowser from "@/storage/drafts/CaseAppealBrowser";

defineProps({
	"caseSid": {
		type: [Number, String],
		default: () => 0,
	},
	"resource": {
		type: String,
		default: () => null,
	},
	"resourceId": {
		type: [String, Number],
		default: () => null,
	},
});

const cases = CaseAppealBrowser();
const idbStore = IndexedDBStore();
const ui = UI();
const account = Account();
const route = useRoute();
const router = useRouter();
const panels = computed(() => {
	return {
		none: !route.params.caseSid, // no resource is inferred ofc
		appealOnly: !!route.params.caseSid && !route.params.resource,
		both: !!route.params.caseSid && !!route.params.resource
	}
});
const initialized = ref(false);
const guild = ref(account.getGuild(route.params.guildId));
const currentCase = ref(null);
const error = ref({
	label: "",
	texts: [],
});

/**
 * For the "New" loader, convert the ID in to a primitive constant instead of reading from
 * the currentCase or any other form of object property.
 * There might be race conditions where that property value could change in the meantime.
 */
const handlers = {
	close: () => {
		router.push({name: route.name, params: {guildId: route.params.guildId}});
		currentCase.value = null;
	},
	closeResource: () => {
		router.push({name: route.name, params: {guildId: route.params.guildId, caseSid: route.params.caseSid}});
	},
	/**
	 * Loads more details about a given case, such as appeals it might have.
	 * Result will override the current case cache automatically.
	 * @param {number} caseSid
	 * @returns {Promise<void>}
	 */
	async loadCaseFull(caseSid) {
		const result = await CaseService.fetchGuildCase(route.params.guildId, caseSid);
		await idbStore.setUsers(result.users);
		currentCase.value = result.case;
		cases.currentItem = result.case;
	},
	// These perform API actions:
	handleArchive: async ({reason}) => {
		// New
		const sid = currentCase.value.sid;
		cases.addLoading("archiveCase", sid)

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}`, "DELETE", {
			query: {
				reason: reason,
			},
		});
		// New
		cases.removeLoading("archiveCase", sid)
	},
	handleDelete: async ({reason}) => {
		// New
		const sid = currentCase.value.sid;
		cases.addLoading("deleteCase", sid);

		await backend(`/public/guilds/${guild.value.id}/cases/${sid}/delete`, "DELETE", {
			query: {
				reason: reason,
			},
		});

		// New
		cases.removeLoading("deleteCase", sid);

		// Redirect away
		if (sid === currentCase.value.sid) {
			await router.push({name: route.name, params: {guildId: route.params.guildId}});
		}
	},
	handleRestore: async ({reason}) => {
		// New
		const sid = currentCase.value.sid;
		cases.addLoading("restoreCase", sid);

		await backend(`/public/guilds/${guild.value.id}/cases/${sid}/restore`, "PUT", {
			body: {
				reason: reason,
			},
		});

		// New
		cases.removeLoading("restoreCase", sid);
	},
	updateVisibility: async (isVisible) => {
		// New
		const sid = currentCase.value.sid;
		cases.addLoading("showToUser", sid);

		await backend(`/public/guilds/${guild.value.id}/cases/${sid}`, "PATCH", {
			body: {
				visibility: isVisible,
			},
		});

		// New
		cases.removeLoading("showToUser", sid);
	},
	updateAppealable: async (isAppealable) => {
		// New
		const sid = currentCase.value.sid;
		cases.addLoading("allowAppeals", sid);

		await backend(`/public/guilds/${guild.value.id}/cases/${sid}`, "PATCH", {
			body: {
				appeals: isAppealable,
			},
		});

		// New
		cases.removeLoading("allowAppeals", sid);
	},
	handleArchiveEntry: async ({reason}) => {
		// New
		const entrySid = parseInt(route.params.resourceId);
		cases.addLoading("entryArchive", entrySid);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/entries/${entrySid}`, "DELETE", {
			query: {
				reason: reason,
			},
		});

		// New
		cases.removeLoading("entryArchive", entrySid);
	},
	handleDeleteEntry: async ({reason}) => {
		// New
		const entrySid = parseInt(route.params.resourceId);
		cases.addLoading("entryDelete", entrySid);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/entries/${entrySid}/delete`, "DELETE", {
			query: {
				reason: reason,
			},
		});

		// New
		cases.removeLoading("entryDelete", entrySid);

		if (entrySid.toString() === route.params.resourceId.toString()) {
			await router.push({
				name: route.name,
				params: {
					guildId: route.params.guildId,
					caseSid: route.params.caseSid,
				},
			});
		}
	},
	handleRestoreEntry: async ({reason}) => {
		// New
		const entrySid = parseInt(route.params.resourceId);
		cases.addLoading("entryRestore", entrySid);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/entries/${entrySid}/restore`, "PUT", {
			body: {
				reason: reason,
			},
		});

		// New
		cases.removeLoading("entryRestore", entrySid);
	},
	editEntry: async ({content, visible}) => {
		// New
		const entrySid = parseInt(route.params.resourceId);
		cases.addLoading("entryEdit", entrySid);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/entries/${entrySid}`, "PATCH", {
			body: {
				content: content,
				visibility: visible,
			},
		});
		cases.editing.entry = false;

		// New
		cases.removeLoading("entryEdit", entrySid);
	},
	createEntry: async ({content, visible}) => {
		// New
		const caseSid = cases.currentId;
		cases.addLoading("entryAdd", caseSid);

		const {
			status,
			body
		} = await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/entries`, "POST", {
			body: {
				content: content,
				visibility: visible,
			},
		});
		if (status === 200) {
			if (route.params.caseSid === caseSid.toString()) {
				// We need the body of the entry here, if we're still inspecting this case
				currentCase.value.entries.push(body.entry);
			}
		}

		// New
		cases.removeLoading("entryAdd", caseSid);

		if (route.params.resource === "entries" && route.params.resourceId === "new") {
			await router.push({
				name: route.name,
				params: {
					guildId: route.params.guildId,
					caseSid: route.params.caseSid,
					resource: "entries",
					resourceId: body.entry.sid,
				}
			});
		}
	},
	createUserLink: async ({userId, label}) => {
		// New
		cases.addLoading("addLinked", userId);
		const caseSid = route.params.caseSid;

		const {status} = await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/linked/users`, "POST", {
			body: {
				userId: userId,
				label: label,
			},
		});

		// New
		cases.removeLoading("addLinked", userId);

		if (status === 200 && caseSid === route.params.caseSid) {
			currentCase.value.linked.users.push({
				label,
				userId,
				by: account.id,
				createdAt: new Date(),
			});

			await router.push({
				name: route.name,
				params: {
					guildId: route.params.guildId,
					caseSid: route.params.caseSid,
					resource: "linked-users",
					resourceId: userId,
				},
			});
		}
	},
	renameLinkedUser: async ({userId, label}) => {
		// New
		cases.addLoading("renameLinked", userId);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/linked/users/${userId}`, "PATCH", {
			body: {
				label: label,
			},
		});
		cases.editing.linkedUser = false;

		// New
		cases.removeLoading("renameLinked", userId);
	},
	deleteLinkedUser: async ({userId, reason}) => {
		// New
		cases.addLoading("deleteLinked", userId);
		const caseSid = route.params.caseSid;

		const {status} = await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/linked/users/${userId}`, "DELETE", {
			query: {
				reason: reason,
			},
		});

		if (status.toString().startsWith("2") && caseSid === route.params.caseSid && route.params.resourceId === userId) {
			await router.push({
				name: route.name,
				params: {
					guildId: route.params.guildId,
					caseSid: route.params.caseSid,
				},
			});
		}

		// New
		cases.removeLoading("deleteLinked", userId);
	},
	createCaseLink: async ({caseSid, label}) => {
		// New
		cases.addLoading("addLinked", caseSid);
		const inspectedCaseSid = route.params.caseSid;

		const {status} = await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/linked/cases`, "POST", {
			body: {
				sid: caseSid,
				label: label,
			},
		});

		// New
		cases.removeLoading("addLinked", caseSid);

		if (status === 200 && inspectedCaseSid === route.params.caseSid) {
			currentCase.value.linked.cases.push({
				label,
				caseSid,
				by: account.id,
				createdAt: new Date(),
			});

			await router.push({
				name: route.name,
				params: {
					guildId: route.params.guildId,
					caseSid: route.params.caseSid,
					resource: "linked-cases",
					resourceId: caseSid,
				},
			});
		}
	},
	renameLinkedCase: async ({sid, label}) => {
		// New
		cases.addLoading("renameLinked", sid);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/linked/cases/${sid}`, "PATCH", {
			body: {
				label: label,
			},
		});
		cases.editing.linkedCase = false;

		// New
		cases.removeLoading("renameLinked", sid);
	},
	deleteLinkedCase: async ({sid, reason}) => {
		// New
		cases.addLoading("deleteLinked", sid);

		const {status} = await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/linked/cases/${sid}`, "DELETE", {
			query: {
				reason: reason,
			},
		});

		if (status.toString().startsWith("2") && route.params.resourceId === sid.toString() && route.params.resource === "linked-cases") {
			await router.push({
				name: route.name,
				params: {
					guildId: route.params.guildId,
					caseSid: route.params.caseSid,
				},
			});
		}

		// New
		cases.removeLoading("deleteLinked", sid);
	},
	updateFileVisibility: async (visible) => {
		// New
		const fileId = route.params.resourceId;
		cases.addLoading("fileVisibility", fileId);

		const {status} = await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/files/${fileId}`, "PATCH", {
			body: {
				visibility: visible,
			},
		});
		// Since fetching all cases is a bit slow, let's also update local cache (it'll be overwritten shortly after)
		const file = currentCase.value.files.find(file => file.fileId === fileId);
		if (file && status===200) {
			file.visibilityFlags = visible
				? file.visibilityFlags |= CaseFileMasks.CaseFileVisibilityMask.visibleToTarget
				: file.visibilityFlags &= ~CaseFileMasks.CaseFileVisibilityMask.visibleToTarget;
		}

		// New
		cases.removeLoading("fileVisibility", fileId);
	},
	archiveFile: async ({id}) => {
		// New
		cases.addLoading("fileArchive", id);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/files/${id}`, "DELETE", {
			query: {
				reason: "Unknown reason",
			},
		});

		// New
		cases.removeLoading("fileArchive", id);
	},
	restoreFile: async ({id}) => {
		// New
		cases.addLoading("fileRestore", id);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/files/${id}/restore`, "PUT", {
			query: {
				reason: "Unknown reason",
			},
		});

		// New
		cases.removeLoading("fileRestore", id);
	},
	deleteFile: async ({id}) => {
		// New
		cases.addLoading("fileDelete", id);

		await backend(`/public/guilds/${guild.value.id}/cases/${currentCase.value.sid}/files/${id}/delete`, "DELETE", {
			query: {
				reason: "Unknown reason",
			},
		});

		// New
		cases.removeLoading("fileDelete", id);

		if (id.toString() !== route.params.resourceId.toString()) {
			await router.push({
				name: route.name,
				params: {
					guildId: route.params.guildId,
					caseSid: route.params.caseSid,
				},
			});
		}
	},
	/**
	 * A case by SID was updated.
	 * @param {{sid: number}} event
	 * @returns {Promise<void>}
	 */
	handleCaseUpdated: async (event) => {
		console.log(event);
		// If the SID is not the one we're inspecting, ignore it
		if (currentCase?.value?.sid !== event.sid) return;
		await handlers.loadCaseFull(event.sid);
	},
	/**
	 * Handles a case being deleted
	 * @param {number} caseSid
	 * @returns {Promise<void>}
	 */
	handleCaseDeleted: async ({ caseSid }) => {
		// If the SID is not the one we're inspecting, ignore it
		if (currentCase?.value?.sid !== caseSid) return;

		currentCase.value = null;
		await router.push({name: route.name, params: {guildId: route.params.guildId}});
	},
};

const inspectedResource = computed(() => {
	if (!route.params?.resource || !route.params?.resourceId) return null;

	switch (route.params.resource) {
		case "entries":
			return currentCase.value?.entries.find(entry => entry.sid === parseInt(route.params.resourceId)) ?? null;
		case "files":
			return currentCase.value?.files.find(file => file.fileId === route.params.resourceId) ?? null;
		default:
			return null;
	}
});
const caseIsArchived = computed(() => {
	if (!currentCase.value) return false;
	return !!currentCase.value.archived.at;
});

const currentId = computed(() => {
	return currentCase.value?._id ?? null;
});
provide("currentId", currentId);
provide("loading", cases.loading);
provide("isArchived", caseIsArchived);
provide("isPersonal", false);
provide("handlers", handlers);

onMounted(async () => {
	cases.itemIdKey = "sid";

	let currentItemSid = route.params.caseSid
		? parseInt(route.params.caseSid)
		: null;
	if (Number.isNaN(currentItemSid)) currentItemSid = null;

	try {
		if (currentItemSid!==null) await handlers.loadCaseFull(currentItemSid);
	} catch(e) {
		error.value = handleErr(e, "Could not load case");
		await router.replace({
			name: route.name,
			params: {
				guildId: route.params.guildId,
			},
		});
	}

	hook([
		GUILD_OPS.CASE_DELETED,
	], handlers.handleCaseDeleted);

	hook([
		GUILD_OPS.CASE_UPDATE,
	], handlers.handleCaseUpdated);

	initialized.value = true;
});
onBeforeRouteUpdate(async (to, from, next) => {
	if (to.name !== route.name) return next();

	if (to.params.caseSid && to.params.caseSid !== from.params.caseSid) {
		await handlers.loadCaseFull(parseInt(to.params.caseSid));

		// Scroll to the top
		const main = document.getElementById("main");
		if (main) main.scrollIntoView({
			behavior: "smooth",
			block: "start",
		})
	} else if (!to.params.caseSid) {
		currentCase.value = null;
	}

	return next();
});
onBeforeUnmount(() => {
	unhook([
		GUILD_OPS.CASE_DELETED,
	], handlers.handleCaseDeleted);

	unhook([
		GUILD_OPS.CASE_UPDATE,
	], handlers.handleCaseUpdated);

	cases.$reset();
});
</script>

<template>
	<TabbedListInspect
		class="self-stretch min-h-0 min-w-0 flex"
		:divider-classes="{
			'hidden xl:block': panels.appealOnly,
			'hidden 2xl:block': panels.both,
		}"
	>
		<template #list>
			<div v-if="!initialized" class="flex justify-center items-center h-full">
				<FancyLoader label="Fetching latest cases&hellip;" />
			</div>
			<CaseList
				v-if="initialized && !error.label"
				:id="ui.setAnchor('mainList')"
				tabindex="-1"
				class="overflow-y-auto w-xs flex-col"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.appealOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both
				}"
			 	:current-case="currentCase"
			/>
			<ErrorCallout
				v-else-if="initialized && error.label"
				:label="error.label"
				:texts="error.texts"
				:class="{
					// Needs to be xl to show if we have appeal
					'hidden xl:flex': panels.appealOnly,
					// Needs to be 2xl to show if we have appeal + resource
					'hidden 2xl:flex': panels.both
				}"
			/>
		</template>

		<template #default>
			<div class="overflow-y-auto max-h-full h-full">
				<!-- TODO We should get to know somehow if there are cases the user can view, for the has-cases -->
				<GuildCasesInspectEmpty
					v-if="!currentCase"
					:has-cases="true"
				/>
				<div
					v-else
					class="p-4"
					:class="{'grid lg:grid-cols-2 gap-4': panels.both}"
				>
					<!-- Main inspection window -->
					<GuildInspectCaseView
						:id="ui.setAnchor('main')"
						class="border border-neutral-700"
						:the-case="currentCase"
						:appeals="currentCase.appeals"
						:handlers="handlers"
						:guild="guild"
						:class="{'hidden lg:block': panels.both}"
						tabindex="-1"
					/>

					<template v-if="panels.both">
						<!-- Other side-panels (resources) -->
						<GuildCaseInspectEntry
							v-if="route.params?.resource==='entries' && route.params?.resourceId !== 'new'"
							:id="ui.setAnchor('mainSecondary')"
							class="border border-neutral-700"
							:entry="inspectedResource"
							:the-case="currentCase"
							:handlers="handlers"
							:close="handlers.closeResource"
							tabindex="-1"
						/>
						<GuildCaseNewEntry
							v-else-if="route.params?.resource==='entries' && route.params?.resourceId === 'new'"
							:id="ui.setAnchor('mainSecondary')"
							class="border border-neutral-700"
							:handlers="handlers"
							:case-type="currentCase.caseType"
							:creator-id="currentCase.creatorId"
							tabindex="-1"
						/>
						<GuildLinkedUser
							v-else-if="route.params?.resource==='linked-users'"
							:id="ui.setAnchor('mainSecondary')"
							class="border border-neutral-700"
							:the-case="currentCase"
							:resource-id="route.params?.resourceId"
							:handlers="handlers"
							tabindex="-1"
						/>
						<GuildLinkedCase
							v-else-if="route.params?.resource==='linked-cases'"
							:id="ui.setAnchor('mainSecondary')"
							class="border border-neutral-700"
							:the-case="currentCase"
							:resource-id="route.params?.resourceId"
							:handlers="handlers"
							tabindex="-1"
						/>
						<GuildCaseFile
							v-else-if="route.params?.resource==='files'"
							:id="ui.setAnchor('mainSecondary')"
							class="border border-neutral-700"
							tabindex="-1"
							:the-case="currentCase"
							:resource-id="route.params?.resourceId"
						/>
					</template>
				</div>
			</div>
		</template>
	</TabbedListInspect>
</template>

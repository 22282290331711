<template>
	<g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M5.99752 3.49625C5.99752 5.70631 4.20591 7.49792 1.99585 7.49792C4.20591 7.49792 5.99752 9.28952 5.99752 11.4996C5.99752 9.28952 7.78912 7.49792 9.99918 7.49792C7.78912 7.49792 5.99752 5.70631 5.99752 3.49625Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M17.0021 15.5012C17.0021 12.7387 14.7626 10.4992 12 10.4992C14.7626 10.4992 17.0021 8.25966 17.0021 5.49708C17.0021 8.25966 19.2416 10.4992 22.0042 10.4992C19.2416 10.4992 17.0021 12.7387 17.0021 15.5012Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.0022 15.5013V16.5017"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M17.0022 5.49708V4.49667"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.99923 21.5038V22.5042"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M9.99923 14.5008V13.5004"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.99752 11.4996V12.5"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M5.99752 2.49583V3.49625"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M9.99923 14.0006C9.99923 16.2107 8.20762 18.0023 5.99756 18.0023C8.20762 18.0023 9.99923 19.7939 9.99923 22.004C9.99923 19.7939 11.7908 18.0023 14.0009 18.0023C11.7908 18.0023 9.99923 16.2107 9.99923 14.0006Z"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconSparkle"
};
</script>
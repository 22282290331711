<template>
	<g>
		<path
			d="M17.7524 4.99708H20.2534"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.0029 6.2476V3.74656"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M19.0029 8.99875C16.793 8.99875 15.0013 7.207 15.0013 4.99708C15.0013 2.78716 16.793 0.995415 19.0029 0.995415C21.2128 0.995415 23.0046 2.78716 23.0046 4.99708C23.0046 7.207 21.2128 8.99875 19.0029 8.99875"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M11.4998 9.02376C12.7273 9.02376 13.7227 10.0192 13.7227 11.2467C13.7227 12.4742 12.7273 13.4696 11.4998 13.4696C10.2723 13.4696 9.27687 12.4742 9.27687 11.2467C9.27687 10.0192 10.2723 9.02376 11.4998 9.02376"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.5015 17.0271C15.3854 16.734 15.2043 16.4719 14.9732 16.2578V16.2578C14.5821 15.8956 14.0719 15.6935 13.5386 15.6935C12.6493 15.6935 10.3513 15.6935 9.46195 15.6935C8.92873 15.6935 8.41751 15.8956 8.02635 16.2578V16.2578C7.79525 16.4719 7.61518 16.735 7.49813 17.0271"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
		<path
			d="M15.0073 4.76198C13.9368 4.27578 12.7523 3.99667 11.4998 3.99667C6.80384 3.99667 2.99625 7.80425 2.99625 12.5002C2.99625 17.1962 6.80384 21.0037 11.4998 21.0037C16.1958 21.0037 20.0033 17.1962 20.0033 12.5002C20.0033 11.2477 19.7242 10.0632 19.238 8.99275"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</g>
</template>

<script>
export default {
	name: "IconUserAddCircle"
};
</script>
/**
 * Permission-sets related to guilds
 */
export const SET = {
    /**
     * Permission to anything related to permission sets
     */
    ANY: () => {
        return "permissions.sets.**";
    },
    /**
     * Ability to create, edit, and delete permission sets
     * <h1>WARNING</h1>
     * In the current state of the Archivian, this will also give the user
     * full access to assign any permissions to any permission set, include this one.
     * They can also delete their own permission set!
     *
     * It is essentially almost the same as the ANY permission.
     * The goal is that this might get changed later at some point.
     */
    ADMINISTRATE: () => {
        return "permissions.sets.admin";
    },
};
/**
 * Permission sets related to the global system
 */
export const GLOBAL_SET = {
    /**
     * Permission to anything related to permission sets globally
     */
    ANY: () => {
        return "internal.permissions.sets.**";
    },
    /**
     * Ability to create, edit, and delete permission sets globally
     */
    ADMINISTRATE: () => {
        return "internal.permissions.sets.admin";
    },
};

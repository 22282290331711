<template>
	<div class="px-3 pt-2 pb-3 bg-neutral-800/50 backdrop-blur border border-neutral-700 border-t-neutral-600 text-gray-300 rounded-md whitespace-nowrap drop-shadow-md">
		{{ p.tooltip }}
	</div>
</template>

<script setup>
const p = defineProps({
	"tooltip": {
		type: String,
		required: true,
	}
});
</script>

<style scoped>

</style>